<template>
  <div>
    <composition-input
      :title="variable.title"
      :text="variable.name"
      :info="variable.info"
      :type="variable.variableType"
      :options="options"
      :default-value="variable.defaultValue"
      :is-nested="variable.isNested"
      :is-smart-field="variable.isSmartField"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
    />
    <div class="button" :class="{ 'button--nested': variable.isNested }" v-if="variable.isDuplicable" @click="duplicate">
      <v-icon>add</v-icon>
      Dodaj
    </div>
    <div class="button" :class="{ 'button--nested': variable.isNested }" v-if="variable.isRemovable" @click="remove">
      <v-icon>remove</v-icon>
      Odstrani
    </div>
  </div>
</template>

<script>
import Input from './Input'
import VIcon from 'design-system/VIcon.vue'

export default {
  components: {
    CompositionInput: Input,
    VIcon,
  },
  props: {
    variable: { type: Object, required: true },
  },
  computed: {
    options () {
      if (!this.variable.variableOptions) {
        return []
      }

      return this.variable.variableOptions.map(option => {
        return {
          label: option.value,
          ...option,
        }
      })
    },
  },
  methods: {
    duplicate () {
      this.$emit('duplicate', this.variable.textSegmentId)
    },
    remove () {
      this.$emit('remove', this.variable.textSegmentId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.button {
  width: fit-content;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  background-color: $secondary_color;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 600;

  &--nested {
    position: relative;
    top: -12px;
    margin-left: 16px;
    margin-bottom: 8px;
  }

  .md-icon.md-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    font-size: 16px !important;
    color: white;
    margin: 0 4px 2px 0;
  }
}
</style>
