export function getNearestMultiSelectConditionWithOutcomeIndexForTextSegment (textSegment, conditions) {
  const condition = getSegmentCondition(textSegment)
  if (!condition) {
    return {
      multiSelectCondition: null,
      multiSelectConditionOutcomeIndex: -1,
    }
  }

  return getNearestMultiSelectConditionWithOutcomeIndexForCondition(condition, conditions, textSegment.conditionOutcomeId)

  function getSegmentCondition (textSegment) {
    if (!textSegment.conditionOutcomeId) {
      return null
    }

    return findConditionByOutcomeId(textSegment.conditionOutcomeId, conditions)
  }
}

export function getNearestMultiSelectConditionWithOutcomeIndexForCondition (condition, conditions, conditionOutcomeId = null) {
  if (condition.isMultiSelect) {
    return {
      multiSelectCondition: condition,
      multiSelectConditionOutcomeIndex: getMultiSelectConditionOutcomeIndex(condition.conditionOutcomes, conditionOutcomeId),
    }
  }

  conditionOutcomeId = condition.conditionOutcomeId
  while (condition.conditionOutcomeId) {
    condition = findConditionByOutcomeId(condition.conditionOutcomeId, conditions)
    if (condition && condition.isMultiSelect) {
      return {
        multiSelectCondition: condition,
        multiSelectConditionOutcomeIndex: getMultiSelectConditionOutcomeIndex(condition.conditionOutcomes, conditionOutcomeId),
      }
    }
    conditionOutcomeId = condition.conditionOutcomeId
  }

  return {
    multiSelectCondition: null,
    multiSelectConditionOutcomeIndex: -1,
  }

  function getMultiSelectConditionOutcomeIndex (conditionOutcomes, conditionOutcomeId) {
    return conditionOutcomeId ? conditionOutcomes.findIndex(outcome => outcome.id === conditionOutcomeId) : 0
  }
}

export function getGlobalOrder (configurationObject, multiSelectCondition, multiSelectConditionOutcomeIndex) {
  const globalOrder = configurationObject.globalOrder || configurationObject.order
  if (!multiSelectCondition) {
    return globalOrder
  }

  // order is defined by order of multi select, index of the outcome the object belongs to, and the diluted order of the object itself
  return multiSelectCondition.order + ((multiSelectConditionOutcomeIndex + 1) / 100) + (globalOrder / 1000000)
}

function findConditionByOutcomeId (conditionOutcomeId, conditions) {
  return conditions.find(condition => {
    const outcome = condition.conditionOutcomes.find(outcome => outcome.id === conditionOutcomeId)
    return outcome != null
  })
}
