import axios from 'axios'
import { getResponseErrorMessage } from '@/store/utils'
import formsModule from './forms'
import { paginationState, paginationMutations } from '@/store/mixins/pagination'

const state = {
  blogPosts: [],
  filters: [],
  documentNames: [],
  ...paginationState,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  modules: {
    forms: formsModule,
  },
  state: getState(),
  getters: {
    blogPostsUrl (state) {
      let blogPostsUrl = `/blog_posts?page=${state.page}&limit=${state.perPage}`

      if (state.query) {
        blogPostsUrl += `&by_query=${encodeURIComponent(state.query)}`
      }

      return blogPostsUrl
    },
  },
  actions: {
    initialize ({ dispatch }) {
      return Promise.all([
        dispatch('fetchBlogPosts'),
        dispatch('fetchDocumentNames'),
      ])
    },
    setFilters ({ commit, dispatch }, filters) {
      commit('SET_FILTERS', filters)
      return dispatch('fetchBlogPosts')
    },
    fetchBlogPosts ({ commit, getters, dispatch }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .get(getters.blogPostsUrl)
        .then((response) => {
          commit('SET_BLOG_POSTS', response.data)
          commit('SET_NUMBER_OF_PAGES', parseInt(response.headers['total-pages']))
          commit('SET_TOTAL_RESULTS', parseInt(response.headers['total-count']))
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju blogov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchDocumentNames ({ commit }) {
      return axios
        .get('/documents')
        .then(response => commit('SET_DOCUMENT_NAMES', response.data))
    },
    setQuery ({ commit, dispatch }, query) {
      commit('SET_QUERY', query)
      return dispatch('fetchBlogPosts')
    },
    goToPage ({ commit, dispatch, state }, page) {
      if (page > 0 && page <= state.numberOfPages) {
        commit('SET_PAGE', page)
        return dispatch('fetchBlogPosts')
      }
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_BLOG_POSTS (state, blogPosts) {
      state.blogPosts = blogPosts
    },
    SET_FILTERS (state, filters) {
      state.page = 1
      state.filters = filters
    },
    SET_DOCUMENT_NAMES (state, documents) {
      if (Array.isArray(documents) && documents.length > 0) {
        state.documentNames = documents.map(d => d.name)
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
    ...paginationMutations,
  },
}
