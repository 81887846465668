<template>
  <div class="document-composition__scrollable document-composition__preview" ref="scrollable">
    <div class="document-composition__preview-content" v-html="interpolatedDocumentHtml" ref="previewContent" @mousedown.stop="onPreviewClick" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('pages/documentComposition', ['interpolatedDocumentHtmlFn']),
    interpolatedDocumentHtml () {
      return this.interpolatedDocumentHtmlFn(false)
    },
  },
  methods: {
    scrollTo (elementId) {
      // wait for the element to be added to the DOM
      this.$nextTick(() => {
        setTimeout(() => {
          const element = document.getElementById(elementId)
          if (element) {
            this.$refs.scrollable.scrollTo({
              top: element.offsetTop - 90,
              left: 0,
              behavior: 'smooth',
            })
          }
        }, 250)
      })
    },
    onPreviewClick (ev) {
      ev.preventDefault()
    },
  },
}
</script>

<style lang="scss" scoped>
.document-composition__preview-content {
  padding: 16px 0;
}
</style>

<style lang="scss">
@import './scrollable';

.document-composition__preview {
  user-select: none;
  border-left: 2px solid #E2E3E4;
  line-height: 24px;
}

// override the default font-style: italic for em
.document-composition__preview-content em {
    font-style: initial;
}
</style>
