<template>
  <div class="smart-contracts__list" v-if="documents && documents.length > 0">
    <div
      class="smart-contracts__list__item"
      v-for="document in documents"
      :key="document.id"
      @click="onClick(document, $event)"
    >
      <div>
        <p class="smart-contracts__list__item__title">{{ document.name }}</p>
        <p class="smart-contracts__list__item__description" v-if="document.description">{{ document.description }}</p>
      </div>
      <div class="smart-contracts__list__item__actions">
        <p class="smart-contracts__list__item__price" v-if="document.price">{{ document.price }} €</p>
        <v-button class="smart-contracts__list__item__button" size="medium">Uredi vzorec</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'
export default {
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  components: {
    VButton,
  },
  methods: {
    onClick (document, event) {
      this.$emit('document-click', { document, event })
    },
  },
}
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/variables";

.smart-contracts__list {
  & .smart-contracts__list__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid $input-light;
    cursor: pointer;

    & .smart-contracts__list__item__title {
      color: $black;
      font-size: 16px;

      @include respond-to(small) {
        font-size: 14px;
      }
    }

    & .smart-contracts__list__item__actions {
      display: flex;
      flex-shrink: 0;
      align-items: center;

      @include respond-to(not-large) {
        flex-direction: column;
      }

      & .smart-contracts__list__item__button {
        margin-left: 77px;

        @include respond-to(not-large) {
          margin-left: 16px;
        }

        @include respond-to(small) {
          margin-left: 8px;
        }
      }
    }

    & .smart-contracts__list__item__description {
      color: $modrn-blue-dark-50;
      font-size: 14px;

      @include respond-to(small) {
        font-size: 12px;
      }
    }

    & .smart-contracts__list__item__price {
      color: $primary-blue;
      font-size: 16px;
      font-weight: 600;
      padding-left: 8px;
    }
  }
}
</style>
