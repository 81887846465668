<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />
    <div class="admin__header">
      <h1 class="page-title">Uredi Tekstovni Sestavek</h1>
      <v-button class="delete-button" :disabled="isLoading" @click="onDelete" background-color="red" outlined>Izbriši</v-button>
    </div>
    <text-content-form v-if="textContent" v-show="!isLoading" :text-content="textContent" @submit="submitTextContent" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TextContentForm from './forms/TextContentForm'
import VButton from 'design-system/VButton.vue'
import VProgressBar from 'design-system/VProgressBar.vue'
import prompt from 'design-system/components/prompt/prompt'

export default {
  props: {
    id: { type: [String, Number], required: true },
  },
  components: {
    TextContentForm,
    VButton,
    VProgressBar,
  },
  created () {
    this.initialize(this.id)
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('admin/textContents/forms', ['textContent']),
  },
  methods: {
    ...mapActions('admin/textContents/forms', ['initialize', 'reset', 'submitTextContent', 'deleteTextContent']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati tekstovni sestavek? Akcija bo nepovratno izbrisala tekstovni sestavek vključno z vso vsebino in variablami.')
      if (result) {
        this.deleteTextContent(this.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
