<template>
  <div class="blog-header" :class="`blog-header--${size}`">
    <div class="blog-header-text">
      <v-button size="small" @click="openBlog"><icon class="blog-button-icon" name="arrow-left" />Nazaj na blog</v-button>
      <h1 class="blog-header-title">{{ blogPost.title }}</h1>
      <div class="blog-header-description" v-html="blogPostDescriptionHtml" />
    </div>
    <div class="blog-header-image-container">
      <img class="blog-header-image" alt="Blog prispevek" :src="blogPost.imageUrl" />
    </div>
  </div>
</template>

<script>
import snarkdown from 'snarkdown'
import Icon from 'design-system/icons/Icon.vue'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    Icon,
    VButton,
  },
  props: {
    blogPost: { type: Object, required: true },
    size: { type: String, default: 'small' },
  },
  computed: {
    blogPostDescriptionHtml () {
      if (!this.blogPost.description) {
        return null
      }

      return snarkdown(this.blogPost.description)
    },
  },
  methods: {
    openBlog () {
      this.$router.push({ name: 'blog_path' })
    },
  },
}
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/mixins";
@import "app/assets/stylesheets/variables";

.blog-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-bottom: 94px;

  &--big {
    .blog-header-title {
      padding: 32px 0 24px 0;
      color: $modrn-blue-dark-90;
    }
  }

  @include respond-to('small') {
    padding-bottom: 64px;
  }
}

.blog-header-text {
  flex-shrink: 1;
  padding-right: 64px;

  @include respond-to('small') {
    padding: 0;
  }
}

.blog-header-image-container {
  flex-shrink: 1;

  @include blog-post-image-container;
}

.blog-header-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;

  @include respond-to('small') {
    display: none;
  }
}

.blog-button-icon {
  position: relative;
  top: 1px;
  left: -8px;
}

.blog-header-title {
  padding: 16px 0 24px 0;
  font-size: 54px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: -0.81px;

  @include respond-to('small') {
    font-size: 32px;
  }
}

.blog-header-description {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.27px;

  @include respond-to('small') {
    font-size: 16px;
  }
}
</style>
