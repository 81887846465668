<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <div class="md-title">Statistika</div>
    <div v-if="stats">
      <div class="md-subheading">Število vseh uporabnikov: {{ stats.num_users }}</div>
      <div class="md-subheading">Število uporabnikov, ki se nikoli niso vpisali: {{ stats.num_stale_users }}</div>
      <div class="md-subheading">Število aktivnih uporabnikov danes: {{ stats.num_active_users_day }}</div>
      <div class="md-subheading">Število aktivnih uporabnikov ta teden: {{ stats.num_active_users_week }}</div>
      <div class="md-subheading">Število aktivnih uporabnikov ta mesec: {{ stats.num_active_users_month }}</div>

      <md-divider class="stats__divider" />

      <div class="md-subheading">Število ustvarjenih dokumentov: {{ stats.num_generated_finalized_documents }}</div>
      <div class="md-subheading">Število ustvarjenih dokumentov danes: {{ stats.num_generated_finalized_documents_day }}</div>
      <div class="md-subheading">Število ustvarjenih dokumentov ta teden: {{ stats.num_generated_finalized_documents_week }}</div>
      <div class="md-subheading">Število ustvarjenih dokumentov ta mesec: {{ stats.num_generated_finalized_documents_month }}</div>

      <md-divider class="stats__divider" />

      <div class="md-subheading">Število plačanih dokumentov: {{ stats.num_paid_finalized_documents }}</div>
      <div class="md-subheading">Število plačanih dokumentov preko Stripe: {{ stats.num_paid_finalized_documents_stripe }}</div>
      <div class="md-subheading">Število plačanih dokumentov preko Braintree: {{ stats.num_paid_finalized_documents_braintree }}</div>
      <div class="md-subheading">Število plačanih dokumentov danes: {{ stats.num_paid_finalized_documents_day }}</div>
      <div class="md-subheading">Število plačanih dokumentov ta teden: {{ stats.num_paid_finalized_documents_week }}</div>
      <div class="md-subheading">Število plačanih dokumentov ta mesec: {{ stats.num_paid_finalized_documents_month }}</div>
      <div class="md-subheading">Število neplačanih dokumentov: {{ stats.num_stale_finalized_documents }}</div>

      <md-divider class="stats__divider" />

      <div class="md-subheading">Zaslužek: {{ stats.total_earned }} EUR</div>
      <div class="md-subheading">Zaslužek danes: {{ stats.total_earned_day }} EUR</div>
      <div class="md-subheading">Zaslužek v zadnjih 7 dneh: {{ stats.total_earned_week }} EUR</div>
      <div class="md-subheading">Zaslužek v zadnjih 30 dneh: {{ stats.total_earned_month }} EUR</div>

      <br />
      <br />

      <div class="md-title">Uporaba dokumentov</div>
      <div class="document-usage">
        <entity-table
          title="Kupljeni dokumenti"
          :is-add-new-present="false"
          :columns="columns"
          :items="stats.document_usage"
          :page="1"
          :number-of-pages="1"
          :is-search-present="false"
          :is-pagination-present="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'

export default {
  components: {
    EntityTable,
  },
  beforeCreate () {
    this.columns = [
      { id: 'name', name: 'Ime' },
      { id: 'usage', name: 'Število uporab' },
    ]
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('admin/stats', ['stats']),
  },
  methods: {
    ...mapActions('admin/stats', ['initialize', 'reset']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';

.stats__divider {
  margin: 16px 0;
}
</style>
