<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="new-blog-post__header">
      <h1 class="page-title">Nov Blog</h1>
      <v-button class="regular-button new-blog-post__header-button" @click="saveBlogPost">Shrani</v-button>
    </div>
    <blog-post-form @submit="submitBlogPost" ref="blogPostForm" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BlogPostForm from './forms/BlogPostForm'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    BlogPostForm,
    VButton,
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    ...mapActions('admin/blogPosts/forms', ['initialize', 'reset', 'submitBlogPost']),
    saveBlogPost () {
      this.$refs.blogPostForm.submit()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';

.new-blog-post__header {
  display: flex;
  justify-content: space-between;
}

.new-blog-post__header-button {
  position: relative;
  top: -12px;
}
</style>
