import axios from 'axios'
import { getResponseErrorMessage } from '@/store/utils'

const state = {
  stats: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {},
  actions: {
    initialize ({ commit }) {
      commit('SET_IS_LOADING', true, { root: true })

      return axios
        .get('/admin/stats')
        .then((response) => commit('SET_STATS', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju statistike, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_STATS (state, stats) {
      state.stats = stats
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
