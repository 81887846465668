<template>
  <div class="notifications" v-click-outside="closeNotificationFeed">
    <div
      class="notifications__button"
      :class="{ ' notifications__button--unread': unreadNotificationsCount > 0 }"
      @click="toggleNotificationFeed"
    >
      <icon name="bell" />
    </div>
    <div class="notifications__modal" v-if="isNotificationFeedOpen">
      <div class="notifications__header">
        <div class="notifications__header-title">Obvestila</div>
      </div>

      <div v-if="isLoading" class="notifications__loader">
        <v-progress-spinner class="md-accent" :md-diameter="40" :md-stroke="3" md-mode="indeterminate" />
      </div>

      <template v-else>
        <div v-if="notifications.length == 0" class="notifications__no-items">
          Nimate obvestil
        </div>
        <div
          v-else
          class="notifications__item"
          v-for="n in notifications"
          :key="n.id"
          :class="{ 'notifications__item-unread': !n.isRead }"
          @click="openNotificationDestination(n)"
        >
          <div class="notifications__item-avatar" :class="{ 'notifications__item-avatar--initials' : n.authorInitials }">
            <span v-if="n.authorInitials">{{ n.authorInitials }}</span>
            <img v-else class="notifications__item-avatar-image" alt="Pigenta Logo" :src="require('app/assets/images/pigenta_logo.png')" />
          </div>
          <div class="notifications__item-content">
            {{ n.body }}
            <div class="notifications__item-content-time">
              {{ n.publishTime }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Icon from 'design-system/icons/Icon.vue'
import VProgressSpinner from 'design-system/VProgressSpinner.vue'

export default {
  data () {
    return {
      isNotificationFeedOpen: false,
    }
  },
  components: {
    Icon,
    VProgressSpinner,
  },
  beforeDestroy () {
    this.closeNotificationFeed()
  },
  computed: {
    ...mapState(['user', 'unreadNotificationsCount']),
    ...mapState('notifications', ['notifications', 'isLoading']),
  },
  methods: {
    ...mapActions('notifications', ['loadNotifications', 'reset']),
    toggleNotificationFeed () {
      if (this.isNotificationFeedOpen) {
        this.isNotificationFeedOpen = false
      } else {
        this.loadNotifications()
        this.isNotificationFeedOpen = true
      }
    },
    closeNotificationFeed () {
      this.isNotificationFeedOpen = false
      this.reset()
    },
    openNotificationDestination (notification) {
      window.location.href = notification.link
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.notifications {
  position: relative;
}

.notifications__button {
  position: relative;
  margin-top: 5px;
  color: $modrn-blue-dark;
  cursor: pointer;

  &--unread {
    &::after {
      position: absolute;
      top: 0;
      right: 2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $red;
      content: '';
    }
  }

  .logged-in-header__notifications-feed {
    position: absolute;
    top: 36px;
    right: 0;
    cursor: default;
  }
}

.notifications__modal {
  position: absolute;
  top: 46px;
  left: -417px;
  width: 440px;
  max-height: 480px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: $white;
  // stylelint-disable-next-line
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));

  @include respond-to('small') {
    left: -260px;
    width: 350px;
    max-height: 80vh;
  }
}

.notifications__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  color: $modrn-blue-dark;
  font-size: 14px;

  &-title {
    font-weight: 500;
  }
}

.notifications__item {
  position: relative;
  display: flex;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: $light_gray_background_color;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $silk-gray;
  }

  &-avatar {
    display: flex;
    min-width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: $white;
    font-size: 18px;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;

    &--initials {
      background-color: $modrn-blue;
    }
  }

  &-avatar-image {
    width: 20px;
    height: 20px;
    object-fit: contain;
    background-color: $white;
  }

  &-content {
    &-time {
      margin-top: 16px;
      color: $input-medium;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &-unread::after {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    border: 1px solid #4299E1;
    border-radius: 50%;
    background: #90CDF4;
    content: "";
  }
}

.notifications__no-items {
  padding: 16px 16px 32px 64px;
  font-size: 15px;
  font-weight: 600;
}

.notifications__loader {
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
}
</style>
