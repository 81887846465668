<template>
  <div class="blog-post-related">
    <div class="heading">Preberite še</div>
    <blog-card-view
      class="related-blog-posts"
      :blog-posts="blogPosts"
      @blog-post-click="openBlogPost"
      :show-newsletter="false"
    />
  </div>
</template>

<script>
import BlogCardView from './BlogCardView.vue'

export default {
  components: {
    BlogCardView,
  },
  props: {
    blogPosts: { type: Array, required: true },
  },
  methods: {
    openBlogPost ({ blogPost }) {
      this.$router.push({
        name: 'blog_post_path',
        params: {
          id: blogPost.slug,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/variables";

.heading {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;
  padding-bottom: 32px;
}

.blog-post-related {
  overflow-x: auto;

  /deep/ .blog-card {
    max-width: 400px;
    max-height: 432px;
    border: 1px solid #E4E7EC;
    flex-shrink: 0;
  }

  /deep/ .blog-cards {
    display: flex;

    @include respond-to('small') {
      display: block;
    }
  }
}
</style>
