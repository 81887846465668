<template>
  <div class="text">
    <h1>Pogoji</h1>

    <br />

    <h2>Stranke</h2>
    Ti splošni pogoji poslovanja spletne trgovine Pigenta ("splošni pogoji") urejajo postopek nakupa v spletni trgovini, ki se nahaja na spletni strani www.pigenta.com oziroma njenih podstraneh ("spletna trgovina") in predstavljajo splošne pogoje pogodbe, ki je sklenjena med družbo zero2one, digitalni laboratorij d.o.o., Litijska cesta 152, 1000 Ljubljana, matična št. 8581355000, davčna št. 35408944 ("prodajalec" ali "Pigenta") in vami kot kupcem, ko v spletni trgovini opravite nakup.
    <br />
    <br />
    V spletni trgovini je možen nakup in izpolnitev t.i. pametnih vzorcev. Pametni vzorci so predloge, ki vam omogočajo, da si na enostaven in hiter način pripravite pogodbe in druge dokumente. Le-te po zaključku nakupa lahko prenesete na svojo napravo v .docx ali .pdf datotečni obliki, jih natisnete in podpišete.

    <h2>Registracija in prijava</h2>
    Obiskovalci spletne trgovine lahko med pametnimi vzorci brskate brez registracije oziroma prijave. Nakup pa lahko opravite zgolj s predhodnim kreiranjem uporabniškega računa ("registracijo"). Postopek registracije morate opraviti le enkrat, pri naslednjih nakupih pa lahko v uporabniški račun vstopite z vpisom vašega uporabniškega imena in gesla, ki ste ga določili v postopku registracije.

    <h2>Uporabniške strani</h2>
    Če želite spremeniti ali dopolniti uporabniške podatke, ki ste jih navedli ob registraciji, lahko to kadarkoli storite v vaših uporabniških straneh.
    <br />
    <br />
    V uporabniških straneh lahko tudi pregledujete nakupe, ki ste jih opravili v zadnjih 3 mesecih. Tako lahko kadarkoli v obdobju 3 mesecev od dneva nakupa ponovno prenesete končne verzije dokumentov, ki ste jih ustvarili in prenesli v spletni trgovini.
    <br />
    <br />
    Po prvem prenosu dokumenta le-tega ne morete več spreminjati preko našega urejevalnika. Ta funkcija je na voljo le naročnikom storitve dokumentarne avtomatizacije.

    <h2>Sklenitev pogodbe</h2>
    Pred začetkom urejanja pametnega vzorca vas bo spletna trgovina pozvala na registracijo oz. prijavo, nato pa še na plačilo izbranega pametnega vzorca. Cena vsakega vzorca je navedena na spletni strani vsakega posameznega vzorca, dodatno pa vam ceno jasno prikaže tudi spletna stran neposredno pred nakupom.
    <br />
    <br />
    Takoj po uspešnem plačilu vam bomo poslali email, s katerim bomo potrdili vaš nakup v spletni trgovini. Šteje se, da je prodajna pogodba sklenjena v trenutku, ko prejmete omenjeni email.
    <br />
    <br />
    Po uspešnem zaključku nakupa boste lahko pričeli z urejanjem pametnega vzorca. Ko boste z urejanjem končali, lahko končno verzijo prenesete v .docx in .pdf datotečni obliki. Funkcija shranjevanja osnutka pametnega vzorca trenutno še ni na voljo in končno verzijo pametnega vzorca lahko prenesete zgolj po izpolnitvi vseh zahtevanih polj. Zahtevana polja morajo biti izpolnjena znotraj iste brskalne seje, sicer se lahko zgodi, da boste morali z izpolnjevanjem pametnega vzorca začeti znova.
    <br />
    <br />
    POMEMBNO: Ko enkrat dokument prenesete, se šteje, da ste prenesli končno verzijo dokumenta. Po tem trenutku dokumenta ne morete več urejati preko našega urejevalnika. Lahko pa ga ponovno prenesete preko uporabniških strani.

    <h2>Račun in cena</h2>
    Kupec se izrecno strinja, da mu Pigenta v skladu s 84. členom ZDDV-1 po zaključku nakupa na email, naveden v njegovih uporabniški straneh, pošlje račun za opravljene storitve in sicer v pdf formatu. Kupec ni upravičen do izdaje in pošiljanja računa v tiskani obliki.
    <br />
    <br />
    Kupec je dolžan preveriti pravilnost podatkov pred oddajo naročila. Kasneje podanih ugovorov glede pravilnosti izdanih računov Pigenta ne upošteva.
    <br />
    <br />
    <div class="image-container">
      <img class="furs-image" alt="FURS nalepka" :src="require('app/assets/images/FURS_sticker.png')" />
    </div>
    <br />
    <br />
    Pigenta nismo zavezanec za plačilo DDV, zato DDV-ja ne obračunavamo. Prizadevamo si za pravilnost podatkov o ceni pametnih vzorcev, vendar pa se včasih zgodi, da je prikazana cena napačna. V tem primeru vam bomo omogočil odstop od prodajne pogodbe in vračilo kupnine, bodisi vam bomo ponudili doplačilo do prave vrednosti oz. vračilo kupnine v zadevnem znesku (odvisno od primera).

    <h2>Odstop od pogodbe</h2>
    Soglašate, da z izvedbo storitve začnemo takoj po uspešnem zaključku nakupa. Ker vam bomo digitalno vsebino, ki se ne dostavi na otipljivem nosilcu podatkov, na vašo zahtevo posredovali takoj po zaključku nakupa, v skladu s 43.č členom Zakona o varstvu potrošnikov nimate običajne pravice do odstopa od pogodbe v roku 14 dni od njene sklenitve.
    <br />
    <br />
    V kolikor bi prišlo do morebitnega daljšega nedelovanja storitve po uspešnem zaključku nakupa in pred prenosom končne verzije dokumenta, vam bomo vrnili kupnino. Kupnino vam bomo vrnili na vaš transakcijski račun, odprt pri banki v Republiki Sloveniji ali drugi državi članici Evropske Unije.

    <h2>Potrošniški spori</h2>
    Prodajalec ne priznava nobenega izvajalca izvensodnega reševanja potrošniških sporov (IRPS) kot pristojnega za reševanje potrošniškega spora.
    <br />
    <br />
    V skladu z evropsko uredbo o reševanju potrošniških sporov (Uredbe o spletnem reševanju potrošniških sporov (EU) št. 524/2013) je za izvensodno reševanje sporov, ki zadevajo pogodbene obveznosti, ki izhajajo iz pogodb o spletni prodaji ali spletnih storitvah med potrošnikom z bivališčem v Uniji in trgovcem s sedežem v Uniji na voljo ustrezna platforma. Evropska komisija je razvila platformo za spletno reševanje potrošniških sporov in predstavlja osrednjo začetno točko za izvensodno poravnavo potrošniških sporov. <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=SL" target="_blank">Na tej povezavi</a> lahko obiščete platformo za spletno reševanje potrošniških sporov.

    <h2 id="garancije">Garancije</h2>
    <div class="container" v-for="guarantee in guarantees" :key="guarantee.icon">
      <icon :name="guarantee.icon" :width="45" :height="45" class="guarantee-icon" />
      <div>
        <div class="guarantee-heading">{{ guarantee.heading }}</div>
        <div class="guarantee-content">{{ guarantee.text }}</div>
      </div>
    </div>

    <h2>Avtorske pravice</h2>
    Pametni vzorci in njihova vsebina so predmet avtorskih pravic prodajalca in varovani v skladu z določbami Zakona o avtorski in sorodnih pravicah. Pametne vzorce in kupljene dokumente lahko uporabljate za dosego namena, ki izhaja iz njihove vsebine (predvsem za sklenitev pogodb z vašimi poslovnimi partnerji, za zagotovitev skladnosti vašega poslovanja ipd.). Prepovedano pa je tržno izkoriščanje kupljenih dokumentov, predvsem v obliki nadaljnje prodaje.

    <h2>Spremembe</h2>
    Splošne pogoje lahko kadarkoli po lastni presoji spremenimo. Zato priporočamo, da si jih pred vsakim nakupom ponovno preberete.
    <br />
    <br />
    <br />
    Ljubljana, januar 2021
    <br />
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'

export default {
  components: {
    Icon,
  },
  data () {
    return {
      guarantees: [
        {
          icon: 'moneyback',
          heading: '30-dnevno vračilo kupnine',
          text: 'Če s poslovnim partnerjem v 30 dneh od nakupa ne sklenete kupljene pogodbe, vam vrnemo kupnino.',
        },
        {
          icon: 'award',
          heading: '60-dnevna garancija kvalitete',
          text: 'Če v 60 dneh od nakupa kateregakoli pravnega dokumenta odvetnik v njem odkrije pravno napako, vam vrnemo celotno kupnino.',
        },
        {
          icon: 'cloud',
          heading: 'Takojšni prevzem dokumenta',
          text: 'Takoj po zaključku nakupa lahko kupljen dokument prenesete na svojo napravo v docx ali pdf obliki, ali ga pošljete v e-podpis. Do dokumenta lahko vedno dostopate v vašem uporabniškem računu, v zavihku ‘Hramba’. Če dokumenta ne vidite, s klikom na ‘x’ odstranite ‘Status: Veljavno’ in dokument se bo prikazal.',
        },
      ],
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped lang='scss'>
@import '../common';

.image-container {
  width: 100%;
  text-align: center;
}

.furs-image {
  width: 250px;
  margin: auto;
}

.container {
  display: flex;
  padding-bottom: 16px;
}

.guarantee-icon {
  margin-right: 24px;
  padding: 4px;
}

.guarantee-heading {
  font-size: 14px;
  font-weight: 700;
}

.guarantee-content {
  font-size: 14px;
  font-weight: 500;
}
</style>
