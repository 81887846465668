<template>
  <div class="packages__container">
    <div class="packages__title">Paket</div>
    <div class="packages__package-card">
      <div class="packages__package-card__header">
        <div class="packages__package-card__header-tag">
          <r-tag background-color="modrn-blue-50">Izbrano</r-tag>
        </div>
        <div class="packages__package-card__header-title">Sprotno plačevanje</div>
        <div class="packages__package-card__header-text">Plačilo vsakega dokumenta posebej.</div>
      </div>
      <div class="packages__package-card__content">
        <div class="packages__package-card__content__list">
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Fleksibilna uporaba
          </div>
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Obsežna baza vzorcev
          </div>
        </div>
      </div>
    </div>

    <div class="packages__package-card">
      <div class="packages__package-card__header">
        <div class="packages__package-card__header-title">Naročnina</div>
        <div class="packages__package-card__header-text">Avtomatizacija pravnega dela vašega poslovanja.</div>
      </div>
      <div class="packages__package-card__content">
        <div class="packages__package-card__content__list">
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Neomejena uporaba obsežne baze
          </div>
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Pametni vzorci po meri
          </div>
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Elektronsko podpisovanje
          </div>
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Pametna hramba
          </div>
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Urejevalnik ustvarjenih
            dokumentov
          </div>
          <div class="packages__package-card__content__list-item">
            <icon name="checkmark" class="packages__package-card__content__list-icon" />Integracije po meri
          </div>
        </div>
        <div class="packages__package-card__content__action">
          <v-button @click="openMailTo">Pošlji povpraševanje</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'
import VButton from 'design-system/VButton.vue'
import RTag from 'design-system/components/RTag.vue'
import MailToMixin from '@/components/shared/MailToMixin'
export default {
  components: {
    Icon,
    VButton,
    RTag,
  },
  mixins: [MailToMixin],
}
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/variables";

$gray-900: #111827;
$gray-500: #6B7280;
$gray-50: #F9FAFB;

.packages__container {
  padding: 36px 48px;

  .packages__title {
    margin-bottom: 32px;
    color: $text-black;
    font-size: 28px;
    font-weight: 600;
  }

  .packages__package-card {
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 24px;
    // stylelint-disable-next-line
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.05);

    .packages__package-card__header {
      padding: 24px 32px 16px;
      background: $white;

      .packages__package-card__header-tag {
        margin-bottom: 16px;
      }

      .packages__package-card__header-title {
        margin-bottom: 24px;
        color: $gray-900;
        font-size: 28px;
        font-weight: 700;
        line-height: 30px;
      }

      .packages__package-card__header-text {
        color: $gray-500;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .packages__package-card__content {
      padding: 24px 32px 32px;
      background: $gray-50;

      .packages__package-card__content__list {
        .packages__package-card__content__list-item {
          display: flex;
          align-items: center;
          color: $modrn-blue-dark-60;
          font-size: 16px;
          font-weight: 500;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        .packages__package-card__content__list-icon {
          display: inline;
          margin: 4px 16px 0 0;
        }
      }

      .packages__package-card__content__action {
        margin-top: 24px;
      }
    }
  }
}
</style>
