<template>
  <div class="blog-cards">
    <template v-for="(blogPost, index) in blogPosts">
      <div :key="blogPost.id" class="blog-card" @click="onClick(blogPost, $event)">
        <div class="blog-card-image-container">
          <img class="blog-card-image" alt="Blog prispevek" :src="blogPost.imageUrl" />
        </div>
        <div class="blog-card-content">
          <div class="blog-card-title">{{ blogPost.title }}</div>
          <v-button class="blog-card-button" size="small">Preberi več</v-button>
        </div>
      </div>

      <div v-if="index === 0 && !isLoggedIn && showNewsletter" :key="index" @click="redirectToBlogNewsletterSignUp">
        <div class="blog-card blog-card--newsletter">
          <div class="blog-card-newsletter-icon">
            <icon name="email" :width="60" :height="48" />
          </div>
          <div class="blog-card-newsletter-text">
            Bodite vedno na tekočem s Pigento.
          </div>
          <div class="blog-card-newsletter-button">
            <v-button color="white" background-color="transparent" :outlined="true">Prijavi se</v-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    Icon,
    VButton,
  },
  props: {
    blogPosts: {
      type: Array,
      required: true,
    },
    showNewsletter: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick (blogPost, event) {
      this.$emit('blog-post-click', { blogPost, event })
    },
    redirectToBlogNewsletterSignUp () {
      this.$router.push({ name: 'blog_post_path', params: { id: this.blogPosts[0].slug }, hash: '#newsletter' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/mixins";
@import "app/assets/stylesheets/variables";

.blog-cards {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 400px));
  margin-top: 8px;

  @include respond-to ('small') {
    display: block;
  }
}

.blog-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  }

  @include respond-to ('small') {
    margin-bottom: 16px;
  }
}

.blog-card--newsletter {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 432px;
  padding: 42px;
  background-color: $secondary_color;
  color: white;
}

.blog-card-image-container {
  @include blog-post-image-container;
}

.blog-card-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-content {
  height: 152px;
  position: relative;
  flex-grow: 1;
  padding: 24px;
}

.blog-card-title {
  height: 72px;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.18px;
}

.blog-card-button {
  position: absolute;
  bottom: 24px;
}

.blog-card-newsletter-text {
  font-size: 45px;
  line-height: 49px;
  font-weight: 600;

  @include respond-to ('not-large') {
    font-size: 28px;
    line-height: 32px;
  }
}

.blog-card-newsletter-icon {
  margin-bottom: 16px;
}

.blog-card-newsletter-button {
  position: absolute;
  bottom: 50px;
  width: calc(100% - 84px);
  display: flex;
  justify-content: center;

  @include respond-to ('small') {
    bottom: 40px;
  }
}
</style>
