<template>
  <md-badge v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </md-badge>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

