<template>
  <div>
    <v-field :class="getValidationClass('name')">
      <label>Ime</label>
      <v-input v-model="form.name"></v-input>
      <span class="md-error" v-if="!$v.form.name.required">Vpišite ime</span>
    </v-field>

    <div class="text-with-preview">
      <v-field class="text-field">
        <label>Vsebina</label>
        <v-textarea v-model="form.text"></v-textarea>
      </v-field>

      <div class="text-markdown-preview">
        <div class="text-markdown-preview-title">Predogled</div>
        <div class="text-markdown-preview-content" v-html="textContentMarkdownHtml"></div>
      </div>
    </div>

    <v-field>
      <label>Ključne besede (opcijsko)</label>
      <v-input v-model="form.keywords"></v-input>
    </v-field>

    <div class="md-subheading nested-form-heading" @click="showNestedForm = !showNestedForm">Variable</div>
    <div class="nested-form" v-if="variables.length > 0" v-show="showNestedForm">
      <div v-for="(variable, index) in variables" :key="variable.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">variabla {{ index + 1 }}</div>
          <v-button class="v-icon-button" @click="removeVariable(index)"><v-icon>close</v-icon></v-button>
        </div>
        <variable-form-fields ref="variables" :variable="variable" @change="onVariableChange(index, $event)" />
      </div>
    </div>
    <v-button class="regular-button" size="small" @click="addVariable">Dodaj variablo</v-button>
    <md-divider />
  </div>
</template>

<script>
import snarkdown from 'snarkdown'
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import NestedFormMixin from '../../NestedFormMixin'
import VariableFormFields from './VariableFormFields'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VInput from 'design-system/VInput.vue'
import VTextarea from 'design-system/VTextarea.vue'

export default {
  components: {
    VariableFormFields,
    VButton,
    VField,
    VIcon,
    VInput,
    VTextarea,
  },
  props: {
    textContent: { type: Object, required: false },
  },
  data () {
    return {
      showNestedForm: true,
      form: {
        name: null,
        text: '',
        keywords: null,
        variables: [],
      },
    }
  },
  mixins: [ValidationMixin, NestedFormMixin],
  created () {
    if (!this.textContent) {
      return
    }
    this.form.name = this.textContent.name
    this.form.text = this.textContent.text ?? this.form.text
    this.form.keywords = this.textContent.keywords

    if (this.textContent.variables) {
      const variablesWithFrontendIds = createVariableFrontendIds(this.textContent.variables)
      this.form.variables = variablesWithFrontendIds
    }

    function createVariableFrontendIds (variables) {
      const variablesWithFrontendIds = []
      variables.forEach(variable => {
        variablesWithFrontendIds.push({
          ...variable,
          frontendId: Math.random().toString(36),
        })
      })

      return variablesWithFrontendIds
    }
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.textContent,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  computed: {
    variables () {
      return this.form.variables
    },
    textContentData () {
      const textContent = {
        name: this.form.name,
        text: this.form.text,
        keywords: this.form.keywords,
        variables: this.form.variables,
      }

      if (this.textContent) {
        return {
          id: this.textContent.id,
          ...textContent,
        }
      }

      return textContent
    },
    textContentMarkdownHtml () {
      if (!this.form.text) {
        return ''
      }
      return snarkdown(this.form.text)
    },
  },
  methods: {
    onVariableChange (index, $event) {
      this.form.variables[index] = {
        ...this.form.variables[index],
        ...$event,
      }
    },
    addVariable () {
      this.form.variables.push({ frontendId: Math.random().toString(36), order: this.form.variables.length + 1 })
    },
    removeVariable (index) {
      this.form.variables.splice(index, 1)
    },
    runAllValidations () {
      this.$v.$touch()
      const variableRefs = this.$refs.variables
      if (!variableRefs) {
        return
      }

      variableRefs.forEach(variableRef => variableRef.runAllValidations())
    },
    isValid () {
      if (this.$v.$invalid) {
        return false
      }

      const variableRefs = this.$refs.variables
      if (!variableRefs) {
        return true
      }

      return variableRefs.reduce((areVariableRefsValid, variableRef) => areVariableRefsValid && variableRef.isValid(), true)
    },
  },
  validations: {
    form: {
      name: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '../../markdown_preview';

.text-field {
  width: 50%;
  margin-right: 8px;
  box-sizing: border-box;
}
</style>

<style lang="scss">
.text-field.text-field {
  textarea {
    height: 100%;
    max-height: unset;
  }
}
</style>
