export const paginationState = {
  query: null,
  page: 1,
  perPage: 100,
  numberOfPages: 0,
  totalResults: 0,
}

export const paginationMutations = {
  SET_QUERY (state, query) {
    state.page = 1
    state.query = query
  },
  SET_PAGE (state, page) {
    state.page = parseInt(page)
  },
  SET_NUMBER_OF_PAGES (state, numberOfPages) {
    state.numberOfPages = parseInt(numberOfPages)
  },
  SET_TOTAL_RESULTS (state, totalResults) {
    state.totalResults = parseInt(totalResults)
  },
}
