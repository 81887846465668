<template>
  <div>
    <v-field :class="getValidationClass('value')">
      <label>Vrednost</label>
      <v-input v-model="form.value"></v-input>
      <span class="md-error" v-if="!$v.form.value.required">Vpišite vrednost</span>
    </v-field>

    <v-field>
      <label>Dodatne informacije</label>
      <v-input v-model="form.info"></v-input>
    </v-field>

    <div class="md-subheading nested-form-heading" :class="{ 'nested-form-heading--collapsed': !showSegmentNestedForm }" @click="showSegmentNestedForm = !showSegmentNestedForm">Tekstovni segmenti</div>
    <div class="nested-form" v-show="showSegmentNestedForm">
      <div v-for="(textSegment, index) in form.textSegments" :key="textSegment.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">tekstovni segment {{ index + 1 }}</div>
          <v-button class="md-icon-button" size="small" @click="removeTextSegment(index)"><v-icon>close</v-icon></v-button>
        </div>
        <text-segment-form-fields :text-segment="textSegment" :text-contents="availableTextContents" @change="onTextSegmentChange(index, $event)" />
      </div>

      <v-button class="regular-button" size="small" @click="addTextSegment">DODAJ TEKSTOVNI SEGMENT</v-button>
    </div>

    <div class="md-subheading nested-form-heading" :class="{ 'nested-form-heading--collapsed': !showConditionNestedForm }" @click="showConditionNestedForm = !showConditionNestedForm">Pogoji</div>
    <div class="nested-form" v-show="showConditionNestedForm">
      <v-button class="regular-button" size="small" @click="addCondition">DODAJ POGOJ</v-button>
      <div v-for="(condition, index) in form.conditions" :key="condition.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">pogoj {{ index + 1 }}</div>
          <v-button class="md-icon-button" size="small" @click="removeCondition(index)"><v-icon>close</v-icon></v-button>
        </div>
        <condition-form-fields :condition="condition" @change="onConditionChange(index, $event)" />
      </div>
      <v-button class="regular-button" size="small" v-if="form.conditions.length" @click="addCondition">DODAJ POGOJ</v-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import NestedFormMixin from '../../NestedFormMixin'
import TextSegmentFormFields from './TextSegmentFormFields'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: {
    TextSegmentFormFields,
    // those 2 components have a circular dependency at first sight, but condition outcome needs condition eventually, hence we need to do an async import
    ConditionFormFields: () => import('./ConditionFormFields.vue'),
    VButton,
    VField,
    VIcon,
    VInput,
  },
  props: {
    conditionOutcome: { type: Object, required: false },
  },
  data () {
    return {
      showSegmentNestedForm: true,
      showConditionNestedForm: true,
      form: {
        value: null,
        info: null,
        textSegments: [],
        conditions: [],
      },
    }
  },
  mixins: [ValidationMixin, NestedFormMixin],
  created () {
    if (!this.conditionOutcome) {
      return
    }

    this.form.value = this.conditionOutcome.value
    this.form.info = this.conditionOutcome.info

    if (this.conditionOutcome.textSegments) {
      this.form.textSegments = this.conditionOutcome.textSegments
    }

    if (this.conditionOutcome.conditions) {
      this.form.conditions = this.conditionOutcome.conditions
    }
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', newFormValue)
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('admin/documents/forms', ['availableTextContents']),
  },
  methods: {
    onTextSegmentChange (index, $event) {
      this.form.textSegments[index] = {
        ...this.form.textSegments[index],
        ...$event,
      }
    },
    onConditionChange (index, $event) {
      this.form.conditions[index] = {
        ...this.form.conditions[index],
        ...$event,
      }
    },
    addTextSegment () {
      this.form.textSegments.push({ frontendId: Math.random().toString(36), order: this.form.textSegments.length + 1 })
    },
    addCondition () {
      this.form.conditions.push({ frontendId: Math.random().toString(36), order: this.form.conditions.length + 1 })
    },
    removeTextSegment (index) {
      this.form.textSegments.splice(index, 1)
    },
    removeCondition (index) {
      this.form.conditions.splice(index, 1)
    },
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      value: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
