<template>
  <div class="auth__container">
    <div class="auth__content">
      <router-link class="auth__logo-container" :to="{ name: 'root_path' }">
        <img class="auth__logo" alt="Pigenta Logo" :src="require('app/assets/images/pigenta_logo.svg')" />
      </router-link>
      <router-view ref="routerView" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('auth', ['register']),
  },
}
</script>

<style lang='scss' scoped>
@import 'app/assets/stylesheets/variables';
@import './common';
@import '../common';

.auth__content {
  max-height: 100%;
  padding: 48px 206px 72px;
  border-radius: 16px;
  margin: auto;
  background-color: white;
  overflow-y: auto;
  text-align: center;

  @include respond-to(small) {
    width: 100%;
    padding: 32px;
  }
}

.auth__logo-container {
  padding: 20px;
  border-radius: 8px;
  margin: auto;
   /* stylelint-disable-next-line */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.auth__logo {
  max-width: 22px;
}

.md-divider {
  margin-bottom: 24px;
}

.md-progress-bar.md-progress-bar.md-progress-bar {
  background-color: white;
}
</style>
