<template>
  <div class="extensions">
    <div class="header">
      <div class="header-name">Razširitev</div>
      <div class="header-toggle">Vklop</div>
    </div>
    <div class="content">
      <div class="row" v-for="extension in extensions" :key="extension.title">
        <div class="row-content">
          <div class="row-title">{{ extension.title }}</div>
          <div class="row-description" v-show="extension.isEnabled">{{ extension.description }}</div>
        </div>
        <div class="row-toggle" @click="toggleExtension(extension.title)">
          <svg v-if="extension.isEnabled" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 29">
            <rect x="1" y=".92" width="51" height="27" rx="13.5" fill="#002E2A" stroke="#B8D7D4" />
            <rect x="26.5" y="2.42" width="24" height="24" rx="12" fill="#FFFFFF" />
          </svg>
          <svg v-else fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 29">
            <rect x="1" y=".92" width="51" height="27" rx="13.5" fill="#fff" stroke="#DDE2E5" />
            <rect x="2.5" y="2.42" width="24" height="24" rx="12" fill="#DDE2E5" />
          </svg>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      extensions: [
        { title: 'Napredna obvestila', description: 'Obveščeni boste o spremembah vaših strank v registru AJPES.', isEnabled: true },
        { title: 'E-podpisovanje', description: 'Poleg dokumentov ustvarjenih preko pametnih vzorcev lahko e-podpisujete tudi dokumente, ki jih ročno naložite v hrambo.', isEnabled: false },
        { title: 'Odklenitev pametnega vzorca', description: 'Neomejena uporaba pametnega vzorca.', isEnabled: false },
        { title: 'Vzorec po meri', description: 'Vaše obstoječe dokumente pregledamo in digitaliziramo v pametne vzorce z glavo in nogo vašega podjetja.', isEnabled: false },
        { title: 'Dodatna hramba', description: 'Dodaten prostor v hrambi za večje arhivske baze.', isEnabled: false },
        { title: 'Odvetniški pregled', description: 'Strokovni pregled in prilagoditve ustvarjenega dokumenta.', isEnabled: false },
      ],
    }
  },
  methods: {
    toggleExtension (title) {
      const extension = this.extensions.find(extension => extension.title === title)
      extension.isEnabled = !extension.isEnabled
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.extensions {
  padding: 48px 106px 0 106px;

  @include respond-to('small') {
    padding: 32px 0 0 0;
  }
}

.content {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.21px;
  color: $text-black;
  user-select: none;

  @include respond-to('small') {
    position: relative;
    top: -20px;
  }
}

.header,
.row {
  display: flex;
  justify-content: space-between;
  padding: 22px 32px;
  background-color: white;
  border-bottom: 1px solid $border-gray;
}

.header {
  border-radius: 8px 8px 0 0;
}

.row {
  &:nth-child(odd) {
    background-color: $table-gray;
  }
  &:last-child {
    border-bottom: none;
  }
}

.header,
.row-title {
  font-weight: 600;
  line-height: 20px;
}

.row-description {
  padding: 22px 0 0 3px;
}

.row-toggle {
  width: 52px;
  height: 28px;
  cursor: pointer;
  flex-shrink: 0;
}

.footer {
  height: 16px;
  border-radius: 0 0 8px 8px;
  background-color: white;

  @include respond-to('small') {
    display: none
  }
}
</style>
