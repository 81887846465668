<template>
  <md-select v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </md-select>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss">
@import "app/assets/stylesheets/colors";

// styling applies to v-select and v-autocomplete components
.md-menu-content {
  box-shadow: none;
}

.md-menu-content-container {
  overflow: visible;
}

.md-list {
  border: 1px solid $input-medium;
  border-radius: 6px;
  margin-top: 6px;
}

.md-list-item-content {
  justify-content: start;
}

.md-list-item-content, .md-list-item-text {
  & > * {
    margin-right: 8px;
   }
}

.md-list-item-text {
  flex: none;
  flex-direction: row;
}

</style>

