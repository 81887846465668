<template>
  <form novalidate @submit.prevent="submit">
    <div class="user-form">
      <v-checkbox v-model="isCompany">Pravna oseba</v-checkbox>
      <template v-if="isCompany">
        <v-field :class="getValidationClass('companyName')">
          <label>Ime podjetja</label>
          <v-input v-model="form.companyName" />
          <span class="md-error" v-if="!$v.form.companyName.required">Vpišite ime podjetja</span>
        </v-field>

        <v-field :class="getValidationClass('vatId')">
          <label>Davčna številka</label>
          <v-input v-model="form.vatId" />
          <span class="md-error" v-if="!$v.form.vatId.required">Vpišite davčno številko</span>
        </v-field>

        <v-field>
          <label>Naslov</label>
          <v-input v-model="form.address" />
        </v-field>

        <v-field>
          <label>Poštna številka in pošta</label>
          <v-input v-model="form.postAndPostCode" />
        </v-field>
      </template>

      <template v-else>
        <v-field :class="getValidationClass('firstName')">
          <label>Ime</label>
          <v-input v-model="form.firstName" />
          <span class="md-error" v-if="!$v.form.firstName.required">Vpišite ime</span>
        </v-field>
        <v-field :class="getValidationClass('lastName')">
          <label>Priimek</label>
          <v-input v-model="form.lastName" />
          <span class="md-error" v-if="!$v.form.lastName.required">Vpišite priimek</span>
        </v-field>
      </template>

      <v-field :class="getValidationClass('email')">
        <label>Email</label>
        <v-input v-model="form.email" type="email" autocomplete="email" />
        <span class="md-error" v-if="!$v.form.email.required">Vpišite email naslov</span>
        <span class="md-error" v-if="$v.form.email.required && !$v.form.email.email">Neveljaven email naslov</span>
      </v-field>

      <v-field v-if="!isOmniauthUser" :class="getValidationClass('currentPassword')">
        <label>Trenutno geslo</label>
        <v-input v-model="form.currentPassword" type="password" autocomplete="current-password" />
        <span class="md-error" v-if="!$v.form.currentPassword.required">Za spreminjanje profila je potreben vpis trenutnega gesla</span>
      </v-field>

      <v-field v-if="!isOmniauthUser" :class="getValidationClass('password')">
        <label>Novo geslo</label>
        <v-input v-model="form.password" type="password" autocomplete="new-password" />
        <span class="md-error" v-if="$v.form.password && $v.form.password.required && !$v.form.password.minLength">Geslo mora biti dolgo najmanj 6 znakov</span>
      </v-field>

      <v-field v-if="!isOmniauthUser" :class="getValidationClass('confirmPassword')">
        <label>Ponovite novo geslo</label>
        <v-input v-model="form.confirmPassword" type="password" autocomplete="new-password" />
        <span class="md-error" v-if="!$v.form.confirmPassword.sameAs">Gesli se ne ujemata</span>
      </v-field>

      <v-button type="submit" :disabled="isDisabled">Shrani</v-button>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import UserFormMixin from '@/components/shared/UserFormMixin'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import VCheckbox from 'design-system/VCheckbox.vue'

export default {
  components: { VButton, VField, VInput, VCheckbox },
  props: {
    user: { type: Object, default: null },
    isDisabled: { type: Boolean, default: false },
  },
  data () {
    return {
      isEditPage: true,
    }
  },
  mixins: [ValidationMixin, UserFormMixin],
  computed: {
    isOmniauthUser () {
      if (!this.user) {
        return false
      }
      return !!this.user.provider
    },
  },
  validations () {
    const validations = {
      form: {},
    }
    if (!this.isOmniauthUser) {
      validations.form = {
        currentPassword: { required: required },
      }
    }
    if (!this.isCompany) {
      validations.form.firstName = { required: required }
      validations.form.lastName = { required: required }
    }
    return validations
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/colors';
@import '../common';

.user-form {
  & hr {
      border: 0;
      border: 1px solid $border-gray;
      margin: 16px -32px;
  }
}
</style>

