import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'

const state = {
  textContentId: null,
  textContent: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {},
  actions: {
    initialize ({ commit, dispatch }, textContentId) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_TEXT_CONTENT_ID', textContentId)

      return dispatch('fetchTextContent')
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju tekstovnega sestavka, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    async fetchTextContent ({ commit, state }) {
      if (!state.textContentId) {
        return
      }

      return axios
        .get(`/text_contents/${state.textContentId}?expand=1`)
        .then(response => commit('SET_TEXT_CONTENT', response.data))
    },
    submitTextContent ({ dispatch, commit, state }, textContent) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewTextContent = state.textContentId == null
      const promise = isCreatingNewTextContent ? dispatch('createTextContent', textContent) : dispatch('updateTextContent', textContent)

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Tekstovni sestavek uspešno shranjen.', type: 'success' }, { root: true })
          if (isCreatingNewTextContent) {
            router.push({ name: 'admin_text_contents_path' })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text:`Napaka pri shranjevanju tekstovnega sestavka - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createTextContent ({ commit }, textContent) {
      const payload = { text_content: snakeKeys(textContent, { recursive: true, recursiveInArray: true }) }
      return axios
        .post('/text_contents', payload)
        .then(response => commit('SET_TEXT_CONTENT_ID', response.data.id))
    },
    updateTextContent ({ state }, textContent) {
      if (!state.textContentId) {
        throw new Error('Cannot update a text content without having the id')
      }

      const payload = { text_content: snakeKeys(textContent, { recursive: true, recursiveInArray: true }) }
      return axios.put(`/text_contents/${state.textContentId}`, payload)
    },
    deleteTextContent ({ commit, dispatch }, textContentId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/text_contents/${textContentId}`)
        .then(() => router.push({ name: 'admin_text_contents_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju tekstovnega sestavka, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_TEXT_CONTENT (state, textContent) {
      state.textContent = camelKeys(textContent, { recursive: true, recursiveInArray: true })
    },
    SET_TEXT_CONTENT_ID (state, textContentId) {
      state.textContentId = textContentId
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
