export default {
  mounted () {
    if (!this.storeModule || !Array.isArray(this.storeModule)) {
      throw new Error('storeModule has to be set and has to be an array when using PaginationMixin!')
    }
  },
  computed: {
    pagination () {
      let state = this.$store.state
      this.storeModule.forEach(module => {
        state = state[module]
      })

      return {
        currentPage: state.page,
        totalPages: state.numberOfPages,
        totalResults: state.totalResults,
        perPage: state.perPage,
      }
    },
  },
}
