<template>
  <div class="contact-form">
    <h1 class="title">Pošljite povpraševanje</h1>
    <div class="form-body">
      <div class="form-row">
        <v-field class="form-field" :class="getValidationClass('name')">
          <label>Ime</label>
          <v-input v-model="form.name" />
          <span class="md-error" v-if="!$v.form.name.required">Vpišite ime</span>
        </v-field>
        <v-field class="form-field" :class="getValidationClass('surname')">
          <label>Priimek</label>
          <v-input v-model="form.surname" />
        </v-field>
      </div>
      <v-field class="form-field" :class="getValidationClass('companyName')">
        <label>Ime vašega podjetja</label>
        <v-input v-model="form.companyName" />
      </v-field>
      <v-field class="form-field" :class="getValidationClass('email')">
        <label>Email</label>
        <v-input v-model="form.email" />
        <span class="md-error" v-if="!$v.form.email.required">Vpišite email</span>
      </v-field>
      <v-field class="form-field" :class="getValidationClass('message')">
        <label>Vaše sporočilo</label>
        <v-textarea v-model="form.message" />
        <span class="md-error" v-if="!$v.form.message.required">Vpišite vaše sporočilo</span>
      </v-field>
    </div>

    <v-button v-if="isSent" class="form-button" background-color="white" color="modrn-green-dark" @click="submit">
      <icon class="form-button-icon" name="checkmark-circle" :width="20" :height="20" /> &nbsp;&nbsp; Sporočilo je bilo uspešno poslano
    </v-button>

    <v-button v-else class="form-button" background-color="white" color="modrn-green-dark" @click="submit">
      Pošlji povpraševanje &nbsp;&nbsp; <v-icon>chevron_right</v-icon>
    </v-button>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import Icon from 'design-system/icons/Icon.vue'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VInput from 'design-system/VInput.vue'
import VTextarea from 'design-system/VTextarea.vue'

export default {
  components: {
    Icon,
    VButton,
    VField,
    VIcon,
    VInput,
    VTextarea,
  },
  props: {
    isSent: { type: Boolean, default: false },
  },
  mixins: [ValidationMixin],
  data () {
    return {
      form: {
        name: null,
        surname: null,
        companyName: null,
        email: null,
        message: null,
      },
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit', this.form)
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      message: {
        required,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.title {
  padding-bottom: 32px;;
  font-size: 54px;
  line-height: 64px;
  font-weight: 600;
  letter-spacing: -0.81px;
  color: white;

  @include respond-to('small') {
    font-size: 32px;
    line-height: 40px;
  }
}

.form-body {
  padding: 0 8px 68px 8px;
}

.form-row {
  display: flex;

  @include respond-to('small') {
    display: block;
  }
  .form-field {
    padding-right: 32px;

    @include respond-to('small') {
      padding-right: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.form-button {
  display: flex;
  margin: auto;
}

.form-button-icon {
  padding-top: 4px;
}

/deep/ .md-focused.md-focused.md-focused {
  label {
    color: white;
  }
}
</style>
