<template>
  <form novalidate @submit.prevent="submit" v-if="promotionCode" class="entity-form">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />
    <promotion-code-form-fields ref="promotionCodeFormFields" :promotion-code="promotionCode" @change="updatePromotionCodeObject" />

    <br />
    <div class="u-flex u-space-between">
      <v-button type="submit" class="regular-button" :disabled="isLoading">Shrani</v-button>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import PromotionCodeFormFields from './PromotionCodeFormFields'
import VButton from 'design-system/VButton.vue'
import VProgressBar from 'design-system/VProgressBar.vue'

export default {
  components: {
    PromotionCodeFormFields,
    VButton,
    VProgressBar,
  },
  mixins: [BackMixin],
  computed: {
    ...mapState('admin/promotionCodes/forms', ['promotionCode']),
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('admin/promotionCodes/forms', ['updatePromotionCodeObject']),
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit')
    },
    runAllValidations () {
      this.$refs.promotionCodeFormFields.runAllValidations()
    },
    isValid () {
      return this.$refs.promotionCodeFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
