<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <h1 class="page-title">Dodaj Oznako po meri</h1>
    <attribute-key-form v-show="!isLoading" @submit="submitAttributeKey" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AttributeKeyForm from './forms/AttributeKeyForm'

export default {
  components: {
    AttributeKeyForm,
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('accountStore/attributeKeys/forms', ['initialize', 'reset', 'submitAttributeKey']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
</style>
