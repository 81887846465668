<template>
  <div class="table-loading">
    <div class="table-loading__background" />
    <v-progress-spinner class="md-accent" :md-diameter="40" :md-stroke="3" md-mode="indeterminate"></v-progress-spinner>
  </div>
</template>

<script>
import VProgressSpinner from 'design-system/VProgressSpinner.vue'
export default {
  components: {
    VProgressSpinner,
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

$table_footer_height: 58px;

.table-loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - #{$table_footer_height});
  z-index: $z_pop;

  &__background {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.15;
    border-radius: 8px;
  }

  .md-progress-spinner {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
}
</style>
