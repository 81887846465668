<template>
  <div class="document-composition__configuration-wrapper">
    <div class="document-composition__scrollable document-composition__configuration">
      <div class="document-composition__configuration-header">
        <document-composition-progress />
      </div>

      <div class="document-composition__configuration-elements">
        <configuration-element
          class="document-composition__configuration-element"
          v-for="configurationObject in visibleConfigurationObjects" :key="`${configurationObject.type}-${configurationObject.id}`"
          :configuration-object="configurationObject"
          @input="onConfigurationObjectChange(configurationObject.type, configurationObject.id, $event)"
          @variableFocus="$emit('variableFocus', configurationObject.id)"
          @conditionOutcomeChange="$emit('conditionOutcomeChange', $event)"
          @duplicate="duplicateTextSegment($event)"
          @remove="removeDuplicateTextSegment($event)"
        />
      </div>
    </div>
    <div class="document-composition__button">
      <v-tooltip v-if="saveOrCheckoutButtonTooltip" :md-active.sync="tooltipActive">{{ saveOrCheckoutButtonTooltip }}</v-tooltip>
      <v-button :disabled="!isConfigurationValid" @click="saveDocument">
        Prenesi
      </v-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Progress from './Progress'
import ConfigurationElement from './ConfigurationElement'
import VButton from 'design-system/VButton.vue'
import VTooltip from 'design-system/VTooltip.vue'

export default {
  components: {
    DocumentCompositionProgress: Progress,
    ConfigurationElement,
    VButton,
    VTooltip,
  },
  data () {
    return {
      tooltipActive: false,
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters('pages/documentComposition', ['isConfigurationValid', 'visibleConfigurationObjects', 'isDocumentCompositionFinished']),
    saveOrCheckoutButtonTooltip () {
      if (!this.isConfigurationValid) {
        return 'Za prenos dokumenta morate izpolniti vsa polja.'
      }
      if (!this.isDocumentCompositionFinished) {
        return 'V dokumentu niste izpolnili vseh variabel, dokument lahko še vedno prenesete.'
      }

      return null
    },
    mappedConfigurationObjects () {
      const mappedConfigurationObjects = []
      // only display variable title for first variable in group
      let lastTitle = null
      this.visibleConfigurationObjects.forEach(configurationObject => {
        if (configurationObject.type === 'variable' && configurationObject.title === lastTitle) {
          configurationObject.title = null
        }

        mappedConfigurationObjects.push(configurationObject)
        if (configurationObject.type === 'variable') {
          lastTitle = configurationObject.title
        }
      })
      return mappedConfigurationObjects
    },
  },
  methods: {
    ...mapActions(['openAuthModal', 'setAuthModalTab']),
    ...mapActions('pages/documentComposition', ['setConfigurationObject', 'duplicateTextSegment', 'removeDuplicateTextSegment']),
    onConfigurationObjectChange (configurationObjectType, id, $event) {
      this.setConfigurationObject({
        configurationObjectType,
        id,
        value: $event,
      })
    },
    async saveDocument () {
      if (!this.isConfigurationValid) {
        this.tooltipActive = true
        return
      }

      this.$emit('saveDocument')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';
@import './scrollable';

$document_composition_button_height: 103px;

.document-composition__configuration.document-composition__configuration {
  max-height: calc(#{$scrollable_max_height} - #{$document_composition_button_height});

  @include respond-to('small') {
    max-height: calc(#{$scrollable_max_height} - #{$document_composition_button_height} + 20px);
  }
}

.document-composition__configuration-header {
  position: sticky;
  z-index: 10;
  top: 0;
  overflow: hidden;
  padding-top: 16px;
  background-color: $light_gray_background_color;
}

.document-composition__configuration-header,
.document-composition__configuration-elements {
  padding-right: 24px;
}

.document-composition__configuration-element:first-of-type {
  .document-composition__input-field {
    margin-top: 0;
  }
}

.document-composition__button {
  margin: 8px 32px 32px 0;

  @include respond-to('small') {
    margin: 8px;
  }
}

.document-composition__button /deep/ .md-button {
  width: 100%;
}
</style>
