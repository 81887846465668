<template>
  <div class="hero" :class="{ 'hero--green': color === 'green', 'hero--with-image': hasImage }">
    <div class="hero-background"></div>
    <div class="hero-gradient"></div>

    <svg class="hero-ellipse hero-ellipse-1" preserveAspectRatio="xMinYMin slice" viewBox="0 0 773 833" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity=".6" d="M1247.87 94.01c18.85 35.992 19.67 79.311 4.92 126.876-14.74 47.567-45.04 99.345-88.35 152.2-86.63 105.709-225.249 215.649-395.335 304.74-170.085 89.092-339.392 140.445-475.616 151.465-68.113 5.511-127.93.936-175.429-14.027-47.497-14.962-82.644-40.297-101.497-76.29-18.853-35.993-19.668-79.311-4.923-126.876C26.387 564.53 56.685 512.753 100 459.897c86.626-105.708 225.245-215.648 395.33-304.74C665.415 66.065 834.722 14.713 970.946 3.692c68.114-5.51 127.934-.936 175.434 14.027 47.49 14.962 82.64 40.298 101.49 76.29Z" stroke="currentColor" />
    </svg>
    <svg class="hero-ellipse hero-ellipse-2" preserveAspectRatio="xMinYMin slice" viewBox="0 0 259 580" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M222.831-108.587c19.542 15.66 30.736 39.91 34.196 70.427 3.46 30.52-.821 67.273-12.195 107.863C222.084 150.88 170.99 247.31 96.926 339.73 22.862 432.152-60.12 503.028-134.39 542.917c-37.136 19.945-72.073 32.13-102.614 35.402-30.538 3.273-56.644-2.369-76.186-18.029-19.542-15.66-30.735-39.91-34.195-70.427-3.461-30.521.82-67.273 12.194-107.863 22.749-81.178 73.843-177.607 147.907-270.028C-113.221 19.552-30.24-51.325 44.031-91.214c37.137-19.944 72.073-32.13 102.615-35.402 30.538-3.272 56.644 2.369 76.185 18.029Z" stroke="currentColor" stroke-opacity=".6" />
    </svg>
    <svg class="hero-ellipse hero-ellipse-3" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 524 200">
      <path opacity=".6" d="M656.5.5c-18.833 37.5-101 123.1-279 165.5-178 42.4-325.5 35.667-377 27" stroke="currentColor" stroke-linecap="round" />
    </svg>

    <div class="hero-content">
      <div class="hero-text">
        <h1 class="hero-heading" v-if="heading">{{ heading }}</h1>
        <div class="hero-subheading" v-if="subheading">{{ subheading }}</div>
      </div>
      <img class="hero-image" v-if="hasImage" :alt="heading" :src="require('app/assets/images/extensions.png')" />
    </div>
    <slot />
    <v-button
      v-if="buttonText"
      :background-color="buttonColor"
      @click="$emit('click')"
    >
      {{ buttonText }} &nbsp;&nbsp; <v-icon>chevron_right</v-icon>
    </v-button>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'
import VIcon from 'design-system/VIcon.vue'

export default {
  components: {
    VButton,
    VIcon,
  },
  props: {
    heading: { type: String, default: null },
    subheading: { type: String, default: null },
    buttonText: { type: String, default: null },
    color: {
      type: String,
      default: 'blue',
      validator : value => ['blue', 'green'].includes(value),
    },
    hasImage: { type: Boolean, default: false },
  },
  computed: {
    buttonColor () {
      return this.color === 'blue' ? 'primary-blue' : 'modrn-green-dark'
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/variables';

.hero {
  position: inherit;
  overflow: hidden;
  padding: 144px 80px 112px 80px;
  width: 100%;
  margin: auto;
  text-align: center;

  @include respond-to(small) {
    padding: 76px 16px 32px 16px;
  }

  &--with-image {
    text-align: left;

    @include respond-to(small) {
      text-align: center;
    }

    .hero-content {
      display: flex;
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.hero--green {
  .hero-background {
    background: linear-gradient(117deg, #028277 0%, #92E1D5 56%, #03786E 102%);
    opacity: .25;
  }

  .hero-ellipse {
    color: $modrn-green-dark;
  }

  .hero-heading {
    color: $modrn-green-dark;
  }
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(123deg, #E5E6ED 8%, #DBDDE6 40%, #C9D1D6 87%);
  border-bottom-right-radius: 56px;
  border-bottom-left-radius: 56px;

  @include respond-to(small) {
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 32px;
  }
}

.hero-content.hero-content {
  @include respond-to(small) {
    display: block;
  }
}

.hero-gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 1300px;
  height: 600px;
  background: radial-gradient(50% 50% at 50% 50%, #BACCDD 0%, rgba(171, 189, 206, 0) 100%);
}

.hero-ellipse {
  position: absolute;
  color: $modrn-blue-dark;
}

.hero-ellipse-1 {
  width: 50%;
  left: 50%;
  top: 0;
}

.hero-ellipse-2 {
  width: 20%;
  left: 0;
  top: 0;
}

.hero-ellipse-3 {
  width: 30%;
  left: 80%;
  top: 40%;
}

.hero-text,
.hero-image {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-to(large) {
    width: 50%;
  }

  @include respond-to(small) {
    width: 100%;
    display: block;
  }
}

.hero-heading {
  position: relative;
  padding: 16px 0 24px 0;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  white-space: pre;
  color: $modrn-blue-dark;

  @include respond-to(small) {
    font-size: 40px;
    line-height: 50px;
    white-space: break-spaces;
  }
}

.hero-subheading {
  position: relative;
  padding-bottom: 4px;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  font-family: Merriweather;

  @include respond-to(small) {
    padding-bottom: 24px;
    white-space: break-spaces;
  }
}

.hero-slot {
  position: relative;
}

.hero-image {
  overflow: hidden;
}
</style>
