<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="admin__header">
      <h1 class="page-title">Uredi Račun</h1>
      <v-button class="delete-button" :disabled="isLoading" @click="onDelete" background-color="red" outlined>Izbriši</v-button>
    </div>
    <account-form v-show="!isLoading" @submit="submitAccount" @delete="onDelete" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AccountForm from './forms/AccountForm'
import VButton from 'design-system/VButton.vue'
import prompt from 'design-system/components/prompt/prompt'

export default {
  props: {
    id: { type: [String, Number], required: true },
  },
  components: {
    AccountForm,
    VButton,
  },
  created () {
    this.initialize(this.id)
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('admin/accounts/forms', ['initialize', 'reset', 'submitAccount', 'deleteAccount']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati račun? Akcija bo nepovratno izbrisala račun in vse entitete, ki mu pripadajo.')
      if (result) {
        this.deleteAccount(this.id).then(() => this.$router.push({ name: 'admin_accounts_path' }))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
