import axios from 'axios'
import router from '@/router'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'

const state = {
  finalizedDocument: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {},
  actions: {
    initialize ({ commit }, id) {
      commit('SET_IS_LOADING', true, { root: true })

      return axios.get(`/finalized_documents/${id}`)
        .then(response => commit('SET_FINALIZED_DOCUMENT', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    cancelESigning ({ commit, dispatch }, finalizedDocumentId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .put(`/finalized_documents/${finalizedDocumentId}/cancel_e_signing`)
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri preklicu e-podpisa, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => {
          commit('SET_IS_LOADING', false, { root: true })
          router.go()
        })
    },
    eSignDocument ({ commit, dispatch }, { finalizedDocumentId, signers }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const payload = {
        signers,
        finalizedDocumentId,
      }

      return axios
        .post('/eversign/create', snakeKeys(payload, { recursive: true, recursiveInArray: true }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri e-podpisovanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_FINALIZED_DOCUMENT (state, finalizedDocument) {
      state.finalizedDocument = camelKeys(finalizedDocument, { recursive: true, recursiveInArray: true })
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
