import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import sharedModule from './modules/shared'
import adminPanelModule from './modules/admin'
import accountModule from './modules/account'
import authModule from './modules/auth'
import pagesModule from './modules/pages'
import paymentsModule from './modules/payments'
import notificationsModule from './modules/notifications'
import eSignModule from './modules/account/e_sign'

const store = new Vuex.Store({
  ...sharedModule,
  modules: {
    admin: adminPanelModule,
    accountStore: accountModule,
    auth: authModule,
    pages: pagesModule,
    payments: paymentsModule,
    notifications: notificationsModule,
    eSign: eSignModule,
  },
})

export default store
