import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import router from '@/router'
import { getResponseErrorMessage, getAllRecords } from '@/store/utils'
import { deleteFinalizedDocument, getFinalizedDocumentPdfUrl } from '../utils.js'

const state = {
  finalizedDocumentAccountId: null,
  finalizedDocumentId: null,
  finalizedDocument: null,
  availableEnvelopes: [],
  availableCustomAttributeKeys: [],
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    finalizedDocumentId (state) {
      if (state.finalizedDocumentId) {
        return state.finalizedDocumentId
      }
      if (state.finalizedDocument) {
        return state.finalizedDocument.id
      }

      return null
    },
    finalizedDocument (state) {
      if (!state.finalizedDocument) {
        return null
      }

      return {
        ...state.finalizedDocument,
        previewUrl: getFinalizedDocumentPdfUrl(state.finalizedDocument.id, true),
        pdfDownloadUrl: getFinalizedDocumentPdfUrl(state.finalizedDocument.id),
      }
    },
    putPayload (state, _, rootState, rootGetters) {
      return {
        finalized_document: {
          account_id: state.finalizedDocumentAccountId ?? rootGetters.accountId,
          ...snakeKeys(state.finalizedDocument, { recursive: true, recursiveInArray: true }),
        },
      }
    },
    postPayload (_, getters, rootState) {
      const payload = getters.putPayload
      payload.userId = rootState.user.id
      return payload
    },
  },
  actions: {
    async initialize ({ commit, dispatch }, { finalizedDocumentId, envelopeId }) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_FINALIZED_DOCUMENT_ID', finalizedDocumentId)

      try {
        await Promise.all([
          dispatch('fetchEnvelopes'),
          dispatch('fetchCustomAttributeKeys'),
        ])

        if (finalizedDocumentId) {
          await dispatch('fetchFinalizedDocument')
        } else {
          const finalizedDocument = {}
          if (envelopeId) {
            finalizedDocument.envelopeId = envelopeId
          }
          commit('SET_FINALIZED_DOCUMENT', finalizedDocument)
        }
      } finally {
        commit('SET_IS_LOADING', false, { root: true })
      }
    },
    prefillFinalizedDocumentAttributesFromEnvelope ({ commit, state, rootGetters }, envelopeId) {
      return axios
        .get(`/finalized_documents?by_account_id=${rootGetters.accountId}&by_envelope_id=${envelopeId}&sort=-created_at&limit=1`)
        .then(finalizedDocumentsResponse => {
          const finalizedDocument = camelKeys(finalizedDocumentsResponse.data[0])
          if (finalizedDocument) {
            const updatedFinalizedDocument = {
              ...state.finalizedDocument,
              counterpartyName: finalizedDocument.counterpartyName,
              counterpartyRegistrationNumber: finalizedDocument.counterpartyRegistrationNumber,
            }
            commit('SET_FINALIZED_DOCUMENT', updatedFinalizedDocument)
          }
        })
    },
    fetchFinalizedDocument ({ commit, state }) {
      if (!state.finalizedDocumentId) {
        throw new Error('finalizedDocumentId should be set in state')
      }

      return axios
        .get(`/finalized_documents/${state.finalizedDocumentId}?expand=1`)
        .then(response => commit('SET_FINALIZED_DOCUMENT', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchEnvelopes ({ commit, rootGetters }) {
      return getAllRecords(`/envelopes?expand=1&by_account_id=${rootGetters.accountId}`)
        .then(records => commit('SET_AVAILABLE_ENVELOPES', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju map, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchCustomAttributeKeys ({ commit, rootGetters }) {
      return getAllRecords(`/custom_attribute_keys?by_account_id=${rootGetters.accountId}&expand=1`)
        .then(records => commit('SET_AVAILABLE_CUSTOM_ATTRIBUTE_KEYS', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju tipov oznak, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateFinalizedDocumentObject ({ commit, dispatch, state }, newFinalizedDocument) {
      const isEnvelopePresent = newFinalizedDocument.envelope && newFinalizedDocument.envelope.id
      const isEnvelopeIdDifferent = newFinalizedDocument.envelope.id !== state.finalizedDocument.envelopeId
      if (isEnvelopeIdDifferent) {
        newFinalizedDocument.envelopeId = newFinalizedDocument.envelope.id
      }
      if (isEnvelopePresent && isEnvelopeIdDifferent) {
        dispatch('prefillFinalizedDocumentAttributesFromEnvelope', newFinalizedDocument.envelope.id)
      }
      commit('UPDATE_FINALIZED_DOCUMENT_OBJECT', newFinalizedDocument)
    },
    submitFinalizedDocument ({ commit, dispatch, getters }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewFinalizedDocument = getters.finalizedDocumentId == null
      const promise = isCreatingNewFinalizedDocument ? dispatch('createFinalizedDocument') : dispatch('updateFinalizedDocument')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Dokument uspešno shranjen.', type: 'info' }, { root: true })
          router.push({ name: 'account_finalized_documents_path' })
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju dokumenta - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createFinalizedDocument ({ commit, getters }) {
      return axios
        .post('/finalized_documents', getters.postPayload)
        .then(response => commit('SET_FINALIZED_DOCUMENT_ID', response.data.id))
    },
    updateFinalizedDocument ({ state, getters }) {
      if (!state.finalizedDocumentId) {
        throw new Error('finalizedDocumentId should be set in state')
      }

      return axios.put(`/finalized_documents/${state.finalizedDocumentId}`, getters.putPayload)
    },
    deleteFinalizedDocument ({ commit }, finalizedDocumentId) {
      return deleteFinalizedDocument(commit, finalizedDocumentId)
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_FINALIZED_DOCUMENT_ID (state, finalizedDocumentId) {
      state.finalizedDocumentId = finalizedDocumentId
    },
    SET_FINALIZED_DOCUMENT (state, finalizedDocument) {
      state.finalizedDocument = camelKeys(finalizedDocument, { recursive: true, recursiveInArray: true })
      if (finalizedDocument.envelope && finalizedDocument.envelope.account_id) {
        state.finalizedDocumentAccountId = finalizedDocument.envelope.account_id
      }
    },
    UPDATE_FINALIZED_DOCUMENT_OBJECT (state, newFinalizedDocument) {
      if (newFinalizedDocument.envelope && newFinalizedDocument.envelope.id) {
        newFinalizedDocument.envelopeId = newFinalizedDocument.envelope.id
      }

      state.finalizedDocument = {
        ...state.finalizedDocument,
        ...newFinalizedDocument,
      }
    },
    SET_AVAILABLE_ENVELOPES (state, availableEnvelopes) {
      state.availableEnvelopes = availableEnvelopes.map(e => camelKeys(e, { recursive: true, recursiveInArray: true }))
    },
    SET_AVAILABLE_CUSTOM_ATTRIBUTE_KEYS (state, availableCustomAttributeKeys) {
      state.availableCustomAttributeKeys = availableCustomAttributeKeys.map(customAttributeKey => camelKeys(customAttributeKey, { recursive: true, recursiveInArray: true }))
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
