<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="admin__header">
      <h1 class="page-title">Uredi Dokument</h1>
      <div>
        <a class="link-in-button" v-if="!isLoading && document" :href="exportDocumentPath" :download="`${document.name}.json`"><v-button>Izvozi</v-button></a>
        <v-button class="delete-button" :disabled="isLoading" @click="onDelete" background-color="red" outlined>Izbriši</v-button>
      </div>
    </div>
    <document-form v-show="!isLoading" @submit="submitDocument" @delete="onDelete" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import DocumentForm from './forms/DocumentForm'
import VButton from 'design-system/VButton.vue'
import prompt from 'design-system/components/prompt/prompt'

export default {
  props: {
    id: { type: [String, Number], required: true },
  },
  components: {
    DocumentForm,
    VButton,
  },
  created () {
    this.initialize(this.id)
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('admin/documents/forms', ['document']),
    ...mapGetters('admin/documents/forms', ['exportDocumentPath']),
  },
  methods: {
    ...mapActions('admin/documents/forms', ['initialize', 'reset', 'submitDocument', 'deleteDocument']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati dokument? Akcija bo nepovratno izbrisala dokument vključno z vso vsebino.')
      if (result) {
        this.deleteDocument(this.id).then(() => this.$router.push({ name: 'admin_documents_path' }))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
