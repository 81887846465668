<template>
  <md-table-cell>
    <div>{{ getCustomAttributeColumnValue(finalizedDocument, column) }}</div>
  </md-table-cell>
</template>

<script>
import FinalizedDocumentDisplayMixin from '../../shared/FinalizedDocumentDisplayMixin'

export default {
  props: {
    finalizedDocument: { type: Object, required: true },
    column: { type: Object, required: true },
  },
  mixins: [FinalizedDocumentDisplayMixin],
  methods: {
    getCustomAttributeColumnValue (finalizedDocument, column) {
      const customAttribute = finalizedDocument.customAttributes.find(attribute => attribute.customAttributeKeyId === column.id)
      if (!customAttribute) {
        return '/'
      }

      // TODO: move logic to store
      const isDateAttribute = customAttribute.customAttributeKeyType === 'date'
      const customAttributeValue = isDateAttribute ? customAttribute.value : customAttribute.effectiveValue
      return this.formatColumnValue(customAttributeValue, isDateAttribute)
    },
  },
}
</script>
