import { render, staticRenderFns } from "./_Header.vue?vue&type=template&id=2b5ed096&scoped=true&"
import script from "./_Header.vue?vue&type=script&lang=js&"
export * from "./_Header.vue?vue&type=script&lang=js&"
import style0 from "./_Header.vue?vue&type=style&index=0&id=2b5ed096&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5ed096",
  null
  
)

export default component.exports