<template>
  <div class="text">
    <h1>Zasebnost</h1>

    <br />

    <h2>Podatki o upravljavcu</h2>
    Dragi obiskovalec, pri uporabi spletne strani www.pigenta.com in njenih podstrani ("spletna stran") bomo v družbi zero2one, digitalni laboratorij, d.o.o., Litijska cesta 152, 1000 Ljubljana, matična št. 8581355000, davčna št. 35408944 ("upravljavec") pridobili in obdelovali vaše osebne podatke.

    <h2>Pridobitev podatkov</h2>
    Vaše osebne podatke navadno pridobimo na tri načine:
    <ul>
      <li>z namestitvijo piškotkov na vašo napravo,</li>
      <li>ko kreirate uporabniški račun oz. ga posodobite z dodatnimi osebnimi podatki,</li>
      <li>ko v vzorce vnašate osebne podatke.</li>
    </ul>

    <h2>Pravne podlage in tipi podatkov</h2>
    Tipi osebnih podatkov, ki jih bomo obdelovali, so predvsem vaše ime in priimek, naslov, email, podatek o tipu plačilnega sredstva ipd. Če boste na spletni strani ob izpolnjevanju vzorca vanj vnesli osebne podatke (npr. vaš transakcijski račun, davčna številka ipd.), bomo obdelovali tudi te.
    Vaše osebne podatke bomo obdelovali na naslednjih pravnih podlagah:
    <ul>
      <li>vaša privolitev (po točki a) prvega odstavka 6. člena Splošne uredbe o varstvu podatkov ("GDPR")) je podlaga za obdelavo vašega imena, priimka in emaila v primeru pošiljanja oglasnih emailov,</li>
      <li>potrebnost za sklenitev oz. za izvedbo pogodbe (po točki b) prvega odstavka 6. člena GDPR) je podlaga za obdelavo vašega imena, priimka in emaila ter osebnih podatkov, ki jih vnesete v vzorce. Če nam teh podatkov ne posredujete, si ne morete izdelati in prenesti izbranega vzorca. Te osebne podatke lahko obdelujejo tudi naši pogodeni sodelavci, ki nam nudijo gostovanje oz. za nas razvijajo in vzdržujejo programsko opremr. Razkrijemo jim lahko tudi druge osebne podatke, ki so nujno potrebni v zvezi z izvedbo njihovih storitev,</li>
      <li>naše zakonske obveznosti (po točki c) prvega odstavka 6. člena GDPR) so podlaga za obdelavo vašega imena, priimka, emaila in podatka o tipu plačilnega sredstva,</li>
      <li>naš zakonit interes (po točki f) prvega odstavka 6. člena GDPR) je podlaga za obdelavo osebnih podatkov, ki se nanašajo na način, trajanje, specifiko in število dostopov do spletne strani, njihov namen pa je preprečitev nameščanja škodljive programske opreme ali uporabe spletne strani na način, ki bi ogrozil ali vplival na njeno nemoteno ali pravilno delovanje.</li>
    </ul>

    Določene osebne podatke (npr. uporabniški profili) lahko posredujemo načrtovalcem naše prodajne strategije, ki nam pomagajo pri učinkovitosti naših prodajnih naporov.
    Na podlagi zakonitega interesa lahko vse osebne podatke razkrijemo morebitnim potencialnim in končnim strankam ali partnerjem v postopku prenosa ali prodaje podjetja ali sodelovanja v obliki skupnega podjema.
    Naš zakoniti interes nam tudi omogoča, da vse osebne podatke delimo s povezanimi družbami.

    V izogib nejasnostim izpostavljamo, da podatkov o vašem plačilnem sredstvu trajno ne obdelujemo. Ti podatki so na naših strežnikih prisotni le v kratkem času od vašega vnosa do posredovanja obdelovalcu plačil, takoj po tem pa so nemudoma trajno izbrisani. Niti v kratkem času, ko se podatki nahajajo na naših strežnikih, do njih ne moremo na noben način dostopati.
    Čeprav obdelujemo podatke, ki jih vnesete v vzorce, pa so ti kriptirani, tako da vanje nimamo vpogleda (t.i. zero-knowledge protocol).

    <h2>Pridobivanje osebnih podatkov s strani tretjih oseb</h2>
    V določenih primerih lahko vaše osebne podatke pridobimo od tretjih oseb. To se na primer zgodi v primeru, ko uporabniški račun kreirate tako, da nas (spletno stran) v vašem računu družbenega omrežja (Facebook ali Google; “družbeno omrežje”) pooblastite (avtorizirate), da iz vašega računa družbenega omrežja pridobivamo in za čas trajanja avtorizacije posodabljamo naslednje vaše osebne podatke: ime, priimek in email. Vse osebne podatke, ki jih bomo pridobili od družbenih omrežij in drugih tretjih oseb bomo hranili v skladu s tem pravilnikom. Po odreku avtorizacije od družbenih omrežij ne moremo več pridobivati vaših osebnih podatkov. Svoj uporabniški račun lahko kadarkoli izbrišete. S tem bodo izbrisani tudi vsi podatki pridobljeni s strani družbenih omrežij in drugih tretjih oseb.

    <h2>Čas hrambe</h2>
    Za hrambo istega osebnega podatka lahko sočasno obstaja več pravnih podlag in obdelovali ga bomo vse dokler za obdelavo obstaja vsaj ena pravna podlaga.
    Načeloma bomo osebne podatke, ki nam jih boste posredovali ob registraciji oz. jih boste vnesli v uporabniški račun, hranili do vaše zahteve po izbrisu uporabniškega računa. Pridržujemo si pravico, da vas o morebitnem samodejnem izbrisu vašega uporabniškega računa (npr. v primeru daljšega obdobja neaktivnosti) pravočasno obvestimo na email, ki ste ga vnesli v vaš uporabniški račun.
    Osebne podatke, ki so razvidni na računu, bomo na podlagi računovodskih predpisov hranili vsaj 10 let po poteku leta, v katerem je bil tak račun izdan.

    <h2>Vaše pravice</h2>
    Glede vaših osebnih podatkov imate pravico, da od nas preko emaila info@pigenta.com zahtevate dostop do osebnih podatkov, ki jih o vas hranimo, pri čemer vam moramo le-te zagotoviti v strukturirani, splošno uporabljani, strojno berljivi in interoperabilni obliki. Vaše osebne podatke lahko preko nastavitev uporabniškega profila kadarkoli popravite, če pa to ne bi bilo mogoče, nam za to lahko pišete na zgornji naslov. Prav tako lahko v nastavitvah vašega uporabniškega računa izberete dejanje izbrisa le-tega oziroma nam za to pišete na zgornji email. Dodatno imate pravico, da od nas zahtevate omejitev obdelave oz. ji ugovarjate. V kolikor pa menite, da vaše osebne podatke kršimo, pa lahko kadarkoli pri Informacijskem pooblaščencu podate prijavo kršitve.
    V kolikor je podlaga za privolitev obdelave katerega osebnega podatka vaša privolitev, jo lahko kadarkoli prekličete, pri čemer pa to ne vpliva na zakonitost obdelave podatkov, ki se je na podlagi privolitve izvajala do njegovega preklica.

    <h2>Spremembe</h2>
    Pridržujemo si pravico do spremembe tega pravilnika. Vse spremembe bodo objavljene na spletni strani. V primeru bistvenih sprememb pa boste o le-teh obveščeni tudi preko e-maila, navedenega v vašem uporabniškem računu.

    <h2>Piškotki</h2>
    Poglavje o piškotkih je sestavni del pravilnika zasebnosti. Za njegovo pravilno razumevanje je bistvena seznanjenost s splošnim delom pravilnika o zasebnosti. Slednji ureja vse vidike varstva osebnih podatkov, ki niso drugače urejeni v tem poglavju o piškotkih.
    Ob uporabni spletne strani se na vašo napravo namestijo piškotki. Piškotki so majhne tekstovne datoteke. Tako imenovani nujni piškotki se na vašo napravo namestijo na podlagi našega zakonitega interesa, ne glede na to, ali s tem soglašate, ali ne. Ti piškotki nam preko informacij, kot so tip vaše naprave in njen operacijski sistem omogočijo vsaj osnoven prikaz in delovanje spletne strani. Zgolj z vašo potrditvijo pa se na vašo napravo lahko namestijo funkcionalni in analitični piškotki. Namen funkcionalnih piškotkov je izboljšati vašo uporabniško izkušnjo preko hrambe vaših nastavietv, prilagoditve spletne strani lastnostim vaše naprave, omogočenju določenih funkcionalnosti ipd. Namen analitičnih piškotkov pa je spremljanje števila obiskov in vašega obnašanja na spletni strani kot npr. strani, ki ste si jih ogledali, čas, ki ste ga preživeli na spletni posamezni podstrani ipd.

    V nadaljevanju je tabela s piškotki, ki jih na vašo napravo lahko namesti naša spletna stran:
    <br />
    <br />
    <br />

    <div class="privacy__cookies-table-container">
      <table class="privacy__cookies-table">
        <tr>
          <th>Ime</th>
          <th>Vir</th>
          <th>Oznaka</th>
          <th>Tip</th>
          <th>Čas poteka</th>
          <th>Namen</th>
        </tr>
        <tr>
          <td>Prijavni</td>
          <td>Interno</td>
          <td><i>_rubikon_session</i></td>
          <td>Nujni</td>
          <td>po končanju seje</td>
          <td>Omogočanje registracije in prijave, omogočanje uporabniške seje.</td>
        </tr>
        <tr>
          <td>Izbira uporabe piškotkov</td>
          <td>Interno</td>
          <td><i>consent_status</i></td>
          <td>Nujni</td>
          <td>1 leto</td>
          <td>Shranjevanje izbire uporabe piškotkov.</td>
        </tr>
        <tr>
          <td>Google analytics</td>
          <td>Google</td>
          <td><i>_ga</i></td>
          <td>Analitični</td>
          <td>2 leti</td>
          <td>Zbiranje anonimiziranih podatkov o uporabi spletne strani.</td>
        </tr>
        <tr>
          <td>Google analytics</td>
          <td>Google</td>
          <td><i>_ga_06QVTDCYJ1</i></td>
          <td>Analitični</td>
          <td>2 leti</td>
          <td>Zbiranje anonimiziranih podatkov o uporabi spletne strani.</td>
        </tr>
        <tr>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped lang='scss'>
@import '../common';

@include respond-to(small) {
  .privacy__cookies-table-container {
    max-width: 100%;
    overflow-y: auto;
  }
}
</style>
