<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <entity-table
      :columns="columns"
      :items="accounts"
      :are-items-clickable="true"
      :is-loaded="!!numberOfPages"
      :pagination="pagination"
      title="Podjetja"
      add-text="Nov račun"
      :empty-text="emptyText"
      @search="setQuery"
      @page-change="goToPage"
      @click="openEditAccount"
      @add="$router.push({ name: 'admin_new_account_path' })"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import PaginationMixin from '../../shared/PaginationMixin'

export default {
  components: { EntityTable },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'Ime' },
      { id: 'created_at', name: 'Datum kreacije' },
    ]
  },
  created () {
    this.initialize()
    this.storeModule = ['admin', 'accounts']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('admin/accounts', ['accounts', 'numberOfPages', 'query']),
    emptyText () {
      return this.query ? 'Noben račun ne ustreza iskanju.' : 'Zaenkrat še nimate računov.'
    },
  },
  methods: {
    ...mapActions('admin/accounts', ['initialize', 'reset', 'setQuery', 'goToPage']),
    openEditAccount (account) {
      this.$router.push({
        name: 'admin_account_edit_path',
        params: {
          id: account.slug,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';
</style>
