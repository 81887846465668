<template>
  <div v-if="isShown" class="email-newsletter">
    <svg class="email-newsletter-gradient" viewBox="0 0 1440 481" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.32" filter="url(#filter0_f_1024_7832)">
        <rect y="120" width="1440" height="242" fill="#DBDDE6" />
      </g>
      <defs>
        <filter id="filter0_f_1024_7832" x="-216" y="-96" width="1872" height="674" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="108" result="effect1_foregroundBlur_1024_7832" />
        </filter>
      </defs>
    </svg>

    <div class="email-newsletter-heading">
      Novosti, najnovejši vzorci in pravni nasveti. Neposredno v vaš e-nabiralnik.
    </div>
    <div class="email-newsletter-text">
      Pridružite se posameznikom, pravnikom in gospodarskim družbam in prejemajte mesečne novice, ter obvladajte pravne izzive.
    </div>

    <div v-if="hasJustSubscribedToNewsletter" class="email-newsletter-success">
      <icon class="email-newsletter-success-icon" name="checkmark-circle" />
      <p>{{ successMessageToShow }}</p>
    </div>
    <template v-else>
      <div class="email-newsletter-form">
        <v-field :class="getValidationClass('email')">
          <label>Email</label>
          <v-input class="email-newsletter-input" v-model="form.email" type="email" />
          <span class="md-error" v-if="!$v.form.email.required">Vpišite email naslov</span>
          <span class="md-error" v-if="$v.form.email.required && !$v.form.email.email">Neveljaven email naslov</span>
        </v-field>
        <v-button class="email-newsletter-button" @click="submit" background-color="primary-blue" size="large">Prijavi se</v-button>
      </div>

      <div class="email-newsletter-text">
        Odjavite se lahko kadarkoli. Vaše podatke obdelujemo skladno z našo <router-link class="consent-link" :to="{ name: 'privacy_path' }" target="_blank">politiko zasebnosti</router-link>.
      </div>
    </template>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import Icon from 'design-system/icons/Icon.vue'
import VInput from 'design-system/VInput.vue'
import ValidationMixin from '@/components/shared/ValidationMixin'

export default {
  components: {
    VButton,
    VField,
    Icon,
    VInput,
  },
  mixins: [ValidationMixin],
  props: {
    useSecondaryMailingList: { type: Boolean, default: false },
  },
  data () {
    return {
      successMessage: null,
      hasJustSubscribedToNewsletter: false,
      form: {
        email: null,
      },
    }
  },
  created () {
    if (this.user) {
      this.form.email = this.user.email
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isLoggedIn']),
    isShown () {
      return !this.isLoggedIn || !(this.user.isSubscribedToNewsletter === true)
    },
    successMessageToShow () {
      return this.successMessage || 'Hvala za prijavo na naše novice!'
    },
  },
  methods: {
    ...mapActions(['subscribeToNewsletter']),
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.subscribeToNewsletter({
        email: this.form.email,
        useSecondaryMailingList: this.useSecondaryMailingList,
      }).then(message => {
        if (message) {
          this.successMessage = message
        }
        this.hasJustSubscribedToNewsletter = true
      })
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.email-newsletter {
  text-align: left;
  position: relative;
}

.email-newsletter-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.email-newsletter-heading {
  position: relative;
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  padding-bottom: 32px;
}

.email-newsletter-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
}

.email-newsletter-success,
.email-newsletter-form {
  display: flex;
}

.email-newsletter-form {
  /deep/ .md-field,
  /deep/ .md-input {
    min-width: 100px;
  }
}

.email-newsletter-success {
  justify-content: center;
  padding-top: 32px;
  font-size: 20px;
  font-weight: 600;
}

.email-newsletter-success-icon {
  margin-right: 16px;
  padding-top: 18px;
}

.email-newsletter-button {
  width: 160px;
  position: relative;
  top: 24px;
  margin-left: 96px;

  @include respond-to(small) {
    width: auto;
    min-width: 116px;
    margin-left: 8px;
  }
}
</style>
