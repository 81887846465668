import axios from 'axios'
import { getResponseErrorMessage } from '@/store/utils'
import { paginationState, paginationMutations } from '@/store/mixins/pagination'
import formsModule from './forms'

const state = {
  accounts: [],
  ...paginationState,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  modules: {
    forms: formsModule,
  },
  state: getState(),
  getters: {},
  actions: {
    initialize ({ dispatch }) {
      return dispatch('fetchAccounts')
    },
    setQuery ({ commit, dispatch }, query) {
      commit('SET_QUERY', query)
      return dispatch('fetchAccounts')
    },
    fetchAccounts ({ commit, state }) {
      commit('SET_IS_LOADING', true, { root: true })
      let url = `/accounts?page=${state.page}&limit=${state.perPage}`
      if (state.query) {
        url += `&by_name=${state.query}`
      }

      return axios
        .get(url)
        .then((response) => {
          commit('SET_ACCOUNTS', response.data)
          commit('SET_TOTAL_RESULTS', response.headers['total-count'])
          commit('SET_NUMBER_OF_PAGES', response.headers['total-pages'])
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju skupin, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    goToPage ({ commit, dispatch, state }, page) {
      if (page > 0 && page <= state.numberOfPages) {
        commit('SET_PAGE', page)
        return dispatch('fetchAccounts')
      }
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_ACCOUNTS (state, accounts) {
      state.accounts = accounts
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
    ...paginationMutations,
  },
}
