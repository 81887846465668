import accountsModule from './accounts'
import blogPostsModule from './blog_posts'
import documentsModule from './documents'
import promotionCodesModule from './promotion_codes'
import statsModule from './stats'
import textContentsModule from './text_contents'
import usersModule from './users'

export default {
  namespaced: true,
  modules: {
    accounts: accountsModule,
    blogPosts: blogPostsModule,
    documents: documentsModule,
    promotionCodes: promotionCodesModule,
    stats: statsModule,
    textContents: textContentsModule,
    users: usersModule,
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {},
}
