<template>
  <div class="pagination__container">
    <div class="pagination__results-text" v-if="totalResults">
      Prikazanih je {{ showingResultsFrom }} - {{ showingResultsTo }} od
      {{ totalResults }} rezultatov
    </div>
    <div class="pagination">
      <div
        class="pagination__prev-item"
        @click="onPrevClick"
        :class="{ 'pagination__prev-item--disabled': isPrevDisabled }"
      >
        <icon name="chevron-left" />
      </div>
      <div
        v-for="page in totalPages"
        :key="page"
        class="pagination__page-item"
        :class="{ 'pagination__page-item--active': page === currentPage }"
        @click="onPageClick(page)"
      >
        {{ page }}
      </div>
      <div
        class="pagination__next-item"
        @click="onNextClick"
        :class="{ 'pagination__next-item--disabled': isNextDisabled }"
      >
        <icon name="chevron-right" />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'
export default {
  components: { Icon },
  props: {
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    totalPages: {
      type: Number,
      required: true,
      default: 1,
    },
    next: {
      type: Number,
      required: true,
      default: null,
    },
    prev: {
      type: Number,
      required: true,
      default: null,
    },
    totalResults: {
      type: Number,
      required: true,
      default: 0,
    },
    perPage: {
      type: Number,
      required: true,
      default: 25,
    },
  },
  computed: {
    pages () {
      const pages = []
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i)
      }
      return pages
    },
    isPrevDisabled () {
      return !this.prev
    },
    isNextDisabled () {
      if (this.totalResults <= this.perPage * this.currentPage) {
        return true
      } else {
        return false
      }
    },
    showingResultsFrom () {
      return this.perPage * (this.currentPage - 1) + 1
    },
    showingResultsTo () {
      return Math.min(this.perPage * this.currentPage, this.totalResults)
    },
  },
  methods: {
    onPageClick (page) {
      this.$emit('page-change', page)
    },
    onPrevClick () {
      if (this.prev) {
        this.$emit('page-change', this.prev)
      }
    },
    onNextClick () {
      if (this.next) {
        this.$emit('page-change', this.next)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

$gray-300: #d1d5db;
$gray-500: #6b7280;
$gray-700: #374151;

.pagination__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @include respond-to(small) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.pagination__results-text {
  color: $gray-700;
  font-size: 14px;
  font-weight: 500;

  @include respond-to(small) {
    margin-bottom: 10px;
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  border: none;
  border-radius: 6px;
  background: $white;
  color: $gray-500;
  user-select: none;

  & .pagination__prev-item,
  .pagination__next-item,
  .pagination__page-item {
    display: flex;
    width: 38px;
    height: 38px;
    align-items: center;
    justify-content: center;
    border: 1px solid $input-light;
    cursor: pointer;

    &:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }

    // stylelint-disable-next-line selector-not-notation
    &:hover:not(.pagination__prev-item--disabled):not(.pagination__next-item--disabled) {
      background: $modrn-blue-50;
    }
  }

  & .pagination__prev-item,
  .pagination__next-item {
    color: $gray-500;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  & .pagination__page-item--active {
    border: 1px solid $modrn-blue;
    background: $modrn-blue-50;
  }
}
</style>
