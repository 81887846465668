<template>
  <div>
    <v-field :class="getValidationClass('name')">
      <label>Ime</label>
      <v-input v-model="form.name"></v-input>
      <span class="md-error" v-if="!$v.form.name.required">Vpišite ime</span>
    </v-field>

    <v-field>
      <label>Mapa, kjer so datoteke, ki definirajo izgled dokumentov</label>
      <v-input v-model="form.stylingFilesFolder"></v-input>
    </v-field>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VField, VInput },
  data () {
    return {
      form: {
        name: null,
        stylingFilesFolder: null,
      },
    }
  },
  props: {
    account: { type: Object, required: false },
  },
  mixins: [ValidationMixin],
  created () {
    if (!this.account) {
      return
    }
    this.form.name = this.account.name
    this.form.stylingFilesFolder = this.account.stylingFilesFolder
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.account,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      name: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
