<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <entity-table
      :columns="visibleColumns(columns)"
      :items="augmentedFinalizedDocuments"
      :is-pagination-present="false"
      :is-add-new-present="false"
      :is-header-sticky="true"
      title="Zadnje ustvarjeni"
      search-text="Išči po dokumentih"
      :empty-text="emptyText"
      @search="searchQuery = $event"
    >
      <md-table-row
        class="profile__table-row"
        v-for="finalizedDocument in augmentedFinalizedDocuments"
        :key="finalizedDocument.id"
      >
        <md-table-cell
          v-for="column in visibleColumns(textColumns)"
          :key="`${finalizedDocument.id}-${column.id}`"
          :class="getTableCellClass(column, finalizedDocument)"
        >
          <span v-if="column.id === 'name' && finalizedDocument.isPurchased" @click="openPreview(finalizedDocument, column.id)">
            {{ getFinalizedDocumentValue(finalizedDocument, column) }}
          </span>
          <template v-else-if="column.id === 'status'">
            <div class="profile__table-row--tag" :class="finalizedDocument.statusClass" @click="handleStatusClick(finalizedDocument)">
              <icon v-if="finalizedDocument.iconName" :name="finalizedDocument.iconName" :width="16" :height="16" />
              <span>{{ finalizedDocument.statusText }}</span>
              <icon v-if="finalizedDocument.hasArrowIcon" name="arrow-right" :width="14" :height="14" class="profile__table-row--arrow-icon" />
            </div>
            <div class="profile__table-row--icon">
              <icon name="alert-circle" v-if="!finalizedDocument.isPurchased"> </icon>
              <v-tooltip>Neplačani dokumenti se po 30 dneh samodejno izbrišejo.</v-tooltip>
            </div>
          </template>
          <span v-else>
            {{ getFinalizedDocumentValue(finalizedDocument, column) }}
          </span>
        </md-table-cell>
        <md-table-cell>
          <v-menu class="profile__table-row-download" :class="{ 'profile__table-row-download--disabled': !finalizedDocument.isPurchased }">
            <v-tooltip v-if="!finalizedDocument.isPurchased">Za prenos dokumenta je potrebno plačilo.</v-tooltip>
            <icon name="download" md-menu-trigger />
            <md-menu-content v-show="finalizedDocument.isPurchased" class="profile__table-row-download__dropdown">
              <a class="profile__table-row-download__dropdown__item" :href="finalizedDocument.pdfDownloadUrl">
                <icon name="file-pdf" />Prenesi PDF
              </a>
              <a class="profile__table-row-download__dropdown__item" :href="finalizedDocument.docxDownloadUrl">
                <icon name="file-text" />Prenesi DOCX
              </a>
            </md-menu-content>
          </v-menu>
        </md-table-cell>
      </md-table-row>
    </entity-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import VTooltip from 'design-system/VTooltip.vue'
import VMenu from 'design-system/VMenu.vue'
import Icon from 'design-system/icons/Icon.vue'
import FinalizedDocumentDisplayMixin from '../shared/FinalizedDocumentDisplayMixin'

export default {
  components: {
    EntityTable,
    VTooltip,
    Icon,
    VMenu,
  },
  props: {
    isNewDocumentPresent: { type: Boolean, default: false },
  },
  data () {
    return {
      checkoutFinalizedDocumentId: null,
      searchQuery: null,
    }
  },
  beforeCreate () {
    this.columns = [
      { id: 'name', name: 'Dokument' },
      { id: 'status', name: 'Stanje' },
      { id: 'accountName', name: 'Pigenta račun' },
      { id: 'createdAt', name: 'Datum' },
      { id: 'urls', name: 'Prenesi' },
    ]
    this.textColumns = this.columns.slice(0, -1)
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  mixins: [FinalizedDocumentDisplayMixin],
  computed: {
    ...mapState(['user', 'isLoading']),
    ...mapState(['account']),
    ...mapGetters(['getAccountIdForPath']),
    ...mapGetters('accountStore/recentFinalizedDocuments', ['finalizedDocumentsWithUrlsAndStatus']),
    isAccountNamePresent () {
      return this.finalizedDocumentsWithUrlsAndStatus.reduce((isAccountNamePresent, finalizedDocument) => {
        if (finalizedDocument.accountName) {
          return true
        }

        return isAccountNamePresent
      }, false)
    },
    filteredFinalizedDocuments () {
      if (!this.searchQuery) {
        return this.finalizedDocumentsWithUrlsAndStatus
      }

      return this.finalizedDocumentsWithUrlsAndStatus.filter(c => c.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
    augmentedFinalizedDocuments () {
      return this.filteredFinalizedDocuments.map(fd => this.getFinalizedDocumentWithStatus(fd, true))
    },
    emptyText () {
      return this.searchQuery ? 'Noben dokument ne ustreza iskanju.' : 'Niste ustvarili še nobenega dokumenta. Ustvarite prvega s klikom na "Vzorci"!'
    },
  },
  methods: {
    ...mapActions('accountStore/recentFinalizedDocuments', ['initialize', 'reset']),
    visibleColumns (columns) {
      if (this.isAccountNamePresent) {
        return columns
      } else {
        return columns.filter(c => c.id !== 'accountName')
      }
    },
    handleStatusClick (finalizedDocument) {
      if (finalizedDocument.isESigned || finalizedDocument.signedOn) {
        this.goToFinalizedDocumentPage(finalizedDocument)
        return
      }
      if (finalizedDocument.isWaitingESignature || finalizedDocument.isPurchased) {
        this.goToESignaturePage(finalizedDocument)
        return
      }
      this.goToPaymentPage(finalizedDocument)
    },
    goToPaymentPage (finalizedDocument) {
      this.checkoutFinalizedDocumentId = finalizedDocument.id
      this.$router.push({ name: 'finalized_document_payment', params: { id: finalizedDocument.id } })
    },
    goToFinalizedDocumentPage (finalizedDocument) {
      this.$router.push({
        name: 'account_finalized_documents_path',
        params: {
          accountId: this.getAccountIdForPath(finalizedDocument.accountId),
        },
        query: {
          id: finalizedDocument.id,
        },
      })
    },
    goToESignaturePage (finalizedDocument) {
      this.$router.push({
        name: 'account_finalized_document_e_signature_path',
        params: {
          id: finalizedDocument.id,
          accountId: this.getAccountIdForPath(finalizedDocument.accountId),
        },
      })
    },
    openPreview (finalizedDocument, columnId) {
      if (columnId !== 'name') {
        return
      }

      if (finalizedDocument.isPurchased) {
        window.open(finalizedDocument.previewUrl, '_blank')
      }
    },
    getFinalizedDocumentValue (finalizedDocument, column) {
      const value = finalizedDocument[column.id]
      const isDateColumn = column.id.endsWith('At')
      if (!isDateColumn) {
        return value
      }

      const dateValue = new Date(value)
      return dateValue.toLocaleDateString('sl')
    },
    getTableCellClass (column, finalizedDocument) {
      if (column.id === 'name' && finalizedDocument.isPurchased) {
        return 'profile__table-row--link'
      }
      if (column.id === 'name' && !finalizedDocument.isPurchased) {
        return 'profile__table-row--disabled-link'
      }
      if (column.id === 'status') {
        return 'profile__table-row--status'
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import '../../pages/common';

$status-gray: #E9EAF0;

.profile__table-row {
  &:not(:last-child) {
    border-bottom: 1px solid $table-border-gray
  }
}

.profile__table-row--link {
  & span {
    cursor: pointer;
  }
}

.profile__table-row--disabled-link {
  cursor: default;

  & span {
    color: $modrn-blue-dark-60;
  }
}

.profile__table-row--status {
  min-width: 274px;
}

.profile__table-row--tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 18px;
  border-radius: 100px;
  background: $red;
  color: $white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  max-width: 208px;
  cursor: pointer;

  & :not(:last-child) {
    margin-right: 8px;
  }

  & .icon-wrapper {
    display: flex;
    align-items: center;
  }

  & .profile__table-row--arrow-icon {
    margin-top: 4px;
  }
}

.status--signed {
  background: $status-gray;
  color: $black;
}

.status--waiting-signature {
  background: $status-signing;
}

.status--signed {
  max-width: 227px;
}

.status--signable {
  background: $primary-blue;
  color: $white;
}

.status--unavailable {
  background: $status-gray;
  color: $black;
  cursor: default;
}

.profile__table-row-download {
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  & .icon-wrapper {
    color: #667877;
  }

}

.profile__table-row-download__dropdown {
  & /deep/ .md-list {
    border: none;
    border-radius: 8px;
    // stylelint-disable-next-line
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

  }

  & .profile__table-row-download__dropdown__item {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    margin: 8px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;

    & .icon-wrapper {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    &:hover {
      border-radius: 8px;
      background-color: #F1F2F7;
      color: $primary-blue;
    }

  }
}

.profile__table-row--icon {
  position: absolute;
  top: 17px;
  left: 246px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red;
  cursor: pointer;
}

/deep/ .v-table {
  padding-bottom: 13px;
  border: none;
  // stylelint-disable-next-line
  filter: drop-shadow(0 6px 16px rgba(0, 0, 0, 0.05));

  tr {
    background: $white;
  }
}

</style>
