<template>
  <div class="document-composition__progress">
    <div class="document-composition__progress-bar-container">
      <v-progress-bar class="document-composition__progress-bar md-accent" md-mode="determinate" :md-value="progressToDisplay" />
      <div class="document-composition__progress-value">{{ progressValueToDisplay }}%</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VProgressBar from 'design-system/VProgressBar.vue'
export default {
  components: {
    VProgressBar,
  },
  computed: {
    ...mapGetters('pages/documentComposition', ['progress']),
    progressToDisplay () {
      return Math.max(1, this.progress)
    },
    progressValueToDisplay () {
      return Math.round(this.progressToDisplay)
    },
  },
}
</script>

<style lang="scss" scoped>
.document-composition__progress {
  display: flex;
  margin-bottom: 12px;
}

.document-composition__progress-value {
  width: 100%;
  text-align: center;
  position: relative;
  top: -16px;
  color: white;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
}

.document-composition__progress-bar {
  height: 16px;
  position: relative;
  width: auto;
  min-width: 100px;
  border-radius: 8px;
  z-index: 0;
}

.document-composition__progress-bar-container {
  width: 100%;
  margin-top: 5px;
}
</style>
