<template>
  <v-snackbar class="toast" md-position="center" :md-duration="Infinity" :md-active="isVisible" :type="messageType">
    <span>{{ messageText }}</span>
    <div class="toast__close" @click="emitClosed">
      <v-icon class="toast__close-icon">close</v-icon>
    </div>
  </v-snackbar>
</template>

<script>
import VIcon from 'design-system/VIcon.vue'
import VSnackbar from 'design-system/VSnackbar.vue'

export default {
  components: { VIcon, VSnackbar },
  props: {
    message: {
      type: Object,
      default: () => ({
        text: '',
        type: 'info',
      }),
    },
  },
  computed: {
    messageText () {
      if (!this.message) {
        return null
      }

      return this.message.text
    },
    messageType () {
      const defaultType = 'info'
      if (!this.message) {
        return defaultType
      }

      return this.message.type || defaultType
    },
    isVisible () {
      return !!this.message?.text
    },
  },
  methods: {
    emitClosed () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.toast {
  z-index: $z_heaven;
  background-color: $secondary_color;
}

.toast__close {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
</style>
