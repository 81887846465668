<template>
  <md-radio
    :model="model"
    :value="value"
    @change="$emit('change', $event)"
    v-bind="$attrs"
  >
    <slot></slot>
  </md-radio>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Boolean, Object],
    },
    model: {
      type: [String, Number, Boolean, Object],
    },
  },
  model: {
    prop: 'model',
    event: 'change',
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/colors";

.md-radio.md-theme-default /deep/ .md-radio-container,
.md-radio.md-theme-default.md-disabled /deep/ .md-radio-container,
.md-radio.md-theme-default.md-disabled.md-checked /deep/ .md-radio-container {
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-color: $input-light;
  background-color: white;
}

.md-radio.md-theme-default.md-disabled.md-checked /deep/ .md-radio-container::after {
  background-color: $input-light;
}

.md-radio /deep/ .md-radio-label {
  height: fit-content;
  padding: 4px 0 0 12px;
  font-size: 16px;
}

.md-radio.md-disabled /deep/ .md-radio-label {
  color: $input-light;
}
</style>
