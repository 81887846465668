import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'

const state = {
  envelopeId: null,
  envelope: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    envelopeId (state) {
      if (state.envelopeId) {
        return state.envelopeId
      }
      if (state.envelope) {
        return state.envelope.id
      }

      return null
    },
    payload (state, _, rootState, rootGetters) {
      return {
        envelope: {
          account_id: rootGetters.accountId,
          ...snakeKeys(state.envelope, { recursive: true, recursiveInArray: true }),
        },
      }
    },
  },
  actions: {
    setEnvelopeId ({ commit }, envelopeId) {
      commit('SET_ENVELOPE_ID', envelopeId)
    },
    initialize ({ commit, dispatch, state }) {
      commit('SET_IS_LOADING', true, { root: true })

      const promises = []
      if (state.envelopeId) {
        promises.push(dispatch('fetchEnvelope'))
      } else {
        commit('SET_ENVELOPE', {})
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchEnvelope ({ commit, state }) {
      if (!state.envelopeId) {
        throw new Error('envelopeId should be set in the state')
      }

      return axios
        .get(`/envelopes/${state.envelopeId}`)
        .then(response => commit('SET_ENVELOPE', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju mape, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateEnvelopeObject ({ commit }, newEnvelope) {
      commit('UPDATE_ENVELOPE_OBJECT', newEnvelope)
    },
    submitEnvelope ({ commit, dispatch, state }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewEnvelope = state.envelopeId == null
      const promise = isCreatingNewEnvelope ? dispatch('createEnvelope') : dispatch('updateEnvelope')

      return promise
        .then(() => commit('SET_MESSAGE', { text: 'Mapa uspešno shranjena.', type: 'success' }, { root: true }))
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju mape - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createEnvelope ({ getters }) {
      return axios.post('/envelopes', getters.payload)
    },
    updateEnvelope ({ state, getters }) {
      if (!state.envelopeId) {
        throw new Error('envelopeId should be set in state')
      }

      return axios.put(`/envelopes/${state.envelopeId}`, getters.payload)
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_ENVELOPE_ID (state, envelopeId) {
      state.envelopeId = envelopeId
    },
    SET_ENVELOPE (state, envelope) {
      state.envelope = camelKeys(envelope, { recursive: true, recursiveInArray: true })
    },
    UPDATE_ENVELOPE_OBJECT (state, newEnvelope) {
      if (newEnvelope.envelope && newEnvelope.envelope.id) {
        newEnvelope.envelopeId = newEnvelope.envelope.id
      }

      state.envelope = {
        ...state.envelope,
        ...newEnvelope,
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
