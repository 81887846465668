import axios from 'axios'
import { getResponseErrorMessage } from '@/store/utils'
import { paginationState, paginationMutations } from '@/store/mixins/pagination'
import formsModule from './forms'

const state = {
  textContents: [],
  filters: [],
  documentNames: [],
  ...paginationState,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  modules: {
    forms: formsModule,
  },
  state: getState(),
  getters: {
    availableFilters (state) {
      return [
        {
          id: 'by_document_name',
          apiField: 'by_document_name',
          label: 'Dokument',
          type: 'list',
          options: state.documentNames.map(documentName => {
            return {
              id: documentName,
              label: documentName,
            }
          }),
        },
      ]
    },
    filtersWithValues (state) {
      return state.filters.filter(filter => filter.valueId)
    },
    textContentsUrl (state, getters) {
      let textContentsUrl = `/text_contents?page=${state.page}&limit=${state.perPage}`

      if (state.query) {
        textContentsUrl += `&by_query=${encodeURIComponent(state.query)}`
      }

      getters.filtersWithValues.forEach(filter => {
        textContentsUrl += `&${filter.apiField}=${filter.valueId}`
      })

      return textContentsUrl
    },
  },
  actions: {
    initialize ({ dispatch }) {
      return Promise.all([
        dispatch('fetchTextContents'),
        dispatch('fetchDocumentNames'),
      ])
    },
    setFilters ({ commit, dispatch }, filters) {
      commit('SET_FILTERS', filters)
      return dispatch('fetchTextContents')
    },
    fetchTextContents ({ commit, getters, dispatch }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .get(getters.textContentsUrl)
        .then((response) => {
          commit('SET_TEXT_CONTENTS', response.data)
          commit('SET_NUMBER_OF_PAGES', parseInt(response.headers['total-pages']))
          commit('SET_TOTAL_RESULTS', parseInt(response.headers['total-count']))
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju tekstovnih sestavkov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchDocumentNames ({ commit }) {
      return axios
        .get('/documents')
        .then(response => commit('SET_DOCUMENT_NAMES', response.data))
    },
    setQuery ({ commit, dispatch }, query) {
      commit('SET_QUERY', query)
      return dispatch('fetchTextContents')
    },
    goToPage ({ commit, dispatch, state }, page) {
      if (page > 0 && page <= state.numberOfPages) {
        commit('SET_PAGE', page)
        return dispatch('fetchTextContents')
      }
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_TEXT_CONTENTS (state, textContents) {
      state.textContents = textContents
    },
    SET_FILTERS (state, filters) {
      state.page = 1
      state.filters = filters
    },
    SET_DOCUMENT_NAMES (state, documents) {
      if (Array.isArray(documents) && documents.length > 0) {
        state.documentNames = documents.map(d => d.name)
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
    ...paginationMutations,
  },
}
