export default {
  methods: {
    goBack () {
      let isHistoryAvailable = true
      const initialUrl = window.location.href
      while (window.location.href === initialUrl && isHistoryAvailable) {
        isHistoryAvailable = history.back()
      }
    },
  },
}
