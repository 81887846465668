<template>
  <div>
    <v-autocomplete :md-options="envelopeOptions" v-model="selectedEnvelope" md-input-name="envelope" md-dense @md-opened="sizeProperly">
      <label>Pripada mapi (če mape ne izberete, bo ustvarjena nova)</label>
    </v-autocomplete>
    <span class="md-error envelope-error" v-if="!isEnvelopeSelected">Izberite mapo iz seznama ali pustite polje prazno</span>

    <v-field :class="getValidationClass('name')">
      <label>Naziv dokumenta</label>
      <v-input v-model="form.name" />
      <span class="md-error" v-if="!$v.form.name.required">Vpišite naziv dokumenta</span>
    </v-field>

    <v-field>
      <label>Kratek opis</label>
      <v-input v-model="form.description" />
    </v-field>

    <v-field>
      <label>Zabeležke</label>
      <v-input v-model="form.notes" />
    </v-field>

    <v-field>
      <label>Stranka</label>
      <v-input v-model="form.counterpartyName" />
    </v-field>

    <v-field>
      <label>Matična št. ali EMŠO stranke</label>
      <v-input type="number" v-model="form.counterpartyRegistrationNumber" />
    </v-field>

    <v-datepicker v-model="selectedSigningDate" md-immediately>
      <label>Datum podpisa</label>
    </v-datepicker>

    <v-datepicker v-model="selectedEffectiveDate" md-immediately>
      <label>Datum začetka učinkovanja</label>
    </v-datepicker>

    <v-datepicker v-model="selectedExpirationDate" md-immediately>
      <label>Datum prenehanja</label>
      <input-help v-if="selectedExpirationDate === null" text="Če veljavnost dokumenta časovno ni omejena, pustite prazno." :style="{ margin: 'auto' }" />
    </v-datepicker>

    <custom-attributes-form-fields :custom-attributes="customAttributes" :custom-attribute-keys="customAttributeKeys" @change="updateCustomAttributesAttributes" />

    <v-field :class="getValidationClass('pdfBlobHash')">
      <r-file-upload
        v-model="selectedFile"
        :disabled="isUploadingFile"
        :accepts="['application/pdf']"
        file-upload-prefix="finalized_documents"
        @uploading="onUploading"
        @uploaded="onUploaded"
      />
      <span class="md-error" v-if="!$v.form.pdfBlobHash.required">Izberite PDF datoteko</span>
    </v-field>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import AutocompleteFixMixin from '@/components/shared/AutocompleteFixMixin'
import CustomAttributesFormFields from './CustomAttributesFormFields'
import InputHelp from '@/components/shared/InputHelp'
import VAutocomplete from 'design-system/VAutocomplete.vue'
import VDatepicker from 'design-system/VDatepicker.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import RFileUpload from 'design-system/components/RFileUpload.vue'

export default {
  data () {
    return {
      isEnvelopeSelected: true,
      selectedFileName: null,
      selectedEnvelope: null,
      selectedSigningDate: null,
      selectedExpirationDate: null,
      selectedEffectiveDate: null,
      isUploadingFile: false,
      form: {
        envelope: {
          id: null,
        },
        name: null,
        description: null,
        notes: null,
        counterpartyName: null,
        counterpartyRegistrationNumber: null,
        signedOn: null,
        effectiveOn: null,
        expiresOn: null,
        pdfBlobHash: null,
        customAttributesAttributes: [],
      },
    }
  },
  props: {
    finalizedDocument: { type: Object, required: true },
    envelopes: { type: Array, required: true },
    customAttributeKeys: { type: Array, required: true },
  },
  components: {
    CustomAttributesFormFields,
    InputHelp,
    VAutocomplete,
    VDatepicker,
    VField,
    VInput,
    RFileUpload,
  },
  mixins: [ValidationMixin, AutocompleteFixMixin],
  mounted () {
    if (!this.finalizedDocument) {
      return
    }

    this.setFinalizedDocumentAttributes()

    if (this.form.signedOn) {
      this.selectedSigningDate = new Date(this.form.signedOn)
    }
    if (this.form.effectiveOn) {
      this.selectedEffectiveDate = new Date(this.form.effectiveOn)
    }
    if (this.form.expiresOn) {
      this.selectedExpirationDate = new Date(this.form.expiresOn)
    }
  },
  watch: {
    selectedEnvelope: {
      handler (newSelectedEnvelope) {
        if (!newSelectedEnvelope) {
          this.form.envelope.id = null
          this.isEnvelopeSelected = true
          return
        }
        if (typeof newSelectedEnvelope === 'string') {
          this.form.envelope.id = null
          this.isEnvelopeSelected = false
          return
        }

        this.form.envelope.id = newSelectedEnvelope.id
        this.isEnvelopeSelected = true
      },
      immediate: true,
    },
    selectedSigningDate: {
      handler (newSelectedSigningDate) {
        if (!newSelectedSigningDate) {
          this.form.signedOn = null
          return
        }

        this.form.signedOn = newSelectedSigningDate.toDateString()
        if (!this.selectedEffectiveDate) {
          this.selectedEffectiveDate = newSelectedSigningDate
          this.form.effectiveOn = this.form.signedOn
          this.runAllValidations()
        }
      },
      immediate: true,
    },
    selectedEffectiveDate: {
      handler (newSelectedEffectiveDate) {
        if (!newSelectedEffectiveDate) {
          this.form.effectiveOn = null
          return
        }

        this.form.effectiveOn = newSelectedEffectiveDate.toDateString()
      },
      immediate: true,
    },
    selectedExpirationDate: {
      handler (newSelectedExpirationDate) {
        if (!newSelectedExpirationDate) {
          this.form.expiresOn = null
          return
        }

        this.form.expiresOn = newSelectedExpirationDate.toDateString()
      },
      immediate: true,
    },
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.finalizedDocument,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    setFinalizedDocumentAttributes () {
      this.form.name = this.finalizedDocument.name
      this.form.description = this.finalizedDocument.description
      this.form.notes = this.finalizedDocument.notes
      this.form.counterpartyName = this.finalizedDocument.counterpartyName
      this.form.counterpartyRegistrationNumber = this.finalizedDocument.counterpartyRegistrationNumber === '' ? null : this.finalizedDocument.counterpartyRegistrationNumber
      this.form.signedOn = this.finalizedDocument.signedOn
      this.form.effectiveOn = this.finalizedDocument.effectiveOn
      this.form.expiresOn = this.finalizedDocument.expiresOn
      this.form.pdfBlobHash = this.finalizedDocument.pdfBlobHash
      if (this.finalizedDocument.envelopeId) {
        this.selectedEnvelope = this.envelopeOptions.find(option => option.id === this.finalizedDocument.envelopeId)
      }
    },
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid && this.isEnvelopeSelected
    },
    isUploading () {
      return this.isUploadingFile
    },
    onUploading () {
      this.$emit('uploading')
      this.isUploadingFile = true
    },
    onUploaded () {
      this.$emit('uploaded')
      this.isUploadingFile = false
    },
    updateCustomAttributesAttributes (newCustomAttributes) {
      this.form.customAttributesAttributes = newCustomAttributes
    },
  },
  computed: {
    selectedFile: {
      get () {
        if (!this.form.pdfBlobHash) {
          return {}
        }
        return {
          name: this.pdfName,
        }
      },
      set (fileData) {
        this.selectedFileName = fileData.name
        this.form.pdfBlobHash = fileData.blobHash
      },
    },
    envelopeOptions () {
      // all the mapping is required due to a bug in the component https://github.com/vuematerial/vue-material/issues/1243
      return this.envelopes.map(this.toAutocompleteItem)
    },
    customAttributes () {
      return this.finalizedDocument.customAttributes ?? []
    },
    pdfName () {
      if (this.selectedFileName) {
        return this.selectedFileName
      }
      return this.form.name ? `${this.form.name}.pdf` : null
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      pdfBlobHash: {
        required,
      },
    },
    isUploadingFile: {
      isUploading: value => value === false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';

.envelope-error {
  position: relative;
  top: -16px;
  height: 0;
}
</style>
