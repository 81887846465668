<template>
  <div class="feature-tiles" :class="{ 'feature-tiles--indent': isAutomaticNumberingEnabled }">
    <div class="feature-tile" v-for="(featureTile, index) in featureTiles" :key="featureTile.title">
      <div class="feature-tile-icon" v-if="featureTile.iconName"><icon :name="featureTile.iconName" :height="26" /></div>
      <div class="feature-tile-heading">
        <div v-if="isAutomaticNumberingEnabled" class="feature-tile-number">{{ index + 1 }}.</div>
        <div class="feature-tile-heading-text">{{ featureTile.heading }}</div>
      </div>
      <div class="feature-tile-text" v-if="featureTile.text">{{ featureTile.text }}</div>
    </div>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    featureTiles: { type: Array, required: true },
    isAutomaticNumberingEnabled: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';
@import './feature_tiles.scss';

.feature-tiles--indent {
  .feature-tile-heading {
    position: absolute;
    bottom: 32px;
    padding-bottom: 0;
  }

  .feature-tile-heading-text {
    max-width: calc(100% - 28px);
    position: relative;
    left: 28px;
  }
}

.feature-tile-icon {
  position: absolute;
  top: 32px;
  color: $modrn-green-500;
}

.feature-tile-number {
  position: absolute;
}
</style>
