import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import router from '@/router'
import { getResponseErrorMessage } from '@/store/utils'

const state = {
  documents: [],
  numberOfPages: null,
  page: 1,
  mostUsedDocumentId: null,
  query: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    documents (state, _, rootState) {
      if (!state.documents) {
        return []
      }

      return state.documents.map(d => {
        return {
          ...d,
          price: rootState.account ? null : d.price,
        }
      })
    },
    isLastPage (state) {
      return state.numberOfPages === state.page
    },
  },
  actions: {
    initialize ({ commit, dispatch }) {
      commit('SET_PAGE', 1)

      let query = null
      if (router.currentRoute.query.search) {
        query = router.currentRoute.query.search
      }

      commit('SET_QUERY', query)
      return Promise.all([
        dispatch('fetchDocuments', query),
        dispatch('fetchMostUsedDocument'),
      ])
    },
    fetchDocuments ({ commit, dispatch }, query) {
      commit('SET_DOCUMENTS', [])
      commit('SET_PAGE', 1)
      commit('SET_IS_LOADING', true, { root: true })

      // We first fetch only 30 documents, but enqueue another page load immediately
      return dispatch('fetchDocumentsPage', { query, page: 1 })
        .then((response) => {
          const documents = response.data.map(d => camelKeys(d, { recursive: true, recursiveInArray: true }))
          const numberOfPages = parseInt(response.headers['total-pages'])
          commit('SET_DOCUMENTS', documents)
          commit('SET_NUMBER_OF_PAGES', numberOfPages)
          if (numberOfPages > 1) {
            dispatch('fetchMoreDocuments')
          }
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumentov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchMoreDocuments ({ commit, dispatch, state }) {
      if (state.numberOfPages === state.page) {
        return
      }
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_PAGE', state.page + 1)

      return dispatch('fetchDocumentsPage', { query: state.query, page: state.page })
        .then((response) => {
          const documents = response.data.map(d => camelKeys(d, { recursive: true, recursiveInArray: true }))
          const numberOfPages = parseInt(response.headers['total-pages'])
          commit('ADD_DOCUMENTS', documents)
          commit('SET_NUMBER_OF_PAGES', numberOfPages)
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumentov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchDocumentsPage ({ rootState }, { query, page }) {
      let url = `/documents?limit=20&page=${page}`
      if (rootState.account) {
        url += `&account_id=${rootState.account.id}`
      }
      if (query) {
        url += `&by_query=${encodeURIComponent(query)}`
      }

      return axios.get(url)
    },
    fetchMostUsedDocument ({ commit }) {
      return axios
        .get('/documents/most_used')
        .then((response) => commit('SET_MOST_USED_DOCUMENT_ID', response.data.id))
        .catch(error => console.error(error))
    },
    setQuery ({ commit, dispatch, state }, query) {
      commit('SET_QUERY', query)
      commit('SET_PAGE', 1)
      return dispatch('fetchDocuments', state.query)
    },
    sendMessage ({ commit, dispatch }, message) {
      dispatch('setForTracking', {
        key: 'user_data',
        value: {
          email: message.email,
          first_name: message.name,
          last_name: message.surname,
          company_name: message.companyName,
        },
      }, { root: true })

      dispatch('trackEvents', [
        {
          name: 'conversion',
          data: {
            send_to: 'AW-16663620351/we_ACJSLvsgZEP_V6ok-',
          },
        },
        {
          name: 'generate_lead',
          data: {
            send_to: 'G-G-5JHZXZD27X',
            lead_source: 'Razširitve', // TODO: if the form is rendered on multiple pages, make this configurable
          },
        },
      ], { root: true })

      const payload = {
        message: {
          ...snakeKeys(message),
        },
      }

      return axios
        .post('/pigeon', payload)
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pošiljanju sporočila, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
  },
  mutations: {
    SET_DOCUMENTS (state, documents) {
      state.documents = documents
    },
    ADD_DOCUMENTS (state, documents) {
      state.documents = [...state.documents, ...documents]
    },
    SET_PAGE (state, page) {
      state.page = page
    },
    SET_NUMBER_OF_PAGES (state, numberOfPages) {
      state.numberOfPages = numberOfPages
    },
    SET_MOST_USED_DOCUMENT_ID (state, mostUsedDocumentId) {
      state.mostUsedDocumentId = mostUsedDocumentId
    },
    SET_QUERY (state, query) {
      state.query = query
    },
  },
}
