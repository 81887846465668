<template>
  <div class="container">
    <guarantee-header v-if="$route.meta.showGuaranteeHeader && !isLoggedIn" />

    <header-component
      v-if="!isLoggedIn"
      v-show="!$route.meta.isHeaderHidden"
      class="header"
    />
    <div class="page-and-sidebar">
      <sidebar class="sidebar" v-if="isLoggedIn" />
      <div class="page">
        <guarantee-header v-if="$route.meta.showGuaranteeHeader && isLoggedIn" />
        <logged-in-header v-if="isLoggedIn" @toggle-sidebar="toggleIsFloatingSidebarVisible" />
        <div class="page-wrapper" :class="{ 'page-wrapper--with-header': !isLoggedIn && !$route.meta.isHeaderEmbedded, 'page-wrapper--logged-in': isLoggedIn }">
          <v-progress-bar class="page-wrapper__loader" md-mode="indeterminate" v-if="isLoading" />
          <div class="page-content" :class="{ 'page-content--no-padding': $route.meta.isHeaderEmbedded, 'page-content--logged-in': isLoggedIn }">
            <router-view ref="routerView" />
          </div>
          <toast :message="message" @close="clearMessage" />
          <footer-component v-show="$route.meta.showFooter || ($route.meta.showFooterIfNotLoggedIn && !isLoggedIn)" />
        </div>
      </div>
    </div>

    <cookie-consent />
    <r-prompt />
    <auth-modal />
    <tracker />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { getFromMeta, getCurrentAccount } from '@/utils'
import VProgressBar from 'design-system/VProgressBar'
import GuaranteeHeader from './shared/_GuaranteeHeader.vue'
import LoggedInHeader from './shared/_LoggedInHeader.vue'
import RPrompt from 'design-system/components/prompt/RPrompt.vue'
import AuthModal from './shared/_AuthModal.vue'
import { setPageTitle } from '../utils'

export default {
  components: {
    VProgressBar,
    GuaranteeHeader,
    LoggedInHeader,
    RPrompt,
    AuthModal,
  },
  created () {
    this.$root.$on('set-page-meta', this.setPageMeta)
    this.$store.dispatch('setAccount', getCurrentAccount(getFromMeta('accounts'), this.$router.currentRoute))
    this.$material.selectLocale('sl')
    this.$material.locale.dateFormat = 'dd.MM.yyyy'
  },
  beforeDestroy () {
    this.$root.$off('set-page-meta')
  },
  watch : {
    $route: {
      handler () {
        this.showNewsletterPopup()
        setTimeout(this.scrollToAnchor, 50)
      },
      immediate: true,
    },
    account () {
      this.$nextTick(() => {
        this.updateRoute()
        this.reloadView()
      })
    },
  },
  computed: {
    ...mapState(['isLoading', 'message', 'account']),
    ...mapGetters(['accountSlug', 'getAccountIdForPath']),
    title () {
      if (this.$route.meta.title === 'Pigenta') {
        return null
      }
      return this.$route.meta.title ?? null
    },
  },
  methods: {
    ...mapActions(['toggleIsFloatingSidebarVisible', 'clearMessage']),
    scrollToAnchor () {
      if (this.isLoading) {
        setTimeout(this.scrollToAnchor, 50)
      }

      setTimeout(() => {
        const anchor = this.$route.hash
        if (!anchor) {
          return
        }

        const element = document.querySelector(anchor)
        if (!element) {
          return
        }

        element.scrollIntoView()
        if (this.isLoggedIn) {
          // account for header
          window.scrollBy(0, -90)
        }
      }, 500)
    },
    showNewsletterPopup () {
      if (!this.isAnalyticsAllowed) {
        return
      }

      const elementId = 'pixel-script-poptin'
      const newsletterScript = document.getElementById(elementId)
      if (newsletterScript) {
        newsletterScript.remove()
      }

      if (this.$route.meta.showNewsletterPopup) {
        const newsletterScript = document.createElement('script')
        newsletterScript.type = 'text/javascript'
        newsletterScript.id = elementId
        newsletterScript.async = true
        newsletterScript.src = 'https://cdn.popt.in/pixel.js?id=e18866c693148'
        document.head.appendChild(newsletterScript)
      }
    },
    updateRoute () {
      const currentRoute = this.$router.currentRoute
      if (!currentRoute.params.accountId) {
        return
      }

      const updatedPath = this.$router.resolve({
        name: currentRoute.name,
        params: {
          ...currentRoute.params,
          accountId: this.getAccountIdForPath(this.accountSlug),
        },
      })
      const historyRecord = {
        title: currentRoute.meta.title,
        url: updatedPath.href,
      }
      window.history.pushState(historyRecord, historyRecord.tile, historyRecord.url)
    },
    reloadView () {
      const view = this.$refs.routerView
      if (view.$options.created && view.$options.created.length > 0) {
        view.$options.created[0].call(view)
      }
    },
    setPageMeta (meta) {
      this.$nextTick(() => {
        setPageTitle(meta.title)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.page-and-sidebar {
  display: flex;
}

.sidebar {
  flex-shrink: 0;
}

.page {
  position: relative;
  width: calc(100% - #{$sidebar_width});
  flex-grow: 1;
}

.page-wrapper {
  width: 100%;
  min-height: calc(100vh - #{$desktop_header_height});
  background-color: $white;
  float: right;

  @include respond-to(small) {
    width: 100vw;
    min-height: calc(100vh - #{$mobile_header_height});
  }

  &--with-header {
    padding-top: $desktop_header_height;

    @include respond-to(small) {
      padding-top: $mobile_header_height;
    }
  }

  &__loader {
    position: fixed;
  }

  &--logged-in {
    min-height: calc(100vh - #{$logged_in_header_height});
  }
}

.page-content {
  min-height: calc(100vh - #{$desktop_header_height});
  padding-bottom: 48px;
  background-color: $background_color;

  @include respond-to(small) {
    min-height: calc(100vh - #{$mobile_header_height});
  }

  &--no-padding {
    padding-bottom: 0;
  }

  &--logged-in {
    min-height: calc(100vh - #{$logged_in_header_height});
  }
}
</style>

<style lang="scss">
// Material design theme
@import 'app/assets/stylesheets/variables';
@import '~vue-material/dist/theme/engine';
@include md-register-theme('default', (
  primary: $primary_color,
  accent: $secondary_color,
  default-background: $background_color,
));
@import '~vue-material/dist/theme/all';
@import './common';
</style>
