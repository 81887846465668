<template>
  <div>
    <v-field :class="getValidationClass('firstName')">
      <label>Ime</label>
      <v-input v-model="form.firstName" />
      <span class="md-error" v-if="!$v.form.firstName.required">Vpišite ime</span>
    </v-field>

    <v-field :class="getValidationClass('lastName')">
      <label>Priimek</label>
      <v-input v-model="form.lastName" />
      <span class="md-error" v-if="!$v.form.lastName.required">Vpišite priimek</span>
    </v-field>

    <v-field :class="getValidationClass('email')">
      <label>Email</label>
      <v-input v-model="form.email" type="email" />
      <span class="md-error" v-if="!$v.form.email.required">Vpišite email naslov</span>
      <span class="md-error" v-if="$v.form.email.required && !$v.form.email.email">Neveljaven email naslov</span>
    </v-field>

    <v-checkbox v-model="form.isAccountAdmin">Je administrator računa</v-checkbox>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es'
import { required, email } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import VCheckbox from 'design-system/VCheckbox.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VCheckbox, VField, VInput },
  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        isAccountAdmin: false,
      },
    }
  },
  props: {
    user: { type: Object, required: true },
  },
  mixins: [ValidationMixin],
  created () {
    if (isEmpty(this.user)) {
      return
    }

    this.form.firstName = this.user.firstName
    this.form.lastName = this.user.lastName
    this.form.email = this.user.email
    // for now we'll only allow setting isAccountAdmin
    // for all or for none of the accounts the user is member of
    this.form.isAccountAdmin = this.user.accountMemberships.some(a => a.isAccountAdmin)
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.user,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
