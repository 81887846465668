<template>
  <div class="r-card" ref="card" @click="$emit('click', $event)">
    <div class="r-card-body">
      <div class="r-card-title" ref="title">{{ title }}</div>
      <div class="r-card-text" v-if="text" ref="text">{{ text }}</div>
    </div>
    <div class="r-card-footer" ref="footer">
      <img class="r-card-image" alt="Logo podjetja" v-if="base64Image" :src="base64Image" />
      <span class="r-card-footer-title" v-else :class="{ 'r-card-footer-title--hidden': subtitle == null }">{{ subtitle }}</span>
      <v-button v-if="buttonStyle" size="small">{{ buttonText }}</v-button>
      <div class="r-card-button" v-else>{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import VButton from '../VButton.vue'

export default {
  props: {
    title: { type: String },
    text: { type: String, default: null },
    subtitle: { type: String, default: null },
    buttonText: { type: String },
    buttonStyle: { type: Boolean, default: true },
    base64Image: { type: String, default: null },
  },
  mounted () {
    const lineHeight = 18

    const cardHeight = this.$refs.card.offsetHeight
    const titleHeight = this.$refs.title.offsetHeight
    const footerHeight = this.$refs.footer.offsetHeight
    const textSpace = cardHeight - titleHeight - footerHeight - 10
    const numberOfLines = Math.floor(textSpace / lineHeight)
    const textHeight = numberOfLines * lineHeight

    if (this.$refs.text) {
      this.$refs.text.style.height = `${textHeight}px`
      this.$refs.text.style['-webkit-line-clamp'] = numberOfLines
    }
  },
  components: { VButton },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/colors";

.r-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 270px;
  height: 220px;
  border: 1px solid $silk-gray;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  overflow: hidden;

  &:hover {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  }
}

.r-card-body {
  position: relative;
  max-height: 156px;
  overflow: hidden;
}

.r-card-title {
  flex-shrink: 0;
  max-height: 156px;
  padding: 24px 24px 8px 24px;
  color: $black;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
}

.r-card-text {
  padding: 0 24px 0 24px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: $modrn-blue-dark-50;
  overflow: hidden;

  // multi-line ellipsis
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.r-card-image {
  max-width: 65px;
  max-height: 40px;
}

.r-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: $silk-gray;
}

.r-card-footer-title {
  color: $primary-blue;
  font-size: 14px;
  font-weight: 600;

  &--hidden {
    visibility: hidden;
  }
}

.r-card-button {
  margin: auto;
  color: $primary-blue;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
</style>
