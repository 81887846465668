import AdminPanelStats from '@/components/admin/stats/Stats.vue'
import AdminPanelUsers from '@/components/admin/users/Users.vue'
import AdminPanelEditUser from '@/components/admin/users/EditUser.vue'
import AdminPanelAccounts from '@/components/admin/accounts/Accounts.vue'
import AdminPanelEditAccount from '@/components/admin/accounts/EditAccount.vue'
import AdminPanelNewAccount from '@/components/admin/accounts/NewAccount.vue'
import AdminPanelPromotionCodes from '@/components/admin/promotion_codes/PromotionCodes.vue'
import AdminPanelEditPromotionCode from '@/components/admin/promotion_codes/EditPromotionCode.vue'
import AdminPanelNewPromotionCode from '@/components/admin/promotion_codes/NewPromotionCode.vue'
import AdminPanelDocuments from '@/components/admin/documents/Documents.vue'
import AdminPanelEditDocument from '@/components/admin/documents/EditDocument.vue'
import AdminPanelNewDocument from '@/components/admin/documents/NewDocument.vue'
import AdminPanelImportDocument from '@/components/admin/documents/ImportDocument.vue'
import AdminPanelTextContents from '@/components/admin/text_contents/TextContents.vue'
import AdminPanelEditTextContent from '@/components/admin/text_contents/EditTextContent.vue'
import AdminPanelNewTextContent from '@/components/admin/text_contents/NewTextContent.vue'
import AdminPanelBlogPosts from '@/components/admin/blog_posts/BlogPosts.vue'
import AdminPanelEditBlogPost from '@/components/admin/blog_posts/EditBlogPost.vue'
import AdminPanelNewBlogPost from '@/components/admin/blog_posts/NewBlogPost.vue'

const adminRoutes = [
  { path: '/admin/statistika', component: AdminPanelStats, name: 'admin_stats_path', meta: { title: 'Admin: Statistika' } },
  { path: '/admin/uporabniki', component: AdminPanelUsers, name: 'admin_users_path', meta: { title: 'Admin: Uporabniki' } },
  { path: '/admin/uporabniki/:id', component: AdminPanelEditUser, name: 'admin_user_edit_path', meta: { title: 'Admin: Uredi uporabnika' }, props: true },
  { path: '/admin/racuni', component: AdminPanelAccounts, name: 'admin_accounts_path', meta: { title: 'Admin: Računi' } },
  { path: '/admin/racuni/new', component: AdminPanelNewAccount, name: 'admin_new_account_path', meta: { title: 'Admin: Nov organizacijski račun' } },
  { path: '/admin/racuni/:id', component: AdminPanelEditAccount, name: 'admin_account_edit_path', meta: { title: 'Admin: Uredi račun' }, props: true },
  { path: '/admin/promocijske-kode', component: AdminPanelPromotionCodes, name: 'admin_promotion_codes_path', meta: { title: 'Admin: Promocijske kode' } },
  { path: '/admin/promocijske-kode/new', component: AdminPanelNewPromotionCode, name: 'admin_new_promotion_code_path', meta: { title: 'Admin: Nova promocijska koda' } },
  { path: '/admin/promocijske-kode/:id', component: AdminPanelEditPromotionCode, name: 'admin_promotion_code_edit_path', meta: { title: 'Admin: Uredi promocijsko kodo' }, props: true },
  { path: '/admin/dokumenti', component: AdminPanelDocuments, name: 'admin_documents_path', meta: { title: 'Admin: Dokumenti' } },
  { path: '/admin/dokumenti/new', component: AdminPanelNewDocument, name: 'admin_new_document_path', meta: { title: 'Admin: Nov dokument' } },
  { path: '/admin/dokumenti/import', component: AdminPanelImportDocument, name: 'admin_import_document_path', meta: { title: 'Admin: uvozi dokument' } },
  { path: '/admin/dokumenti/:id', component: AdminPanelEditDocument, name: 'admin_document_edit_path', meta: { title: 'Admin: Uredi dokument' }, props: true },
  { path: '/admin/tekstovni-sestavki', component: AdminPanelTextContents, name: 'admin_text_contents_path', meta: { title: 'Admin: Tekstovni sestavki' } },
  { path: '/admin/tekstovni-sestavki/new', component: AdminPanelNewTextContent, name: 'admin_new_text_content_path', meta: { title: 'Admin: Nov tekstovni sestavek' } },
  { path: '/admin/tekstovni-sestavki/:id', component: AdminPanelEditTextContent, name: 'admin_text_content_edit_path', meta: { title: 'Admin: Uredi tekstovni sestavek' }, props: true },
  { path: '/admin/blog', component: AdminPanelBlogPosts, name: 'admin_blog_posts_path', meta: { title: 'Admin: Blog' } },
  { path: '/admin/blog/new', component: AdminPanelNewBlogPost, name: 'admin_new_blog_post_path', meta: { title: 'Admin: Nov blog' } },
  { path: '/admin/blog/:id', component: AdminPanelEditBlogPost, name: 'admin_blog_post_edit_path', meta: { title: 'Admin: Uredi blog' }, props: true },
]

export default adminRoutes.map(route => {
  route.meta.isLoginRequired = true
  route.meta.isAdminRequired = true
  return route
})
