<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <h1 class="page-title">Nova Promocijska koda</h1>
    <promotion-code-form @submit="submitPromotionCode" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionCodeForm from './forms/PromotionCodeForm'

export default {
  components: {
    PromotionCodeForm,
  },
  created () {
    this.initialize(this.id)
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    ...mapActions('admin/promotionCodes/forms', ['initialize', 'reset', 'submitPromotionCode']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
