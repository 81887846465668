import { render, staticRenderFns } from "./NewAttributeKey.vue?vue&type=template&id=c55f7390&scoped=true&"
import script from "./NewAttributeKey.vue?vue&type=script&lang=js&"
export * from "./NewAttributeKey.vue?vue&type=script&lang=js&"
import style0 from "./NewAttributeKey.vue?vue&type=style&index=0&id=c55f7390&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c55f7390",
  null
  
)

export default component.exports