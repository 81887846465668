import axios from 'axios'
import router from '@/router'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'

const state = {}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    canDeleteProfile (state, _, rootState) {
      return !rootState.user.isAdmin
    },
  },
  actions: {
    editUser ({ commit, dispatch, rootState }, user) {
      if (!rootState.user) {
        return
      }

      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .put('/users', { user: snakeKeys(user, { recursive: true, recursiveInArray: true }) })
        .then(() => {
          dispatch('reloadUser')
          router
            .push({ name: 'user_profile_edit_path', params: { id: rootState.user.id } })
            .catch(() => router.go())
          commit('SET_MESSAGE', { text: 'Profil uspešno posodobljen.', type: 'info' }, { root: true })
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri posodabljanju profila, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reloadUser ({ dispatch }) {
      return axios
        .get('/me')
        .then(response => {
          const user = camelKeys(response.data, { recursive: true, recursiveInArray: true })
          dispatch('setUser', user, { root: true })
        })
    },
    deleteUser ({ commit, rootState, dispatch }) {
      if (!rootState.user) {
        return
      }

      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete('/users')
        .then(() => {
          dispatch('setUser', null, { root: true })
          commit('SET_MESSAGE', { text: 'Uporabniški račun je bil uspešno izbrisan.', type: 'success' }, { root: true })
          router.push({ name: 'root_path' })
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju uporabniškega računa, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {},
}
