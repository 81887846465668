<template>
  <div class="dialog">
    <div class="dialog__blur"></div>
    <div class="dialog__content">
      <div class="dialog__header" v-if="canClose" @click="$emit('close')">
        <div class="dialog__close"><v-icon class="dialog__close-button">close</v-icon></div>
      </div>
      <div class="dialog__inner-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from 'design-system/VIcon.vue'

export default {
  props: {
    canClose: { type: Boolean, default: true },
  },
  components: { VIcon },
  created () {
    window.addEventListener('keydown', this.onKeydown)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    onKeydown (event) {
      if (!this.canClose || event.key !== 'Escape') {
        return
      }
      event.preventDefault()
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z_heaven;
}

.dialog__blur,
.dialog__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialog__blur {
  background-color: $black;
  opacity: .6;
}

.dialog__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  max-width: 900px;
  height: fit-content;
  max-height: 60%;
  background-color: $white;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  top: 50%;
  left: 50%;

  @include respond-to(small) {
    width: 90%;
    max-width: 90%;
    max-height: 80%;
    max-width: none;
    padding: 0 16px 16px 16px;
  }
}

.dialog__inner-content {
  overflow-y: auto;
}

.dialog__header {
  position: absolute;
  top: 0;
  right: 0;
  height: 64px;
  padding: 24px;
  box-sizing: border-box;
  cursor: pointer;
}

.dialog__close-button.dialog__close-button.dialog__close-button {
  color: black;
  font-size: 18px !important;
}
</style>
