<template>
  <v-field class="search" :class="{ ' search--with-icon': hasSearchIcon }">
    <icon name="search" v-if="hasSearchIcon" /><label>{{ inputLabel }}</label>
    <v-input v-model="query" @input="debounceEmitSearch"></v-input>
  </v-field>
</template>

<script>
import { debounce } from 'lodash-es'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import Icon from 'design-system/icons/Icon.vue'

export default {
  props: {
    inputLabel: { type: String, default: 'Iskanje' },
    hasSearchIcon: { type: Boolean, default: false },
  },
  data () {
    return {
      query: null,
    }
  },
  components: {
    VField,
    VInput,
    Icon,
  },
  methods: {
    debounceEmitSearch: debounce(function () {
      this.emitSearch()
    }, 250),
    emitSearch () {
      this.$emit('change', this.query)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.search--with-icon {
  .md-input {
    padding-left: 58px;
    border: 1px solid $input-light;
    background: $white;
  }

  & .icon-wrapper {
    position: absolute;
    top: 28px;
    left: 22px;
  }

  & label:not(.md-focused) {
    margin-left: 44px;
  }

  &.md-has-value,
  &.md-focused {
    & label {
      margin-left: 0;
    }
  }
}
</style>
