<template>
  <div class="page-container">
    <h1 class="page-title">
      Nastavitve profila
    </h1>
    <edit-user-form
      class="auth__section user-form"
      :user="user"
      :is-disabled="isLoading"
      @submit="editUser"
    />
    <div class="edit-profile__delete-container">
      <div class="edit-profile__delete-container__text">Permanentno izbriši uporabniški profil</div>
      <div class="edit-profile__delete-container__button-container">
        <v-button
          class="edit-profile__delete-container__button"
          @click="onDelete"
          background-color="red"
          size="medium"
          :disabled="!canDeleteProfile"
          outlined
        >
          Izbriši profil
        </v-button>
        <v-tooltip v-if="!canDeleteProfile">Admin uporabnika ni mogoče izbrisati</v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import EditUserForm from './EditUserForm.vue'
import VButton from 'design-system/VButton.vue'
import VTooltip from 'design-system/VTooltip.vue'
import prompt from 'design-system/components/prompt/prompt'

export default {
  components: {
    EditUserForm,
    VButton,
    VTooltip,
  },
  computed: {
    ...mapState(['user', 'isLoading']),
    ...mapGetters('pages/profile', ['canDeleteProfile']),
  },
  methods: {
    ...mapActions('pages/profile', ['editUser', 'deleteUser']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati uporabniški profil? Z izbrisom bodo izbrisani vsi vaši podatki, vključno s kupljenimi pametnimi vzorci.')
      if (result) {
        this.deleteUser()
      }
    },
  },
}
</script>

<style scoped lang='scss'>
@import '../../common';
@import 'app/assets/stylesheets/variables';

.profile__header {
  display: flex;
  justify-content: space-between;
}

.user-form {
  padding: 24px 32px;
  border-radius: 8px;
  background: $white;
  // stylelint-disable-next-line
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

}

.edit-profile__delete-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 44px;

  .edit-profile__delete-container__text {
    color: $text-black;
    font-size: 18px;
    font-weight: 500;
  }

  .edit-profile__delete-container__button {
    flex-shrink: 0;
  }
}
</style>
