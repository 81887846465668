<template>
  <div class="banner" :class="{ 'banner--green': color === 'green' }">
    <div v-if="heading" class="banner-heading">{{ heading }}</div>
    <div v-if="subheading" class="banner-subheading">{{ subheading }}</div>
    <v-button
      v-if="buttonText"
      :size="buttonSize"
      background-color="white"
      :color="buttonColor"
      @click="$emit('click')"
      class="banner-button"
    >
      {{ buttonText }} &nbsp;&nbsp; <v-icon>chevron_right</v-icon>
    </v-button>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'
import VIcon from 'design-system/VIcon.vue'

export default {
  components: {
    VButton,
    VIcon,
  },
  props: {
    heading: { type: String, default: null },
    subheading: { type: String, default: null },
    buttonText: { type: String, default: null },
    color: {
      type: String,
      default: 'blue',
      validator : value => ['blue', 'green'].includes(value),
    },
  },
  computed: {
    buttonSize () {
      return this.isMobile ? 'small' : 'medium'
    },
    buttonColor () {
      return this.color === 'green' ? 'modrn-green-dark' : 'primary-blue'
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.banner {
  width: calc(100% - 160px); // minus margin
  height: fit-content;
  max-width: 1400px;
  margin: 60px auto;
  padding: 74px 60px;
  border-radius: 24px;
  background-color: $primary-blue;
  color: $white;
  text-align: left;

  @include respond-to(small) {
    width: 100%;
    margin: 0;
    padding: 24px;
  }

  &--green {
    background-color: $modrn-green-dark;
  }
}

.banner-heading {
  padding-bottom: 40px;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;

  @include respond-to(small) {
    font-size: 32px;
    line-height: 40px;
  }
}

.banner-subheading {
  position: relative;
  top: -16px;
  padding-bottom: 40px;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;

  @include respond-to(small) {
    font-size: 18px;
    line-height: 24px;
  }
}

.banner-button.banner-button.banner-button {
  @include respond-to(small) {
    font-size: 14px;
  }
}
</style>
