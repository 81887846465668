<template>
  <div class="footer">
    <img class="footer__logo" alt="Pigenta Logo" :src="require('app/assets/images/pigenta_logo_full.svg')" />
    <div class="footer__text">{{ compliance }}</div>
    <div class="footer__links">
      <a class="footer__link footer__link--email" @click="openMailTo">{{ infoEmail }}</a>
      <div class="footer__href-links">
        <router-link class="footer__link" :to="{ name: 'terms_path' }">Pogoji uporabe</router-link>
        <router-link class="footer__link" :to="{ name: 'privacy_path' }">Pravilnik o zasebnosti</router-link>
      </div>
    </div>

    <div class="footer__separator" />

    <div v-if="isMobile">
      <footer-icons />
      <div class="footer__text footer__text--small">{{ address }}</div>
      <div class="footer__text footer__text--small footer__text--center">{{ copyright }}</div>
    </div>
    <div v-else>
      <div class="footer__bottom-row">
        <div class="footer__text footer__text--small">{{ address }}, {{ copyright }}</div>
        <footer-icons />
      </div>
    </div>
  </div>
</template>

<script>
import MailToMixin from '@/components/shared/MailToMixin'
import FooterIcons from './_FooterIcons.vue'

export default {
  mixins: [MailToMixin],
  components: { FooterIcons },
  computed: {
    address () {
      return 'zero2one d.o.o., Litijska cesta 152, 1000 Ljubljana'
    },
    copyright () {
      return `© ${new Date().getFullYear()} Pigenta`
    },
    compliance () {
      return 'Zakonska skladnost: Vzorce pogodb za nas pripravlja odvetniška pisarna, specializirana za področje gospodarskega in pogodbenega prava.'
    },
    infoEmail () {
      return 'info@pigenta.com'
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.footer {
  height: 426px;
  padding: 88px 64px 40px 64px;
  color: $modrn-blue-dark-70;
  background-color: $silver-gray;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  @include respond-to('small') {
    height: 550px;
    padding: 48px 32px;
  }
}

.footer__href-links {
  display: flex;

  @include respond-to('small') {
    display: block;
  }
}

.footer__link.footer__link {
  margin-right: 32px;
  color: $modrn-blue-dark-70;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  &:hover {
    color: $secondary_color;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.footer__separator {
  height: 1px;
  margin-top: 32px;
  background-color: #BABDC3;

  @include respond-to('small') {
    margin: 12px 0 32px 0;
  }
}

.footer__logo {
  width: 120px;
  max-width: 90vw;
  margin-bottom: 24px;
}

.footer__link.footer__link {
  display: block;
  padding: 11px 0;
}

.footer__link--email.footer__link--email {
  padding: 32px 0 20px 0;
}

.footer__text {
  max-width: 580px;
}

.footer__text--small {
  padding-bottom: 23px;
  line-height: 24px;
}

.footer__text--center {
  text-align: center;
}

.footer__bottom-row {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}
</style>
