<template>
  <div>
    <div class="auth__image">
      <img alt="Sporočilo poslano" :src="require('app/assets/images/email_sent.svg')" />
    </div>
    <div class="auth__info-title">Preverite svoj poštni predal</div>
    <div class="auth__info-text">
      Poslali smo vam potrditveno sporočilo.<br />
      Če email sporočila ne najdete, vas prosimo, da preverite tudi nezaželjeno pošto.
    </div>

    <v-button @click="$emit('change-page', 'login')">
      Naprej na prijavo
    </v-button>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'
export default {
  components: { VButton },
}
</script>

<style lang="scss" scoped>
@import './common';
@import '../common';

</style>
