<template>
  <div>
    <div class="auth__switch-button-group">
      <button class="auth__switch-button auth__switch-button--active">Registracija</button>
      <button class="auth__switch-button" @click="$emit('change-page', 'login')">Prijava</button>
    </div>
    <social-buttons class="auth__social" />
    <div class="auth__text auth__separator">Prijavite se s svojim e-poštnim naslovom</div>
    <register-user-form :is-disabled="isLoading" @submit="onRegister" class="auth__section" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RegisterUserForm from './RegisterUserForm.vue'
import SocialButtons from './SocialButtons'

export default {
  components: {
    RegisterUserForm,
    SocialButtons,
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('auth', ['register']),
    onRegister (data) {
      this.register(data)
        .then((success) => {
          if (success) {
            this.$emit('change-page', 'registered')
          }
        })
    },
  },
}
</script>

<style lang='scss' scoped>
@import './common';
</style>

<style lang='scss'>
// TODO: refactor when we have the popup for login & register
.auth__section {
  .md-field {
    label {
      overflow: hidden;
      width: calc(100% - 24px);
      height: 20px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
