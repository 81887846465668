<template>
  <div>
    <v-field :class="getValidationClass('name')">
      <label>Ime</label>
      <v-input v-model="form.name"></v-input>
      <span class="md-error" v-if="!$v.form.name.required">Vpišite ime</span>
    </v-field>

    <v-field :class="getValidationClass('code')">
      <label>Koda</label>
      <v-input v-model="form.code"></v-input>
      <span class="md-error" v-if="!$v.form.code.required">Vpišite kodo</span>
    </v-field>

    <v-field :class="getValidationClass('value')">
      <label>Vrednost</label>
      <v-input type="number" v-model="form.value"></v-input>
      <span class="md-error" v-if="!$v.form.value.required">Vpišite vrednost</span>
    </v-field>

    <v-datepicker v-model="selectedExpirationDate" md-immediately :class="getValidationClass('expiresOn')">
      <label>Datum veljavnosti</label>
      <span class="md-error" v-if="!$v.form.value.selectedExpirationDate">Vpišite datum veljavnosti</span>
    </v-datepicker>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import VDatepicker from 'design-system/VDatepicker.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VDatepicker, VField, VInput },
  data () {
    return {
      selectedExpirationDate: null,
      form: {
        name: null,
        code: null,
        value: null,
        expiresOn: null,
      },
    }
  },
  props: {
    promotionCode: { type: Object, required: false },
  },
  mixins: [ValidationMixin],
  created () {
    if (!this.promotionCode) {
      return
    }
    this.form.name = this.promotionCode.name
    this.form.code = this.promotionCode.code
    this.form.value = this.promotionCode.value
    this.form.expiresOn = this.promotionCode.expiresOn
    if (this.form.expiresOn) {
      this.selectedExpirationDate = new Date(this.form.expiresOn)
    }
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.promotionCode,
          ...newFormValue,
        })
      },
      deep: true,
    },
    selectedExpirationDate: {
      handler (newSelectedExpirationDate) {
        if (!newSelectedExpirationDate) {
          this.form.expiresOn = null
          return
        }
        this.form.expiresOn = newSelectedExpirationDate.toDateString()
      },
      immediate: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      name: { required },
      code: { required },
      value: { required },
      expiresOn: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
