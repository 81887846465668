import sharedModule from './shared'
import blogModule from './blog'
import documentCompositionModule from './document_composition'
import profileModule from './profile'

export default {
  namespaced: true,
  modules: {
    blog: blogModule,
    documentComposition: documentCompositionModule,
    profile: profileModule,
  },
  ...sharedModule,
}
