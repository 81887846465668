<template>
  <div>
    <v-field :class="getValidationClass('text')">
      <label>Vprašanje</label>
      <v-input v-model="form.text"></v-input>
      <span class="md-error" v-if="!$v.form.text.required">Vpišite vprašanje</span>
    </v-field>

    <v-field>
      <label>Dodatne informacije</label>
      <v-input v-model="form.info"></v-input>
    </v-field>

    <v-field :class="getValidationClass('order')">
      <label>Vrstni red pogoja v dokumentu</label>
      <v-input v-model="form.order" type="number"></v-input>
      <span class="md-error" v-if="!$v.form.order.required">Vpišite vrstni red</span>
    </v-field>

    <v-checkbox v-model="form.isMultiSelect">Večizbirni pogoj</v-checkbox>

    <div class="md-subheading nested-form-heading" :class="{ 'nested-form-heading--collapsed': !showNestedForm }" @click="showNestedForm = !showNestedForm">Izidi pogoja</div>
    <div class="nested-form" v-show="showNestedForm">
      <div v-for="(conditionOutcome, index) in conditionOutcomes" :key="conditionOutcome.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">izid {{ index + 1 }}</div>
          <v-button class="md-icon-button" v-if="conditionOutcomes.length > 2" @click="removeConditionOutcome(index)"><v-icon>close</v-icon></v-button>
        </div>
        <condition-outcome-form-fields ref="conditionOutcomes" :condition-outcome="conditionOutcome" @change="onConditionOutcomeChange(index, $event)" />
      </div>
      <v-button class="regular-button" size="small" @click="addConditionOutcome">DODAJ IZID POGOJA</v-button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import NestedFormMixin from '../../NestedFormMixin'
import ConditionOutcomeFormFields from './ConditionOutcomeFormFields'
import VButton from 'design-system/VButton.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: {
    ConditionOutcomeFormFields,
    VButton,
    VCheckbox,
    VField,
    VIcon,
    VInput,
  },
  props: {
    condition: { type: Object, required: false },
  },
  data () {
    return {
      showNestedForm: true,
      form: {
        text: null,
        info: null,
        order: null,
        isMultiSelect: null,
        conditionOutcomes: [
          { frontendId: Math.random().toString(36) },
          { frontendId: Math.random().toString(36) },
        ],
      },
    }
  },
  mixins: [ValidationMixin, NestedFormMixin],
  created () {
    if (!this.condition) {
      return
    }

    this.form.text = this.condition.text
    this.form.info = this.condition.info
    this.form.order = this.condition.order
    this.form.isMultiSelect = this.condition.isMultiSelect

    if (this.condition.conditionOutcomes) {
      const conditionOutcomesWithFrontendIds = createConditionOutcomesFrontendIds(this.condition.conditionOutcomes)
      this.form.conditionOutcomes = conditionOutcomesWithFrontendIds
    }

    function createConditionOutcomesFrontendIds (conditionOutcome) {
      const conditionOutcomesWithFrontendIds = []
      conditionOutcome.forEach(conditionOutcome => {
        conditionOutcomesWithFrontendIds.push({
          ...conditionOutcome,
          frontendId: Math.random().toString(36),
        })
      })

      return conditionOutcomesWithFrontendIds
    }
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', newFormValue)
      },
      deep: true,
    },
  },
  computed: {
    conditionOutcomes () {
      return this.form.conditionOutcomes
    },
  },
  methods: {
    onConditionOutcomeChange (index, $event) {
      this.form.conditionOutcomes[index] = {
        ...this.form.conditionOutcomes[index],
        ...$event,
      }
      this.$emit('change', this.form)
    },
    addConditionOutcome () {
      this.form.conditionOutcomes.push({ frontendId: Math.random().toString(36) })
      this.$emit('change', this.form)
    },
    removeConditionOutcome (index) {
      this.form.conditionOutcomes.splice(index, 1)
      this.$emit('change', this.form)
    },
    runAllValidations () {
      this.$v.$touch()
      const conditionOutcomeRefs = this.$refs.conditionOutcomes
      if (!conditionOutcomeRefs) {
        return
      }

      conditionOutcomeRefs.forEach(conditionOutcome => conditionOutcome.runAllValidations())
    },
    isValid () {
      if (this.$v.$invalid) {
        return false
      }

      const conditionOutcomes = this.$refs.conditionOutcomes
      if (!conditionOutcomes) {
        return true
      }

      return conditionOutcomes.reduce((areConditionOutcomesValid, conditionOutcome) => areConditionOutcomesValid && conditionOutcome.isValid(), true)
    },
  },
  validations: {
    form: {
      text: { required },
      order: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
