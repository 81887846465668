<template>
  <div class="document-composition__panel" v-if="Object.keys(document).length > 0">
    <div class="document-composition__container">
      <div class="document-composition__content" ref="content">
        <configuration
          v-if="!isDisabled"
          class="document-composition__content-child document-composition__content-child--configuration"
          :class="{ 'document-composition__content-child--full-width': !!visibleSliderButton }"
          @variableFocus="onVariableInputFocus"
          @conditionOutcomeChange="onConditionOutcomeChange"
          @saveDocument="$emit('saveDocument')"
        />
        <preview class="document-composition__content-child document-composition__content-child--preview" :class="{ 'document-composition__content-child--full-width': !!visibleSliderButton }" ref="preview" />
      </div>

      <div v-show="visibleSliderButton === 'left'" @click="slideToLeft" class="document-composition__slider-button document-composition__slider-button--left">
        <v-icon class="document-composition__slider-icon md-size-2x">keyboard_arrow_left</v-icon>
      </div>
      <div v-show="visibleSliderButton === 'right'" @click="slideToRight" class="document-composition__slider-button document-composition__slider-button--right">
        <v-icon class="document-composition__slider-icon md-size-2x">keyboard_arrow_right</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash-es'
import Configuration from './Configuration'
import Preview from './Preview'
import VIcon from 'design-system/VIcon.vue'

export default {
  props: {
    isDisabled: { type: Boolean, default: false },
  },
  data () {
    return {
      visibleSliderButton: null,
    }
  },
  mounted () {
    window.addEventListener('resize', this.setVisibleSidebarButton)
    this.setVisibleSidebarButton()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setVisibleSidebarButton)
  },
  components: {
    Configuration,
    Preview,
    VIcon,
  },
  computed: {
    ...mapState('pages/documentComposition', ['document']),
    ...mapGetters('pages/documentComposition', ['visibleVariables', 'getVariableDOMIdFn', 'getOutcomeDOMIdFn']),
  },
  methods: {
    ...mapActions('pages/documentComposition', []),
    setVisibleSidebarButton () {
      if (isEmpty(this.document)) {
        setTimeout(this.setVisibleSidebarButton, 250)
        return
      }

      if (this.$refs.content.getBoundingClientRect().width > 560) {
        this.visibleSliderButton = null
        return
      }

      this.visibleSliderButton = this.$refs.content.scrollLeft === 0 ? 'right' : 'left'
    },
    slideToLeft () {
      if (this.visibleSliderButton !== 'left') {
        return
      }

      this.$refs.content.scrollLeft = 0
      this.visibleSliderButton = 'right'
    },
    slideToRight () {
      if (this.visibleSliderButton !== 'right') {
        return
      }

      this.$refs.content.scrollLeft = this.$refs.content.scrollWidth
      this.visibleSliderButton = 'left'
    },
    onVariableInputFocus (variableId) {
      this.$refs.preview.scrollTo(this.getVariableDOMIdFn(variableId))
    },
    onConditionOutcomeChange (conditionOutcomeId) {
      this.$nextTick(() => this.$refs.preview.scrollTo(this.getOutcomeDOMIdFn(conditionOutcomeId)))
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.document-composition__container {
  position: relative;
  max-width: 2000px;
  min-width: 280px;
  margin: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.15);
}

.document-composition__header-container {
  width: 100%;
}

.document-composition__content {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.document-composition__slider-button {
  position: absolute;
  top: 45%;
  cursor: pointer;
  background-color: $border_color;
  z-index: $z_pop;

  &--left {
    left: 0;
    border-radius: 0 8px 8px 0;
  }

  &--right {
    right: 0;
    border-radius: 8px 0 0 8px;
  }
}

.document-composition__slider-icon {
  width: 32px;
  min-width: 32px;
}

.document-composition__content-child.document-composition__content-child {
  box-sizing: border-box;
  min-width: 280px;
  padding-left: 24px;

  &--configuration {
    width: 35%;
    background-color: $light_gray_background_color;
  }

  &--preview {
    width: 65%;
    padding: 48px;
    background-color: #FEFEFE;
  }

  &--full-width {
    min-width: 100%;
  }

  @include respond-to(not-large) {
    padding: 0 16px 16px 16px;
  }

  @include respond-to(small) {
    min-width: 100%;
    padding: 0 16px 16px 16px;
  }
}
</style>

<style lang="scss">
@import 'app/assets/stylesheets/colors';

.document-composition__panel {
  .md-field.md-theme-default > .md-icon:after {
    background-color: $light_gray_background_color;
  }
}
</style>
