import { camelKeys } from 'js-convert-case'
import { getAllRecords, getResponseErrorMessage } from '@/store/utils'

const state = {
  notifications: [],
  isLoading: false,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
  },
  actions: {
    loadNotifications ({ commit, dispatch }) {
      commit('SET_IS_LOADING', true)

      return getAllRecords('/notifications/mine')
        .then(records => {
          commit('SET_NOTIFICATIONS', records)
          dispatch('setUnreadNotificationsCountInterval', null, { root: true })
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju obvestil, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_NOTIFICATIONS (state, notifications) {
      state.notifications = notifications.map(notification => camelKeys(notification, { recursive: true, recursiveInArray: true }))
    },
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
