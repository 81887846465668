import axios from 'axios'
import { camelKeys, toSnakeCase } from 'js-convert-case'
import { getResponseErrorMessage, getAllRecords } from '@/store/utils'
import formsModule from './forms'
import envelopeFormsModule from './envelope_forms'
import { deleteFinalizedDocument, getFinalizedDocumentPdfUrl } from './utils.js'
import { paginationState, paginationMutations } from '@/store/mixins/pagination'

const STATUS_FILTER_DEFINITION = {
  id: 'by_finalized_document_status',
  apiField: 'by_finalized_document_status',
  label: 'Status',
  type: 'list',
}

const state = {
  envelopes: [],
  availableCustomAttributeKeys: [],
  filters: [
    {
      ...STATUS_FILTER_DEFINITION,
      valueId: 'valid',
      valueLabel: 'Veljavno',
    },
  ],
  query: null,
  counterpartyNames: [],
  sortBy: 'envelopes.name',
  isLoadingEnvelopes: false,
  selectedFinalizedDocument: null,
  ...paginationState,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  modules: {
    forms: formsModule,
    envelopeForms: envelopeFormsModule,
  },
  state: getState(),
  getters: {
    envelopes (state) {
      return state.envelopes.map(toEnvelopeWithFormattedFinalizedDocuments)

      function toEnvelopeWithFormattedFinalizedDocuments (envelope) {
        return {
          ...envelope,
          finalizedDocuments: envelope.finalizedDocuments.map(toAugmentedFinalizedDocument),
        }
      }

      function toAugmentedFinalizedDocument (finalizedDocument) {
        const statusFilter = state.filters.find(f => f.id === 'by_finalized_document_status')
        const statusFilterValue = statusFilter?.valueId
        return {
          ...finalizedDocument,
          // if we're filtering by status hide the finalized documents that don't have desired status
          // the API will return all the finalized documents of envelopes that contain at least one finalized document with desired status
          isHidden: statusFilterValue && finalizedDocument.status !== statusFilterValue,
          previewUrl: getFinalizedDocumentPdfUrl(finalizedDocument.id, true),
          pdfDownloadUrl: getFinalizedDocumentPdfUrl(finalizedDocument.id),
        }
      }
    },
    idFilter () {
      return {
        id: 'by_finalized_document_id',
        apiField: 'by_finalized_document_id',
        label: 'ID',
        type: 'string',
        isHidden: true,
      }
    },
    availableFilters (state, getters) {
      const customAttributeFilters = state.availableCustomAttributeKeys.map(customAttributeKey => {
        return {
          id: `by_custom_attribute_${customAttributeKey.id}`,
          apiField: 'by_custom_attribute',
          label: customAttributeKey.label,
          type: customAttributeKey.customAttributeKeyType,
          options: toOptions(customAttributeKey.customAttributeListValues),
        }
      })

      return [
        getters.idFilter,
        {
          id: 'by_finalized_document_counterparty_name',
          apiField: 'by_finalized_document_counterparty_name',
          label: 'Stranka',
          type: 'list',
          options: state.counterpartyNames.map(counterpartyName => {
            return {
              id: counterpartyName.name,
              label: counterpartyName.name,
            }
          }),
        },
        {
          id: 'by_finalized_document_counterparty_registration_number',
          apiField: 'by_finalized_document_counterparty_registration_number',
          label: 'Matična številka',
          type: 'number',
        },
        {
          ...STATUS_FILTER_DEFINITION,
          options: [
            { id: 'valid', label: 'Veljavno' },
            { id: 'warning', label: 'Poteče kmalu' },
            { id: 'expired', label: 'Poteklo ali neveljavno' },
            { id: 'signing', label: 'V podpisovanju' },
          ],
        },
        {
          id: 'by_finalized_document_value',
          apiField: 'by_finalized_document_value',
          label: 'Vrednost',
          type: 'range',
        },
        {
          id: 'by_finalized_document_effective_on',
          apiField: 'by_finalized_document_effective_on',
          label: 'Začetek učinkovanja',
          type: 'date',
        },
        {
          id: 'by_finalized_document_expires_on',
          apiField: 'by_finalized_document_expires_on',
          label: 'Prenehanje',
          type: 'date',
        },
        ...customAttributeFilters,
      ]

      function toOptions (customAttributeListValues) {
        if (!customAttributeListValues) {
          return null
        }

        return customAttributeListValues.map(listValue => {
          return {
            id: listValue.label,
            label: listValue.label,
          }
        })
      }
    },
    filtersWithValues (state) {
      return state.filters.filter(filter => filter.valueId)
    },
    envelopesUrl (state, getters, _, rootGetters) {
      let envelopesUrl = `/envelopes?by_finalized_document_is_purchased=1&by_account_id=${rootGetters.accountId}&expand=1&page=${state.page}&limit=${state.perPage}`
      if (state.query) {
        envelopesUrl += `&by_query=${encodeURIComponent(state.query)}`
      }
      getters.filtersWithValues.forEach(filter => {
        envelopesUrl += `&${filter.apiField}=${getFilterValue(filter)}`
      })

      if (state.sortBy) {
        envelopesUrl += `&sort=${state.sortBy}`
      }

      return envelopesUrl

      function getFilterValue (filter) {
        let value = filter.valueId
        if (filter.type === 'date') {
          value = (new Date(value)).toISOString().split('T')[0]
        }
        if (filter.apiField === 'by_custom_attribute') {
          value = `${filter.label}:${value}`
        }

        return value
      }
    },
  },
  actions: {
    initialize ({ commit, dispatch }) {
      commit('RESET_STATE')
      commit('SET_IS_LOADING', true, { root: true })

      return Promise.all([
        dispatch('fetchCustomAttributeKeys'),
        dispatch('fetchEnvelopes'),
        dispatch('fetchCounterpartyNames'),
      ]).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    setQuery ({ commit, dispatch }, query) {
      commit('SET_QUERY', query)
      return dispatch('fetchEnvelopes')
    },
    setFilters ({ commit, dispatch }, filters) {
      commit('SET_FILTERS', filters)
      return dispatch('fetchEnvelopes')
    },
    setSortBy ({ commit, dispatch }, column) {
      if (column.sortById) {
        commit('UPDATE_SORT_BY', column.sortById)
        return dispatch('fetchEnvelopes')
      }
    },
    fetchCustomAttributeKeys ({ commit, rootGetters }) {
      return getAllRecords(`/custom_attribute_keys?by_account_id=${rootGetters.accountId}&expand=1`)
        .then(records => commit('SET_AVAILABLE_CUSTOM_ATTRIBUTE_KEYS', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju tipov oznak, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchEnvelopes ({ commit, getters }) {
      commit('SET_IS_LOADING_ENVELOPES', true)
      const promises = [
        new Promise(resolve => setTimeout(resolve, 250)), // to prevent flashing of loading screen
        axios.get(getters.envelopesUrl),
      ]

      return Promise.all(promises)
        .then((responses) => {
          commit('SET_ENVELOPES', responses[1].data)
          commit('SET_NUMBER_OF_PAGES', responses[1].headers['total-pages'])
          commit('SET_TOTAL_RESULTS', responses[1].headers['total-count'])
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumentov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING_ENVELOPES', false))
    },
    fetchCounterpartyNames ({ commit, rootGetters }) {
      return axios
        .get(`/finalized_documents/counterparty_names?by_account_id=${rootGetters.accountId}`)
        .then(response => commit('SET_COUNTERPARTY_NAMES', response.data))
    },
    goToPage ({ commit, dispatch, state }, page) {
      if (page > 0 && page <= state.numberOfPages) {
        commit('SET_PAGE', page)
        return dispatch('fetchEnvelopes')
      }
    },
    async deleteFinalizedDocument ({ commit, dispatch }, finalizedDocumentId) {
      await deleteFinalizedDocument(commit, finalizedDocumentId)
      commit('SET_SELECTED_DOCUMENT', null)
      await dispatch('fetchEnvelopes')
    },
    async deleteEnvelope ({ commit, dispatch }, envelopeId) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_MESSAGE', null, { root: true })

      return axios
        .delete(`/envelopes/${envelopeId}`)
        .then(() => {
          commit('SET_SELECTED_DOCUMENT', null)
          dispatch('fetchEnvelopes')
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju mape, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
    setSelectedFinalizedDocument ({ commit }, finalizedDocument) {
      commit('SET_SELECTED_DOCUMENT', finalizedDocument)
    },
  },
  mutations: {
    SET_ENVELOPES (state, envelopes) {
      state.envelopes = envelopes.map(envelope => camelKeys(envelope, { recursive: true, recursiveInArray: true }))
    },
    SET_AVAILABLE_CUSTOM_ATTRIBUTE_KEYS (state, availableCustomAttributeKeys) {
      state.availableCustomAttributeKeys = availableCustomAttributeKeys.map(customAttributeKey => camelKeys(customAttributeKey, { recursive: true, recursiveInArray: true }))
    },
    SET_QUERY (state, query) {
      state.query = query
    },
    SET_FILTERS (state, filters) {
      const mappedFilters = []
      filters.forEach(filter => {
        if (filter.type !== 'range' && filter.type !== 'date') {
          mappedFilters.push(filter)
          return
        }

        const valueIdSplit = filter.valueId.split('-')
        if (valueIdSplit.length !== 2) {
          throw new Error(`Unexpected range filter value: '${filter.valueId}'. Expected format: 'from-to'.`)
        }

        const from = valueIdSplit[0]
        const to = valueIdSplit[1]
        if (from) {
          mappedFilters.push({
            ...filter,
            apiField: `${filter.apiField}_gte`,
            valueId: from,
          })
        }
        if (to) {
          mappedFilters.push({
            ...filter,
            apiField: `${filter.apiField}_lte`,
            valueId: to,
          })
        }
      })
      state.filters = mappedFilters
    },
    SET_COUNTERPARTY_NAMES (state, counterpartyNames) {
      if (Array.isArray(counterpartyNames) && counterpartyNames.length > 0) {
        state.counterpartyNames = counterpartyNames
      }
    },
    UPDATE_SORT_BY (state, clickedColumnId) {
      const clickedSortBy = `finalized_documents.${toSnakeCase(clickedColumnId)}`
      if (state.sortBy.includes(clickedSortBy)) {
        state.sortBy = state.sortBy.startsWith('-') ? clickedSortBy : `-${clickedSortBy}`
      } else {
        state.sortBy = clickedSortBy
      }
    },
    SET_IS_LOADING_ENVELOPES (state, isLoadingEnvelopes) {
      state.isLoadingEnvelopes = isLoadingEnvelopes
    },
    SET_SELECTED_DOCUMENT (state, finalizedDocument) {
      state.selectedFinalizedDocument = finalizedDocument
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
    ...paginationMutations,
  },
}
