<template>
  <svg class="dialog__spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle class="dialog__spinner-path" :class="`dialog__spinner-path--${theme}`" fill="none" stroke-width="10" stroke-linecap="butt" cx="50" cy="50" r="40" />
  </svg>
</template>

<script>
export default {
  props: {
    theme: { type: String, default: 'dark' },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.dialog__spinner {
  width: 32px;
  height: 32px;
  animation: rotator 5s linear infinite;
  transform-origin: center;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dialog__spinner-path {
  stroke-dasharray: 265;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: $primary_color;
  animation: dash 1s ease-in-out infinite;

  &--light {
    stroke: white;
  }
}

@keyframes dash {
  0% { stroke-dashoffset: 265; }
  50% { stroke-dashoffset: 65; transform:rotate(90deg); }
  100% { stroke-dashoffset: 265; transform:rotate(360deg); }
}
</style>
