import UserEdit from '@/components/pages/profile/Edit.vue'

const userProfileRoutes = [
  { path: '/uporabniki/:id/edit', component: UserEdit, name: 'user_profile_edit_path', meta: { title: 'Nastavitve' } },
]

export default userProfileRoutes.map(route => {
  route.meta.isLoginRequired = true
  return route
})
