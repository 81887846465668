<template>
  <div>
    <v-field :class="getValidationClass('label')">
      <label>Ime</label>
      <v-input v-model="form.label" />
      <span class="md-error" v-if="!$v.form.label.required">Vpišite ime oznake</span>
    </v-field>

    <label>Tip oznake</label>
    <div :class="getValidationClass('customAttributeKeyType')" class="attribute-key-form__radio-container">
      <v-radio v-model="form.customAttributeKeyType" value="text">Tekst</v-radio>
      <v-radio v-model="form.customAttributeKeyType" value="date">Datum</v-radio>
      <v-radio v-model="form.customAttributeKeyType" value="list">Izbira</v-radio>
      <br />
      <div class="md-error" v-if="!$v.form.customAttributeKeyType.required">Izberite tip oznake</div>
    </div>

    <v-field :class="getValidationClass('customAttributeListValuesString')" v-if="form.customAttributeKeyType === 'list'">
      <label>Vrednosti seznama (ločene z vejico)</label>
      <v-input v-model="form.customAttributeListValuesString" />
      <span class="md-error" v-if="!$v.form.customAttributeListValuesString.required">Vpišite vsaj eno vrednost seznama</span>
    </v-field>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es'
import { required, requiredIf } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import VRadio from 'design-system/VRadio.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VRadio, VField, VInput },
  data () {
    return {
      form: {
        label: null,
        customAttributeKeyType: null,
        customAttributeListValuesString: null,
      },
    }
  },
  props: {
    attributeKey: { type: Object, required: true },
  },
  mixins: [ValidationMixin],
  created () {
    if (isEmpty(this.attributeKey)) {
      this.form.customAttributeKeyType = 'text'
      return
    }

    this.form.label = this.attributeKey.label
    this.form.customAttributeKeyType = this.attributeKey.customAttributeKeyType
    if (this.attributeKey.customAttributeListValues) {
      this.form.customAttributeListValuesString = this.attributeKey.customAttributeListValues.map(listValue => listValue.label).join(', ')
    }
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.attributeKey,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  computed: {
    isListAttribute () {
      return this.form.customAttributeKeyType === 'list'
    },
  },
  validations: {
    form: {
      label: {
        required,
      },
      customAttributeKeyType: {
        required,
      },
      customAttributeListValuesString: {
        required: requiredIf(parent => parent.customAttributeKeyType === 'list'),
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';

.radio-label {
  top: 0;
}

.attribute-key-form__radio-container {
  display: flex;
  flex-direction: column;

  & .md-radio {
    margin: 8px 0;
  }
}
</style>

<style lang="scss">
.md-radio-label.md-radio-label {
  position: relative;
  top: 0;
}
</style>
