<template>
  <md-table-cell
    :class="{ 'admin__table-row--name': column.id === 'name', 'admin__table-row--description': column.id === 'truncatedDescription', 'admin__table-row--status': column.id === 'status' }"
  >
    <v-radio v-if="column.id === 'name'" :value="finalizedDocument" v-model="localSelectedFinalizedDocument" />
    <div class="u-h-100" :class="{ 'pointer': column.id === 'status' }" @click="onCellClick(column.id)">
      <v-badge
        v-if="column.id === 'status'"
        class="status-badge"
        :class="getStatusBadgeClass(finalizedDocument)"
        :md-content="getColumnValue(finalizedDocument, column)"
      />
      <div v-else class="u-inline-flex"
           @click.stop="column.id === 'name' && openPreviewFinalizedDocumentPdf(finalizedDocument)"
           @click.middle.stop="column.id === 'name' && openPreviewFinalizedDocumentPdf(finalizedDocument, true)"
      >
        {{ getColumnValue(finalizedDocument, column) }}
      </div>
      <v-tooltip v-if="column.id === 'truncatedDescription' && finalizedDocument.isDescriptionTruncated" md-direction="top" md-delay="250">{{ finalizedDocument.description }}</v-tooltip>
    </div>
  </md-table-cell>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FinalizedDocumentDisplayMixin from '../../shared/FinalizedDocumentDisplayMixin'
import VBadge from 'design-system/VBadge.vue'
import VTooltip from 'design-system/VTooltip.vue'
import VRadio from 'design-system/VRadio.vue'
import { truncateText } from '@/utils'

export default {
  components: { VBadge, VTooltip, VRadio },
  props: {
    finalizedDocuments: { type: Array, required: true },
    column: { type: Object, required: true },
  },
  mixins: [FinalizedDocumentDisplayMixin],
  computed: {
    ...mapState('accountStore/finalizedDocuments', ['selectedFinalizedDocument']),
    localSelectedFinalizedDocument: {
      get () {
        return this.selectedFinalizedDocument
      },
      set (value) {
        this.setSelectedFinalizedDocument(value)
      },
    },
    finalizedDocument () {
      // Choose the finalized document that expires the latest.
      const sortedFinalizedDocuments = this.finalizedDocuments
      sortedFinalizedDocuments.sort((a, b) => {
        if (!a.expiresOn && !b.expiresOn) {
          return new Date(b.signedOn) - new Date(a.signedOn)
        }

        if (!a.expiresOn) {
          return 1
        }

        if (!b.expiresOn) {
          return -1
        }

        if (a.expiresOn === b.expiresOn) {
          return new Date(b.signedOn) - new Date(a.signedOn)
        }

        return new Date(b.expiresOn) - new Date(a.expiresOn)
      })
      return this.getFinalizedDocumentWithStatus(sortedFinalizedDocuments[0])
    },
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments', ['setSelectedFinalizedDocument']),
    getColumnValue (item, column) {
      if (column.id === 'status') {
        return item.statusText
      }

      const value = item[column.id]
      if (value == null) {
        return '/'
      }
      const columnIdString = column.id.toString()
      const isDateColumn = (columnIdString.endsWith('At') || columnIdString.endsWith('On')) && !columnIdString.toLowerCase().includes('formatted')

      if (column.id === 'name') {
        return truncateText(value, 55)
      }

      return this.formatColumnValue(value, isDateColumn)
    },
    getStatusBadgeClass (finalizedDocument) {
      return `status-badge--${finalizedDocument.status}`
    },
    onCellClick (column) {
      if (column === 'status') {
        this.$emit('status-click', this.finalizedDocument)
      }
    },
  },
}
</script>

<style lang="scss">
@import 'app/assets/stylesheets/variables';

.pointer {
  cursor: pointer;
}

.status-badge.status-badge.status-badge {
  position: relative;
  width: 120px;
  padding: 5.5px 10px;
  border-radius: 100px;
  background-color: $silver-gray;
  color: $black;
  font-size: 13px;
  font-weight: 500;
  text-transform: lowercase;

  &--signing {
    cursor: pointer;
    background-color: $status-signing;
  }

  &--valid {
    background-color: $modrn-green;
  }

  &--warning {
    background-color: $status-expiring;
  }

  &--expired {
    background-color: $status-expired;
  }
}

/deep/ .md-content {
  position: relative;
}
</style>
