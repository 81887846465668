<template>
  <div>
    <v-field :class="getValidationClass('value')">
      <label>Vrednost</label>
      <v-input v-model="form.value"></v-input>
      <span class="md-error" v-if="!$v.form.value.required">Vpišite vrednost</span>
    </v-field>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VField, VInput },
  data () {
    return {
      form: {
        value: null,
      },
    }
  },
  props: {
    variableOption: { type: Object, required: false },
  },
  mixins: [ValidationMixin],
  created () {
    if (!this.variableOption) {
      return
    }
    this.form.value = this.variableOption.value
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.variableOption,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      value: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
