export function getFromMeta (entityName, isJson = true) {
  const metaTags = [...document.getElementsByTagName('meta')]
  const metaTag = metaTags.find(metaTag => metaTag.getAttribute('name') === entityName)
  const metaTagContent = metaTag.getAttribute('content')
  let parsedMetaTagContent = metaTagContent
  if (isJson) {
    parsedMetaTagContent = metaTagContent ? JSON.parse(metaTagContent) : null
  }
  return parsedMetaTagContent
}

export function getDefaultAccount (accounts) {
  if (!Array.isArray(accounts) || accounts.length === 0) {
    return null
  }

  const defaultAccountId = localStorage.getItem('defaultAccountId')
  let account = null
  if (defaultAccountId && defaultAccountId !== 'null') {
    account = accounts.find(a => a.id == defaultAccountId || a.slug == defaultAccountId)
  }

  return account
}

export function getCurrentAccount (accounts, currentRoute) {
  if (Array.isArray(accounts)) {
    if (currentRoute.meta.isAccountRoute) {
      const accountIdentifier = currentRoute.params.accountId
      const account = accounts.find(a => a.id === accountIdentifier || a.slug === accountIdentifier)
      return account ?? getDefaultAccount(accounts)
    } else {
      return getDefaultAccount(accounts)
    }
  }

  return null
}

export function fontLoaderHook () {
  const styleSheet = insertRule('.md-icon', 'color: transparent !important;')
  document.fonts.ready.then(() => styleSheet.deleteRule(0))

  function insertRule (selector, value) {
    const styleElement = document.createElement('style')
    styleElement.type = 'text/css'
    document.head.insertBefore(styleElement, null)
    const styleSheet = styleElement.sheet
    styleSheet.insertRule(`${selector} { ${value} }`, 0)

    return styleSheet
  }
}

export function isAnalyticsAllowed () {
  return document.cookie.split(';').some(item => item.indexOf('consent_status=2') >= 0)
}

export function isLocalRequest () {
  return document.domain.includes('local')
    || document.domain.includes('staging')
    || document.domain.includes('testing')
    || document.domain.includes('herokuapp.com')
}

export function truncateText (text, numberOfCharacters) {
  return text.length > numberOfCharacters ? `${text.substring(0, numberOfCharacters)}...` : text
}

export function setPageTitle (title) {
  if (!title) {
    document.title = 'Pigenta | Dostopajte do podatkov v vseh vaših pogodbah in dokumentih.'
    return
  }

  document.title = `${title} | Pigenta`
}
