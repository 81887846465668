export default {
  methods: {
    openMailTo () {
      window.open('mailto:info@pigenta.com', '_blank')
    },
    openContactForm () {
      this.$router.push({ name: 'extensions_path', hash: '#kontakt' })
    },
  },
}
