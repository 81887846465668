import axios from 'axios'
import { camelKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import blogPostModule from './blog_post'

const state = {
  blogPosts: [],
  numberOfPages: null,
  page: 1,
  query: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  modules: {
    blogPost: blogPostModule,
  },
  state: getState(),
  getters: {
    isLastPage (state) {
      return state.numberOfPages === state.page
    },
  },
  actions: {
    initialize ({ commit, dispatch }) {
      commit('SET_PAGE', 1)

      return dispatch('fetchBlogPosts', null)
    },
    fetchBlogPosts ({ commit, dispatch }, query) {
      commit('SET_BLOG_POSTS', [])
      commit('SET_IS_LOADING', true, { root: true })

      return dispatch('fetchBlogPostsPage', { query, page: 1 })
        .then((response) => {
          const blogPosts = response.data.map(d => camelKeys(d, { recursive: true, recursiveInArray: true }))
          const numberOfPages = parseInt(response.headers['total-pages'])
          commit('SET_BLOG_POSTS', blogPosts)
          commit('SET_NUMBER_OF_PAGES', numberOfPages)
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju blogov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchMoreBlogPosts ({ commit, dispatch, state }) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_PAGE', state.page + 1)

      return dispatch('fetchBlogPostsPage', { query: state.query, page: state.page })
        .then((response) => {
          const blogPosts = response.data.map(d => camelKeys(d, { recursive: true, recursiveInArray: true }))
          const numberOfPages = parseInt(response.headers['total-pages'])
          commit('ADD_BLOG_POSTS', blogPosts)
          commit('SET_NUMBER_OF_PAGES', numberOfPages)
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju blogov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchBlogPostsPage (_, { query, page }) {
      let url = `/blog_posts?page=${page}`
      if (query) {
        url += `&by_query=${encodeURIComponent(query)}`
      }

      return axios.get(url)
    },
    setQuery ({ commit, dispatch, state }, query) {
      commit('SET_QUERY', query)
      commit('SET_PAGE', 1)
      return dispatch('fetchBlogPosts', state.query)
    },
  },
  mutations: {
    SET_BLOG_POSTS (state, blogPosts) {
      state.blogPosts = blogPosts
    },
    ADD_BLOG_POSTS (state, blogPosts) {
      state.blogPosts = [...state.blogPosts, ...blogPosts]
    },
    SET_PAGE (state, page) {
      state.page = page
    },
    SET_NUMBER_OF_PAGES (state, numberOfPages) {
      state.numberOfPages = numberOfPages
    },
    SET_MOST_USED_BLOG_POST_ID (state, mostUsedBlogPostId) {
      state.mostUsedBlogPostId = mostUsedBlogPostId
    },
    SET_QUERY (state, query) {
      state.query = query
    },
  },
}
