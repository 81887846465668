<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="new-document__header">
      <h1 class="page-title">Uvozi Dokument</h1>
    </div>
    <v-field>
      <label>JSON dokumenta</label>
      <v-textarea v-model="documentString"></v-textarea>
    </v-field>
    <v-button class="regular-button new-document__header-button" @click="importDocument(documentString)">Uvozi</v-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VTextarea from 'design-system/VTextarea.vue'

export default {
  components: { VButton, VField, VTextarea },
  data () {
    return {
      documentString: null,
    }
  },
  methods: {
    ...mapActions('admin/documents/forms', ['importDocument']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';

.new-document__header-button {
  position: relative;
  top: -12px;
}
</style>
