<template>
  <div class="guarantee-header" v-show="isGuaranteeHeaderVisible">
    <div class="guarantee-header-text">
      <router-link class="guarantee-header-link" :to="{ name: 'terms_path', hash: '#garancije' }" target="_blank">60-dnevna garancija kvalitete:</router-link> Vračilo kupnine, če odvetnik odkrije napako v dokumentu.
    </div>
    <div class="guarantee-header-close" @click="hideGuaranteeHeader"><icon name="close" /></div>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'

export default {
  components: {
    Icon,
  },
  data () {
    return {
      isGuaranteeHeaderVisible: true,
    }
  },
  methods: {
    hideGuaranteeHeader () {
      this.isGuaranteeHeaderVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.guarantee-header {
  width: 100%;
  background-color: $header_blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 40px 24px 24px;

  @include respond-to(small) {
    padding: 16px 36px 16px 16px;
  }
}

.guarantee-header-text {
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  color: white;
}

.guarantee-header-link.guarantee-header-link {
  text-decoration: underline;
  color: white;

  &:hover {
    color: white;
  }
}

.guarantee-header-close {
  position: absolute;
  right: 16px;
  padding-top: 4px;
  color: white;
  cursor: pointer;

  @include respond-to(small) {
    right: 8px;
  }
}
</style>
