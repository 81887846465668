import attributeKeysModule from './attribute_keys'
import finalizedDocumentsModule from './finalized_documents'
import recentFinalizedDocumentsModule from './recent_finalized_documents'
import usersModule from './users'

export default {
  namespaced: true,
  modules: {
    attributeKeys: attributeKeysModule,
    finalizedDocuments: finalizedDocumentsModule,
    recentFinalizedDocuments: recentFinalizedDocumentsModule,
    users: usersModule,
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {},
}
