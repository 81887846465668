import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'

const state = {
  finalizedDocuments: [],
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    finalizedDocumentsWithUrlsAndStatus (state) {
      return state.finalizedDocuments
        .map(finalizedDocument => {
          let status = finalizedDocument.isPurchased ? 'plačano' : 'neplačano'
          if (finalizedDocument.price === 0) {
            status = 'brezplačno'
          }

          return {
            ...finalizedDocument,
            isPurchased: finalizedDocument.isPurchased,
            status: status,
            previewUrl: finalizedDocument.isPurchased ? getPdfUrl(finalizedDocument.id, true) : null,
            pdfDownloadUrl: finalizedDocument.isPurchased ? getPdfUrl(finalizedDocument.id) : null,
            docxDownloadUrl: finalizedDocument.isPurchased ? getDocxUrl(finalizedDocument.id) : null,
          }
        })
    },
  },
  actions: {
    initialize ({ commit, dispatch, rootGetters }) {
      const unpaidFinalizedDocuments = state.finalizedDocuments.filter(fd => !fd.isPurchased)
      dispatch('trackEvents', [
        {
          name: 'view_cart',
          data: {
            send_to: 'G-G-5JHZXZD27X',
            currency: 'EUR',
            value: unpaidFinalizedDocuments.reduce((acc, document) => acc + document.price, 0),
            items: unpaidFinalizedDocuments.map(document => {
              return {
                item_category: 'Vsi vzorci',
                item_list_id: 'vzorci',
                item_list_name: 'Vzorci',
                item_id: document.id,
                item_name: document.name,
                price: document.price,
                quantity: 1,
              }
            }),
          },
        },
      ], { root: true })
      commit('SET_IS_LOADING', true, { root: true })

      return fetchRecentFinalizedDocuments(rootGetters.accountId)
        .then(response => commit('SET_FINALIZED_DOCUMENTS', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumentov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    eSignDocument ({ commit, dispatch }, { finalizedDocumentId, signers }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const payload = {
        signers,
        finalizedDocumentId,
      }

      return axios
        .post('/eversign/create', snakeKeys(payload, { recursive: true, recursiveInArray: true }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri e-podpisovanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
          throw new Error(error)
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_FINALIZED_DOCUMENTS (state, finalizedDocuments) {
      state.finalizedDocuments = finalizedDocuments.map(cd => camelKeys(cd, { recursive: true, recursiveInArray: true }))
    },
    ADD_FINALIZED_DOCUMENTS (state, finalizedDocuments) {
      state.finalizedDocuments = state.finalizedDocuments.concat(finalizedDocuments.map(cd => camelKeys(cd, { recursive: true, recursiveInArray: true })))
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}

function getPdfUrl (finalizedDocumentId, isPreview = false) {
  let url = `/finalized_documents/${finalizedDocumentId}/download?format=pdf`
  if (isPreview) {
    url += '&disposition=inline'
  }

  return url
}

function getDocxUrl (finalizedDocumentId) {
  return `/finalized_documents/${finalizedDocumentId}/download?format=docx`
}

function fetchRecentFinalizedDocuments (accountId = null) {
  return axios.get(`/finalized_documents/recent?generated=1&by_account_id=${accountId}`)
}
