<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <div class="import">
      <v-button class="regular-button" @click="$router.push({ name: 'admin_import_document_path' })">Uvozi</v-button>
    </div>
    <entity-table
      :columns="columns"
      :items="documents"
      :are-items-clickable="true"
      :is-loaded="!!numberOfPages"
      :pagination="pagination"
      title="Urejevalnik"
      add-text="Nov dokument"
      :empty-text="emptyText"
      @search="setQuery"
      @page-change="goToPage"
      @add="$router.push({ name: 'admin_new_document_path' })"
      @click="openEditDocument"
      @clickWithModifier="openEditDocumentInNewTab"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import VButton from 'design-system/VButton.vue'
import PaginationMixin from '../../shared/PaginationMixin'

export default {
  components: { EntityTable, VButton },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'Ime' },
      { id: 'keywords', name: 'Ključne besede' },
      { id: 'description', name: 'Opis' },
      { id: 'stipulations', name: 'Relevantne določbe' },
      { id: 'owner', name: 'Skrbnik' },
      { id: 'price', name: 'Cena' },
      { id: 'created_at', name: 'Datum nastanka' },
    ]
  },
  created () {
    this.initialize()
    this.storeModule = ['admin', 'documents']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('admin/documents', ['documents', 'numberOfPages', 'query']),
    emptyText () {
      return this.query ? 'Noben dokument ne ustreza iskanju.' : 'Zaenkrat še nimate dokumentov.'
    },
  },
  methods: {
    ...mapActions('admin/documents', ['initialize', 'reset', 'setQuery', 'goToPage']),
    openEditDocument (document) {
      this.$router.push({
        name: 'admin_document_edit_path',
        params: {
          id: document.slug,
        },
      })
    },
    openEditDocumentInNewTab (document) {
      const route = this.$router.resolve({
        name: 'admin_document_edit_path',
        params: {
          id: document.slug,
        },
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';

.import {
  display: flex;
  justify-content: flex-end;
}
</style>
