<template>
  <condition
    v-if="configurationObject.type === 'condition'"
    :condition="configurationObject"
    @input="$emit('input', $event)"
    @change="$emit('conditionOutcomeChange', $event)"
  />
  <variable
    v-else-if="configurationObject.type === 'variable'"
    :variable="configurationObject"
    @input="$emit('input', $event)"
    @focus="$emit('variableFocus')"
    @duplicate="$emit('duplicate', $event)"
    @remove="$emit('remove', $event)"
  />
</template>

<script>
import Condition from './Condition'
import Variable from './Variable'

export default {
  components: {
    Condition,
    Variable,
  },
  props: {
    configurationObject: { type: Object, required: true },
  },
}
</script>

<style scoped lang="scss">
</style>
