import axios from 'axios'
import { capitalize } from 'lodash-es'

export function getResponseErrorMessage (error) {
  if (!error.response || !error.response.data) {
    return null
  }

  if (error.response.data.message) {
    return error.response.data.message
  }

  if (error.response.data.error) {
    if (typeof error.response.data.error !== 'string') {
      return null
    }

    return error.response.data.error
  }

  if (error.response.data.errors) {
    const errors = error.response.data.errors
    if (typeof errors !== 'object' || errors === null) {
      return null
    }

    return `${capitalize(Object.keys(errors)[0])}: ${Object.values(errors)[0][0]}`
  }

  if (error.response.data) {
    const errors = error.response.data
    if (typeof errors !== 'object' || errors === null) {
      return null
    }

    return `${capitalize(Object.keys(errors)[0])}: ${Object.values(errors)[0][0]}`
  }

  return null
}

export async function getAllRecords (url, limit = 500) {
  const finalUrl = url.includes('?') ? `${url}&limit=${limit}` : `${url}?limit=${limit}`
  const firstPageResponse = await axios.get(finalUrl)
  const firstPageRecords = firstPageResponse.data
  const numberOfPages = parseInt(firstPageResponse.headers['total-pages'])

  if (numberOfPages === 1) {
    return firstPageRecords
  }

  const promises = []
  // we've fetched the 1st page already
  for (let page = 2; page <= numberOfPages; page++) {
    const pageUrl = `${finalUrl}&page=${page}`
    promises.push(axios.get(pageUrl))
  }

  const pagesResults = await Promise.all(promises)
  return pagesResults.reduce((results, resultsForPage) => results.concat(resultsForPage.data), firstPageRecords)
}
