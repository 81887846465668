<template>
  <transition name="prompt">
    <div v-if="prompt.state.active" class="prompt__container">
      <div class="prompt">
        <div class="prompt__icon-container">
          <icon class="prompt__icon" name="alert-triangle" :width="56" :height="56" />
        </div>
        <div class="prompt__title" v-if="prompt.state.title">{{ prompt.state.title }}</div>
        <div class="prompt__message" v-if="prompt.state.message">{{ prompt.state.message }}</div>
        <div class="prompt__buttons">
          <v-button @click="prompt.cancel()" outlined>{{ prompt.state.cancelText }}</v-button>
          <v-button @click="prompt.ok()" background-color="red">{{ prompt.state.okText }}</v-button>
        </div>
      </div>
      <div class="prompt__background" @click="prompt.cancel()"></div>
    </div>
  </transition>
</template>

<script>
import prompt from './prompt.js'
import VButton from 'design-system/VButton'
import Icon from 'design-system/icons/Icon'
export default {
  components: {
    VButton,
    Icon,
  },
  created () {
    window.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  data () {
    return {
      prompt,
    }
  },
  methods: {
    onKeyDown (event) {
      if (event.key !== 'Escape') {
        return
      }
      event.preventDefault()
      prompt.cancel()
    },
  },
}
</script>

<style lang='scss' scoped>
@import 'app/assets/stylesheets/variables';

$icon-background: #FEE2E2;

.prompt {
  position: fixed;
  z-index: $z_heaven;
  top: 40%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 74px 96px;
  border-radius: 16px;
  background: $white;
  transform: translate(-50%, -50%);

  @include respond-to(not-large) {
    padding: 56px 24px 72px;
  }

  @include respond-to(small) {
    width: 90%;
    padding: 32px 16px;
  }

  & .prompt__icon-container {
    display: flex;
    width: 120px;
    height: 120px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 40px;
    background: $icon-background;

    @include respond-to(not-large) {
      width: 80px;
      height: 80px;
    }
  }

  & .prompt__icon {
    color: $red;
  }

  & .prompt__title {
    margin-bottom: 24px;
    color: $black;
    font-size: 24px;
    font-weight: 600;
    text-align: center;

    @include respond-to(small) {
      font-size: 18px;
    }
  }

  & .prompt__message {
    margin-bottom: 40px;
    color: $black;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    white-space: pre-line;

    @include respond-to(small) {
      font-size: 14px;
    }
  }

  & .prompt__buttons {
    display: flex;

    & > *:not(:last-child) {
      margin-right: 24px;
    }

    @include respond-to(not-large) {
      flex-direction: column;
      align-items: center;

      & > * {
        width: 100%;
      }

      & > *:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.prompt__background {
  position: fixed;
  z-index: $z_heaven - 1;
  background: $black;
  inset: 0;
  opacity: 0.6;
}
</style>
