<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <h1 class="page-title">Nov Račun</h1>
    <account-form @submit="submitAccount" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AccountForm from './forms/AccountForm'

export default {
  components: {
    AccountForm,
  },
  created () {
    this.initialize(this.id)
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    ...mapActions('admin/accounts/forms', ['initialize', 'reset', 'submitAccount']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
