import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'

const state = {
  id: null,
  attributeKeyId: null,
  attributeKey: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    attributeKeyId (state) {
      if (state.attributeKeyId) {
        return state.attributeKeyId
      }
      if (state.attributeKey) {
        return state.attributeKey.id
      }

      return null
    },
    attributeKeyPayload (state, _, rootState, rootGetters) {
      const existingListValues = state.attributeKey.customAttributeListValues ?? []
      const newListValues = []
      if (state.attributeKey.customAttributeKeyType === 'list') {
        const newListValueLabels = state.attributeKey.customAttributeListValuesString.split(',')
        newListValueLabels.forEach((listValueLabel, index) => {
          let id = null
          const label = listValueLabel.trim()
          const existingListValue = existingListValues.find(listValue => listValue.label === label)
          if (existingListValue || existingListValues.length > index) {
            id = existingListValue?.id ?? existingListValues[index].id
          }

          if (existingListValue) {
            id = existingListValue.id
          }

          newListValues.push({
            id,
            label,
          })
        })
      }

      // remove deleted list values
      existingListValues.forEach(listValue => {
        if (!newListValues.find(lv => lv.id === listValue.id)) {
          newListValues.push({ id: listValue.id, _destroy: true })
        }
      })

      return {
        custom_attribute_key: {
          ...snakeKeys(state.attributeKey, { recursive: true, recursiveInArray: true }),
          account_id: rootGetters.accountId,
          custom_attribute_list_values_attributes: newListValues,
        },
      }
    },
  },
  actions: {
    setAttributeKeyId ({ commit }, attributeKeyId) {
      commit('SET_ATTRIBUTE_KEY_ID', attributeKeyId)
    },
    initialize ({ commit, dispatch, state }) {
      commit('SET_IS_LOADING', true, { root: true })

      const promises = []
      if (state.attributeKeyId) {
        promises.push(dispatch('fetchAttributeKey'))
      } else {
        commit('SET_ATTRIBUTE_KEY', {})
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchAttributeKey ({ commit, state, rootGetters }) {
      if (!state.attributeKeyId || !rootGetters.accountId) {
        throw new Error('attributeKeyId and accountId should be set in the state')
      }

      return axios
        .get(`/custom_attribute_keys/${state.attributeKeyId}?expand=1`)
        .then(response => commit('SET_ATTRIBUTE_KEY', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju oznak, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateAttributeKeyObject ({ commit }, newAttributeKey) {
      commit('UPDATE_ATTRIBUTE_KEY_OBJECT', newAttributeKey)
    },
    submitAttributeKey ({ commit, dispatch, state }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewAttributeKey = state.attributeKeyId == null
      const promise = isCreatingNewAttributeKey ? dispatch('createAttributeKey') : dispatch('updateAttributeKey')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Oznaka uspešno shranjena.', type: 'success' }, { root: true })
          router.push({ name: 'account_attribute_keys_path' })
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju oznake - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createAttributeKey ({ commit, getters }) {
      return axios
        .post('/custom_attribute_keys', getters.attributeKeyPayload)
        .then(response => commit('SET_ATTRIBUTE_KEY_ID', response.data.id))
    },
    updateAttributeKey ({ state, getters, rootGetters }) {
      if (!state.attributeKeyId || !rootGetters.accountId) {
        throw new Error('attributeKeyId and accountId should be set in the state')
      }

      return axios.put(`/custom_attribute_keys/${state.attributeKeyId}`, getters.attributeKeyPayload)
    },
    deleteAttributeKey ({ commit, dispatch, rootGetters }, attributeKeyId) {
      if (!rootGetters.accountId) {
        throw new Error('accountId should be set in the state')
      }

      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/custom_attribute_keys/${attributeKeyId}`)
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju oznake, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_ATTRIBUTE_KEY_ID (state, attributeKeyId) {
      state.attributeKeyId = attributeKeyId
    },
    SET_ATTRIBUTE_KEY (state, attributeKey) {
      state.attributeKey = camelKeys(attributeKey, { recursive: true, recursiveInArray: true })
    },
    UPDATE_ATTRIBUTE_KEY_OBJECT (state, newAttributeKey) {
      state.attributeKey = {
        ...state.attributeKey,
        ...newAttributeKey,
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
