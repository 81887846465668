<template>
  <div class="auth__password-reset__container">
    <div class="auth__text auth__separator">Vnesite novo geslo za ponastavitev</div>
    <form novalidate @submit.prevent="submit">
      <div>
        <v-field :class="getValidationClass('password')">
          <label>Geslo</label>
          <v-input v-model="form.password" type="password" @focus="scrollToField" />
          <span class="md-error" v-if="$v.form.password && !$v.form.password.required">Vpišite geslo</span>
          <span class="md-error" v-if="$v.form.password && $v.form.password.required && !$v.form.password.minLength">Geslo mora biti dolgo najmanj 6 znakov</span>
        </v-field>

        <v-field :class="getValidationClass('confirmPassword')">
          <label>Ponovite geslo</label>
          <v-input v-model="form.confirmPassword" type="password"></v-input>
          <span class="md-error" v-if="!$v.form.confirmPassword.sameAs">Gesli se ne ujemata</span>
        </v-field>

        <v-button type="submit" :disabled="isLoading">Ponastavi geslo</v-button>

        <div class="auth__links">
          <router-link class="auth__link" :to="{ name: 'login_path' }">Nazaj na prijavo.</router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import FocusableFieldMixin from '../shared/FocusableFieldMixin'
import ValidationMixin from '../shared/ValidationMixin'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VButton, VField, VInput },
  props: {
    token: { type: String, required: true },
  },
  data () {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
    }
  },
  mixins: [FocusableFieldMixin, ValidationMixin],
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const payload = {
        token: this.token,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
      }
      this.resetPassword(payload)
    },
  },
  validations: {
    form: {
      password: {
        required: required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAs: sameAs('password'),
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import './common';
@import '../common';
</style>
