<template>
  <div v-if="isShown" class="cc">
    <div class="cc__text">
      Spletna stran uporablja piškotke z namenom zagotavljanja boljše uporabniške izkušnje.<br />
      <router-link :to="{ name: 'privacy_path' }" class="cc__link">Preberite več.</router-link>
    </div>

    <div class="cc__choices">
      <div class="cc__choice">
        <v-checkbox v-model="isCookieAllowed" :disabled="true" class="cc__checkbox">Nujni</v-checkbox>
        <v-tooltip md-direction="top" md-delay="500" class="cc__tooltip">Nujnih piškotkov ne morete izklopiti.</v-tooltip>
      </div>
      <div class="cc__choice">
        <v-checkbox v-model="isAnalyticalCookieAllowed" class="cc__checkbox">Analitični</v-checkbox>
      </div>
    </div>

    <div class="cc__buttons">
      <div class="cc__button" @click="acceptSelected">Shrani izbiro</div>
      <v-button background-color="primary-blue" size="small" @click="acceptAll">Sprejmi vse</v-button>
    </div>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'
import VCheckbox from 'design-system/VCheckbox.vue'
import VTooltip from 'design-system/VTooltip.vue'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    VCheckbox,
    VTooltip,
    VButton,
  },
  data () {
    return {
      isShown: true,
      isCookieAllowed: true,
      isAnalyticalCookieAllowed: false,
    }
  },
  created () {
    const wasConsentAccepted = document.cookie.split(';').some(item => item.indexOf('consent_status=') >= 0)
    this.isShown = !wasConsentAccepted
  },
  methods: {
    acceptAll () {
      this.isAnalyticalCookieAllowed = true
      this.acceptSelected()
    },
    acceptSelected () {
      const cookieExpiration = new Date()
      cookieExpiration.setFullYear(cookieExpiration.getFullYear() + 1)
      if (this.isAnalyticalCookieAllowed) {
        document.cookie = `consent_status=2; expires=${cookieExpiration.toString()}`
        bootstrap()
      } else {
        document.cookie = `consent_status=1; expires=${cookieExpiration.toString()}`
      }
      this.isShown = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.cc {
  position: fixed;
  bottom: 8px;
  right: 8px;
  width: 280px;
  padding: 20px;
  background-color: white;
  color: black;
  border-radius: 8px;
  box-shadow: 3px 14px 29px rgba(0, 0, 0, 0.11);
  z-index: $z-heaven;
}

.cc__link {
  text-decoration: underline;
  color: black !important;
}

.cc__choices {
  margin: 16px 0;
  color: $primary_color;
}

.cc__checkbox {
  margin: 4px 0;
}

.cc__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.cc__button {
  height: 10px;
  margin-top: 6px;
  box-sizing: border-box;
  cursor: pointer;
}

.cc__tooltip.cc__tooltip.cc__tooltip {
  color: black;
  background-color: white;

  &::before {
    border-top-color: white;
  }
}
</style>
