<template>
  <div class="smart-contracts__cards" v-if="documents.length > 0">
    <r-cards :cards="cards" @click="onClick" />
  </div>
</template>

<script>
import RCards from 'design-system/components/RCards.vue'
export default {
  components: {
    RCards,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  computed: {
    cards () {
      return this.documents.map(d => {
        return {
          ...d,
          subtitle: d.price ? `${d.price} €` : null,
        }
      })
    },
  },
  methods: {
    onClick ({ card, event }) {
      this.$emit('document-click', { document: card, event })
    },
  },
}
</script>
