<template>
  <div>
    <hero v-if="blogPost && !isLoggedIn" class="blog-hero">
      <blog-post-header size="big" :blog-post="blogPost" />
    </hero>

    <div class="blog-post page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent, 'blog-post--logged-in': isLoggedIn }" v-if="blogPost">
      <div class="blog-post-container">
        <blog-post-header v-if="isLoggedIn" :blog-post="blogPost" />

        <div class="blog-post-contents">
          <div class="blog-post-content" v-for="blogPostContent in blogPostContentsWithHtml" :key="blogPostContent.id">
            <div class="blog-post-content-image-container" v-if="blogPostContent.imageUrl">
              <img class="blog-post-content-image" alt="Slika vsebine bloga" :src="blogPostContent.imageUrl" />
            </div>
            <div class="blog-post-content-wrapper">
              <div class="blog-post-content-text">
                <div class="blog-post-content-title" v-if="blogPostContent.title">{{ blogPostContent.title }}</div>
                <div class="blog-post-content-content" v-html="blogPostContent.htmlContent" />
              </div>
              <div class="blog-post-content-document" v-if="blogPostContent.documentId">
                <r-card
                  class="blog-post-content-document-card"
                  @click="openDocumentComposition({ document: blogPostContent.document, event: $event })"
                  :title="blogPostContent.document.name"
                  button-text="Poglej vzorec"
                  :button-style="false"
                />
              </div>
            </div>
          </div>
        </div>

        <related class="blog-post-related-container" :blog-posts="lastBlogPosts" v-if="lastBlogPosts && lastBlogPosts.length > 0" />
      </div>
    </div>

    <email-newsletter v-if="!isLoggedIn" id="newsletter" class="email-newsletter" :use-secondary-mailing-list="true" />
  </div>
</template>

<script>
import snarkdown from 'snarkdown'
import { mapActions, mapGetters, mapState } from 'vuex'
import Hero from '@/components/pages/static/components/Hero.vue'
import RCard from 'design-system/components/RCard.vue'
import BlogPostHeader from './BlogPostHeader.vue'
import Related from './Related.vue'

export default {
  components: {
    BlogPostHeader,
    Hero,
    RCard,
    Related,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  created () {
    this.initialize(this.id)
      .then(() => {
        if (!this.blogPost) {
          return
        }

        this.$root.$emit('set-page-meta', { title: this.blogPost.title })
      })
  },
  watch: {
    $route: {
      handler () {
        this.initialize(this.id)
      },
    },
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('pages/blog/blogPost', ['blogPost', 'lastBlogPosts']),
    ...mapGetters(['isLoggedIn']),
    blogPostContentsWithHtml () {
      return this.blogPost.blogPostContents.map(blogPostContent => ({
        ...blogPostContent,
        htmlContent: snarkdown(blogPostContent.content),
      }))
    },
  },
  methods: {
    ...mapActions('pages/blog/blogPost', ['initialize']),
    openDocumentComposition ({ document }) {
      const routeData = this.$router.resolve({
        name: 'compose_document_path',
        params: {
          id: document.slug,
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
@import "../common";
@import "../../common";

.blog-hero {
  position: relative;
  text-align: left;
  padding: 144px 80px 0 80px;

  /deep/ .hero-ellipse-1,
  /deep/ .hero-ellipse-3 {
    display: none;
  }

  @include respond-to('small') {
    padding: 96px 32px 0 32px;
  }
}

.blog-post:not(.blog-post--logged-in) {
  padding: 100px 170px;

  @include respond-to('small') {
    padding: 32px;
  }

  /deep/ .blog-post-related {
    width: calc(100% + 180px);
    position: relative;
    left: -90px;

    @include respond-to('small') {
      width: 100%;
      left: 0;
    }
  }
}

.blog-post--logged-in {
  .blog-post-container {
    padding: 40px 50px;
    background-color: white;
    border-radius: 8px;

    @include respond-to('small') {
      padding: 40px 16px;
    }
  }
}

.blog-post-content {
  padding-bottom: 64px;
}

.blog-post-content-image-container {
  padding-bottom: 32px;
}

.blog-post-content-image {
  width: 100%;
}

.blog-post-content-wrapper {
  display: flex;
  justify-content: space-between;

  @include respond-to('small') {
    flex-direction: column;
  }
}

.blog-post-content-text {
  padding-right: 32px;
}

.blog-post-content-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.48px;
  padding-bottom: 24px;

  @include respond-to('small') {
    font-size: 24px;
  }
}

.blog-post-content-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.24px;
  color: $text-gray;

  @include respond-to('small') {
    font-size: 15px;
  }
}

.blog-post-content-document {
  @include respond-to('small') {
    margin: auto;
    padding-top: 32px;
  }
}

.blog-post-content-document-card {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}

.blog-post-related-container {
  margin-top: 56px;

  @include respond-to(small) {
    margin-top: 32px;
  }
}

.email-newsletter {
  padding: 100px 170px;
  background-color: $white;

  @include respond-to('small') {
    padding: 32px;
  }
}
</style>
