<template>
  <div></div>
</template>

<script>
import { isLocalRequest } from '@/utils'

export default {
  created () {
    window.addEventListener('click', this.trackClick)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.trackClick)
  },
  methods: {
    trackClick (ev) {
      if (!this.isAnalyticsAllowed) {
        return
      }

      // for clicks on svgs find the parent as a target
      const target = ev.target.tagName === 'svg' && ev.target.parentElement ? ev.target.parentElement : ev.target
      const className = target.className
      if (className === null || typeof className !== 'string' || className.includes('no-click-tracking')) {
        return
      }
      const id = target.id || className.split(' ')[0]
      if (id === null || id === '') {
        return
      }

      const routeName = this.$route.name
      if (isLocalRequest()) {
        // eslint-disable-next-line no-console
        console.info(`GA click with category: ${routeName} and id: ${id} would be tracked if this request wasn't local`)
        return
      }

      try {
        this.$gtag.query('event', 'click', {
          'event_category': routeName,
          'event_label': id,
        })
      } catch {
        // GA errors should not break anything
      }
    },
  },
}
</script>
