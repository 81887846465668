<template>
  <md-field v-bind="$attrs" v-on="$listeners">
    <slot />
  </md-field>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss">
@import "app/assets/stylesheets/colors";

.md-field {
  margin: 8px 0 24px;
}

.md-field::after, .md-field::before {
  display: none;
}

.md-field label, .md-field.md-has-textarea:not(.md-autogrow) label {
  top: auto;
  left: 16px;
  margin-top: 14px;
}

.md-field.md-focused label, .md-field.md-has-value label {
  top: -6px;
  left: 0;
  margin: 0;
  font-size: 12px;
}

.md-field.md-has-password .md-toggle-password {
 margin: 8px 6px;
}

.md-field.md-theme-default.md-disabled .md-input,
.md-field.md-theme-default.md-disabled .md-textarea {
  border-color: $input-light;
}
</style>
