<template>
  <div class="upload-container" :class="{ 'upload-container--drop-active': isDropActive }">
    <div
      class="drop-area"
      :class="{ 'drop-area--active': isDropActive }"
      @dragover.stop="dragStart"
      @dragleave.stop="dragEnd"
      @drop.prevent="drop"
    />
    <div v-if="isUploading">
      <v-progress-spinner class="md-accent" :md-diameter="30" :md-stroke="3" md-mode="indeterminate" />
      <br />
      <br />
      <div class="upload-text">Datoteka se prenaša...</div>
    </div>

    <template v-else>
      <div v-if="isFilePresent" class="uploaded-file-container">
        <div class="upload-text upload-name">{{ filename }}</div>
        <icon class="remove-icon" name="close" :width="20" :height="20" @click="removeFile" />
      </div>
      <v-button @click="openUploadDialog" outlined size="medium" :disabled="disabled">{{ augmentedButtonText }}</v-button>
      <div class="upload-text" v-if="secondaryText">{{ secondaryText }}</div>
      <input class="input" type="file" ref="fileInput" :accept="accepts ? accepts.join(',') : null" />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmpty } from 'lodash-es'
import VButton from 'design-system/VButton.vue'
import Icon from 'design-system/icons/Icon.vue'
import VProgressSpinner from 'design-system/VProgressSpinner.vue'

export default {
  inheritAttrs: false,
  components: {
    Icon,
    VButton,
    VProgressSpinner,
  },
  data () {
    return {
      currentFile : {},
      error: null,
      isDropActive: false,
      isUploading: false,
    }
  },
  props: {
    model: { type: Object, required: true },
    accepts: { type: Array, default: null },
    buttonText: { type: String, default: 'Naloži datoteko' },
    buttonReuploadText: { type: String, default: 'Zamenjaj datoteko' },
    secondaryText: { type: String, default: null },
    fileUploadPrefix: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  mounted () {
    this.$refs.fileInput.addEventListener('change', () => {
      this.selectFile(this.$refs.fileInput.files[0])
    })

    if (this.model) {
      this.currentFile = this.model
    }
  },
  watch: {
    model (newValue) {
      this.currentFile = newValue
    },
    isUploading: {
      handler (newValue) {
        if (newValue === true) {
          this.$emit('uploading')
        } else {
          this.$emit('uploaded')
        }
      },
    },
  },
  computed: {
    isFilePresent () {
      return !isEmpty(this.currentFile)
    },
    filename () {
      if (!this.isFilePresent) {
        return null
      }

      return this.currentFile.name
    },
    augmentedButtonText () {
      return this.isFilePresent ? this.buttonReuploadText : this.buttonText
    },
  },
  methods: {
    ...mapActions(['uploadFile']),
    openUploadDialog () {
      if (this.disabled) {
        return
      }

      this.$refs.fileInput.click()
    },
    selectFile (file) {
      this.removeFile()
      if (!this.validateFileType(file)) {
        return
      }

      this.isUploading = true
      return this.uploadFile({ file, prefix: this.fileUploadPrefix })
        .then(result => {
          this.currentFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            blobHash: result.data.blob_hash,
          }
          this.$emit('change', this.currentFile)
        })
        .catch(error => this.error = error)
        .finally(() => this.isUploading = false)
    },
    validateFileType (file) {
      if (this.accepts === null || !Array.isArray(this.accepts)) {
        return true
      }

      if (this.accepts.includes(file.type)) {
        return true
      }

      const allowedMimeTypesString = this.accepts
        .map(type => {
          const parts = type.split('/')
          return parts[parts.length - 1]
        })
        .join(', ')

      alert(`Neveljavna datoteka! Prosimo naložite datoteko tipov: ${allowedMimeTypesString}.`)
      return false
    },
    removeFile () {
      this.dragEnd()
      this.currentFile = {}
      this.$refs.fileInput.value = ''
      this.$emit('change', this.currentFile)
    },
    dragStart (ev) {
      ev.preventDefault()
      this.isDropActive = true
    },
    dragEnd () {
      this.isDropActive = false
    },
    drop (ev) {
      ev.preventDefault()
      ev.stopPropagation()
      this.dragEnd()

      if (!ev.dataTransfer.files) {
        return
      }

      if (ev.dataTransfer.files.length !== 1) {
        alert('Spustite lahko samo eno datoteko.')
      }

      this.$refs.fileInput.files = ev.dataTransfer.files
      this.selectFile(ev.dataTransfer.files[0])
    },
  },
}
</script>

<style lang="scss">
@import "app/assets/stylesheets/colors";

.upload-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 136px;
  padding: 16px;
  align-items: center;
  justify-content: space-around;
  /* stylelint-disable-next-line color-function-notation */
  border: 1px dashed rgba(4, 87, 139, 60%);
  border-radius: 4px;
  text-align: center;

  &--drop-active {
    .upload-text,
    .remove-icon {
      z-index: 10;
      color: white;
    }
  }
}

.drop-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &--active {
    background-color: $primary-blue;
    cursor: grab;
  }
}

.upload-text {
  color: $modrn-blue-dark-60;
  font-size: 14px;
  text-align: center;
}

.upload-name {
  height: 0;
}

.uploaded-file-container {
  display: flex;
}

.remove-icon {
  margin-left: 8px;
  cursor: pointer;
  color: $modrn-blue-dark-60;
}

.input {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}
</style>
