<template>
  <div>
    <v-field :class="getValidationClass('name')">
      <label>Ime</label>
      <v-input v-model="form.name" />
      <span class="md-error" v-if="!$v.form.name.required">Vpišite ime mape</span>
    </v-field>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VField, VInput },
  data () {
    return {
      form: {
        name: null,
      },
    }
  },
  props: {
    envelope: { type: Object, required: true },
  },
  mixins: [ValidationMixin],
  created () {
    if (!this.envelope) {
      return
    }
    this.form.name = this.envelope.name
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.envelope,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
