import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage, getAllRecords } from '@/store/utils'
import router from '@/router'

const state = {
  userId: null,
  user: null,
  availableAccounts: [],
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    userId (state) {
      if (state.userId) {
        return state.userId
      }
      if (state.user) {
        return state.user.id
      }

      return null
    },
  },
  actions: {
    initialize ({ commit, dispatch }, userId) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_USER_ID', userId)

      const promises = [dispatch('fetchAccounts')]
      if (userId) {
        promises.push(dispatch('fetchUser'))
      } else {
        commit('SET_USER', {})
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchUser ({ commit, getters }) {
      if (!getters.userId) {
        throw new Error('User id should be set in state')
      }

      return axios
        .get(`/admin/users/${getters.userId}?expand=1`)
        .then(response => commit('SET_USER', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju uporabnika, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchAccounts ({ commit }) {
      return getAllRecords('/accounts?sort=name')
        .then(records => commit('SET_AVAILABLE_ACCOUNTS', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju skupin, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateUserObject ({ commit }, newUser) {
      commit('UPDATE_USER_OBJECT', newUser)
    },
    submitUser ({ dispatch, commit, getters }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewUser = getters.userId == null
      const promise = isCreatingNewUser ? dispatch('createUser') : dispatch('updateUser')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text:'Uporabnik uspešno shranjen.' }, { root: true })
          if (isCreatingNewUser) {
            router.push({ name: 'admin_user_edit_path', params: { id: getters.userId } })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju uporabnika - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createUser ({ commit, state }) {
      const payload = { user: snakeKeys(state.user, { recursive: true, recursiveInArray: true }) }
      return axios
        .post('/admin/users', payload)
        .then(response => commit('SET_USER_ID', response.data.id))
    },
    updateUser ({ state, getters }) {
      if (!getters.userId) {
        throw new Error('Cannot update a user without having the id')
      }

      const accountMembershipsAttributes = state.user.accountIds.map(accountId => {
        return {
          account_id: accountId,
          is_account_admin: state.user.isAccountAdmin,
        }
      })
      const payload = {
        user: {
          ...snakeKeys(state.user, { recursive: true, recursiveInArray: true }),
          account_memberships_attributes: accountMembershipsAttributes,
        },
      }
      return axios.put(`/admin/users/${getters.userId}`, payload)
    },
    deleteUser ({ commit, dispatch }, userId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/admin/users/${userId}`)
        .then(() => router.push({ name: 'admin_users_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju uporabnika, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_USER (state, user) {
      state.user = camelKeys(user, { recursive: true, recursiveInArray: true })
    },
    SET_USER_ID (state, userId) {
      state.userId = userId
    },
    UPDATE_USER_OBJECT (state, newUser) {
      state.user = {
        ...state.user,
        ...newUser,
        accountId: newUser.account && newUser.account.id ? newUser.account.id : null,
      }
    },
    SET_AVAILABLE_ACCOUNTS (state, availableAccounts) {
      state.availableAccounts = availableAccounts
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
