<template>
  <div class="payment-guarantee">
    <div class="payment-guarantee-item" v-for="paymentGuaranteeItem in paymentGuaranteeItems" :key="paymentGuaranteeItem.icon">
      <icon class="payment-guarantee-icon" :name="paymentGuaranteeItem.icon" :width="45" :height="45" />
      <div class="payment-guarantee-text">{{ paymentGuaranteeItem.text }}</div>
    </div>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'

export default {
  components: {
    Icon,
  },
  data () {
    return {
      paymentGuaranteeItems: [
        {
          icon: 'moneyback',
          text: '30-dnevno vračilo kupnine',
        },
        {
          icon: 'award',
          text: '60-dnevna garancija kvalitete',
        },
        {
          icon: 'cloud',
          text: 'Takojšnji prevzem dokumenta',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.payment-guarantee {
  display: flex;
  justify-content: center;
  padding: 24px;

  @include respond-to(not-large) {
    display: block;
  }
}

.payment-guarantee-item {
  text-align: center;
  margin: 8px;

  @include respond-to(small) {
    margin-bottom: 16px;
  }
}

.payment-guarantee-icon {
  margin: auto;
  padding-bottom: 8px;
}

.payment-guarantee-text {
  font-size: 13px;
  font-weight: 500;
}
</style>
