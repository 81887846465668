<template>
  <form novalidate @submit.prevent="submit" v-if="account" class="entity-form">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />
    <account-form-fields ref="accountFormFields" :account="account" @change="updateAccountObject" />

    <br />
    <div class="u-flex u-space-between">
      <v-button type="submit" class="regular-button" :disabled="isLoading">Shrani</v-button>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import AccountFormFields from './AccountFormFields'
import VButton from 'design-system/VButton.vue'
import VProgressBar from 'design-system/VProgressBar.vue'

export default {
  components: {
    AccountFormFields,
    VButton,
    VProgressBar,
  },
  mixins: [BackMixin],
  computed: {
    ...mapState('admin/accounts/forms', ['account']),
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('admin/accounts/forms', ['updateAccountObject']),
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit')
    },
    runAllValidations () {
      this.$refs.accountFormFields.runAllValidations()
    },
    isValid () {
      return this.$refs.accountFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
