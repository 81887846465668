export default {
  bind (el, binding) {
    el.event = (event) => {
      const excludedClassSubstrings = [
        'md-datepicker',
        'md-list-item',
      ]
      const isClassExcluded = [...event.target.classList].some(c => excludedClassSubstrings.some(substring => c.includes(substring)) > 0) > 0
      if (!(el === event.target || el.contains(event.target)) && !isClassExcluded) {
        binding.value(event, el)
      }
    }
    document.addEventListener('click', el.event, true)
  },
  unbind (el) {
    document.removeEventListener('click', el.event, true)
  },
}
