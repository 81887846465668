<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <h1 class="page-title">Dodaj Mapo</h1>
    <envelope-form v-show="!isLoading" @submit="submitEnvelope" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EnvelopeForm from './envelope_forms/EnvelopeForm'

export default {
  props: {
    accountId: { type: [String, Number], required: true },
  },
  components: {
    EnvelopeForm,
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments/envelopeForms', ['initialize', 'reset', 'submitEnvelope']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
