<template>
  <div>
    <div>
      Do veljavnosti dokumenta vas loči le še e-podpis!<br /><br />Vnesite imena in email naslove podpisnikov in
      dobili bodo elektronski zahtevek za podpis dokumenta.
    </div>
    <div>
      <div class="e-signature__signer" v-for="(signer, index) in form.signers" :key="index">
        <v-field class="md-field--dark" :class="geFieldValidationClass(index, 'name')">
          <v-input
            class="md-input--dark"
            v-model="signer.name"
            placeholder="Ime in priimek"
            :key="`name-${index}`"
            ref="nameInputs"
            @keydown.enter="signDocument"
          />
          <span class="md-error" v-if="$v.form.signers.$each[index].name.$invalid">Vpišite ime in priimek</span>
        </v-field>
        <v-field class="md-field--dark" :class="geFieldValidationClass(index, 'email')">
          <v-input
            class="md-input--dark"
            v-model="signer.email"
            placeholder="Email naslov"
            :key="`email-${index}`"
            @keydown.enter="signDocument"
          />
          <span class="md-error" v-if="$v.form.signers.$each[index].email.$invalid">Vpišite veljaven email naslov</span>
        </v-field>
        <div @click="removeSigner(index)">
          <v-icon
            class="e-signature__signer-remove"
            :class="{
              'e-signature__signer-remove--hidden': isRemovingSignersDisabled,
            }"
          >
            close
          </v-icon>
        </div>
      </div>
      <div class="e-signature__content__add-signer-button">
        <v-button size="medium" outlined @click="addSigner">
          + Dodaj podpisnika
        </v-button>
      </div>
      <div class="e-signature__content__add-signer-button">
        <v-button size="medium" :disabled="isSigningDisabled" @click="$emit('sign')">
          Pošlji v E-podpis
        </v-button>
        <v-tooltip v-if="isSigningDisabled" md-direction="top">
          Za pošiljanje v E-podpis je potrebno dodati vsaj enega podpisnika
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VInput from 'design-system/VInput.vue'
import VButton from 'design-system/VButton.vue'
import VTooltip from 'design-system/VTooltip.vue'
import { mapState } from 'vuex'

export default {
  components: { VField, VIcon, VInput, VButton, VTooltip },
  props: {
    signers: {
      type: Array,
      required: true,
    },
  },
  mixins: [ValidationMixin],
  created () {
    if (!this.signers) {
      return
    }
    this.form.signers = this.signers
  },
  data () {
    return {
      form: {
        signers: [{ name: '', email: '' }],
      },
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.$refs.nameInputs[0]) {
        return
      }
      this.$refs.nameInputs[0].$el.focus()
    })
  },
  computed: {
    ...mapState(['isLoading']),
    isRemovingSignersDisabled () {
      return this.form.signers.length < 2
    },
    isSigningDisabled () {
      return this.isLoading || !this.isValid()
    },
  },
  methods: {
    addSigner () {
      this.form.signers.push({ name: '', email: '' })
    },
    removeSigner (index) {
      if (this.isRemovingSignersDisabled) {
        return
      }
      this.form.signers.splice(index, 1)
    },
    isSignerPropertyForIndexEmpty (index, propertyName) {
      return this.form.signers[index][propertyName] === ''
    },
    geFieldValidationClass (index, propertyName) {
      if (this.isSignerPropertyForIndexEmpty(index, propertyName)) {
        return
      }

      const signer = this.$v.form.signers.$each[index]
      if (signer) {
        const fieldValue = signer[propertyName]
        return {
          'md-invalid': fieldValue.$invalid,
        }
      }
    },
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.signers,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  validations: {
    form: {
      signers: {
        required,
        minLength: minLength(1),
        $each: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.e-signature__signer {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 24px;
  }

  @include respond-to(small) {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}

.e-signature__signer-remove {
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }

  &--hidden {
    visibility: hidden;
  }
}

.e-signature__content__add-signer-button {
  width: fit-content;
  margin-top: 24px;
}
</style>
