<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="admin__header">
      <h1 class="page-title">Uredi Mapo</h1>
    </div>
    <envelope-form v-if="envelope" v-show="!isLoading" @submit="submitEnvelope" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EnvelopeForm from './envelope_forms/EnvelopeForm'

export default {
  props: {
    id: { type: [String, Number], required: true },
  },
  components: {
    EnvelopeForm,
  },
  created () {
    this.setEnvelopeId(this.id).then(() => this.initialize())
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('accountStore/finalizedDocuments/envelopeForms', ['envelope']),
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments/envelopeForms', ['initialize', 'setEnvelopeId', 'reset', 'submitEnvelope']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
