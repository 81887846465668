import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'

const state = {
  promotionCodeId: null,
  promotionCode: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    promotionCodeId (state) {
      if (state.promotionCodeId) {
        return state.promotionCodeId
      }
      if (state.promotionCode) {
        return state.promotionCode.id
      }

      return null
    },
  },
  actions: {
    initialize ({ commit, dispatch }, promotionCodeId) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_PROMOTION_CODE_ID', promotionCodeId)

      const promises = []
      if (promotionCodeId) {
        promises.push(dispatch('fetchPromotionCode'))
      } else {
        commit('SET_PROMOTION_CODE', {})
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchPromotionCode ({ commit, getters }) {
      if (!getters.promotionCodeId) {
        throw new Error('PromotionCode id should be set in state')
      }

      return axios
        .get(`/promotion_codes/${getters.promotionCodeId}?expand=1`)
        .then(response => commit('SET_PROMOTION_CODE', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju promocijske kode, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updatePromotionCodeObject ({ commit }, newPromotionCode) {
      commit('UPDATE_PROMOTION_CODE_OBJECT', newPromotionCode)
    },
    submitPromotionCode ({ dispatch, commit, getters }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewPromotionCode = getters.promotionCodeId == null
      const promise = isCreatingNewPromotionCode ? dispatch('createPromotionCode') : dispatch('updatePromotionCode')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Promocijska koda uspešno shranjena.', type: 'success' }, { root: true })
          if (isCreatingNewPromotionCode) {
            router.push({ name: 'admin_promotion_code_edit_path', params: { id: getters.promotionCodeId } })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text:`Napaka pri shranjevanju promocijske kode - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createPromotionCode ({ commit, state }) {
      const payload = { promotion_code: snakeKeys(state.promotionCode, { recursive: true, recursiveInArray: true }) }
      return axios
        .post('/promotion_codes', payload)
        .then(response => commit('SET_PROMOTION_CODE_ID', response.data.id))
    },
    updatePromotionCode ({ state, getters }) {
      if (!getters.promotionCodeId) {
        throw new Error('Cannot update a promotionCode without having the id')
      }

      const payload = { promotion_code: snakeKeys(state.promotionCode, { recursive: true, recursiveInArray: true }) }
      return axios.put(`/promotion_codes/${getters.promotionCodeId}`, payload)
    },
    deletePromotionCode ({ commit, dispatch }, promotionCodeId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/promotion_codes/${promotionCodeId}`)
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju promocijske kode, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_PROMOTION_CODE (state, promotionCode) {
      state.promotionCode = camelKeys(promotionCode, { recursive: true, recursiveInArray: true })
    },
    SET_PROMOTION_CODE_ID (state, promotionCodeId) {
      state.promotionCodeId = promotionCodeId
    },
    UPDATE_PROMOTION_CODE_OBJECT (state, newPromotionCode) {
      state.promotionCode = {
        ...state.promotionCode,
        ...newPromotionCode,
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
