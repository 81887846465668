<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <div v-if="numberOfPages">
      <div class="md-subheading">Število vseh uporabnikov: {{ totalResults }}</div>
      <div class="md-subheading">Število novih uporabnikov danes: {{ usersCountToday }}</div>
      <div class="md-subheading">Število novih uporabnikov ta teden: {{ usersCountThisWeek }}</div>
      <div class="md-subheading">Število novih uporabnikov ta mesec: {{ usersCountThisMonth }}</div>
      <br />
      <entity-table
        :columns="columns"
        :items="users"
        :are-items-clickable="true"
        :is-add-new-present="false"
        :is-loaded="!!numberOfPages"
        :pagination="pagination"
        title="Uporabniki"
        @search="setQuery"
        @page-change="goToPage"
        @click="openEditUser"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import PaginationMixin from '../../shared/PaginationMixin'

export default {
  components: {
    EntityTable,
  },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'id', name: 'ID' },
      { id: 'email', name: 'Email' },
      { id: 'first_name', name: 'Ime' },
      { id: 'last_name', name: 'Priimek' },
      { id: 'company_name', name: 'Podjetje' },
      { id: 'vat_id', name: 'Davčna številka' },
      { id: 'address', name: 'Naslov' },
      { id: 'post_and_post_code', name: 'Pošta' },
      { id: 'created_at', name: 'Datum registracije' },
      { id: 'last_active_at', name: 'Nazadnje aktiven' },
      { id: 'sign_in_count', name: '# vpisov' },
      { id: 'documents_count', name: '# dokumentov' },
      { id: 'purchased_documents_count', name: '# plačanih dokumentov' },
      { id: 'is_subscribed', name: 'Ima članstvo' },
      { id: 'is_admin', name: 'Admin' },
    ]
  },
  created () {
    this.initialize()
    this.storeModule = ['admin', 'users']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('admin/users', ['users', 'totalResults', 'numberOfPages']),
    usersCountToday () {
      const today = this.dateToTimestampWithDaysOnly(new Date())
      const usersCount = this.users.filter(user => user.created_at >= today).length
      return this.getUsersLabel(usersCount)
    },
    usersCountThisWeek () {
      const monday = this.dateToTimestampWithDaysOnly(getMonday(new Date()))
      const usersCount = this.users.filter(user => user.created_at >= monday).length
      return this.getUsersLabel(usersCount)

      function getMonday (d) {
        d = new Date(d)
        const day = d.getDay()
        const diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
        return new Date(d.setDate(diff))
      }
    },
    usersCountThisMonth () {
      const now = new Date()
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
      const usersCount = this.users.filter(user => user.created_at >= startOfMonth).length
      return this.getUsersLabel(usersCount)
    },
  },
  methods: {
    ...mapActions('admin/users', ['initialize', 'reset', 'setQuery', 'goToPage']),
    dateToTimestampWithDaysOnly (date) {
      return date.toISOString().split('T')[0]
    },
    openEditUser (user) {
      this.$router.push({
        name: 'admin_user_edit_path',
        params: {
          id: user.id,
        },
      })
    },
    getUsersLabel (usersCount) {
      return usersCount < 100 ? usersCount.toString() : `${usersCount}+`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';
</style>
