// all the mapping is required due to a bug in the Autocomplete component https://github.com/vuematerial/vue-material/issues/1243
export default {
  methods: {
    toAutocompleteItem (item) {
      return {
        id: item.id,
        name: item.name,
        toLowerCase: () => item.name.toLowerCase(),
        toString: () => item.name,
      }
    },
    sizeProperly () {
      this.$nextTick(() => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 50)
      })
    },
  },
}
