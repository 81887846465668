<template>
  <md-icon class="v-icon" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </md-icon>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
.v-icon.v-icon.v-icon {
  color: currentColor;
}
</style>

<style lang="scss">
.md-field > .md-icon {
  position: absolute;
  margin: 12px 6px;
}
</style>
