<template>
  <form novalidate @submit.prevent="submit" v-if="document" class="entity-form">
    <document-form-fields ref="documentFormFields" :document="document" :accounts="availableAccounts" @change="updateDocumentObject" />

    <div class="md-subheading nested-form-heading" :class="{ 'nested-form-heading--collapsed': !showSegmentNestedForm }" @click="showSegmentNestedForm = !showSegmentNestedForm">Tekstovni segmenti</div>
    <div class="nested-form" v-show="showSegmentNestedForm">
      <v-button size="small" class="regular-button" @click="addTextSegment">DODAJ TEKSTOVNI SEGMENT</v-button>
      <div v-for="(textSegment, index) in documentTextSegments" :key="textSegment.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">tekstovni segment {{ index + 1 }}</div>
          <v-button size="small" class="md-icon-button" @click="removeTextSegment(index)"><v-icon>close</v-icon></v-button>
        </div>
        <text-segment-form-fields ref="textSegmentFormFields" :text-segment="textSegment" :text-contents="availableTextContents" @change="setDocumentTextSegment({ index: index, textSegment: $event })" />
      </div>
      <v-button size="small" class="regular-button" v-if="documentTextSegments.length" @click="addTextSegment">DODAJ TEKSTOVNI SEGMENT</v-button>
      <md-divider />
    </div>

    <div class="md-subheading nested-form-heading" :class="{ 'nested-form-heading--collapsed': !showConditionNestedForm }" @click="showConditionNestedForm = !showConditionNestedForm">Pogoji</div>
    <div class="nested-form" v-show="showConditionNestedForm">
      <v-button size="small" class="regular-button" @click="addCondition">DODAJ POGOJ</v-button>
      <div v-for="(condition, index) in documentConditions" :key="condition.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">pogoj {{ index + 1 }}</div>
          <v-button size="small" class="md-icon-button" @click="removeCondition(index)"><v-icon>close</v-icon></v-button>
        </div>
        <condition-form-fields ref="conditionFormFields" :condition="condition" @change="setDocumentCondition({ index: index, condition: $event})" />
      </div>
      <v-button size="small" class="regular-button" v-if="documentConditions.length" @click="addCondition">DODAJ POGOJ</v-button>
      <md-divider />
    </div>

    <br />
    <div class="u-flex u-space-between">
      <v-button type="submit" class="regular-button" :disabled="isLoading">Shrani</v-button>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import ValidationMixin from '@/components/shared/ValidationMixin'
import NestedFormMixin from '../../NestedFormMixin'
import ConditionFormFields from './ConditionFormFields'
import DocumentFormFields from './DocumentFormFields'
import TextSegmentFormFields from './TextSegmentFormFields'
import VButton from 'design-system/VButton.vue'
import VIcon from 'design-system/VIcon.vue'

export default {
  components: {
    ConditionFormFields,
    DocumentFormFields,
    TextSegmentFormFields,
    VButton,
    VIcon,
  },
  mixins: [BackMixin, NestedFormMixin, ValidationMixin],
  data () {
    return {
      showSegmentNestedForm: true,
      showConditionNestedForm: true,
    }
  },
  computed: {
    ...mapState('admin/documents/forms', ['document', 'availableTextContents', 'availableAccounts']),
    ...mapState(['isLoading']),
    documentTextSegments () {
      if (!this.document || !this.document.textSegments) {
        return []
      }

      return this.document.textSegments.filter(textSegment => textSegment.conditionOutcomeId == null)
    },
    documentConditions () {
      if (!this.document || !this.document.conditions) {
        return []
      }

      return this.document.conditions.filter(condition => condition.conditionOutcomeId == null)
    },
  },
  methods: {
    ...mapActions('admin/documents/forms', [
      'updateDocumentObject',
      'addTextSegment',
      'removeTextSegment',
      'addCondition',
      'removeCondition',
      'setDocumentCondition',
      'setDocumentTextSegment',
    ]),
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit')
    },
    runAllValidations () {
      this.$refs.documentFormFields.runAllValidations()

      if (this.$refs.textSegmentFormFields) {
        this.$refs.textSegmentFormFields.map(textSegmentFormFieldRef => textSegmentFormFieldRef.runAllValidations())
      }
      if (this.$refs.conditionFormFields) {
        this.$refs.conditionFormFields.map(conditionFormFieldRef => conditionFormFieldRef.runAllValidations())
      }

      this.focusFirstError()
    },
    isValid () {
      if (!this.$refs.documentFormFields.isValid()) {
        return false
      }

      let areTextSegmentsValid, areConditionsValid
      if (this.$refs.textSegmentFormFields) {
        areTextSegmentsValid = this.$refs.textSegmentFormFields.reduce((areValid, textSegmentFormFieldRef) => areValid && textSegmentFormFieldRef.isValid(), true)
      } else {
        areTextSegmentsValid = true
      }

      if (this.$refs.conditionFormFields) {
        areConditionsValid = this.$refs.conditionFormFields.reduce((areValid, conditionFormFieldRef) => areValid && conditionFormFieldRef.isValid(), true)
      } else {
        areConditionsValid = true
      }

      return areTextSegmentsValid && areConditionsValid
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
