<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="new-document__header">
      <h1 class="page-title">Nov Dokument</h1>
      <v-button class="regular-button new-document__header-button" @click="saveDocument">Shrani</v-button>
    </div>
    <document-form @submit="submitDocument" ref="documentForm" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DocumentForm from './forms/DocumentForm'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    DocumentForm,
    VButton,
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    ...mapActions('admin/documents/forms', ['initialize', 'reset', 'submitDocument']),
    saveDocument () {
      this.$refs.documentForm.submit()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';

.new-document__header {
  display: flex;
  justify-content: space-between;
}

.new-document__header-button {
  position: relative;
  top: -12px;
}
</style>
