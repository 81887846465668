import { isArray } from 'lodash-es'

export default {
  methods: {
    getValidationClass (fieldName) {
      let field = null
      let root = this.$v.form
      if (isArray(fieldName)) {
        fieldName.forEach(fieldNamePart => {
          root = accessObjectProperty(root, fieldNamePart)
        })
        field = root
      } else {
        field = accessObjectProperty(root, fieldName)
      }

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        }
      }
    },
    focusFirstError (component = this) {
      this.$nextTick(() => {
        if (component.$v && component.$v.$invalid === true) {
          component.$el.scrollIntoView(true)
          return true
        }

        let focused = false
        component.$children.some((childComponent) => {
          focused = this.focusFirstError(childComponent)
          return focused
        })

        return focused
      })
    },
  },
}

function accessObjectProperty (root, fieldName) {
  return root[fieldName]
}
