<template>
  <div>
    <v-field>
      <label for="accountIds">Pripada računom</label>
      <v-select v-model="form.accountIds" name="accountIds" id="accountIds" md-dense multiple>
        <v-option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</v-option>
      </v-select>
    </v-field>

    <v-checkbox v-model="form.isAccountAdmin">Je administrator računov</v-checkbox>
  </div>
</template>

<script>
import ValidationMixin from '@/components/shared/ValidationMixin'
import AutocompleteFixMixin from '@/components/shared/AutocompleteFixMixin'
import VCheckbox from 'design-system/VCheckbox'
import VField from 'design-system/VField.vue'
import VSelect from 'design-system/VSelect.vue'
import VOption from 'design-system/VOption.vue'

export default {
  components: {
    VCheckbox,
    VField,
    VSelect,
    VOption,
  },
  data () {
    return {
      form: {
        accountIds: [],
        isAccountAdmin: false,
      },
    }
  },
  props: {
    user: { type: Object, required: true },
    accounts: { type: Array, required: true },
  },
  mixins: [ValidationMixin, AutocompleteFixMixin],
  created () {
    if (!this.user) {
      return
    }
    if (this.form.accountIds.length === 0) {
      this.form.accountIds = this.user.accounts.map(a => a.id)
    }
    // for now we'll only allow setting isAccountAdmin
    // for all or for none of the accounts the user is member of
    this.form.isAccountAdmin = this.user.accountMemberships.some(a => a.isAccountAdmin)
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.user,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {},
}
</script>

<style lang="scss" scoped>
</style>
