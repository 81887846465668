<template>
  <div class="sidebar" :class="{ 'sidebar--visible': isFloatingSidebarVisible, 'sidebar--collapsed': isSidebarCollapsed }" ref="sidebar">
    <div class="sidebar__logo-container">
      <img
        class="sidebar__rubikon-logo"
        alt="Pigenta Logo"
        :src="require('app/assets/images/pigenta_logo_full.svg')"
      />
      <div class="sidebar-button" v-if="isMobile" @click="toggleIsFloatingSidebarVisible">
        <v-icon class="sidebar-icon">menu</v-icon>
      </div>
      <div class="sidebar-button" v-else @click="toggleSidebarCollapse">
        <icon name="menu" />
      </div>
    </div>

    <div class="sidebar__top-bar">
      <div class="sidebar__content-container" v-for="link in filteredLinks" :key="link.text">
        <div class="sidebar__content-heading">{{ link.title }}</div>
        <div class="sidebar__links">
          <router-link
            class="navigation-link"
            :class="{ 'navigation-link--active': $route.name === linkItem.to.name, 'navigation-link--disabled': linkItem.disabled }"
            :is="linkItem.disabled ? 'span' : 'router-link'"
            :to="linkItem.to"
            v-for="linkItem in link.items"
            :key="linkItem.text"
            v-show="linkItem.condition === undefined || linkItem.condition"
          >
            <icon :name="linkItem.icon" />
            <span class="navigation-text">{{ linkItem.text }}</span>
            <v-tooltip v-show="showSidebarTooltip(linkItem)" :md-direction="tooltipsDirection">{{ linkItem.text }}</v-tooltip>
            <v-tooltip v-if="linkItem.disabled" :md-direction="tooltipsDirection">Funkcija je na voljo v naročniškem paketu.</v-tooltip>
          </router-link>
        </div>
      </div>
    </div>

    <div class="sidebar__bottom-bar">
      <div class="sidebar__content-container">
        <div class="sidebar__links">
          <div class="navigation-link" @click="openMailTo">
            <icon name="question-mark-circle" /><span class="navigation-text">Pomoč</span>
          </div>
          <div class="navigation-link" @click="logout">
            <icon name="log-out" /><span class="navigation-text">Odjava</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import router from '@/router'
import VIcon from 'design-system/VIcon.vue'
import VTooltip from 'design-system/VTooltip.vue'
import MailToMixin from '@/components/shared/MailToMixin'
import Icon from 'design-system/icons/Icon.vue'

export default {
  components: {
    VIcon,
    VTooltip,
    Icon,
  },
  mixins: [MailToMixin],
  data () {
    return {
      isSidebarCollapsed: false,
    }
  },
  mounted () {
    if (this.isMobile && !this.isFloatingSidebarVisible) {
      this.$refs.sidebar.style.visibility = 'hidden'
    }

    if (this.$refs.sidebar) {
      this.$refs.sidebar.addEventListener('transitionend', this.onSidebarAnimationEnd)
    }

    window.addEventListener('resize', this.setSidebarHeightOnMobile())
    this.setSidebarHeightOnMobile()
  },
  beforeDestroy () {
    if (this.$refs.sidebar) {
      this.$refs.sidebar.removeEventListener('transitionend', this.onSidebarAnimationEnd)
    }
    window.removeEventListener('resize', this.setSidebarHeightOnMobile())
  },
  watch: {
    isFloatingSidebarVisible: {
      handler (newValue) {
        if (newValue === true) {
          this.showSidebar()
        }
      },
    },
  },
  computed: {
    ...mapState(['user', 'account', 'unreadNotificationsCount']),
    ...mapGetters(['accountId', 'accountSlug', 'isAccountAdmin', 'isFloatingSidebarVisible']),
    accounts () {
      return this.user.accounts
    },
    filteredLinks () {
      return this.links.filter(link => link.condition === undefined || link.condition)
    },
    links () {
      return [
        {
          title: 'Admin',
          condition: this.user.isAdmin,
          items: [
            {
              text: 'Statistika',
              icon: 'analytics',
              to: { name: 'admin_stats_path' },
            },
            {
              text: 'Uporabniki',
              icon: 'people',
              to: { name: 'admin_users_path' },
            },
            {
              text: 'Podjetja',
              icon: 'organization',
              to: { name: 'admin_accounts_path' },
            },
            {
              text: 'Urejevalnik',
              icon: 'file-text',
              to: { name: 'admin_documents_path' },
            },
            {
              text: 'Sestavki',
              icon: 'file-text',
              to: { name: 'admin_text_contents_path' },
            },
            {
              text: 'Blog',
              icon: 'file-text',
              to: { name: 'admin_blog_posts_path' },
            },
            {
              text: 'Promocijske kode',
              icon: 'percent',
              to: { name: 'admin_promotion_codes_path' },
            },
          ],
        },
        {
          title: 'Meni',
          items: [
            {
              text: 'Vzorci',
              icon: 'layers',
              to: { name: 'smart_contracts_path' },
            },
            {
              text: 'Zadnje ustvarjeni',
              icon: 'file-text',
              to: { name: 'user_profile_path', params: { id: this.user?.id } },
            },
            {
              text: 'Hramba',
              icon: 'archive',
              to: { name: 'account_finalized_documents_path', params: { accountId: this.accountSlug } },
            },
            {
              text: 'Oznake',
              icon: 'analytics',
              to: { name: 'account_attribute_keys_path', params: { accountId: this.accountSlug } },
              condition: this.isAccountAdmin,
            },
          ],
        },
        {
          title: 'Profil',
          items: [
            {
              text: 'Nastavitve',
              icon: 'settings',
              to: { name: 'user_profile_edit_path', params: { id: this.user?.id } },
            },
            {
              text: 'Paket',
              icon: 'credit-card',
              to: { name: 'packages_path' },
            },
            {
              text: 'Računi',
              icon: 'people',
              to: { name: 'account_users_path', params: { accountId: this.accountSlug } },
              condition: this.isAccountAdmin,
            },
          ],
        },
        {
          title: 'Nasveti',
          items: [
            {
              text: 'Blog',
              icon: 'file-text',
              to: { name: 'blog_path' },
            },
          ],
        },
      ]
    },
    tooltipsDirection () {
      return this.isMobile ? 'bottom' : 'right'
    },
    isAccountSelected () {
      return !!this.account
    },
  },
  methods: {
    ...mapActions(['setAccount', 'toggleIsFloatingSidebarVisible']),
    ...mapActions('auth', ['logout']),
    selectAccount (accountId) {
      if (this.accountId === accountId) {
        return
      }

      const account = this.user.accounts.find(a => a.id === accountId)
      this.setAccount(account)
      router.push({ name: 'account_root_path', params: { accountId: account.slug } })
    },
    goToAdminPage () {
      if (!this.user.isAdmin) {
        return
      }

      this.$router.push({ name: 'admin_path' })
    },
    goToProfilePage () {
      this.$router.push({
        name: 'user_profile_path',
        params: { id: this.user.id },
      })
    },
    goToEditProfilePage () {
      this.$router.push({
        name: 'user_profile_edit_path',
        params: { id: this.user.id },
      })
    },
    showSidebar () {
      this.$refs.sidebar.style.visibility = 'visible'
      this.preventScroll()
    },
    showSidebarTooltip (linkItem) {
      return this.isSidebarCollapsed && !this.isMobile && !linkItem.disabled
    },
    onSidebarAnimationEnd () {
      if (!this.isFloatingSidebarVisible) {
        this.$refs.sidebar.style.visibility = 'hidden'
        this.enableScroll()
      }
    },
    preventScroll () {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'
    },
    enableScroll () {
      document.body.style.height = 'auto'
      document.body.style.overflow = 'visible'
    },
    toggleSidebarCollapse () {
      this.isSidebarCollapsed = !this.isSidebarCollapsed
    },
    setSidebarHeightOnMobile () {
      if (this.isMobile && this.$refs.sidebarContainer) {
        this.$refs.sidebarContainer.style.height = `${window.innerHeight}px`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";
@import "../common";

$bottom_bar_height: 140px;
$bottom_bar_height_mobile: 140px;

.sidebar {
  position: sticky;
  z-index: $z_heaven;
  top: 0;
  width: $sidebar_width;
  height: 100vh;
  border-right: 1px solid $border_color;
  background-color: $white;
  color: $modrn-blue-dark-60;
  transition:  0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: left, width;

  @include respond-to(small) {
    position: fixed;
    left: -100vw;
    width: 100vw;
    height: 100vh;

    &--visible {
      left: 0;
    }
  }
}

.sidebar__fixed-container {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.sidebar__content-container {
  padding: 16px 16px 16px 24px;

  @include respond-to(small) {
    padding: 8px;
  }
}

.sidebar__logo-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px 0;
  margin-bottom: 32px;

  @include respond-to(not-large) {
    padding: 12px 18px 0;
  }

  @include respond-to(small) {
    height: $mobile_header_height;
    margin-bottom: 8px;
  }

  & > .sidebar-button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.sidebar__rubikon-logo {
  width: 100px;

  @include respond-to(small) {
    width: 136px;
  }
}

.sidebar__user-data.sidebar__user-data {
  display: flex;
  width: 100%;
  padding-left: 8px;
  margin-bottom: 4px;
  font-weight: 500;
  user-select: none;

  &:hover {
    color: $secondary_color;
    text-decoration: none;
  }
}

.sidebar__account-selector {
  cursor: pointer;
  line-height: 32px;

  &:hover {
    color: $secondary_color;
  }

  @include respond-to(small) {
    line-height: 28px;
  }
}

.sidebar__user-icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 8px;
  background-color: $secondary_color;
  color: white;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
}

.sidebar__user-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;

  &:hover,
  &--active {
    color: $secondary_color;
  }

  @include respond-to(small) {
    font-size: 16px;
    line-height: 28px;
  }
}

.sidebar__content-heading {
  padding: 0 0 8px 16px;
  font-size: 12px;
  text-transform: uppercase;

  @include respond-to(small) {
    font-size: 14px;
    line-height: 18px;
  }
}

.sidebar__links {
  user-select: none;
}

.navigation-link.navigation-link {
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  color: $modrn-blue-dark-60;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;

  @include respond-to(small) {
    padding: 16px 0 16px 16px;
    font-size: 16px;
    line-height: 22px;
  }

  & > .icon-wrapper {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    color: $modrn-blue-dark-50;
    transform: translateZ(0);
  }

  &:hover {
    padding: 16px;
    border-radius: 5px;
    background: #e7ecf0;
    color: $modrn-blue-dark;
    font-weight: 600;
    text-decoration: none;

    & > .icon-wrapper {
      color: $primary-blue;
    }

    .sidebar__navigation-icon {
      color: $secondary_color;
    }
  }

  &--active {
    color: $modrn-blue-dark;
    font-weight: 600;

    & > .icon-wrapper {
      color: $primary-blue;
    }
  }

  &--disabled {
    color: $gray-text;
    cursor: not-allowed;
  }
}

.sidebar__top-bar {
  max-height: calc(100% - #{$bottom_bar_height} - #{$logged_in_header_height} - 16px);
  overflow-y: auto;

  @include respond-to(small) {
    max-height: calc(100% - #{$bottom_bar_height_mobile} - #{$logged_in_header_height} - 100px);
  }
}

.sidebar__bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $bottom_bar_height;

  @include respond-to(small) {
    bottom: calc(#{$bottom_bar_height_mobile} / 2);
    height: $bottom_bar_height_mobile;
    border-top: 1px solid $border_color;
  }
}

.sidebar__navigation-icon.sidebar__navigation-icon {
  margin: 4px 8px 0 0;
  color: white;

  @include respond-to(small) {
    margin-top: 2px;
  }
}

.sidebar__unread-notifications.sidebar__unread-notifications {
  position: relative;
  top: 4px;
  right: -22px;
}

.sidebar--collapsed {
  width: 72px;
  transition: width 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  @include respond-to(small) {
    width: 0;
  }

  & .sidebar__logo-container {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
  }

  & .sidebar__rubikon-logo {
    display: none;
  }

  & .sidebar__content-heading {
    padding: 0 0 8px;
    text-align: center;
  }

  & .sidebar__content-container {
    padding: 16px 0;
  }

  & .navigation-link.navigation-link {
    justify-content: center;
    padding: 8px 0;
    margin: 13px;

    & > .icon-wrapper {
      margin-right: 0;
    }

    & .navigation-text {
      display: none;
    }
  }
}
</style>
