<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <entity-table
      :columns="columns"
      :items="attributeKeys"
      :are-items-clickable="false"
      :is-loaded="!!numberOfPages"
      title="Oznake dokumentov"
      search-text="Išči po oznakah"
      add-text="Dodaj oznako"
      :empty-text="emptyText"
      :pagination="pagination"
      @page-change="goToPage"
      @search="setQuery"
      @add="openCreateAttributeKey"
    >
      <template #actions="actionsProps">
        <md-table-cell>
          <icon name="edit" @click="openEditAttributeKey(actionsProps.item)" />
          <v-tooltip>Uredi</v-tooltip>
        </md-table-cell>
      </template>
    </entity-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import Icon from 'design-system/icons/Icon.vue'
import VTooltip from 'design-system/VTooltip.vue'
import PaginationMixin from '../../shared/PaginationMixin'

export default {
  components: {
    EntityTable,
    VTooltip,
    Icon,
  },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'label', name: 'Ime' },
      { id: 'customAttributeKeyType', name: 'Tip' },
      { id: 'customAttributeListValues', name: 'Seznam vrednosti' },
      { id: 'createdAt', name: 'Datum kreacije' },
    ]
  },
  created () {
    this.initialize()
    this.storeModule = ['accountStore', 'attributeKeys']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('accountStore/attributeKeys', ['numberOfPages', 'query']),
    ...mapGetters(['accountSlug']),
    ...mapGetters('accountStore/attributeKeys', ['attributeKeys']),
    emptyText () {
      return this.query ? 'Nobena oznaka ne ustreza iskanju.' : 'Zaenkrat še nimate oznak.'
    },
  },
  methods: {
    ...mapActions('accountStore/attributeKeys', ['initialize', 'reset', 'setQuery', 'goToPage']),
    openCreateAttributeKey () {
      this.$router.push({
        name: 'account_attribute_key_create_path',
        params: {
          accountId: this.accountSlug,
        },
      })
    },
    openEditAttributeKey (attributeKey) {
      this.$router.push({
        name: 'account_attribute_key_edit_path',
        params: {
          id: attributeKey.id,
          accountId: this.accountSlug,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/clickable_table_actions';
</style>
