<template>
  <form novalidate @submit.prevent="submit" class="entity-form">
    <text-content-form-fields ref="textContentFormFields" :text-content="textContent" />
    <br />
    <div class="u-flex u-space-between">
      <v-button class="regular-button" type="submit" :disabled="isLoading">Shrani</v-button>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import ValidationMixin from '@/components/shared/ValidationMixin'
import TextContentFormFields from './TextContentFormFields'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    TextContentFormFields,
    VButton,
  },
  mixins: [BackMixin, ValidationMixin],
  data () {
    return {
      form: {
        name: null,
        text: null,
        keywords: null,
      },
    }
  },
  props: {
    textContent: { type: Object, required: false },
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit', this.$refs.textContentFormFields.textContentData)
    },
    runAllValidations () {
      this.$refs.textContentFormFields.runAllValidations()
      this.focusFirstError()
    },
    isValid () {
      return this.$refs.textContentFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
