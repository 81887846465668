<template>
  <div class="logged-in-header">
    <div class="logged-in-header__left">
      <div v-if="isMobile" class="logged-in-header__toggle-sidebar" @click="$emit('toggle-sidebar')">
        <icon name="menu" />
      </div>
      <div class="logged-in-header__menu">
        <account-selector
          v-if="accounts.length > 0"
          :account="account"
          :available-accounts="accounts"
          @select="selectAccount"
        />
      </div>
    </div>
    <div class="logged-in-header__right">
      <notifications />
      <div class="logged-in-header__avatar" @click="goToUserSettings">
        {{ user.initials }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Icon from 'design-system/icons/Icon.vue'
import AccountSelector from './_AccountSelector.vue'
import Notifications from './_Notifications.vue'

export default {
  computed: {
    ...mapState(['user', 'account']),
    ...mapGetters(['accounts']),
  },
  components: { Icon, AccountSelector, Notifications },
  methods: {
    ...mapActions(['setAccount']),
    selectAccount (accountId) {
      if (this.account?.id === accountId) {
        return
      }

      if (accountId) {
        const account = this.user.accounts.find((a) => a.id === accountId)
        this.setAccount(account)
      } else {
        this.setAccount(null)
      }
    },
    goToUserSettings () {
      this.$router.push({ name: 'user_profile_edit_path', params: { id: this.user.id } }).catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/variables';

.logged-in-header {
  position: sticky;
  z-index: $z_overlay;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px 10px 48px;
  background: $white;

  @include respond-to(small) {
    padding: 10px 16px 10px 48px;
  }
}

.logged-in-header__left,
.logged-in-header__right {
  display: flex;
}

.logged-in-header__left {
  padding-right: 16px;
}

.logged-in-header__right {
  align-items: center;

  > * + * {
    margin-left: 24px;
  }
}

.logged-in-header__toggle-sidebar {
  position: absolute;
  top: 20px;
  left: 12px;
  cursor: pointer;
}

.logged-in-header__avatar {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: $modrn-blue;
  color: $white;
  cursor: pointer;
  font-size: 18px;
}
</style>
