<template>
  <md-button v-bind="$attrs" v-on="$listeners" :class="classes.join(' ')">
    <slot></slot>
  </md-button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'large',
      validator: value => ['small', 'medium', 'large'].includes(value),
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color : {
      type: String,
      default: 'white',
      validator : value => ['primary-blue', 'modrn-blue-dark-40', 'modrn-green-dark', 'red', 'azureish-white', 'white'].includes(value),
    },
    backgroundColor : {
      type: String,
      default: 'primary-blue',
      validator : value => ['primary-blue', 'modrn-blue-dark-40', 'modrn-green-dark', 'red', 'azureish-white', 'white', 'transparent'].includes(value),
    },
  },
  computed: {
    classes () {
      const classes = []
      switch (this.size) {
      case 'small':
        classes.push('md-button-small')
        break
      case 'medium':
        classes.push('md-button-medium')
        break
      case 'large':
        classes.push('md-button-large')
        break
      }
      if (this.outlined) {
        classes.push('md-button-outlined')
      }
      switch (this.color) {
      case 'primary-blue':
        classes.push('md-button-primary-blue')
        break
      case 'modrn-blue-dark-40':
        classes.push('md-button-modrn-blue-dark-40')
        break
      case 'modrn-green-dark':
        classes.push('md-button-modrn-green-dark')
        break
      case 'red':
        classes.push('md-button-red')
        break
      case 'azureish-white':
        classes.push('md-button-azureish-white')
        break
      case 'white':
        classes.push('md-button-white')
        break
      }
      switch (this.backgroundColor) {
      case 'modrn-blue-dark-40':
        classes.push('md-button-bg-modrn-blue-dark-40')
        break
      case 'modrn-green-dark':
        classes.push('md-button-bg-modrn-green-dark')
        break
      case 'red':
        classes.push('md-button-bg-red')
        break
      case 'azureish-white':
        classes.push('md-button-bg-azureish-white')
        break
      case 'white':
        classes.push('md-button-bg-white')
        break
      case 'transparent':
        classes.push('md-button-bg-transparent')
        break
      }
      return classes
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/colors";

.md-button:not(.md-icon-button) {
  height: auto;
  background-color: $primary-blue;
  color: $white;
  text-transform: none;

  &.md-button-small {
    height: 32px;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 13px;
  }

  &.md-button-medium {
    height: 40px;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 14px;
  }

  &.md-button-large {
    height: 48px;
    padding: 16px 24px;
    border-radius: 8px;
    font-size: 15px;
  }

  &.md-button-bg-modrn-blue-dark-40 {
    background-color: $modrn-blue-dark-40;
  }

  &.md-button-bg-modrn-green-dark {
    background-color: $modrn-green-dark;
  }

  &.md-button-bg-red {
    background-color: $red;
    color:$white;
  }

  &.md-button-bg-azureish-white {
    background-color: $azureish-white;
    color: $modrn-blue-dark;
  }

  &.md-button-bg-white {
    background-color: white;
    color: $modrn-blue-dark;
  }

  &.md-button-modrn-blue-dark-40 {
    color: $modrn-blue-dark-40;
  }

  &.md-button-modrn-green-dark {
    color: $modrn-green-dark;
  }

  &.md-button-red {
    color: $red;
  }

  &.md-button-azureish-white {
    color: $azureish-white;
  }

  &.md-button-primary-blue {
    color: $primary-blue;
  }

  &:hover {
    background-color: $primary-blue-hover;

    &.md-button-bg-modrn-blue-dark-40 {
      background-color: $modrn-blue-dark-50;
    }

    &.md-button-bg-modrn-green-dark {
      background-color: $modrn-green-dark;
    }

    &.md-button-bg-red {
      background-color: $red;
    }

    &.md-button-bg-azureish-white {
      background-color: $gainsboroish;
    }

    &.md-button-bg-white {
      background-color: $gainsboroish;
    }

    &.md-button-bg-transparent {
      background-color: transparent;
    }
  }

  &:disabled {
    background-color: $input-medium;
    color: $white;
  }

  &.md-button-outlined {
    border: 1px solid $primary-blue;
    background-color: $white;
    color: $primary-blue;

    &.md-button-bg-modrn-blue-dark-40 {
      border: 1px solid $modrn-blue-dark-40;
      color: $modrn-blue-dark-40;
    }

    &.md-button-bg-modrn-green-dark {
      border: 1px solid $modrn-green-dark;
      color: $modrn-green-dark;
    }

    &.md-button-bg-red {
      border: 1px solid $red;
      color: $red;
    }

    &.md-button-bg-azureish-white {
      border: 1px solid $azureish-white;
      color: $azureish-white;
    }

    &.md-button-bg-white {
      border: 1px solid $white;
      color: $white;
    }

    &.md-button-bg-transparent {
      border: 1px solid $white;
      color: $white;
      background-color: transparent;
    }

    &:hover {
      background-color: $primary-blue;
      color: white;

      &.md-button-bg-modrn-blue-dark-40 {
        background-color: $modrn-blue-dark-40;
      }

      &.md-button-bg-modrn-green-dark {
        background-color: $modrn-green-dark;
      }

      &.md-button-bg-red {
        background-color: $red;
      }

      &.md-button-bg-azureish-white {
        background-color: $azureish-white;
      }

      &.md-button-bg-white {
        background-color: $white;
      }
    }

    &:disabled {
      border: 1px solid $input-medium;
      color: $input-medium;
    }
  }
}

.md-button {
  margin: 0;
}

.md-button .md-ripple {
  overflow: visible;
  mask-image: none;
}

.md-button /deep/ .md-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
</style>

<style lang="scss">
.md-field > .md-button {
  position: absolute;
  margin: 8px 6px;
}
</style>
