import axios from 'axios'
import { camelKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'

const state = {
  blogPostId: null,
  blogPost: null,
  lastBlogPosts: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  actions: {
    initialize ({ commit, dispatch }, blogPostId) {
      dispatch('reset')

      if (!blogPostId) {
        throw new Error('Blog post id is required.')
      }

      commit('SET_BLOG_POST_ID', blogPostId)

      return Promise.all([
        dispatch('fetchBlogPost'),
        dispatch('fetchLastBlogPosts'),
      ])
    },
    fetchBlogPost ({ commit, state }) {
      commit('SET_IS_LOADING', true, { root: true })

      return axios.get(`/blog_posts/${state.blogPostId}`)
        .then(response => commit('SET_BLOG_POST', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju bloga, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchLastBlogPosts ({ commit }) {
      return axios.get('/blog_posts?limit=4')
        .then(response => commit('SET_LAST_BLOG_POSTS', response.data))
        .catch(error => console.error(error))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_BLOG_POST_ID (state, blogPostId) {
      state.blogPostId = blogPostId
    },
    SET_BLOG_POST (state, blogPost) {
      state.blogPost = camelKeys(blogPost, { recursive: true, recursiveInArray: true })
    },
    SET_LAST_BLOG_POSTS (state, lastBlogPosts) {
      // filter out the current blog post if present
      lastBlogPosts = lastBlogPosts.filter(blogPost => blogPost.id !== state.blogPostId && blogPost.slug !== state.blogPostId)
      lastBlogPosts = lastBlogPosts.slice(0, 3)
      state.lastBlogPosts = lastBlogPosts.map(blogPost => camelKeys(blogPost, { recursive: true, recursiveInArray: true }))
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
