<template>
  <div>
    <v-field :class="getValidationClass('title')">
      <label>Naslov</label>
      <v-input v-model="form.title"></v-input>
      <span class="md-error" v-if="!$v.form.title.required">Vpišite ime</span>
    </v-field>

    <v-checkbox v-model="form.isVisible">Viden uporabnikom</v-checkbox>

    <div class="text-with-preview">
      <v-field>
        <label>Opis</label>
        <v-textarea v-model="form.description"></v-textarea>
      </v-field>

      <div class="text-markdown-preview">
        <div class="text-markdown-preview-title">Predogled</div>
        <div class="text-markdown-preview-content" v-html="getMarkdown(form.description)"></div>
      </div>
    </div>

    <v-field :class="getValidationClass('keywords')">
      <label>Ključne besede</label>
      <v-input v-model="form.keywords"></v-input>
      <span class="md-error" v-if="!$v.form.keywords.required">Vpišite ključne besede</span>
    </v-field>

    <v-field>
      <label>SEO ključne besede</label>
      <v-input v-model="form.seoKeywords"></v-input>
    </v-field>

    <v-field>
      <label>Slika</label>
      <r-file-upload
        v-model="blogPostFile"
        :disabled="numFilesUploading > 0"
        :accepts="acceptedMimeTypes"
        file-upload-prefix="blog_post_images"
        @uploading="onUploading"
        @uploaded="onUploaded"
      />
    </v-field>

    <div v-for="(blogPostContent, index) in form.blogPostContents" :key="blogPostContent.id">
      <div>Vsebina {{ index + 1 }}</div>
      <v-field>
        <label>Naslov</label>
        <v-input v-model="form.blogPostContents[index].title"></v-input>
      </v-field>

      <v-field>
        <label>ID vzorca</label>
        <v-input v-model="form.blogPostContents[index].documentId"></v-input>
      </v-field>

      <v-field>
        <label>Slika</label>
        <r-file-upload
          :model="blogPostContentFiles[index]"
          :disabled="numFilesUploading > 0"
          :accepts="acceptedMimeTypes"
          file-upload-prefix="blog_post_images"
          @change="setBlogPostContentFile($event, index)"
          @uploading="onUploading"
          @uploaded="onUploaded"
        />
      </v-field>

      <div class="text-with-preview">
        <v-field class="text-field">
          <label>Vsebina</label>
          <v-textarea v-model="form.blogPostContents[index].content"></v-textarea>
        </v-field>

        <div class="text-markdown-preview">
          <div class="text-markdown-preview-title">Predogled</div>
          <div class="text-markdown-preview-content" v-html="getMarkdown(form.blogPostContents[index].content)"></div>
        </div>
        <v-button v-if="form.blogPostContents.length > 1" class="v-icon-button" @click="removeBlogPostContent(index)"><v-icon>close</v-icon></v-button>
      </div>
    </div>
    <v-button class="regular-button" size="small" @click="addBlogPostContent">Dodaj vsebino bloga</v-button>
  </div>
</template>

<script>
import snarkdown from 'snarkdown'
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import NestedFormMixin from '../../NestedFormMixin'
import VButton from 'design-system/VButton.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VInput from 'design-system/VInput.vue'
import VTextarea from 'design-system/VTextarea.vue'
import RFileUpload from 'design-system/components/RFileUpload.vue'

// TODO: extract functions to utils/mixins
function createBlogPostContentFrontendIds (blogPostContents) {
  const blogPostContentsWithFrontendIds = []
  blogPostContents.forEach(blogPostContent => {
    const blogPostContentWithFrontendId = { ...blogPostContent }
    if (blogPostContent.frontendId) {
      blogPostContentWithFrontendId.frontendId = getFrontendId()
    }
    blogPostContentsWithFrontendIds.push(blogPostContentWithFrontendId)
  })

  return blogPostContentsWithFrontendIds
}

function getFrontendId () {
  return Math.random().toString(36)
}

export default {
  components: {
    VButton,
    VCheckbox,
    VField,
    VIcon,
    VInput,
    VTextarea,
    RFileUpload,
  },
  props: {
    blogPost: { type: Object, required: false },
  },
  data () {
    return {
      acceptedMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
      showNestedForm: true,
      numFilesUploading: 0,
      form: {
        title: null,
        description: null,
        keywords: null,
        seoKeywords: null,
        isVisible: false,
        imageFileName: null,
        imageBlobHash: null,
        blogPostContents: [
          {
            frontendId: getFrontendId(),
            title: null,
            content: null,
            documentId: null,
          },
        ],
      },
    }
  },
  mixins: [ValidationMixin, NestedFormMixin],
  created () {
    this.updateForm()
  },
  watch: {
    blogPost: {
      handler () {
        this.updateForm()
      },
      deep: true,
    },
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.blogPost,
          ...newFormValue,
        })
      },
      deep: true,
    },
    numFilesUploading: {
      handler (newNumFilesUploading) {
        if (newNumFilesUploading > 0) {
          this.$emit('uploading')
        } else {
          this.$emit('uploaded')
        }
      },
    },
  },
  computed: {
    blogPostFile: {
      get () {
        if (!this.form.imageBlobHash) {
          return {}
        }
        return {
          name: this.form.imageFileName ?? this.form.imageBlobHash,
        }
      },
      set (fileData) {
        this.form.imageFilename = fileData.name
        this.form.imageBlobHash = fileData.blobHash
      },
    },
    blogPostContentFiles () {
      return this.form.blogPostContents.map(blogPostContent => {
        if (!blogPostContent.imageBlobHash) {
          return {}
        }
        return {
          name: blogPostContent.imageFileName ?? blogPostContent.imageBlobHash,
        }
      })
    },
    blogPostData () {
      const blogPost = {
        title: this.form.title,
        description: this.form.description,
        keywords: this.form.keywords,
        seoKeywords: this.form.seoKeywords,
        isVisible: this.form.isVisible,
        imageBlobHash: this.form.imageBlobHash ?? null,
        blogPostContentsAttributes: this.form.blogPostContents,
      }

      if (this.blogPost) {
        return {
          id: this.blogPost.id,
          ...blogPost,
        }
      }

      return blogPost
    },
  },
  methods: {
    updateForm () {
      if (!this.blogPost) {
        return
      }
      this.form.title = this.blogPost.title
      this.form.description = this.blogPost.description
      this.form.keywords = this.blogPost.keywords
      this.form.seoKeywords = this.blogPost.seoKeywords
      this.form.isVisible = this.blogPost.isVisible
      this.form.imageBlobHash = this.blogPost.imageBlobHash ?? null
      this.form.blogPostContents = createBlogPostContentFrontendIds(this.blogPost.blogPostContents)
    },
    setBlogPostContentFile (fileData, index) {
      this.form.blogPostContents[index].imageFileName = fileData.name
      this.form.blogPostContents[index].imageBlobHash = fileData.blobHash ?? null
    },
    addBlogPostContent () {
      this.form.blogPostContents.push({
        frontendId: getFrontendId(),
        title: null,
        content: null,
        documentId: null,
      })
    },
    removeBlogPostContent (index) {
      this.form.blogPostContents.splice(index, 1)
    },
    getMarkdown (content) {
      if (!content) {
        return ''
      }
      return snarkdown(content)
    },
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
    onUploading () {
      this.numFilesUploading++
    },
    onUploaded () {
      this.numFilesUploading--
    },
  },
  validations: {
    form: {
      title: { required },
      keywords: { required },
    },
    numFilesUploading: {
      numFilesUploading: value => value === 0,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '../../markdown_preview';

.text-field {
  width: 50%;
  margin-right: 8px;
  box-sizing: border-box;
}
</style>

<style lang="scss">
.text-field.text-field {
  textarea {
    height: 100%;
    max-height: unset;
  }
}
</style>
