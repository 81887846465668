<template>
  <div>
    <composition-input
      :id="condition.id"
      :title="condition.title"
      :text="condition.text"
      :info="condition.info"
      :title-info="condition.titleInfo"
      :default-value="condition.defaultValue"
      :type="condition.conditionType || 'option'"
      :options="options"
      :is-checkbox="condition.isCheckbox"
      :is-nested="condition.isNested"
      @input="onInput"
    />
  </div>
</template>

<script>
import Input from './Input'

export default {
  components: {
    CompositionInput: Input,
  },
  props: {
    condition: { type: Object, required: true },
  },
  computed: {
    options () {
      if (!this.condition.conditionOutcomes) {
        return []
      }

      return this.condition.conditionOutcomes.map(outcome => {
        return {
          ...outcome,
          label: outcome.value,
          value: outcome.id,
        }
      })
    },
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)

      const conditionOutcomeId = this.condition.isCheckbox ? this.condition.id : event
      this.$emit('change', conditionOutcomeId)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
