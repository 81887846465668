import { render, staticRenderFns } from "./_AuthModal.vue?vue&type=template&id=812b83b4&scoped=true&"
import script from "./_AuthModal.vue?vue&type=script&lang=js&"
export * from "./_AuthModal.vue?vue&type=script&lang=js&"
import style0 from "./_AuthModal.vue?vue&type=style&index=0&id=812b83b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "812b83b4",
  null
  
)

export default component.exports