import Vue from 'vue'

const state = Vue.observable({
  active: false,
  message: '',
  title: '',
  okText: 'Potrdi',
  cancelText: 'Nazaj',
})

let close
const promptPromise = () => new Promise((resolve) => close = resolve)
const open = (message) =>{
  state.message = message
  state.active = true
  return promptPromise()
}
const reset = () =>{
  state.active = false
  state.message = ''
  state.okText = 'Potrdi'
  state.cancelText = 'Nazaj'
  state.title = ''
}

const prompt = {
  get state () {
    return state
  },
  confirm (message) {
    return open(message)
  },
  cancel () {
    if (!close) {
      return
    }
    close(false)
    reset()
  },
  ok (input=true) {
    close(input)
    reset()
  },
  title (title) {
    state.title = title
    return this
  },
  okText (text) {
    state.okText = text
    return this
  },
  cancelText (text) {
    state.cancelText = text
    return this
  },
}

export default prompt
