<template>
  <md-table v-bind="$attrs" v-on="$listeners" class="v-table">
    <slot></slot>
    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </md-table>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss">
@import "app/assets/stylesheets/colors";

.md-table.md-theme-default {
  border: 1px solid $input-light;
  border-radius: 8px;
}

.md-table-head-container {
  background: $white;
  color: $text-black;
  font-size: 14px;
  font-weight: 600;
}

.md-table-cell-container {
  color: $black;
  font-size: 14px;
  font-weight: 400;
}

.md-table-row:nth-child(2n + 1) {
  background-color: $table-gray;
}

.md-table-row:first-child {
  border-bottom: 1px solid $input-light;
}

.md-table-row .md-table-cell {
  border: none;
}

.md-table-row .md-table-cell:first-child .md-table-cell-container {
 color: $primary-blue;
 font-weight: 500
}
</style>
