<template>
  <smart-contracts />
</template>

<script>
import { mapActions } from 'vuex'
import SmartContracts from '../pages/SmartContracts.vue'

export default {
  components: {
    SmartContracts,
  },
  created () {
    this.openAuthModal()
  },
  methods: {
    ...mapActions(['openAuthModal']),
  },
}
</script>
