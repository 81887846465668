<template>
  <md-snackbar v-bind="$attrs" v-on="$listeners" :class="classes">
    <span class="v-snackbar-content">
      <icon :name="iconName" class="v-snackbar-icon" />
      <slot></slot>
    </span>
  </md-snackbar>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'
export default {
  inheritAttrs: false,
  components: { Icon },
  props: {
    type: { type: String, default: 'error', validator: (value) => ['error', 'success', 'info'].includes(value) },
  },
  computed: {
    classes () {
      const classes = []
      switch (this.type) {
      case 'error':
        classes.push('v-snackbar-error')
        break
      case 'success':
        classes.push('v-snackbar-success')
        break
      case 'info':
        classes.push('v-snackbar-info')
      }
      return classes.join(' ')
    },
    iconName () {
      switch (this.type) {
      case 'error':
        return 'alert-triangle'
      case 'success':
        return 'checkmark-circle'
      case 'info':
        return 'checkmark-circle'
      default:
        throw new Error(`Unsupported type: ${this.type}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.md-snackbar.md-theme-default {
  max-width: 988px;
  min-height: 48px;
  padding: 12px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: $white;
  color: $primary-blue;
  /* stylelint-disable-next-line */
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
  font-size: 16px;

  @include respond-to(small) {
    width: 90%;
  }

  &.v-snackbar-error {
    color: $red;
  }

  &.v-snackbar-success, .v-snackbar-info {
    color: $primary-blue;
  }
}

.v-snackbar-content {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.v-snackbar-icon {
  position: relative;
  top: 3px;
  margin-right: 16px;
}
</style>
