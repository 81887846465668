<template>
  <div>
    <div class="auth__switch-button-group">
      <button class="auth__switch-button" @click="$emit('change-page', 'register')">Registracija</button>
      <button class="auth__switch-button auth__switch-button--active">Prijava</button>
    </div>
    <social-buttons class="auth__social" />
    <div class="auth__text auth__separator">Prijavite se s svojim e-poštnim naslovom</div>
    <form novalidate @submit.prevent="submit">
      <div>
        <v-field :class="getValidationClass('email')">
          <label>Uporabniško ime</label>
          <v-input v-model="form.email" type="email" autocomplete="email" tabindex="1" ref="email" @focus="scrollToField" />
          <span class="md-error" v-if="!$v.form.email.required">Vpišite email naslov</span>
          <span class="md-error" v-if="$v.form.email.required && !$v.form.email.email">Neveljaven email naslov</span>
        </v-field>

        <v-field :class="getValidationClass('password')">
          <label>Geslo</label>
          <v-input v-model="form.password" type="password" autocomplete="current-password" tabindex="2"></v-input>
          <span class="md-error" v-if="!$v.form.password.required">Vpišite geslo</span>
        </v-field>
        <div class="auth__inline-auth-link-container">
          <router-link class="auth__link" :to="{ name: 'password_reset_path' }">Pozabljeno geslo?</router-link>
        </div>

        <v-button type="submit" :disabled="isLoading" tabindex="3" class="auth__submit-button">Prijava</v-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import FocusableFieldMixin from '../shared/FocusableFieldMixin'
import ValidationMixin from '../shared/ValidationMixin'
import SocialButtons from './SocialButtons'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: {
    SocialButtons,
    VButton,
    VField,
    VInput,
  },
  data () {
    return {
      form: {
        email: null,
        password: null,
      },
    }
  },
  mounted () {
    this.$refs.email.focus()
  },
  mixins: [FocusableFieldMixin, ValidationMixin],
  computed: {
    ...mapState(['isLoading']),
    loginData () {
      return { email: this.form.email, password: this.form.password }
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.login(this.loginData)
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import './common';
@import '../common';

.auth__inline-auth-link-container {
  position: relative;
  top: -8px;
  width: 100%;
  margin-bottom: 14px;
  text-align: right;
}

</style>
