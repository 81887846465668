<template>
  <form novalidate @submit.prevent="submit" class="entity-form">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />
    <envelope-form-fields
      ref="envelopeFormFields"
      :envelope="envelope"
      @change="updateEnvelopeObject"
    />

    <br />
    <div class="u-flex u-space-between">
      <v-button type="submit" class="regular-button" :disabled="isLoading">Shrani</v-button>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import EnvelopeFormFields from './EnvelopeFormFields'
import VButton from 'design-system/VButton.vue'
import VProgressBar from 'design-system/VProgressBar.vue'

export default {
  components: {
    EnvelopeFormFields,
    VButton,
    VProgressBar,
  },
  mixins: [BackMixin],
  computed: {
    ...mapState('accountStore/finalizedDocuments/envelopeForms', ['envelope']),
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments/envelopeForms', ['updateEnvelopeObject']),
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit')
    },
    runAllValidations () {
      this.$refs.envelopeFormFields.runAllValidations()
    },
    isValid () {
      return this.$refs.envelopeFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>
