<template>
  <div class="section" :class=" {'section--green': color === 'green' }">
    <div class="section-content-container">
      <div class="section-text-container">
        <div v-if="hasHeading" class="section-heading" :class=" {'section-heading--green': color === 'green' }">
          <template v-if="heading">
            {{ heading }}
          </template>
          <slot v-else-if="hasHeadingSlot" name="heading" />
        </div>
        <div v-if="hasText" class="section-text">
          <template v-if="text">
            {{ text }}
          </template>
          <slot v-else-if="hasTextSlot" name="text" />
        </div>
      </div>
      <div class="section-content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: { type: String, default: null },
    text: { type: String, default: null },
    color: {
      type: String,
      default: 'blue',
      validator : value => ['blue', 'green'].includes(value),
    },
  },
  computed: {
    hasHeadingSlot () {
      return !!this.$slots.heading
    },
    hasHeading () {
      return !!this.heading || this.hasHeadingSlot
    },
    hasTextSlot () {
      return !!this.$slots.text
    },
    hasText () {
      return !!this.text || this.hasTextSlot
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/variables';

.section {
  width: 100%;
  position: relative;
  padding: 64px 96px;
  background-color: $white;
  background: $blue-gradient;
  overflow: hidden;

  &--green {
    background: $green-gradient;
  }

  @include respond-to(small) {
    padding: 24px;
  }
}

.section-content-container {
  cursor: default;
  text-align: center;
}

.section-image-container {
  display: block;
  min-width: 200px;
  max-width: 400px;
  margin: auto;

  @include respond-to(small) {
    width: 80%;
  }
}

.section-text-container {
  width: 100%;
  max-width: 1400px;
  margin: auto;

  @include respond-to(small) {
    margin: 0;
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;

    @include respond-to(small) {
      text-align: center;
    }
  }

  &--single {
    max-width: 600px;

    @include respond-to(small) {
      margin: auto;
    }
  }

  &--fit-content {
    width: fit-content;
  }
}

.section-heading {
  max-width: 650px;
  margin: auto;
  padding: 16px 0 80px 0;
  font-weight: 600;
  font-size: 54px;
  line-height: 66px;
  color: $modrn-blue-dark;

  &--green {
    color: $modrn-green-dark;
  }

  @include respond-to('small') {
    padding: 16px 0;
  }
}

.section-text {
  position: relative;
  top: -60px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: $black;

  @include respond-to(small) {
    top: 0;
  }
}

.section-content {
  size: 14px;
  line-height: 20px;
  font-weight: 400;

  &--left {
    text-align: left;

    @include respond-to(small) {
      text-align: center;
    }
  }

  &--left-on-mobile {
    @include respond-to(small) {
      text-align: left;
    }
  }
}
</style>
