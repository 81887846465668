<template>
  <div class="page-container" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <h1 class="page-title">Dodaj dokument</h1>
    <finalized-document-form v-if="!isLoading" @submit="submitFinalizedDocument" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FinalizedDocumentForm from './forms/FinalizedDocumentForm'

export default {
  props: {
    accountId: { type: [String, Number], required: true },
    envelopeId: { type: [String, Number], required: false },
  },
  components: {
    FinalizedDocumentForm,
  },
  created () {
    this.initialize({ envelopeId: this.envelopeId })
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments/forms', ['initialize', 'reset', 'submitFinalizedDocument']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '../../common'
</style>
