<template>
  <div class="footer__icons">
    <payment-options class="footer__payment-options" />
    <div class="footer__vertical-separator"></div>
    <a class="footer__linked-in" href="https://www.linkedin.com/company/pigenta" target="_blank">
      <img class="footer__linked-in-image" :src="require('app/assets/images/linkedin.png')" alt="LinkedIn">
    </a>
  </div>
</template>

<script>
import PaymentOptions from './_PaymentOptions.vue'

export default {
  components: {
    PaymentOptions,
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.footer__icons {
  display: flex;

  @include respond-to('small') {
    position: relative;
    top: -4px;
    margin-bottom: 32px;
  }
}

.footer__vertical-separator {
  width: 1px;
  height: 20px;
  margin: 0 22px;
  border-radius: 10px;
  background-color: $modrn-blue-dark-40;
}

.footer__linked-in-image {
  width: 26px;
  height: 22px;
}

.footer__payment-options {
  position: relative;
  top: -13px;

  @include respond-to('small') {
    position: initial;
  }
}
</style>
