<template>
  <div class="configurator">
    <div class="configurator-wrapper" v-if="selectedFilterId == null">
      <div
        class="configurator-option"
        v-for="availableFilter in availableFilters"
        :key="availableFilter.id"
        @click="selectedFilterId = availableFilter.id"
      >
        {{ availableFilter.label }}
      </div>
    </div>

    <div class="configuration" v-else>
      <v-field class="configurator-range-field" v-if="selectedFilter.type === 'date'">
        <v-datepicker class="configurator-range-field" v-model="selectedDateFrom" md-immediately @md-opened="$emit('datepicker-open')" @md-closed="$emit('datepicker-closed')">
          <label>Od</label>
        </v-datepicker>
        <v-datepicker class="configurator-range-field" v-model="selectedDateTo" md-immediately @md-opened="$emit('datepicker-open')" @md-closed="$emit('datepicker-closed')">
          <label>Do</label>
        </v-datepicker>
      </v-field>

      <v-autocomplete v-else-if="selectedFilter.type === 'list'" :md-options="selectedFilterOptions" v-model="selectedOption" md-dense>
        <label>{{ selectedFilter.label }}</label>
      </v-autocomplete>

      <v-field v-else-if="selectedFilter.type === 'number'">
        <label>{{ selectedFilter.label }}</label>
        <v-input type="number" v-model="selectedNumber" />
      </v-field>

      <div class="configurator-range" v-else-if="selectedFilter.type === 'range'">
        <v-field class="configurator-range-field">
          <label>Od</label>
          <v-input type="number" v-model="selectedNumberFrom" />
        </v-field>
        <v-field class="configurator-range-field">
          <label>Do</label>
          <v-input type="number" v-model="selectedNumberTo" />
        </v-field>
      </div>

      <v-field v-else>
        <label>{{ selectedFilter.label }}</label>
        <v-input v-model="selectedText" />
      </v-field>

      <div class="configuration-actions">
        <v-button class="configuration-action-button" size="small" background-color="white" @click="closeFilterInput">Nazaj</v-button>
        <v-button class="configuration-action-button" size="small" :disabled="areFiltersInvalid" @click="addFilter">Potrdi</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VAutocomplete from 'design-system/VAutocomplete.vue'
import VButton from 'design-system/VButton.vue'
import VDatepicker from 'design-system/VDatepicker.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VAutocomplete, VButton, VDatepicker, VField, VInput },
  data () {
    return {
      selectedFilterId: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedOption: null,
      selectedText: null,
      selectedNumber: null,
      selectedNumberFrom: null,
      selectedNumberTo: null,
      isDatepickerOpen: false,
    }
  },
  props: {
    availableFilters: { type: Array, required: true },
  },
  computed: {
    selectedFilter () {
      if (this.selectedFilterId == null) {
        return null
      }

      return this.availableFilters.find(f => f.id === this.selectedFilterId)
    },
    selectedFilterOptions () {
      if (this.selectedFilter == null) {
        return []
      }

      return this.selectedFilter.options.map(option => {
        return {
          id: option.id,
          name: option.label,
          toLowerCase: () => option.label.toLowerCase(),
          toString: () => option.label,
        }
      })
    },
    areFiltersInvalid () {
      return !this.selectedOption &&
        !this.selectedText &&
        !this.selectedNumber &&
        !this.selectedNumberFrom &&
        !this.selectedNumberTo &&
        !this.selectedDateFrom &&
        !this.selectedDateTo
    },
  },
  methods: {
    addFilter () {
      const filter = this.selectedFilter
      if (filter.type === 'range') {
        filter.valueId = `${this.selectedNumberFrom ?? ''}-${this.selectedNumberTo ?? ''}`
        filter.valueLabel = `${this.selectedNumberFrom ?? ''}-${this.selectedNumberTo ?? ''}`
      } else if (filter.type === 'date') {
        filter.valueId = `${this.selectedDateFrom ?? ''}-${this.selectedDateTo ?? ''}`
        filter.valueLabel = `${this.selectedDateFrom ?? ''}-${this.selectedDateTo ?? ''}`
      } else {
        filter.valueId = this.selectedText || this.selectedNumber || this.selectedOption.id
        filter.valueLabel = this.selectedText || this.selectedNumber || this.selectedOption.name
      }
      this.selectedFilterId = null
      this.$emit('add-filter', filter)
    },
    closeFilterInput () {
      this.selectedFilterId = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.configurator {
  position: relative;
  user-select: none;

}

.configuration {
  & .configuration-actions {
    display: flex;
    justify-content: flex-end;
  }
}

.configurator-option {
  padding: 4px;
  padding: 8px;
  border-radius: 6px;
  margin: 5px;
  color: #242533;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;

  &:hover {
    background-color: $background_color;
    color: $primary-blue;
  }
}

.configurator-range {
  display: flex;
  justify-content: space-between;
}

.configurator-range-field {
  margin-right: 8px;
}

.configuration-action-button {
  margin-left: 8px;
}
</style>

<style lang="scss">
@import 'app/assets/stylesheets/variables';

.md-menu-content.md-menu-content.md-menu-content {
  z-index: $z_heaven;
}

.md-datepicker-dialog {
  z-index: $z_pop;
}
</style>
