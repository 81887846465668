import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'

const state = {
  accountId: null,
  account: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    accountId (state) {
      if (state.accountId) {
        return state.accountId
      }
      if (state.account) {
        return state.account.id
      }

      return null
    },
  },
  actions: {
    initialize ({ commit, dispatch }, accountId) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_ACCOUNT_ID', accountId)

      const promises = []
      if (accountId) {
        promises.push(dispatch('fetchAccount'))
      } else {
        commit('SET_ACCOUNT', {})
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchAccount ({ commit, getters }) {
      if (!getters.accountId) {
        throw new Error('Account id should be set in state')
      }

      return axios
        .get(`/accounts/${getters.accountId}?expand=1`)
        .then(response => commit('SET_ACCOUNT', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju računa, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateAccountObject ({ commit }, newAccount) {
      commit('UPDATE_ACCOUNT_OBJECT', newAccount)
    },
    submitAccount ({ dispatch, commit, getters }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewAccount = getters.accountId == null
      const promise = isCreatingNewAccount ? dispatch('createAccount') : dispatch('updateAccount')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Račun uspešno shranjen.', type: 'success' }, { root: true })
          if (isCreatingNewAccount) {
            router.push({ name: 'admin_account_edit_path', params: { id: getters.accountId } })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju računa - ${parsedError}` ,type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createAccount ({ commit, state }) {
      const payload = { account: snakeKeys(state.account, { recursive: true, recursiveInArray: true }) }
      return axios
        .post('/accounts', payload)
        .then(response => commit('SET_ACCOUNT_ID', response.data.id))
    },
    updateAccount ({ state, getters }) {
      if (!getters.accountId) {
        throw new Error('Cannot update a account without having the id')
      }

      const payload = { account: snakeKeys(state.account, { recursive: true, recursiveInArray: true }) }
      return axios.put(`/accounts/${getters.accountId}`, payload)
    },
    deleteAccount ({ commit, dispatch }, accountId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/accounts/${accountId}`)
        .then(() => router.push({ name: 'admin_accounts_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju računa, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_ACCOUNT (state, account) {
      state.account = camelKeys(account, { recursive: true, recursiveInArray: true })
    },
    SET_ACCOUNT_ID (state, accountId) {
      state.accountId = accountId
    },
    UPDATE_ACCOUNT_OBJECT (state, newAccount) {
      state.account = {
        ...state.account,
        ...newAccount,
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
