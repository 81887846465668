<template>
  <div class="help-wrapper">
    <icon class="help" name="question-mark" :width="18" :height="18" />
    <v-tooltip md-direction="bottom">{{ text }}</v-tooltip>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'
import VTooltip from 'design-system/VTooltip.vue'

export default {
  components: { Icon, VTooltip },
  props: {
    text: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.help-wrapper {
  display: inline-block;
  height: fit-content;
  margin-left: 4px;
}

.help {
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: help;
}

.md-tooltip {
  max-width: 200px;
  height: fit-content;
  padding: 8px;
  line-height: 14px;
  white-space: normal;
  z-index: $z_heaven;
}
</style>
