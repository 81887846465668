<template>
  <md-input v-bind="$attrs" v-on="$listeners" ref="mdInput">
    <slot></slot>
  </md-input>
</template>

<script>
export default {
  inheritAttrs: false,
  methods: {
    focus () {
      this.$refs.mdInput.$el.focus()
    },
  },
}
</script>

<style lang="scss">
@import "design-system/styles/input";

.md-field .md-input {
  @include input-box;
}

.md-field .md-input:focus {
  @include input-focus;
}

.md-invalid.md-field .md-input {
  @include input-error;
}

.md-field > .md-icon ~ .md-input,
.md-field > .md-icon ~ .md-input label {
  padding-left: 36px;
  margin-left: 0;
}
</style>
