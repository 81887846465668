<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <entity-table
      :columns="columns"
      :items="blogPosts"
      :are-items-clickable="true"
      :is-loaded="!!numberOfPages"
      :pagination="pagination"
      title="Blog"
      add-text="Nov Blog"
      :empty-text="emptyText"
      @search="setQuery"
      @page-change="goToPage"
      @click="openEditBlog"
      @clickWithModifier="openEditBlogPostInNewTab"
      @add="$router.push({ name: 'admin_new_blog_post_path' })"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import PaginationMixin from '../../shared/PaginationMixin'

export default {
  components: { EntityTable },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'id', name: 'ID' },
      { id: 'title', name: 'Naslov' },
      { id: 'keywords', name: 'Ključne besede' },
    ]
  },
  created () {
    this.initialize()
    this.storeModule = ['admin', 'blogPosts']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('admin/blogPosts', ['blogPosts', 'numberOfPages', 'query']),
    emptyText () {
      return this.query ? 'Noben blog ne ustreza iskanju.' : 'Zaenkrat še nimate blogov.'
    },
  },
  methods: {
    ...mapActions('admin/blogPosts', ['initialize', 'reset', 'setQuery', 'goToPage', 'setFilters']),
    openEditBlog (blogPost) {
      this.$router.push({
        name: 'admin_blog_post_edit_path',
        params: {
          id: blogPost.id,
        },
      })
    },
    openEditBlogPostInNewTab (blogPost) {
      const route = this.$router.resolve({
        name: 'admin_blog_post_edit_path',
        params: {
          id: blogPost.id,
        },
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';
</style>
