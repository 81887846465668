<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <entity-table
      :columns="columns"
      :items="users"
      :are-items-clickable="false"
      :is-loaded="!!numberOfPages"
      :pagination="pagination"
      title="Uporabniki"
      search-text="Išči po uporabnikih"
      add-text="Dodaj uporabnika"
      :empty-text="emptyText"
      @search="setQuery"
      @page-change="goToPage"
      @add="openCreateUser"
    >
      <template #actions="actionsProps">
        <md-table-cell>
          <icon name="edit" @click="openEditUser(actionsProps.item)" />
          <v-tooltip>Uredi</v-tooltip>
        </md-table-cell>
      </template>
    </entity-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import PaginationMixin from '../../shared/PaginationMixin'
import Icon from 'design-system/icons/Icon.vue'
import VTooltip from 'design-system/VTooltip.vue'

export default {
  props: {
    accountId: { type: [String, Number], required: true },
  },
  components: {
    EntityTable,
    Icon,
    VTooltip,
  },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'email', name: 'Email' },
      { id: 'first_name', name: 'Ime' },
      { id: 'last_name', name: 'Priimek' },
      { id: 'created_at', name: 'Datum registracije' },
      { id: 'last_active_at', name: 'Nazadnje aktiven' },
      { id: 'is_account_admin', name: 'Admin' },
    ]
  },
  created () {
    this.initialize(this.accountId)
    this.storeModule = ['accountStore', 'users']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('accountStore/users', ['users', 'query', 'numberOfPages']),
    emptyText () {
      return this.query ? 'Noben uporabnik ne ustreza iskanju.' : 'Zaenkrat še nimate uporabnikov.'
    },
  },
  methods: {
    ...mapActions('accountStore/users', ['initialize', 'reset', 'setQuery', 'goToPage']),
    openCreateUser () {
      this.$router.push({
        name: 'account_user_create_path',
        params: {
          accountId: this.accountId,
        },
      })
    },
    openEditUser (user) {
      this.$router.push({
        name: 'account_user_edit_path',
        params: {
          id: user.id,
          accountId: this.accountId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';
@import '@/components/clickable_table_actions';
</style>
