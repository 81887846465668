<template>
  <div class="document-composition__container">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />

    <template v-if="!isLoading && Object.keys(document).length > 0">
      <document-composition-window :is-disabled="!!finalizedDocumentId" @saveDocument="saveDocumentOrGoToPaymentPage" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Window from './Window'
import VProgressBar from 'design-system/VProgressBar.vue'

export default {
  components: {
    DocumentCompositionWindow: Window,
    VProgressBar,
  },
  props: {
    id: { type: [String, Number], required: true },
    isEditingRestricted: { type: Boolean, default: false },
  },
  created () {
    this.initialize({
      documentId: this.id,
      isEditingRestricted: this.isEditingRestricted,
    })
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['user']),
    ...mapState('pages/documentComposition', ['document', 'finalizedDocumentId', 'isLoading']),
  },
  methods: {
    ...mapActions('pages/documentComposition', ['initialize', 'reset', 'finishDocumentComposition']),
    saveDocumentOrGoToPaymentPage () {
      if (this.finalizedDocumentId) {
        this.goToPaymentPage()
      } else {
        this.saveDocument()
      }
    },
    saveDocument () {
      this.finishDocumentComposition()
    },
    goToPaymentPage () {
      this.$router.push({ name: 'finalized_document_payment', params: { id: this.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../common';
@import '../../common';

.document-composition__container {
  position: relative;
  width: 100%;
}

/deep/ .md-checkbox-label {
  height: fit-content;
}
</style>
