<template>
  <div class="smart-contract page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <template v-if="Object.keys(document).length > 0">
      <div class="smart-contract__heading">
        <div class="smart-contract__breadcrumbs">
          <router-link class="smart-contract__contracts-link" :to="{ name: 'smart_contracts_path' }">Vzorci</router-link>
          <div class="smart-contract__name-separator">></div>
          <div class="smart-contract__name">{{ document.name }}</div>
        </div>
        <div class="smart-contract__price" v-if="document.accountId == null && document.priceLabel">{{ document.priceLabel }}</div>
      </div>
    </template>

    <document-composition :id="id" :is-editing-restricted="true" />

    <div v-if="document.generalRemarks" class="smart-contract__info">
      <div class="smart-contract__info-button" @click="toggleIsInfoShown">
        <div class="smart-contract__info-button-text">Priporočila o uporabi dokumenta</div>
        <div class="smart-contract__info-button-icon">
          <v-icon v-if="isInfoShown">expand_less</v-icon>
          <v-icon v-else>expand_more</v-icon>
        </div>
      </div>
      <div class="smart-contract__info-content" v-html="generalRemarksMarkdownHtml" ref="infoContent"></div>
    </div>
  </div>
</template>

<script>
import snarkdown from 'snarkdown'
import { mapActions, mapState, mapGetters } from 'vuex'
import DocumentComposition from './document_composition/DocumentComposition'
import VIcon from 'design-system/VIcon.vue'
import prompt from 'design-system/components/prompt/prompt.js'

export default {
  components: {
    DocumentComposition,
    VIcon,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  data () {
    return {
      isInfoShown: false,
      infoContentHeight: null,
    }
  },
  watch: {
    id: {
      handler (id) {
        this.initialize({ documentId: id })
      },
      immediate: true,
    },
    document: {
      handler (documentObject) {
        this.$nextTick(() => {
          emitDocumentMeta.call(this, documentObject)
          setInfoContentHeight.call(this)
          function emitDocumentMeta (document) {
            if (!document) {
              return
            }
            this.$root.$emit('set-page-meta', { title: document.name })
          }

          function setInfoContentHeight () {
            if (!this.$refs.infoContent) {
              return
            }

            this.infoContentHeight = this.$refs.infoContent.scrollHeight + 100 // some safety margin
          }
        })
      },
    },
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapGetters('pages/documentComposition', ['document', 'hasUnsavedChanges']),
    ...mapGetters(['isLoggedIn']),
    generalRemarksMarkdownHtml () {
      if (!this.document.generalRemarks) {
        return ''
      }

      return `${snarkdown(this.document.generalRemarks)}<br/>`
    },
  },
  methods: {
    ...mapActions('pages/documentComposition', ['initialize']),
    toggleIsInfoShown () {
      if (!this.isInfoShown && this.$refs.infoContent) {
        this.$refs.infoContent.style.maxHeight = `${this.infoContentHeight}px`
      } else if (this.$refs.infoContent) {
        this.$refs.infoContent.style.maxHeight = 0
      }

      this.isInfoShown = !this.isInfoShown
    },
  },
  async beforeRouteLeave (to, from, next) {
    if (!this.hasUnsavedChanges || !this.isLoggedIn) {
      next()
      return
    }

    const confirmMessage = 'Dokument še ni shranjen.\n\nČe boste zapustili to stran, bo vaš vnos izgubljen. Izberite OK, da zapustite stran in izgubite vnos ali Nazaj, da ostanete na tej strani.'
    const confirmed = await prompt.okText('OK').confirm(confirmMessage)
    if (confirmed) {
      next()
    }
  },
}
</script>

<style scoped lang='scss'>
@import 'app/assets/stylesheets/variables';
@import './common';
@import '../common';

.smart-contract {
  padding-bottom: 0; // override default padding bottom, we don't need it here
}

.smart-contract__heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  @include respond-to('small') {
    position: relative;
    top: -8px;
    padding-bottom: 8px;
  }
}

.smart-contract__price {
  min-width: 64px;
  height: fit-content;
  font-size: 14px;
  line-height: 36px;
  font-weight: 600;
  color: $primary-blue;
  flex-shrink: 0;
}

.smart-contract__breadcrumbs {
  display: flex;
  line-height: 36px;
  font-size: 14px;
  font-weight: 500;
  color: $black-text;
}

.smart-contract__contracts-link {
  text-decoration: none;
}

.smart-contract__name-separator {
  padding: 0 14px;

  @include respond-to('small') {
    padding: 0 6px;
  }
}

.smart-contract__name {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: $disabled-gray;
}

.smart-contract__info {
  margin-top: 24px;
  color: black;
  background-color: white;
  border-radius: 8px;
  user-select: none;
}

.smart-contract__info-button {
  display: flex;
  padding: 32px;
  justify-content: space-between;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
}

.smart-contract__info-content {
  max-height: 0;
  overflow: hidden;
  padding: 0 32px;
  font-size: 14px;
  transition: .3s cubic-bezier(.25,.8,.25,1);
  transition-property: max-height;
}
</style>
