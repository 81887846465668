<template>
  <common-dialog @close="closeDialog">
    <div class="checkout checkout--center">
      <img class="checkout__image" :src="require('app/assets/images/fail.svg')" alt="Plačilo neuspešno">
      <div class="checkout__heading">Plačilo ni bilo uspešno.</div>
      <div class="checkout__explanation">Znesek ni bil trgan z vašega računa. Prosimo poskusite ponovno.</div>
    </div>
  </common-dialog>
</template>

<script>
import Dialog from '../shared/_Dialog'

export default {
  components: {
    CommonDialog: Dialog,
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './common';
</style>
