<template>
  <div>
    <v-checkbox v-model="form.isRootSegment">Korenski segment</v-checkbox>
    <v-checkbox v-model="form.isNumberedSegment" :disabled="isNumberedTextSegmentDisabled">Oštevilčen segment</v-checkbox>
    <v-checkbox v-model="form.isSegmentContentNumbered">Oštevilčena vsebina segmenta</v-checkbox>
    <v-checkbox v-model="form.isDuplicable">Omogoča dupliciranje</v-checkbox>

    <v-field>
      <label>Naziv konteksta (opcijsko)</label>
      <v-input v-model="form.sectionName"></v-input>
    </v-field>

    <v-field :class="getValidationClass('order')">
      <label>Vrstni red tekstovnega segmenta v dokumentu</label>
      <v-input v-model="form.order" type="number"></v-input>
      <span class="md-error" v-if="!$v.form.order.required">Vpišite vrstni red</span>
    </v-field>

    <v-field :class="getValidationClass(['textContent', 'id'])">
      <v-autocomplete :md-options="textContentOptions" v-model="selectedTextContent" md-dense>
        <label>Tekstovni sestavek</label>
        <template slot="md-autocomplete-item" slot-scope="{item}">{{ item.name }}</template>
      </v-autocomplete>
      <span class="md-error" v-if="!$v.form.textContent.id.required">Izberite tekstovni sestavek</span>
    </v-field>

    <template v-if="isCreatingNewTextContent">
      <div class="md-subheading">tekstovni sestavek</div>
      <text-content-form-fields ref="textContent" @change="onTextContentChange" />
    </template>
  </div>
</template>

<script>
import { isString } from 'lodash-es'
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '@/components/shared/ValidationMixin'
import AutocompleteFixMixin from '@/components/shared/AutocompleteFixMixin'
import TextContentFormFields from '../../text_contents/forms/TextContentFormFields'
import VAutocomplete from 'design-system/VAutocomplete.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: {
    TextContentFormFields,
    VAutocomplete,
    VCheckbox,
    VField,
    VInput,
  },
  props: {
    textSegment: { type: Object, required: false },
    textContents: { type: Array, default: () => [] },
  },
  data () {
    return {
      isNumberedTextSegmentDisabled: false,
      selectedTextContent: null,
      form: {
        sectionName: null,
        order: null,
        isRootSegment: false,
        isNumberedSegment: true,
        isSegmentContentNumbered: false,
        isDuplicable: false,
        textContent: {
          id: null,
        },
      },
    }
  },
  mixins: [ValidationMixin, AutocompleteFixMixin],
  created () {
    if (!this.textSegment) {
      return
    }

    // Extra data that comes from the server, remove it to prevent confusion.
    // We're building the text content as an object here.
    delete this.textSegment.textContentId

    this.form.sectionName = this.textSegment.sectionName
    this.form.order = this.textSegment.order
    this.form.isRootSegment = this.textSegment.isRootSegment ?? this.form.isRootSegment
    this.form.isNumberedSegment = this.textSegment.isNumberedSegment ?? this.form.isNumberedSegment
    this.form.isSegmentContentNumbered = this.textSegment.isSegmentContentNumbered ?? this.form.isSegmentContentNumbered
    this.form.isDuplicable = this.textSegment.isDuplicable ?? this.form.isDuplicable

    if (!this.textSegment.textContent) {
      return
    }

    this.form.textContent.id = this.textSegment.textContent.id
  },
  watch: {
    textContentOptions: {
      handler (newTextContentOptions) {
        if (!this.textSegment || !this.textSegment.textContent) {
          return
        }
        this.selectedTextContent = newTextContentOptions.find(option => option.id === this.textSegment.textContent.id)
      },
      immediate: true,
    },
    selectedTextContent: {
      handler (newSelectedTextContent) {
        if (!newSelectedTextContent) {
          this.form.textContent.id = null
          return
        }

        if (isString(newSelectedTextContent)) {
          return
        }

        this.form.textContent.id = newSelectedTextContent.id
      },
    },
    form: {
      handler (newFormValue) {
        if (newFormValue.isSegmentContentNumbered === true) {
          this.form.isNumberedSegment = true
          this.isNumberedTextSegmentDisabled = true
        } else if (newFormValue.isSegmentContentNumbered === false) {
          this.isNumberedTextSegmentDisabled = false
          if (newFormValue.isSegmentContentNumbered === true) {
            this.form.isSegmentContentNumbered = false
          }
        }

        this.$emit('change', {
          ...this.textSegment,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  computed: {
    isCreatingNewTextContent () {
      return this.form.textContent.id === 'new'
    },
    textContentOptions () {
      // all the mapping is required due to a bug in the component https://github.com/vuematerial/vue-material/issues/1243
      const options = this.textContents.map(this.toAutocompleteItem)

      options.unshift({
        id: 'new',
        name: 'Ustvari nov sestavek',
        toLowerCase: () => 'ustvari nov sestavek',
        toString: () => 'Ustvari nov sestavek',
      })

      return options
    },
  },
  methods: {
    onTextContentChange ($event) {
      this.form.textContent = {
        id: this.form.textContent.id,
        ...$event,
      }
      this.$emit('change', this.form)
    },
    runAllValidations () {
      this.$v.$touch()

      if (this.$refs.textContent) {
        this.$refs.textContent.runAllValidations()
      }
    },
    isValid () {
      let isTextContentValid = true
      if (this.$refs.textContent) {
        isTextContentValid = this.$refs.textContent.isValid()
      }

      return !this.$v.$invalid && isTextContentValid
    },
  },
  validations: {
    form: {
      order: { required },
      textContent: {
        id: { required },
      },
    },
  },
}
</script>
