import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueGtag from 'vue-gtag'
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { MdAutocomplete, MdBadge, MdButton, MdCard, MdContent, MdDatepicker, MdDialog, MdDivider, MdCheckbox, MdField, MdIcon, MdList, MdMenu, MdProgress, MdRadio, MdSnackbar, MdTable, MdTooltip } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default-dark.css'
import VueMaterialLocales from '@undecaf/vue-material-locales'
import sl from '@undecaf/vue-material-locales/dist/locale/sl'
import { getFromMeta, getDefaultAccount, fontLoaderHook, isAnalyticsAllowed } from './utils'
import clickOutside from './directives/clickOutside'
import router from '@/router'
import store from '@/store'
import App from '@/components/App.vue'
import Header from './components/shared/_Header'
import Sidebar from './components/shared/_Sidebar'
import Footer from './components/shared/_Footer'
import CookieConsent from './components/shared/_CookieConsent'
import Tracker from './components/shared/_Tracker'
import EmailNewsletter from './components/shared/_EmailNewsletter'
import Toast from './components/shared/_Toast'
import { merge } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.withCredentials = true

fontLoaderHook()

Vue.use(Vuelidate)
Vue.mixin({
  mounted () {
    this.setIsMobile()
    window.addEventListener('resize', this.setIsMobile)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setIsMobile)
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapGetters(['isLoggedIn', 'isSidebarPresent']),
    isAnalyticsAllowed () {
      return isAnalyticsAllowed()
    },
  },
  methods: {
    ...mapActions(['setIsMobile']),
  },
})
function mergeValidations (a, b) {
  if (typeof a === 'function' || typeof b === 'function') {
    return function () {
      const aObj = typeof a === 'function' ? a.call(this) : a
      const bObj = typeof b === 'function' ? b.call(this) : b
      return merge(aObj, bObj)
    }
  }
  return merge(a, b)
}

Vue.config.optionMergeStrategies.validations = mergeValidations
Vue.use(VueGtag, {
  config: { id: 'G-5JHZXZD27X' },
  bootstrap: isAnalyticsAllowed(),
}, router)
Vue.directive('click-outside', clickOutside)

const dsn = getFromMeta('sentry-dsn', false)
if (dsn) {
  Sentry.init({
    Vue,
    dsn: dsn,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

store.dispatch('setAccount', getDefaultAccount(getFromMeta('accounts')))
store.dispatch('setUser', getFromMeta('user'))

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    Vue.use(MdAutocomplete)
    Vue.use(MdBadge)
    Vue.use(MdButton)
    Vue.use(MdCard)
    Vue.use(MdContent)
    Vue.use(MdDatepicker)
    Vue.use(MdDivider)
    Vue.use(MdCheckbox)
    Vue.use(MdField)
    Vue.use(MdIcon)
    Vue.use(MdProgress)
    Vue.use(MdRadio)
    Vue.use(MdSnackbar)
    Vue.use(MdTable)
    Vue.use(MdTooltip)

    // components not used directly but required by other components
    Vue.use(MdList)
    Vue.use(MdMenu)
    Vue.use(MdDialog)

    // Datepicker locales
    Vue.use(VueMaterialLocales, [sl])

    Vue.component('Sidebar', Sidebar)
    Vue.component('HeaderComponent', Header)
    Vue.component('FooterComponent', Footer)
    Vue.component('CookieConsent', CookieConsent)
    Vue.component('Tracker', Tracker)
    Vue.component('EmailNewsletter', EmailNewsletter)
    Vue.component('Toast', Toast)

    new Vue({
      router,
      store,
      el: document.querySelector('#vue-app'),
      render: h => h(App),
    })
  })
}
