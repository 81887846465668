import axios from 'axios'
import { camelKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import formsModule from './forms'
import { paginationState, paginationMutations } from '@/store/mixins/pagination'

const state = {
  attributeKeys: [],
  ...paginationState,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  modules: {
    forms: formsModule,
  },
  state: getState(),
  getters: {
    attributeKeys (state) {
      return state.attributeKeys.map(attributeKey => {
        let type = 'Tekst'
        let listValues = ''
        if (attributeKey.customAttributeKeyType === 'list') {
          type = 'Izbira'
          listValues = attributeKey.customAttributeListValues.reduce((listValuesString, listValue) => {
            if (listValuesString !== '') {
              listValuesString += ', '
            }

            return listValuesString + listValue.label
          }, '')
        } else if (attributeKey.customAttributeKeyType === 'date') {
          type = 'Datum'
        }

        return {
          ...attributeKey,
          customAttributeKeyType: type,
          customAttributeListValues: listValues,
        }
      })
    },
  },
  actions: {
    initialize ({ dispatch }) {
      return dispatch('fetchAttributeKeys')
    },
    setQuery ({ commit, dispatch }, query) {
      commit('SET_QUERY', query)
      return dispatch('fetchAttributeKeys')
    },
    fetchAttributeKeys ({ commit, state, rootGetters }) {
      if (!rootGetters.accountId) {
        throw new Error('Account id has to be set in the state')
      }

      commit('SET_IS_LOADING', true, { root: true })
      let url = `/custom_attribute_keys?by_account_id=${rootGetters.accountId}&expand=1&page=${state.page}&limit=${state.perPage}`
      if (state.query) {
        url += `&by_label=${state.query}`
      }

      return axios
        .get(url)
        .then((response) => {
          commit('SET_ATTRIBUTE_KEYS', response.data)
          commit('SET_NUMBER_OF_PAGES', response.headers['total-pages'])
          commit('SET_TOTAL_RESULTS', response.headers['total-count'])
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju oznak, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    goToPage ({ commit, dispatch, state }, page) {
      if (page > 0 && page <= state.numberOfPages) {
        commit('SET_PAGE', page)
        return dispatch('fetchAttributeKeys')
      }
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_ATTRIBUTE_KEYS (state, attributeKeys) {
      state.attributeKeys = attributeKeys.map(key => camelKeys(key, { recursive: true, recursiveInArray: true }))
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
    ...paginationMutations,
  },
}
