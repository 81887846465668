<template>
  <common-dialog @close="closeDialog">
    <div class="checkout checkout--center">
      <img class="checkout__image" :src="require('app/assets/images/success.svg')" alt="Plačilo uspešno">
      <div class="checkout__heading">Plačilo je bilo uspešno.</div>
      <div class="checkout__explanation">Kupljen dokument lahko vidite v pogledu <b>Zadnje ustvarjeni</b> in <b>Hramba</b> ter si ga prenesete v <b>PDF</b> ali <b>DOCX</b> obliki ali ga elektronsko podpišete.</div>
    </div>
  </common-dialog>
</template>

<script>
import Dialog from '../shared/_Dialog'

export default {
  components: {
    CommonDialog: Dialog,
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './common';
</style>
