<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="admin__header">
      <h1 class="page-title">Uredi Oznako</h1>
      <v-button class="delete-button" :disabled="isLoading" @click="onDelete" background-color="red" outlined>Izbriši</v-button>
    </div>
    <attribute-key-form v-show="!isLoading" @submit="submitAttributeKey" @delete="onDelete" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import AttributeKeyForm from './forms/AttributeKeyForm'
import VButton from 'design-system/VButton.vue'
import prompt from 'design-system/components/prompt/prompt'

export default {
  props: {
    id: { type: [String, Number], required: true },
  },
  components: {
    AttributeKeyForm,
    VButton,
  },
  created () {
    this.setAttributeKeyId(this.id).then(() => this.initialize())
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapGetters(['accountSlug']),
  },
  methods: {
    ...mapActions('accountStore/attributeKeys/forms', ['initialize', 'setAttributeKeyId', 'reset', 'submitAttributeKey', 'deleteAttributeKey']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati oznako? Akcija bo nepovratno izbrisala oznako.')
      if (result) {
        this.deleteAttributeKey(this.id).then(() => {
          this.$router.push({
            name: 'account_attribute_keys_path',
            params: {
              accountId: this.accountSlug,
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
