<template>
  <form novalidate @submit.prevent="submit" class="entity-form">
    <blog-post-form-fields
      ref="blogPostFormFields"
      :blog-post="blogPost"
      @uploading="isUploading = true"
      @uploaded="isUploading = false"
    />
    <br />
    <div class="u-flex u-space-between">
      <div>
        <v-button class="regular-button" type="submit" :disabled="isSavingDisabled">Shrani</v-button>
        <v-tooltip v-if="isUploading" md-direction="top">Datoteka se prenaša...</v-tooltip>
      </div>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import ValidationMixin from '@/components/shared/ValidationMixin'
import BlogPostFormFields from './BlogPostFormFields'
import VButton from 'design-system/VButton.vue'
import VTooltip from 'design-system/VTooltip.vue'

export default {
  data () {
    return {
      isUploading: false,
    }
  },
  components: {
    BlogPostFormFields,
    VButton,
    VTooltip,
  },
  mixins: [BackMixin, ValidationMixin],
  props: {
    blogPost: { type: Object, required: false },
  },
  computed: {
    ...mapState(['isLoading']),
    isSavingDisabled () {
      return this.isLoading || this.isUploading
    },
  },
  methods: {
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit', this.$refs.blogPostFormFields.blogPostData)
    },
    runAllValidations () {
      this.$refs.blogPostFormFields.runAllValidations()
      this.focusFirstError()
    },
    isValid () {
      return this.$refs.blogPostFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
