<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="new-text-content__header">
      <h1 class="page-title">Nov Tekstovni Sestavek</h1>
      <v-button class="regular-button new-text-content__header-button" @click="saveTextContent">Shrani</v-button>
    </div>
    <text-content-form @submit="submitTextContent" ref="textContentForm" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TextContentForm from './forms/TextContentForm'
import VButton from 'design-system/VButton.vue'

export default {
  components: {
    TextContentForm,
    VButton,
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    ...mapActions('admin/textContents/forms', ['initialize', 'reset', 'submitTextContent']),
    saveTextContent () {
      this.$refs.textContentForm.submit()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';

.new-text-content__header {
  display: flex;
  justify-content: space-between;
}

.new-text-content__header-button {
  position: relative;
  top: -12px;
}
</style>
