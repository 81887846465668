import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { clone } from 'lodash-es'
import { getResponseErrorMessage, getAllRecords } from '@/store/utils'
import router from '@/router'

const state = {
  documentId: null,
  document: null,
  availableTextContents: [],
  availableAccounts: [],
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    documentId (state) {
      if (state.documentId) {
        return state.documentId
      }
      if (state.document) {
        return state.document.id
      }

      return null
    },
    exportDocumentPath (state) {
      if (!state.documentId) {
        return null
      }

      return `/documents/${state.documentId}/export.json`
    },
  },
  actions: {
    initialize ({ commit, dispatch }, documentId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      commit('SET_DOCUMENT_ID', documentId)

      const promises = [
        dispatch('fetchAccounts'),
        dispatch('fetchTextContents'),
      ]
      if (documentId) {
        promises.push(dispatch('fetchDocument'))
      } else {
        commit('SET_DOCUMENT', {
          textSegments: [],
          conditions: [],
        })
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchDocument ({ commit, getters }) {
      if (!getters.documentId) {
        throw new Error('Document id should be set in state')
      }

      return axios
        .get(`/documents/${getters.documentId}?expand=1`)
        .then(response => commit('SET_DOCUMENT', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchTextContents ({ commit }) {
      return getAllRecords('/text_contents')
        .then(records => commit('SET_AVAILABLE_TEXT_CONTENTS', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju tekstovnih sestavkov, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchAccounts ({ commit }) {
      return getAllRecords('/accounts?sort=name')
        .then(records => commit('SET_AVAILABLE_ACCOUNTS', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju skupin, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateDocumentObject ({ commit }, newDocument) {
      commit('UPDATE_DOCUMENT_OBJECT', newDocument)
    },
    addTextSegment ({ commit }) {
      commit('ADD_TEXT_SEGMENT')
    },
    removeTextSegment ({ commit }, index) {
      commit('REMOVE_TEXT_SEGMENT', index)
    },
    addCondition ({ commit }) {
      commit('ADD_CONDITION')
    },
    removeCondition ({ commit }, index) {
      commit('REMOVE_CONDITION', index)
    },
    setDocumentTextSegment ({ commit }, textSegmentData) {
      commit('SET_DOCUMENT_TEXT_SEGMENT', textSegmentData)
    },
    setDocumentCondition ({ commit }, conditionData) {
      commit('SET_DOCUMENT_CONDITION', conditionData)
    },
    submitDocument ({ dispatch, commit, getters }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewDocument = getters.documentId == null
      const promise = isCreatingNewDocument ? dispatch('createDocument') : dispatch('updateDocument')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Dokument uspešno shranjen.', type: 'success' }, { root: true })
          if (isCreatingNewDocument) {
            router.push({ name: 'admin_document_edit_path', params: { id: getters.documentId } })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju dokumenta - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createDocument ({ commit, state }) {
      const payload = { document: snakeKeys(state.document, { recursive: true, recursiveInArray: true }) }
      return axios
        .post('/documents', payload)
        .then(response => commit('SET_DOCUMENT_ID', response.data.id))
    },
    updateDocument ({ state, getters }) {
      if (!getters.documentId) {
        throw new Error('Cannot update a document without having the id')
      }

      const payload = { document: snakeKeys(state.document, { recursive: true, recursiveInArray: true }) }
      return axios.put(`/documents/${getters.documentId}`, payload)
    },
    deleteDocument ({ commit, dispatch }, documentId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/documents/${documentId}`)
        .then(() => router.push({ name: 'admin_documents_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    importDocument ({ commit, dispatch }, documentString) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      let documentJson = null
      try {
        documentJson = JSON.parse(documentString)
      } catch (error) {
        console.error(error)
      }

      if (!documentJson) {
        commit('SET_MESSAGE', { text: 'Neveljaven JSON objekt', type: 'error' }, { root: true })
        commit('SET_IS_LOADING', false, { root: true })
        return
      }

      return axios
        .post('/documents/import', documentJson)
        .then(() => router.push({ name: 'admin_documents_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri uvažanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_DOCUMENT (state, document) {
      const camelCasedDocument = camelKeys(document, { recursive: true, recursiveInArray: true })
      camelCasedDocument.textSegments = camelCasedDocument.textSegments.filter(segment => !segment.conditionOutcomeId)
      camelCasedDocument.conditions = camelCasedDocument.conditions.filter(condition => !condition.conditionOutcomeId)
      state.document = addFrontendIds(camelCasedDocument)

      function addFrontendIds (document) {
        const documentWithFrontendIds = clone(document)
        documentWithFrontendIds.textSegments.forEach(textSegment => processTextSegment(textSegment))
        documentWithFrontendIds.conditions.forEach(condition => processCondition(condition))

        return documentWithFrontendIds

        function processTextSegment (textSegment) {
          textSegment.frontendId = Math.random().toString(36)
        }

        function processCondition (condition) {
          condition.frontendId = Math.random().toString(36)
          condition.conditionOutcomes.forEach(conditionOutcome => {
            conditionOutcome.frontendId = Math.random().toString(36)
            conditionOutcome.conditions.forEach(condition => processCondition(condition))
            conditionOutcome.textSegments.forEach(textSegment => processTextSegment(textSegment))
          })
        }
      }
    },
    SET_DOCUMENT_ID (state, documentId) {
      state.documentId = documentId
    },
    SET_AVAILABLE_TEXT_CONTENTS (state, availableTextContents) {
      state.availableTextContents = availableTextContents
    },
    SET_AVAILABLE_ACCOUNTS (state, availableAccounts) {
      state.availableAccounts = availableAccounts
    },
    UPDATE_DOCUMENT_OBJECT (state, newDocument) {
      state.document = {
        ...state.document,
        ...newDocument,
      }
    },
    ADD_TEXT_SEGMENT (state) {
      state.document.textSegments.push({
        frontendId: Math.random().toString(36),
        order: state.document.textSegments.length + 1,
      })
    },
    REMOVE_TEXT_SEGMENT (state, index) {
      state.document.textSegments.splice(index, 1)
    },
    ADD_CONDITION (state) {
      state.document.conditions.push({
        frontendId: Math.random().toString(36),
        order: state.document.conditions.length + 1,
      })
    },
    REMOVE_CONDITION (state, index) {
      state.document.conditions.splice(index, 1)
    },
    SET_DOCUMENT_TEXT_SEGMENT (state, textSegmentData) {
      state.document.textSegments[textSegmentData.index] = {
        ...textSegmentData.textSegment,
        frontendId: state.document.textSegments[textSegmentData.index].frontendId,
      }
    },
    SET_DOCUMENT_CONDITION (state, conditionData) {
      state.document.conditions[conditionData.index] = {
        ...conditionData.condition,
        frontendId: state.document.conditions[conditionData.index].frontendId,
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
