<template>
  <div class="admin" :class="{ 'admin--with-sidebar': isSidebarPresent }">
    <entity-table
      :columns="columns"
      :items="promotionCodes"
      :are-items-clickable="true"
      :is-loaded="!!numberOfPages"
      :pagination="pagination"
      title="Promocijske kode"
      add-text="Nova promocijska koda"
      :empty-text="emptyText"
      @search="setQuery"
      @page-change="goToPage"
      @click="openEditPromotionCode"
      @add="$router.push({ name: 'admin_new_promotion_code_path' })"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import PaginationMixin from '../../shared/PaginationMixin'

export default {
  components: { EntityTable },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.columns = [
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'Ime' },
      { id: 'code', name: 'Koda' },
      { id: 'value', name: 'vrednost' },
      { id: 'expires_on', name: 'Datum veljavnosti' },
      { id: 'created_at', name: 'Datum kreacije' },
    ]
  },
  created () {
    this.initialize()
    this.storeModule = ['admin', 'promotionCodes']
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState('admin/promotionCodes', ['promotionCodes', 'numberOfPages', 'query']),
    emptyText () {
      return this.query ? 'Nobena promocijska koda ne ustreza iskanju.' : 'Zaenkrat še nimate promocijskih kod.'
    },
  },
  methods: {
    ...mapActions('admin/promotionCodes', ['initialize', 'reset', 'setQuery', 'goToPage']),
    openEditPromotionCode (promotionCode) {
      this.$router.push({
        name: 'admin_promotion_code_edit_path',
        params: {
          id: promotionCode.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
@import '@/components/admin_common';
</style>
