<template>
  <md-checkbox
    :model="model"
    :value="value"
    @change="$emit('change', !!$event)"
    v-bind="$attrs"
  >
    <slot></slot>
  </md-checkbox>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: true,
    },
    model: {
      type: [String, Number, Boolean, Object],
    },
  },
  model: {
    prop: 'model',
    event: 'change',
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/colors";

.md-checkbox.md-theme-default /deep/ .md-checkbox-container,
.md-checkbox.md-theme-default.md-disabled /deep/ .md-checkbox-container {
  width: 28px;
  min-width: 28px;
  height: 28px;
  border: 1px solid $input-light;
  border-radius: 4px;
  background-color: white;
}

.md-checkbox /deep/ .md-checkbox-container::after {
  top: 6px;
  left: 10px;
  border: 3px solid transparent;
  border-top: 0;
  border-left: 0;
}

.md-checkbox /deep/ .md-checkbox-label {
  padding-top: 4px;
  padding-left: 12px;
  font-size: 16px;
}

.md-checkbox.md-disabled /deep/ .md-checkbox-label {
  color: $input-light;
}

</style>
