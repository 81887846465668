<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="finalized-documents__header">
      <div class="finalized-documents__header-row">
        <h1 class="page-title">Vsi dokumenti</h1>
        <search class="finalized-documents__search" has-search-icon input-label="Išči" @change="setQuery" />
        <v-button class="finalized-documents__add-button" @click="openCreateFinalizedDocument">
          <icon name="plus" :width="20" :height="20" /> Nov dokument
        </v-button>
        <v-button class="finalized-documents__add-button" @click="openCreateEnvelope">
          <icon name="plus" :width="20" :height="20" /> Nova mapa
        </v-button>
      </div>

      <filters
        :filters="filters"
        :available-filters="availableFilters"
        @change="onFilterChange"
      />

      <div class="finalized-documents__actions">
        <v-button :href="selectedFinalizedDocument && selectedFinalizedDocument.pdfDownloadUrl" target="_blank" :disabled="!selectedFinalizedDocument" size="small" outlined>
          <icon name="download" :width="20" :height="20" />
          Prenesi
        </v-button>
        <v-button @click="openEditFinalizedDocument" :disabled="!selectedFinalizedDocument" size="small" outlined>
          <icon name="edit" :width="20" :height="20" />
          Uredi lastnosti
        </v-button>
        <div>
          <v-button disabled size="small" outlined>
            <icon name="crop" :width="20" :height="20" />
            Odpri urejevalnik
          </v-button>
          <v-tooltip>Funkcija je na voljo samo v naročniškem paketu.</v-tooltip>
        </div>
        <div>
          <v-button @click="eSignDocument" :disabled="!selectedFinalizedDocument || !isDocumentSignable" size="small" outlined>
            <icon name="signature" :width="20" :height="20" />
            {{ signatureLabel }}
          </v-button>
          <v-tooltip v-if="signatureTooltip">{{ signatureTooltip }}</v-tooltip>
        </div>
        <v-button @click="removeFinalizedDocument" :disabled="!selectedFinalizedDocument" background-color="red" size="small" outlined>
          <icon name="trash" :width="20" :height="20" />
          Izbriši
        </v-button>
      </div>
    </div>

    <div class="admin__content" v-if="numberOfPages">
      <entity-table
        class="finalized-documents__table"
        ref="table"
        :columns="finalizedDocumentColumns"
        :sort-by="transformedSortBy"
        :items="envelopes"
        :are-items-clickable="false"
        :is-search-present="false"
        :is-add-new-present="false"
        :is-loaded="!!numberOfPages"
        :empty-text="emptyText"
        :pagination="pagination"
        @column-click="setSortBy($event)"
        @page-change="goToPage"
      >
        <finalized-documents-loading v-if="isLoadingEnvelopes" />
        <template v-for="(envelope, index) in augmentedEnvelopes" v-else>
          <envelope
            :key="`envelope-${envelope.id}`"
            :envelope="envelope"
            :custom-attribute-columns="customAttributeColumns"
            :text-finalized-document-columns="textFinalizedDocumentColumns"
            :expanded-envelope-ids="expandedEnvelopeIds"
            @toggle-envelope="toggleEnvelope"
            :class="{ 'envelope__row--even': index % 2 === 0 }"
          />
          <template v-for="finalizedDocument in envelope.finalizedDocuments">
            <finalized-document
              :key="`finalized-document-${finalizedDocument.id}`"
              v-show="expandedEnvelopeIds.includes(envelope.id) && !finalizedDocument.isHidden"
              :finalized-document="finalizedDocument"
              :custom-attribute-columns="customAttributeColumns"
              :text-finalized-document-columns="textFinalizedDocumentColumns"
            />
          </template>
        </template>
      </entity-table>
    </div>
  </div>
</template>

<script>
import { toCamelCase } from 'js-convert-case'
import { mapState, mapGetters, mapActions } from 'vuex'
import EntityTable from '@/components/shared/EntityTable'
import Filters from '@/components/shared/Filters'
import Envelope from './Envelope'
import FinalizedDocument from './FinalizedDocument'
import FinalizedDocumentsLoading from './FinalizedDocumentsLoading'
import VButton from 'design-system/VButton.vue'
import VTooltip from 'design-system/VTooltip.vue'
import Icon from 'design-system/icons/Icon.vue'
import PaginationMixin from '../../../shared/PaginationMixin'
import Search from '@/components/shared/_Search'
import prompt from 'design-system/components/prompt/prompt'

export default {
  props: {
    wasDocumentJustESigned: { type: Boolean, default: false },
    finalizedDocumentId: { type: String, required: false },
  },
  data () {
    return {
      expandedEnvelopeIds: [],
    }
  },
  components: {
    Envelope,
    FinalizedDocument,
    Filters,
    EntityTable,
    FinalizedDocumentsLoading,
    VButton,
    VTooltip,
    Icon,
    Search,
  },
  mixins: [PaginationMixin],
  beforeCreate () {
    this.textFinalizedDocumentColumns = [
      { id: 'name', name: 'Dokument', sortById: 'name' },
      { id: 'truncatedDescription', name: 'Opis' },
      { id: 'counterpartyName', name: 'Stranka', sortById: 'counterpartyName' },
      { id: 'status', name: 'Status' },
      { id: 'formattedEffectiveOn', name: 'Začetek učinkovanja', sortById: 'effectiveOn' },
      { id: 'formattedExpiresOn', name: 'Prenehanje', sortById: 'expiresOn' },
    ]
  },
  created () {
    this.storeModule = ['accountStore', 'finalizedDocuments']

    this.initialize().then(() => {
      if (this.finalizedDocumentId) {
        const filterByFinalizedDocumentId = {
          ...this.idFilter,
          valueId: this.finalizedDocumentId.toString(),
          valueLabel: this.finalizedDocumentId.toString(),
        }
        this.setFilters([filterByFinalizedDocumentId])
      }
    })

    window.addEventListener('scroll', this.updateTableHeaderPosition)
    window.addEventListener('resize', this.updateTableHeaderPosition)
  },
  mounted () {
    if (this.wasDocumentJustESigned) {
      this.setMessage({
        type: 'info',
        text: 'Dokument je bil uspešno podpisan.',
      })
    }
  },
  beforeDestroy () {
    this.reset()
    window.removeEventListener('scroll', this.updateTableHeaderPosition)
    window.removeEventListener('resize', this.updateTableHeaderPosition)
  },
  computed: {
    ...mapState('accountStore/finalizedDocuments', [
      'numberOfPages',
      'availableCustomAttributeKeys',
      'sortBy',
      'filters',
      'query',
      'isLoadingEnvelopes',
      'selectedFinalizedDocument',
    ]),
    ...mapGetters(['accountSlug', 'getAccountIdForPath']),
    ...mapGetters('accountStore/finalizedDocuments', ['envelopes', 'availableFilters', 'idFilter']),
    augmentedEnvelopes () {
      return this.envelopes.map(envelope => {
        envelope.finalizedDocuments.forEach(finalizedDocument => {
          const isDescriptionLong = finalizedDocument.description && finalizedDocument.description.length > 80
          finalizedDocument.truncatedDescription = isDescriptionLong ? finalizedDocument.description.slice(0, 65) + '...' : finalizedDocument.description
          finalizedDocument.isDescriptionTruncated = isDescriptionLong
        })
        const firstFinalizedDocumentData = envelope.finalizedDocuments.length > 0 ? envelope.finalizedDocuments[0] : {}
        const isExpanded = this.expandedEnvelopeIds.includes(envelope.id)

        return {
          ...firstFinalizedDocumentData,
          ...envelope,
          isExpanded: isExpanded,
          icon: isExpanded ? 'arrow-ios-upward' : 'arrow-ios-right',
        }
      })
    },
    customAttributeColumns () {
      return this.availableCustomAttributeKeys.map(customAttributeKey => {
        return {
          id: customAttributeKey.id,
          name: customAttributeKey.label,
        }
      })
    },
    finalizedDocumentColumns () {
      return this.textFinalizedDocumentColumns.concat(this.customAttributeColumns)
    },
    transformedSortBy () {
      if (!this.sortBy || !this.sortBy.replace('-', '').startsWith('finalized_documents')) {
        return null
      }

      const splitSortBy = this.sortBy.split('.')
      const transformedSortBy = toCamelCase(splitSortBy[splitSortBy.length - 1])
      return this.sortBy.startsWith('-') ? `-${transformedSortBy}` : transformedSortBy
    },
    signatureLabel () {
      if (!this.selectedFinalizedDocument) {
        return 'Podpiši'
      }

      if (this.selectedFinalizedDocument.isWaitingESignature) {
        return 'Ponovno pošlji v podpis'
      }

      return this.isDocumentSignable ? 'Podpiši' : 'Podpisan'
    },
    signatureTooltip () {
      if (this.isDocumentSignable || !this.selectedFinalizedDocument) {
        return null
      }

      return 'Dokument je že podpisan.'
    },
    isDocumentSignable () {
      if (!this.selectedFinalizedDocument) {
        return false
      }

      return !this.selectedFinalizedDocument.isESigned && !this.selectedFinalizedDocument.signedOn
    },
    emptyText () {
      if (this.envelopes.length > 0) {
        return null
      }

      const areFiltersPresent = this.filters.filter(f => f.valueId && f.valueId !== '').length > 0 || !!this.query
      return areFiltersPresent ? 'Noben dokument ne ustreza iskanju.' : 'V hrambi ni nobenega dokumenta.'
    },
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments', [
      'initialize',
      'reset',
      'setQuery',
      'setFilters',
      'setSortBy',
      'goToPage',
      'deleteFinalizedDocument',
    ]),
    ...mapActions(['setMessage']),
    onFilterChange (filters) {
      const isFilteringByStatus = filters.filter(f => f.id === 'by_finalized_document_status')
      if (isFilteringByStatus) {
        this.expandedEnvelopeIds = this.envelopes
          .filter(envelope => envelope.finalizedDocuments.length > 1)
          .map(envelope => envelope.id)
      }

      this.setFilters(filters)
    },
    toggleEnvelope (envelopeId) {
      if (this.expandedEnvelopeIds.includes(envelopeId)) {
        this.expandedEnvelopeIds = this.expandedEnvelopeIds.filter(id => id !== envelopeId)
      } else {
        this.expandedEnvelopeIds.push(envelopeId)
      }
    },
    openCreateFinalizedDocument () {
      this.$router.push({
        name: 'account_finalized_document_create_path',
        params: {
          accountId: this.accountSlug,
        },
      })
    },
    openCreateEnvelope () {
      this.$router.push({
        name: 'account_envelope_create_path',
        params: {
          accountId: this.accountSlug,
        },
      })
    },
    openEditFinalizedDocument () {
      this.$router.push({
        name: 'account_finalized_document_edit_path',
        params: {
          id: this.selectedFinalizedDocument.id,
          accountId: this.accountSlug,
        },
      })
    },
    async removeFinalizedDocument () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati ta dokumnet? Akcija je nepovratna.')
      if (result) {
        this.deleteFinalizedDocument(this.selectedFinalizedDocument.id)
      }
    },
    eSignDocument () {
      if (this.selectedFinalizedDocument.isESigned) {
        return
      }

      this.$router.push({
        name: 'account_finalized_document_e_signature_path',
        params: {
          id: this.selectedFinalizedDocument.id,
          accountId: this.getAccountIdForPath(this.selectedFinalizedDocument.accountId),
        },
      })
    },
    // WARNING: this is a hack to make the table header sticky.
    // We cannot simply use the isHeaderSticky prop of EntityTable since
    // this table needs to be scrollable horizontally and that breaks it.
    updateTableHeaderPosition () {
      if (!this.$refs.table) {
        return
      }

      const tableHeaderElement = this.$refs.table.getTableHeaderElement()
      if (!tableHeaderElement) {
        return
      }

      if (document.body.clientWidth < 800) {
        tableHeaderElement.style.position = 'static'
        return
      }

      const offsetTop = window.scrollY - 34
      tableHeaderElement.style.position = 'sticky'
      tableHeaderElement.style['z-index'] = '10'
      tableHeaderElement.style.top = `${offsetTop}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';
@import '@/components/common';
@import '@/components/admin_common';

.admin__content {
  position: relative;

  @include respond-to(small) {
    margin-top: 16px;
  }
}

.finalized-documents__header {
  position: sticky;
  top: 64px;
  z-index: $z_pop;
  background-color: $background_color;

  @include respond-to(small) {
    position: inherit;
    top: 0;
  }
}

.finalized-documents__header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .page-title {
    margin: 0;
    white-space: nowrap;
  }

  & .finalized-documents__search {
    margin-right: 16px;
    margin-left: 32px;
    min-width: 120px;

    /deep/ .md-input {
      width: 120px;
    }
  }

  @include respond-to(small) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;

    & .finalized-documents__search {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.finalized-documents__add-button {
  flex-shrink: 0;

  .icon-wrapper {
    position: relative;
    top: 2px;
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 8px;
  }

  @include respond-to(small) {
    &:last-child {
      margin-top: 8px;
      margin-left: 0;
    }
  }
}

.finalized-documents__actions {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 20px;

  > * {
    margin-top: 8px;
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }

  & button {
  & .icon-wrapper {
    margin-right: 8px;
  }

  // otherwise icons are cut off
   /deep/ & .md-ripple {
      overflow: visible;
      mask-image: none;
    }
  }
}

.finalized-documents__table {
  position: relative;
}
</style>
