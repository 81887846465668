<template>
  <div class="home">
    <hero
      class="home__hero"
      :heading="`Pogodbe in\npravni dokumenti`"
      subheading="Hitro in skladno z zakonodajo."
      button-text="Vsi vzorci in pogodbe"
      @click="openSmartContracts"
    >
      <r-cards class="home__cards" :cards="mostUsedDocuments" @click="openDocumentComposition" />
    </hero>

    <page-section text="Opazujte sprotno prilagajanje vsebine v vzorcu pogodbe.">
      <template #heading>
        Interaktivni urejevalnik vzorcev pogodb
      </template>
      <template #content>
        <br />
        <br />
        <document-composition class="home__live-composition" v-if="mostUsedDocumentId" :id="mostUsedDocumentId" @click.stop.prevent />
      </template>
    </page-section>

    <page-section heading="Kako napisati pogodbo?">
      <template #content>
        <div class="home__how-it-works-container">
          <div class="home__how-it-works-content home__how-it-works-content--aligned-left">
            <div class="home__how-it-works-heading home__how-it-works-heading--number">1.</div>
            <div class="home__how-it-works-heading">Izberite pametni vzorec</div>
            <div class="home__how-it-works-text">Iščite po obsežni bazi vzorcev pogodb in pravnih dokumentov. Pomagajte si s predlogi in filtri.</div>
            <div class="home__how-it-works-link" @click="openSmartContracts">
              <div>Poglejte vzorce</div>
              <icon class="home__how-it-works-link-icon" name="arrow-right" />
            </div>
          </div>
          <img class="home__how-it-works-image" alt="Izberite pametni vzorec" :src="require('app/assets/images/how_it_works_1.png')" />
        </div>

        <div class="home__how-it-works-container">
          <img class="home__how-it-works-image" alt="Odgovorite na vprašanja" :src="require('app/assets/images/how_it_works_2.png')" />
          <div class="home__how-it-works-content home__how-it-works-content--aligned-right">
            <div class="home__how-it-works-heading home__how-it-works-heading--number">2.</div>
            <div class="home__how-it-works-heading">Odgovorite na vprašanja</div>
            <div class="home__how-it-works-text">Besedilo pogodbe se interaktivno prilagaja vašim odgovorom, hkrati pa pogodba na vseh mestih ohrani vse obvezne sestavine.</div>
          </div>
        </div>

        <div class="home__how-it-works-container">
          <div class="home__how-it-works-content home__how-it-works-content--aligned-left">
            <div class="home__how-it-works-heading home__how-it-works-heading--number">3.</div>
            <div class="home__how-it-works-heading">Prenesite dokument</div>
            <div class="home__how-it-works-text">Dokument si prenesete v word ali v pdf obliki.</div>
          </div>
          <img class="home__how-it-works-image" alt="Prenesite dokument" :src="require('app/assets/images/how_it_works_3.png')" />
        </div>

        <div class="home__how-it-works-container">
          <img class="home__how-it-works-image" alt="E-podpišite dokument" :src="require('app/assets/images/how_it_works_4.png')" />
          <div class="home__how-it-works-content home__how-it-works-content--aligned-right">
            <div class="home__how-it-works-heading home__how-it-works-heading--number">4.</div>
            <div class="home__how-it-works-heading">E-podpišite dokument</div>
            <div class="home__how-it-works-text">Pošljite v podpis tudi ostalim podpisnikom.</div>
          </div>
        </div>

        <div class="home__how-it-works-container">
          <div class="home__how-it-works-content home__how-it-works-content--aligned-left">
            <div class="home__how-it-works-heading home__how-it-works-heading--number">5.</div>
            <div class="home__how-it-works-heading">Hranite dokument</div>
            <div class="home__how-it-works-text">Kjerkoli in kadarkoli dostopajte do svojih pogodb na preglednem mestu.</div>
          </div>
          <img class="home__how-it-works-image" alt="Hranite dokument" :src="require('app/assets/images/how_it_works_5.png')" />
        </div>
      </template>
    </page-section>

    <page-section>
      <template #content>
        <banner
          heading="Digitalizirajte pravni oddelek"
          subheading="Pravne rešitve, prilagojene vašemu podjetju."
          button-text="Pošlji povpraševanje"
          @click="openContactForm"
        />
      </template>
    </page-section>

    <page-section :heading="`Zakaj vzorci pogodb Pigenta?`">
      <template #content>
        <feature-tiles :feature-tiles="featureTiles" />
      </template>
    </page-section>

    <page-section heading="Zaupajo nam">
      <template #content>
        <reviews />
      </template>
    </page-section>

    <page-section>
      <template #text>
        <banner
          heading="Ste pripravljeni na kreiranje dokumentov?"
          button-text="Vsi vzorci in pogodbe"
          @click="openSmartContracts"
          class="banner-absolute"
        />
      </template>
    </page-section>

    <email-newsletter id="newsletter" class="email-newsletter" :use-secondary-mailing-list="userCameFromNewsletter" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Hero from './components/Hero.vue'
import Section from './components/Section.vue'
import Banner from './components/Banner.vue'
import FeatureTiles from './components/FeatureTiles.vue'
import Reviews from './components/Reviews.vue'
import DocumentComposition from '../document_composition/DocumentComposition'
import Icon from 'design-system/icons/Icon.vue'
import RCards from 'design-system/components/RCards.vue'
import MailToMixin from '@/components/shared/MailToMixin'

export default {
  components: {
    Hero,
    PageSection: Section,
    Banner,
    FeatureTiles,
    Reviews,
    DocumentComposition,
    Icon,
    RCards,
  },
  mixins: [MailToMixin],
  created () {
    this.trackEvents([
      {
        name: '',
        data: {
          send_to: 'AW-16663620351',
          dynx_pagetype: 'home',
        },
      },
    ])

    this.initialize()
    // TODO: fetch dynamically
    this.mostUsedDocuments = [
      { slug: 'najemna-pogodba', name: 'NAJEMNA POGODBA', description: 'Vzorec najemne pogodbe je primeren tako za najem nepremičnine (stanovanja, sobe, poslovnega prostora ...) kot za najem premičnine (avta, čolna, stvari ...).', price: '29.99' },
      { slug: 'posojilna-pogodba', name: 'POSOJILNA POGODBA', description: 'Za primer brezplačne posoje stvari. Pogodba ni primerna za posojo denarja - v ta namen izberite vzorec Posojilna pogodba.', price: '29.99' },
      { slug: 'podjemna-pogodba', name: 'PODJEMNA POGODBA', description: 'Vzorec podjemne pogodbe je primeren za pripravo pogodbe o delu oziroma pogodbe o poslovnem sodelovanju ter izvajalske pogodbe.', price: '29.99' },
    ]
    this.featureTiles = [
      { heading: 'Pravilnost', text: 'Izognite se napakam in spregledanim podatkom.' },
      { heading: 'Pravna skladnost', text: 'Pametni vzorci so v skladu z najnovejšo zakonodajo.' },
      { heading: 'Hitrost', text: 'Skrajšajte čas priprave pogodb z nekaj dni na nekaj sekund.' },
      { heading: 'Samostojnost', text: 'Pravne dokumente lahko pripravlja kdorkoli znotraj vašega podjetja.' },
      { heading: 'Poenotenost', text: 'Enake pogodbe, ne glede na to, kdo jih pripravlja.' },
      { heading: 'Podatkovna varnost', text: 'Podatki se hranijo v skladu z najnovejšimi zakoni in so zaščiteni z naborom varnostnih funkcij.' },
    ]
  },
  computed: {
    ...mapState('pages', ['documents', 'mostUsedDocumentId', 'query']),
    searchQuery: {
      get () {
        return this.query
      },
      set (newQuery) {
        this.setQuery(newQuery)
      },
    },
    userCameFromNewsletter () {
      return this.$route.hash === '#newsletter'
    },
  },
  methods: {
    ...mapActions(['trackEvents']),
    ...mapActions('pages', ['initialize', 'setQuery']),
    openSmartContracts () {
      this.$router.push({ name: 'smart_contracts_path' })
    },
    openDocumentComposition ({ card, event }) {
      const openInNewTab = event.ctrlKey || event.metaKey
      const newTabArgument = openInNewTab ? '_blank' : '_self'
      const routeData = this.$router.resolve({
        name: 'compose_document_path',
        params: {
          id: card.slug,
        },
      })
      window.open(routeData.href, newTabArgument)
    },
  },
}
</script>

<style scoped lang='scss'>
@import 'app/assets/stylesheets/variables';

.home {
  width: 100%;
  color: $black_text;
  position: relative;

  @include respond-to(small) {
    width: 100vw;
    text-align: center;
  }
}

.home__hero {
  background-color: white;
}

.home__cards {
  display: flex;
  position: relative;
  margin-bottom: 36px;

  @include respond-to(small) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 48px;
  }
}

.home__live-composition {
  text-align: left;
}

.home__how-it-works-container {
  display: flex;
  box-sizing: border-box;
  padding: 112px 0 32px 0;

  &:first-of-type {
    padding-top: 24px;
  }

  @include respond-to(small) {
    padding: 32px 0;
  }
}

.home__how-it-works-content {
  position: relative;
  width: 45%;
  padding: 64px 0;
  text-align: left;

  @include respond-to(small) {
    width: 100%;
    padding: 0;
  }

  &--aligned-right {
    padding-right: 32px;
    padding-left: 88px;

    .home__how-it-works-heading--number {
      left: 48px;
    }
  }

  &--aligned-left {
    padding-right: 32px;
    padding-left: 48px;

    .home__how-it-works-heading--number {
      left: 8px;
    }
  }
}

.home__how-it-works-image {
  width: 55%;

  @include respond-to(small) {
    display: none;
  }
}

.home__how-it-works-heading {
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;

  &--number {
    position: absolute;
  }
}

.home__how-it-works-text {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.home__how-it-works-link {
  display: flex;
  margin-top: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $primary-blue;
  cursor: pointer;
  user-select: none;
}

.home__how-it-works-link-icon {
  width: 22px;
  margin: 6px 0 0 22px;
}

.email-newsletter {
  padding: 0 96px 140px 96px;
  background-color: $white;

  @include respond-to(small) {
    padding: 64px 24px 60px 24px;
  }
}
</style>
