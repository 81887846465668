<template>
  <form novalidate @submit.prevent="submit" v-if="user" class="entity-form">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />
    <user-form-fields ref="userFormFields" :user="user" @change="updateUserObject" />

    <br />
    <div class="u-flex u-space-between">
      <v-button type="submit" class="regular-button" :disabled="isLoading">Shrani</v-button>
      <v-button class="regular-button" @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import UserFormFields from './UserFormFields'
import VButton from 'design-system/VButton.vue'
import VProgressBar from 'design-system/VProgressBar.vue'

export default {
  components: {
    UserFormFields,
    VButton,
    VProgressBar,
  },
  mixins: [BackMixin],
  computed: {
    ...mapState('accountStore/users/forms', ['user']),
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('accountStore/users/forms', ['updateUserObject']),
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.$emit('submit')
    },
    runAllValidations () {
      this.$refs.userFormFields.runAllValidations()
    },
    isValid () {
      return this.$refs.userFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/common';
</style>
