<template>
  <div>
    <recent-finalized-documents />
    <new-document-dialog v-if="isNewDocumentDialogOpen" @close="closeDialog" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NewDocumentDialog from './NewDocumentDialog.vue'
import RecentFinalizedDocuments from '../account/recent_finalized_documents/RecentFinalizedDocuments.vue'

export default {
  components: {
    NewDocumentDialog,
    RecentFinalizedDocuments,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  data () {
    return {
      isNewDocumentDialogOpen: true,
    }
  },
  mounted () {
    this.trackPayment(this.id)
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions('payments', ['trackPayment']),
    closeDialog () {
      this.isNewDocumentDialogOpen = false
      this.$router.push({ name: 'user_profile_path', params: { id: this.user.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./common";
@import "../common";
</style>
