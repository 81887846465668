<template>
  <div>
    <div class="document-composition__input-field" :class="{ 'document-composition__input-field--nested': isNested }">
      <div class="document-composition__input-field-title" v-if="title">
        <label>{{ title }}</label>
        <input-help
          v-if="titleInfo"
          :text="titleInfo"
          :style="{ margin: 'auto' }"
        />
      </div>

      <div v-if="type === 'date'" class="document-composition__datepicker">
        <v-datepicker v-model="value" md-immediately @input="$emit('input', value)" @md-opened="$emit('focus')">
          <label class="document-composition__field-label">{{ text }}</label>
        </v-datepicker>
        <input-help
          v-if="info"
          :text="info"
        />
      </div>

      <div v-else-if="type === 'option'">
        <template v-if="isCheckbox">
          <v-checkbox
            class="document-composition__checkbox"
            v-model="value"
            @change="$emit('input', $event)"
          >
            {{ text }}
            <input-help
              v-if="info"
              :text="info"
            />
          </v-checkbox>
        </template>

        <template v-else>
          <label>{{ text }}</label>
          <input-help
            v-if="info"
            :text="info"
          />
          <v-radio
            class="document-composition__radio"
            v-for="option in options"
            :key="option.id"
            v-model="value"
            :value="option.id"
            @change="$emit('input', option.value)"
          >
            {{ option.label }}
            <input-help
              v-if="option.info"
              :text="option.info"
            />
          </v-radio>
        </template>
      </div>

      <div v-else class="document-composition__flex-field">
        <v-field class="document-composition__field">
          <label class="document-composition__field-label document-composition__field-label--autogrow" :class="{ 'document-composition__textarea-label': type === 'text' }" ref="label">{{ text }}</label>
          <v-input
            v-if="type === 'string'"
            v-model="value"
            ref="input"
            @change="$emit('input', value)"
            @focus="$emit('focus')"
          />
          <v-textarea
            v-if="type === 'text'"
            v-model="value"
            ref="input"
            @change="$emit('input', getTextAreaValue(value))"
            @focus="$emit('focus')"
          />
          <v-input
            v-else-if="type === 'integer' || type === 'float'"
            v-model="value"
            ref="input"
            type="number"
            @change="$emit('input', value)"
            @focus="$emit('focus')"
          />
        </v-field>
        <input-help
          v-if="info"
          :text="info"
          :style="{ margin: '25px auto 0 auto', 'margin-left': '8px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputHelp from '@/components/shared/InputHelp'
import VRadio from 'design-system/VRadio.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import VDatepicker from 'design-system/VDatepicker.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import VTextarea from 'design-system/VTextarea.vue'

export default {
  components: {
    InputHelp,
    VRadio,
    VCheckbox,
    VDatepicker,
    VField,
    VInput,
    VTextarea,
  },
  props: {
    id: { type: Number, default: null },
    title: { type: String, default: null },
    text: { type: String, required: true },
    info: { type: String, default: null },
    titleInfo: { type: String, default: null },
    type: { type: String, required: true },
    defaultValue: { type: [String, Number, Boolean, Date], default: null },
    options: { type: Array, default: () => [] },
    isCheckbox: { type: Boolean, default: false },
    isNested: { type: Boolean, default: false },
    isSmartField: { type: Boolean, default: false },
  },
  data () {
    return {
      value: null,
    }
  },
  beforeMount () {
    this.value = this.defaultValue
  },
  mounted () {
    if (!this.$refs.label || !this.$refs.input) {
      return
    }

    // Ensure that multi-row labels are displayed.
    const height = this.$refs.label.clientHeight
    this.$refs.input.$el.style.height = `${height + 26}px`
  },
  watch: {
    defaultValue: {
      handler (newDefaultValue) {
        if (this.isSmartField && newDefaultValue) {
          this.value = newDefaultValue
        }
      },
    },
  },
  methods: {
    flipAndEmitCheckboxValue () {
      if (this.value == null) {
        this.value = false
      }

      this.value = !this.value
      this.$emit('input', this.value)
    },
    getTextAreaValue (value) {
      // We need this transform because this will get transformed to markdown.
      let transformedValue = ''
      let isFirstPart = true
      value.split('\n').forEach(valuePart => {
        if (isFirstPart) {
          transformedValue += valuePart
          isFirstPart = false
          return
        }

        // unordered lists need one newline while newlines on 'plain text need two'
        if (valuePart.startsWith('-')) {
          transformedValue += `\n${valuePart}`
        } else {
          transformedValue += `\n\n${valuePart}`
        }
      })

      return transformedValue
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/colors';

.document-composition__input-field {
  margin-top: 16px;
  font-size: 15px;

  &--nested {
    margin-top: 0;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-left: 16px;
  }
}

.document-composition__field {
  display: block;
  margin-bottom: 4px;

  input {
    width: 100%;
  }

  // Focused and filled in field moves the label so the label should no longer auto-grow.
  &.md-focused,
  &.md-has-value {
    .document-composition__field-label--autogrow {
      height: 20px;
    }
  }
}

.document-composition__radio,
.document-composition__checkbox {
  display: flex;
  padding: 0 16px;
}

.document-composition__datepicker {
  display: flex;
}

.document-composition__flex-field {
  display: flex;
}

.document-composition__field-label {
  width: 85%;
  height: 20px;
  top: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--autogrow {
    height: fit-content;
    white-space: break-spaces;
  }
}

.document-composition__textarea-label {
  height: auto;
  white-space: break;
}

.md-focused,
.md-has-value {
  .document-composition__field-label {
    height: 18px;
    white-space: nowrap;
  }
}

.md-textarea {
  width: 100%;
}
</style>

<style lang="scss">
.document-composition__datepicker {
  .md-field {
    margin-bottom: -2px;
  }
}

.document-composition__input-field--nested {
  .md-field {
    margin-top: -14px;
  }
}
</style>
