<template>
  <div class="account-selector" v-click-outside="closeAccountSelector">
    <div class="account-selector__selected-account" @click="toggleAccountSelector">
      <div class="account-selector__avatar">
        <img class="account-selector__avatar-image" v-if="accountLogoBase64String" :src="accountLogoBase64String" />
        <div class="account-selector__avatar-background" v-else />
      </div>
      {{ userOrAccountName }}
      <icon v-if="multipleAccounts" class="account-selector__icon" :name="isAccountSelectorOpen ? 'arrow-ios-upward' : 'arrow-ios-downward'" />
    </div>
    <div class="account-selector__dropdown" v-if="isAccountSelectorOpen">
      <div
        class="account-selector__account-item"
        @click.stop="selectAccount(null)"
        :class="{ 'account-selector__account-item--selected': account === null }"
      >
        {{ user.fullName }}
      </div>
      <div
        class="account-selector__account-item"
        v-for="availableAccount in availableAccounts"
        :key="availableAccount.id"
        @click.stop="selectAccount(availableAccount.id)"
        :class="{ 'account-selector__account-item--selected': availableAccount.id === (account && account.id) }"
      >
        <img class="account-selector__account-image" v-if="getAccountLogoBase64String(availableAccount)" :src="getAccountLogoBase64String(availableAccount)" />
        <div class="account-selector__account-name">{{ availableAccount.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Icon from 'design-system/icons/Icon.vue'

export default {
  data () {
    return {
      isAccountSelectorOpen: false,
    }
  },
  components: { Icon },
  props: {
    account: { type: [Object, null] },
    availableAccounts: { type: Array, required: true },
  },
  computed: {
    ...mapState(['user']),
    multipleAccounts () {
      return this.availableAccounts.length > 0
    },
    userOrAccountName () {
      return this.account ? this.account.name : this.user.fullName
    },
    accountLogoBase64String () {
      return this.getAccountLogoBase64String(this.account)
    },
  },
  methods: {
    selectAccount (accountId) {
      this.$emit('select', accountId)
      this.isAccountSelectorOpen = false
    },
    toggleAccountSelector () {
      if (!this.multipleAccounts) {
        return
      }
      if (this.isAccountSelectorOpen === true) {
        this.closeAccountSelector()
      } else {
        this.isAccountSelectorOpen = true
      }
    },
    closeAccountSelector () {
      this.isAccountSelectorOpen = false
    },
    getAccountLogoBase64String (account) {
      if (!account) {
        return null
      }
      return account.logoBase64String || account.logoBase64string // camelKeys handling is different in JS and in Ruby :/
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

$hover-background-color: #F1F2F7;
.account-selector {
  position: relative;
  padding: 4px 15px 4px 10px;
  border: 1px solid $input-light;
  border-radius: 100px;
  background: $white;
  color: $modrn-blue-dark;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.account-selector__selected-account {
  display: flex;
  align-items: center;
  user-select: none;
}

.account-selector__dropdown {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  width: 100%;
  padding-top: 30px;
  border-radius: 0 0 8px 8px;
  background-color: white;
  // stylelint-disable-next-line
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  user-select: none;
}

.account-selector__account-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 14px 8px;
  margin: 8px 5px;
  color: $modrn-blue-dark;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  &--selected,
  &:hover {
    border-radius: 8px;
    background-color: $hover-background-color;
  }
}

.account-selector__account-image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  object-fit: contain;
}

.account-selector__icon {
  display: inline;
  margin: 6px 0 0 25px;

  @include respond-to(small) {
    margin: 6px 0 0 4px;
  }
}

.account-selector__avatar {
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.account-selector__avatar-image {
  object-fit: contain;
}

.account-selector__avatar-background {
  width: 100%;
  height: 100%;
  background: $modrn-blue;
  border-radius: 50%;
}
</style>
