<template>
  <div class="b2b">
    <hero
      :heading="`Avtomatizirajte \npravne procese`"
      subheading="Prihranite čas in povečajte učinkovitost."
      color="green"
      :has-image="true"
    />

    <page-section heading="" color="green">
      <template #content>
        <extensions />
      </template>
    </page-section>

    <page-section heading="Digitalizirajte sklepanje poslov" color="green">
      <template #content>
        <feature-tiles :feature-tiles="featureTiles" :is-automatic-numbering-enabled="true" />
      </template>
    </page-section>

    <div class="contact-form-wrapper" ref="contactForm">
      <div class="contact-form-container">
        <contact-form :is-sent="isMessageSent" @submit="sendContactMessage" />
      </div>
    </div>

    <page-section heading="Zaupajo nam" color="green">
      <template #content>
        <reviews />
      </template>
    </page-section>

    <page-section class="banner-absolute__section" color="green">
      <template #text>
        <banner
          heading="Ste pripravljeni na kreiranje dokumentov?"
          button-text="Vsi vzorci in pogodbe"
          color="green"
          @click="openSmartContracts"
          class="banner-absolute"
        />
      </template>
    </page-section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Hero from './components/Hero.vue'
import Extensions from './components/Extensions.vue'
import ContactForm from './components/ContactForm.vue'
import Section from './components/Section.vue'
import Banner from './components/Banner.vue'
import FeatureTiles from './components/FeatureTiles.vue'
import Reviews from './components/Reviews.vue'
import MailToMixin from '@/components/shared/MailToMixin'

export default {
  components: {
    Hero,
    Extensions,
    ContactForm,
    PageSection: Section,
    Banner,
    FeatureTiles,
    Reviews,
  },
  mixins: [MailToMixin],
  data () {
    return {
      isMessageSent: false,
    }
  },
  created () {
    this.featureTiles = [
      { heading: 'Kontaktirajte nas', iconName: 'send' },
      { heading: 'Prepoznali bomo\nvaše potrebe', iconName: 'people' },
      { heading: 'Prejmite demo\ndostop', iconName: 'pc' },
    ]
  },
  mounted () {
    const section = this.$router.currentRoute.hash
    if (!section) {
      return
    }

    if (section === '#kontakt') {
      this.$nextTick(() => this.$refs.contactForm.scrollIntoView())
    }
  },
  methods: {
    ...mapActions('pages', ['sendMessage']),
    sendContactMessage (message) {
      this.sendMessage(message)
        .then(() => this.isMessageSent = true)
    },
    openSmartContracts () {
      this.$router.push({ name: 'smart_contracts_path' })
    },
  },
}
</script>

<style scoped lang='scss'>
@import 'app/assets/stylesheets/variables';
@import './absolute_banner.scss';

.b2b {
  width: 100%;
  color: $black_text;
  position: relative;

  @include respond-to(small) {
    text-align: center;
  }
}

.contact-form-wrapper {
  padding: 64px 96px;
  background-color: $modrn-green-dark;

  @include respond-to(small) {
    padding: 32px 24px;
  }
  @include respond-to(medium) {
    padding: 64px 160px;
  }
  @include respond-to(large) {
    padding: 64px 256px;
  }
}

.contact-form-container {
  max-width: 680px;
  margin: auto;
}
</style>

<style lang='scss'>
@import 'app/assets/stylesheets/variables';

.b2b.b2b {
  ::selection {
    background-color: $modrn-green-dark;
    color: $white;
  }
}
</style>
