<template>
  <div>
    <payment-page :id="id" />
    <payment-failed-dialog @close="goToPaymentPage" />
  </div>
</template>

<script>
import PaymentFailedDialog from './PaymentFailedDialog.vue'
import PaymentPage from './PaymentPage.vue'

export default {
  components: {
    PaymentFailedDialog,
    PaymentPage,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  methods: {
    goToPaymentPage () {
      this.$router.push({
        name: 'finalized_document_payment',
        params: {
          id: this.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./common";
@import "../common";
</style>
