<template>
  <div class="blog page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="blog__heading">
      <h1 class="blog__heading__title">Blog</h1>
      <v-field class="blog__search">
        <icon class="blog__search-icon" name="search" /><label class="blog__search-label">Išči po blogu</label>
        <v-input v-model="searchQuery"></v-input>
      </v-field>
    </div>

    <template v-if="blogPosts.length > 0">
      <blog-card-view
        :blog-posts="blogPosts"
        @blog-post-click="openBlogPost"
      />
      <div class="blog__load-more" v-if="!isLastPage">
        <v-button @click="fetchMoreBlogs" :disabled="isLoading">Prikaži več</v-button>
      </div>
    </template>

    <div class="blog__empty" v-if="blogPosts.length === 0 && !isLoading">
      {{ emptyBlogMessage }}
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'
import Icon from 'design-system/icons/Icon.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import VButton from 'design-system/VButton.vue'
import BlogCardView from './BlogCardView.vue'

export default {
  components: {
    BlogCardView,
    Icon,
    VField,
    VInput,
    VButton,
  },
  created () {
    this.initialize()
  },
  watch: {
    query: {
      handler () {
        window.scrollTo(0, 0)
      },
    },
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('pages/blog', ['blogPosts', 'query']),
    ...mapGetters('pages/blog', ['isLastPage']),
    searchQuery: {
      get () {
        return this.query
      },
      set (newQuery) {
        this.debounceSetQuery(newQuery)
      },
    },
    emptyBlogMessage () {
      if (this.query) {
        return 'Noben blog se ne ujema z iskanjem'
      } else {
        return 'Prihaja kmalu!'
      }
    },
  },
  methods: {
    ...mapActions('pages/blog', ['initialize', 'setQuery', 'fetchMoreBlogPosts']),
    openBlogPost ({ blogPost, event }) {
      const openInNewTab = event.ctrlKey || event.metaKey
      const newTabArgument = openInNewTab ? '_blank' : '_self'
      const routeData = this.$router.resolve({
        name: 'blog_post_path',
        params: {
          id: blogPost.slug,
        },
      })
      window.open(routeData.href, newTabArgument)
    },
    handleSearch (searchQuery) {
      this.setQuery(searchQuery)
    },
    debounceSetQuery: debounce(function (query) {
      this.setQuery(query)
    }, 250),
  },
}
</script>

<style scoped lang="scss">
@import "../common";
@import "../../common";

.blog.page-content {
  background-color: $white;
}

.blog hr {
  border: 0;
  border-bottom: 1px solid $input-light;
}

.blog__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to(small) {
    display: block;
  }

  & .blog__heading__title {
    margin-right: 52px;
    color: $black;
    font-size: 40px;
    font-weight: 600;

    @include respond-to(small) {
      margin-bottom: 16px;
    }
  }

  @include respond-to(small) {
    padding: 16px 0;
  }
}

// TODO: Unify with EntityTable.vue
.blog__search {
  .md-input {
    padding-left: 58px;
    border: 1px solid $input-light;
    background: $white;
  }

  & .icon-wrapper {
    position: absolute;
    top: 28px;
    left: 22px;
  }

  & label:not(.md-focused) {
   margin-left: 44px;
  }

  &.md-has-value, &.md-focused {
    & label {
      margin-left: 0;
    }
  }
}

.blog__empty {
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.blog__search-label {
  height: 23px;
  overflow: hidden;
}

.blog__load-more {
  width: 100%;
  margin-top: 32px;
  text-align: center;
}
</style>
