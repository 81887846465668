<template>
  <md-textarea v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </md-textarea>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss">
@import "design-system/styles/input";

.md-field + .md-has-textarea:not(.md-autogrow) {
  margin-top: 16px;
}

.md-field .md-textarea {
  @include input-box;
}

.md-field .md-textarea:focus {
  @include input-focus;
}

.md-invalid.md-field .md-textarea {
  @include input-error;
}

.md-field.md-has-textarea:not(.md-autogrow).md-focused label,
.md-field.md-has-textarea:not(.md-autogrow).md-has-value label {
  top: -6px;
  left: 0;
  margin: 0;
  font-size: 12px;
}

.md-field > .md-icon ~ .md-textarea,
.md-field > .md-icon ~ .md-field.md-has-textarea:not(.md-autogrow) label {
  padding-left: 36px;
  margin-left: 0;
}
</style>
