<template>
  <div class="filters">
    <div class="filters__chips">
      <v-button class="filters__add-button" size="small" background-color="azureish-white" @click="openFilterMenu">
        <icon name="plus" :width="18" :height="18" />Filter
      </v-button>
      <r-tag
        v-for="filter in augmentedFilters"
        :key="filter.id"
        closable
        @close="removeFilter(filter.id)"
        background-color="yellow"
      >
        {{ filter.chipString }}
      </r-tag>
    </div>

    <div class="filters__menu" v-if="isFilterMenuOpen" v-click-outside="closeFilterMenu">
      <div class="filters__menu__add">
        <filters-configurator
          :available-filters="availableFiltersToSelect"
          @add-filter="addFilter"
          @datepicker-open="closeFilterMenuOnClickOutside = false"
          @datepicker-closed="closeFilterMenuOnClickOutside = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy } from 'lodash-es'
import FiltersConfigurator from './FiltersConfigurator'
import VButton from 'design-system/VButton.vue'
import RTag from 'design-system/components/RTag.vue'
import Icon from 'design-system/icons/Icon.vue'

export default {
  data () {
    return {
      currentFilters: [],
      isFilterMenuOpen: false,
      closeFilterMenuOnClickOutside: true,
    }
  },
  props: {
    filters: { type: Array, required: true },
    availableFilters: { type: Array, required: true },
  },
  components: {
    FiltersConfigurator,
    VButton,
    RTag,
    Icon,
  },
  watch: {
    filters: {
      handler (newFilters) {
        this.currentFilters = newFilters
      },
      immediate: true,
    },
  },
  computed: {
    augmentedFilters () {
      const uniqueCurrentFilters = uniqBy(this.currentFilters, 'id')
      return uniqueCurrentFilters.map(filter => {
        let formattedValue = filter.valueLabel
        if (filter.type === 'date') {
          const values = formattedValue
            .split('-')
            .map(v => {
              if (v) {
                return (new Date(v)).toLocaleDateString('sl')
              } else {
                return ''
              }
            })
          formattedValue = values.join(' - ')
        }

        return {
          ...filter,
          chipString: `${filter.label}: ${formattedValue}`,
        }
      })
    },
    availableFiltersToSelect () {
      return this.availableFilters.filter(filter => this.currentFilters.filter(f => f.id === filter.id).length === 0)
    },
  },
  methods: {
    addFilter (filter) {
      this.currentFilters.push(filter)
      this.emitFilters()
      this.closeFilterMenu()
    },
    removeFilter (filterId) {
      this.currentFilters = this.currentFilters.filter(f => f.id !== filterId)
      this.emitFilters()
    },
    emitFilters () {
      this.$emit('change', this.currentFilters)
    },
    openFilterMenu () {
      this.isFilterMenuOpen = true
    },
    closeFilterMenu () {
      if (this.closeFilterMenuOnClickOutside) {
        this.isFilterMenuOpen = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.filters {
  position: relative;

  & .filters__chips {
    > :not(:last-child) {
      margin-right: 8px;

      @include respond-to(small) {
        margin-bottom: 8px;
      }
    }

    & .filters__add-button {
      & .icon-wrapper {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }

  & .filters__menu {
    position: absolute;
    z-index: $z_overlay;
    width: fit-content;
    padding: 12px;
    border-radius: 8px;
    margin-top: 6px;
    background: $white;
    // stylelint-disable-next-line color-function-notation, alpha-value-notation
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08), 0 0 1px rgba(67, 90, 111, 0.47);

    & .filters__menu__add {
      & .filters__open-dropdown-button {
        // stylelint-disable-next-line color-function-notation, alpha-value-notation
        border: 1px solid rgba(4, 87, 139, 0.5);
        background: $background-color;
        color: $primary-blue;

        & .icon-wrapper {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
    }

    & .filters__menu__actions {
      margin: 20px 0 0;
      text-align: right;
    }
  }

  &__search {
    min-width: 25%;
    height: fit-content;
  }
}

.filters__add-label.filters__add-label.filters__add-label.filters__add-label.filters__add-label.filters__add-label {
  position: relative;
  width: 60px;
  min-width: 60px;
  height: 24px;
  min-height: 24px;
  border-color: transparent;
  border-radius: 4px;
  margin: 0 8px;
  background-color: $gray_text;
  cursor: pointer;
  font-size: 11px;
  user-select: none;
}
</style>
