<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="admin__header">
      <h1 class="page-title">Uredi Uporabnika</h1>
      <v-button class="delete-button" :disabled="isLoading" @click="onDelete" background-color="red" outlined>Izbriši</v-button>
    </div>
    <user-form @submit="submitUser" @delete="onDelete" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserForm from './forms/UserForm'
import VButton from 'design-system/VButton.vue'
import prompt from 'design-system/components/prompt/prompt'

export default {
  props: {
    id: { type: [String, Number], required: true },
  },
  components: {
    UserForm,
    VButton,
  },
  created () {
    this.initialize(this.id)
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('admin/users/forms', ['initialize', 'reset', 'submitUser', 'deleteUser']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati uporabnika? Akcija bo nepovratno izbrisala uporabnika in vse njegove podatke!')
      if (result) {
        this.deleteUser(this.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
