<template>
  <form class="finalized-document__form" novalidate @submit.prevent="submit" v-if="finalizedDocument">
    <v-progress-bar md-mode="indeterminate" v-if="isLoading" />
    <finalized-document-form-fields
      ref="finalizedDocumentFormFields"
      :finalized-document="finalizedDocument"
      :envelopes="availableEnvelopes"
      :custom-attribute-keys="availableCustomAttributeKeys"
      @uploading="isUploading = true"
      @uploaded="isUploading = false"
      @change="updateFinalizedDocumentObject"
    />

    <br />
    <div class="u-flex u-space-between">
      <div>
        <v-button type="submit" :disabled="isSavingDisabled">Shrani</v-button>
        <v-tooltip v-if="isUploading" md-direction="top">Datoteka se prenaša...</v-tooltip>
      </div>
      <v-button @click="goBack">Nazaj</v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import ValidationMixin from '@/components/shared/ValidationMixin'
import FinalizedDocumentFormFields from './FinalizedDocumentFormFields'
import VButton from 'design-system/VButton.vue'
import VProgressBar from 'design-system/VProgressBar.vue'
import VTooltip from 'design-system/VTooltip.vue'

export default {
  data () {
    return {
      isUploading: false,
    }
  },
  components: {
    FinalizedDocumentFormFields,
    VButton,
    VProgressBar,
    VTooltip,
  },
  mixins: [BackMixin, ValidationMixin],
  computed: {
    ...mapState('accountStore/finalizedDocuments/forms', ['availableEnvelopes', 'availableCustomAttributeKeys']),
    ...mapState(['isLoading']),
    ...mapGetters('accountStore/finalizedDocuments/forms', ['finalizedDocument']),
    isSavingDisabled () {
      return this.isLoading || this.isUploading
    },
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments/forms', ['updateFinalizedDocumentObject']),
    submit () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }
      if (this.isUploading) {
        return
      }

      this.$emit('submit')
    },
    runAllValidations () {
      this.$refs.finalizedDocumentFormFields.runAllValidations()
      this.focusFirstError()
    },
    isValid () {
      if (!this.$refs.finalizedDocumentFormFields) {
        return false
      }

      return this.$refs.finalizedDocumentFormFields.isValid()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';

.finalized-document__form {
  padding: 24px 32px;
  border-radius: 6px;
  background: $white;
}
</style>
