<template>
  <div class="cards">
    <r-card
      v-for="card in cards"
      :key="card.id"
      @click="$emit('click', { card, event: $event })"
      :title="card.name"
      :text="card.description"
      :subtitle="card.subtitle"
      button-text="Uredi vzorec"
      :base64-image="card.logoBase64String"
    />
  </div>
</template>

<script>
import RCard from './RCard.vue'

export default {
  props: {
    cards: { type: Array },
  },
  components: { RCard },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.cards {
  display: grid;
  place-content: center;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 270px));
  margin-top: 32px;
  text-align: left;
}
</style>
