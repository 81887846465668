<template>
  <div class="text">
    <h3>Odstop potrošnika od pogodbe</h3>
    <br />
    Potrošnik (navedeno velja izključno za fizične osebe, ki dokument (digitalno vsebino) naročijo za namene izven svoje pridobitne dejavnosti) ima pravico, da v 14 dneh od dne od dne, ko mu prodajalec omogoči dostop do dokumenta, prodajalcu preko e-naslova info@pigenta.com ali s pošiljanjem odstopne izjave na standardnem obrazcu sporoči, da odstopa od pogodbe, ne da bi mu bilo treba navesti razlog za svojo odločitev. Rok se začne šteti en dan po datumu, ko je podjetje potrošniku omogočilo dostop do dokumenta. Potrošnik od trenutka odstopa od pogodbe dokumentov ne sme več uporabljati, vse digitalne kopije zadevnega dokumenta pa mora najkasneje v roku 1 dne od dne odstopa od pogodbe uničiti. Z dnem, ko podjetje prejme odstopno izjavo, potrošniku onemogoči dostop do zadevnega dokumenta.
    <br />
    Potrošnik nima pravice do odstopa od pogodbe, pri pogodbah o dobavi dokumenta (digitalne vsebine), ki se ne dostavi na otipljivem nosilcu podatkov, če se je opravljanje storitve začelo na podlagi potrošnikovega izrecnega predhodnega soglasja in s privolitvijo.
    <br />
    <br />

    <div class="button-container">
      <v-button class="regular-button" @click="goToFormPath">
        Prenesi obrazec za odstop od pogodbe
      </v-button>
    </div>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'

export default {
  components: { VButton },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
    goToFormPath () {
      window.open('/obrazec_odstop_od_pogodbe.docx')
    },
  },
}
</script>

<style scoped lang='scss'>
@import '../common';

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
</style>
