<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="admin__header">
      <h1 class="page-title">Dodaj Uporabnika</h1>
    </div>
    <user-form v-show="!isLoading" @submit="submitUser" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserForm from './forms/UserForm'

export default {
  props: {
    accountId: { type: [String, Number], required: true },
  },
  components: {
    UserForm,
  },
  created () {
    this.initialize({ accountId: this.accountId })
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    ...mapActions('accountStore/users/forms', ['initialize', 'reset', 'submitUser']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
@import '@/components/common';
</style>
