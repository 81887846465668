import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'

const state = {
  accountId: null,
  userId: null,
  user: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    userId (state) {
      if (state.userId) {
        return state.userId
      }
      if (state.user) {
        return state.user.id
      }

      return null
    },
  },
  actions: {
    initialize ({ commit, dispatch }, { accountId, userId }) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_ACCOUNT_ID', accountId)
      commit('SET_USER_ID', userId)

      const promises = []
      if (userId) {
        promises.push(dispatch('fetchUser'))
      } else {
        commit('SET_USER', {})
      }

      return Promise.all(promises).finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchUser ({ commit, state }) {
      if (!state.userId || !state.accountId) {
        throw new Error('userId and accountId should be set in state')
      }

      return axios
        .get(`/account/${state.accountId}/users/${state.userId}?expand=1`)
        .then(response => commit('SET_USER', response.data))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju uporabnika, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateUserObject ({ commit }, newUser) {
      commit('UPDATE_USER_OBJECT', newUser)
    },
    submitUser ({ commit, dispatch, state }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewUser = state.userId == null
      const promise = isCreatingNewUser ? dispatch('createUser') : dispatch('updateUser')

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Uporabnik uspešno shranjen.', type: 'success' }, { root: true })
          if (isCreatingNewUser) {
            router.push({ name: 'account_users_path' })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju uporabnika - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createUser ({ commit, state }) {
      if (!state.accountId) {
        throw new Error('accountId should be set in state')
      }

      const accountMembershipsAttributes = [{ account_id: state.accountId, is_account_admin: state.user.isAccountAdmin }]
      const payload = {
        user: {
          ...snakeKeys(state.user, { recursive: true, recursiveInArray: true }),
          account_memberships_attributes: accountMembershipsAttributes,
        },
      }
      return axios
        .post(`/account/${state.accountId}/users`, payload)
        .then(response => commit('SET_USER_ID', response.data.id))
    },
    updateUser ({ state }) {
      if (!state.userId || !state.accountId) {
        throw new Error('userId and accountId should be set in state')
      }

      const payload = {
        user: snakeKeys(state.user, { recursive: true, recursiveInArray: true }),
      }
      return axios.put(`/account/${state.accountId}/users/${state.userId}`, payload)
    },
    deleteUser ({ commit, state, dispatch }, userId) {
      if (!state.accountId) {
        throw new Error('accountId should be set in state')
      }

      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/account/${state.accountId}/users/${userId}`)
        .then(() => router.push({ name: 'account_users_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju uporabnika, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_ACCOUNT_ID (state, accountId) {
      state.accountId = accountId
    },
    SET_USER_ID (state, userId) {
      state.userId = userId
    },
    SET_USER (state, user) {
      state.user = camelKeys(user, { recursive: true, recursiveInArray: true })
    },
    UPDATE_USER_OBJECT (state, newUser) {
      state.user = {
        ...state.user,
        ...newUser,
        accountId: newUser.account && newUser.account.id ? newUser.account.id : null,
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
