import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['accountSlug']),
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments', ['deleteFinalizedDocument']),
    getFinalizedDocumentWithStatus (finalizedDocument, showPurchaseStatus = false) {
      let iconName = null
      let statusText = null
      let statusClass = null
      let hasArrowIcon = false
      if (!finalizedDocument.isPurchased) {
        statusText = showPurchaseStatus ? 'Plačaj dokument' : '/'
        hasArrowIcon = showPurchaseStatus
      } else if (finalizedDocument.status === 'warning') {
        statusText = 'Poteče kmalu'
        statusClass = 'status--warning'
      } else if (finalizedDocument.status === 'expired') {
        statusText = 'Neveljavno'
        statusClass = 'status--expired'
      } else if (finalizedDocument.isESigned || finalizedDocument.signedOn) {
        iconName = 'checkmark-circle-green'
        statusText = 'Veljavno'
        statusClass = 'status--signed'
      } else if (finalizedDocument.isWaitingESignature) {
        statusText = `V teku (${finalizedDocument.signingProgress})`
        statusClass = 'status--waiting-signature'
      } else if (finalizedDocument.isPurchased) {
        statusText = 'Podpiši'
        statusClass = 'status--signable'
        hasArrowIcon = true
      }

      return {
        ...finalizedDocument,
        iconName,
        statusText,
        statusClass,
        hasArrowIcon,
      }
    },
    openPreviewFinalizedDocument (finalizedDocumentId, openInNewTab = false) {
      const routeData = {
        name: 'account_finalized_document_view_path',
        params: {
          id: finalizedDocumentId,
          accountId: this.accountSlug,
        },
      }
      if (openInNewTab) {
        window.open(this.$router.resolve(routeData).href, '_blank')
      } else {
        this.$router.push(routeData)
      }
    },
    openPreviewFinalizedDocumentPdf (finalizedDocument) {
      window.open(finalizedDocument.previewUrl, '_blank')
    },
    formatColumnValue (value, isDate) {
      if (!isDate) {
        return value
      }

      const dateValue = new Date(value)
      return isNaN(dateValue) ? value : dateValue.toLocaleDateString('sl')
    },
    onStatusClick (finalizedDocument) {
      this.$router.push({
        name: 'account_finalized_document_e_signature_path',
        params: {
          id: finalizedDocument.id,
          accountId: this.getAccountIdForPath(finalizedDocument.accountId),
        },
      })
    },
  },
}
