import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'

const state = {
  blogPostId: null,
  blogPost: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    blogPostPayload (state) {
      return (blogPost) => {
        // we need to remove existing blog posts contents if they were removed from the form
        const existingBlogPostContents = state.blogPost && state.blogPost.blogPostContents ? state.blogPost.blogPostContents : []
        existingBlogPostContents.forEach(blogPostContent => {
          if (blogPost.blogPostContentsAttributes.find(bpc => bpc.id === blogPostContent.id) == null) {
            blogPost.blogPostContentsAttributes.push({ id: blogPostContent.id, destroy: true })
          }
        })

        // snakeKeys will remove trailing _ from _destroy property :/
        const blogPostPayload = snakeKeys(blogPost, { recursive: true, recursiveInArray: true })
        blogPostPayload.blog_post_contents_attributes = blogPostPayload.blog_post_contents_attributes.map(attribute => {
          if (attribute.destroy) {
            return {
              ...attribute,
              _destroy: true,
            }
          } else {
            return attribute
          }
        })

        return {
          blog_post: blogPostPayload,
        }
      }
    },
  },
  actions: {
    initialize ({ commit, dispatch }, blogPostId) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_BLOG_POST_ID', blogPostId)

      return dispatch('fetchBlogPost')
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju bloga, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    async fetchBlogPost ({ commit, state }) {
      if (!state.blogPostId) {
        return
      }

      return axios
        .get(`/blog_posts/${state.blogPostId}?expand=1`)
        .then(response => commit('SET_BLOG_POST', response.data))
    },
    submitBlogPost ({ dispatch, commit, state }, blogPost) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const isCreatingNewBlogPost = state.blogPostId == null
      const promise = isCreatingNewBlogPost ?
        dispatch('createBlogPost', blogPost) :
        dispatch('updateBlogPost', blogPost)
          .then(() => dispatch('initialize', state.blogPostId))

      return promise
        .then(() => {
          commit('SET_MESSAGE', { text: 'Blog uspešno shranjen.', type: 'success' }, { root: true })
          if (isCreatingNewBlogPost) {
            router.push({ name: 'admin_blog_posts_path' })
          }
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text:`Napaka pri shranjevanju bloga - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createBlogPost ({ commit, getters }, blogPost) {
      return axios
        .post('/blog_posts', getters.blogPostPayload(blogPost))
        .then(response => commit('SET_BLOG_POST_ID', response.data.id))
    },
    updateBlogPost ({ state, getters }, blogPost) {
      if (!state.blogPostId) {
        throw new Error('Cannot update a blog post without having the id')
      }

      return axios.put(`/blog_posts/${state.blogPostId}`, getters.blogPostPayload(blogPost))
    },
    deleteBlogPost ({ commit, dispatch }, blogPostId) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete(`/blog_posts/${blogPostId}`)
        .then(() => router.push({ name: 'admin_blog_posts_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju bloga, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_BLOG_POST (state, blogPost) {
      state.blogPost = camelKeys(blogPost, { recursive: true, recursiveInArray: true })
    },
    SET_BLOG_POST_ID (state, blogPostId) {
      state.blogPostId = blogPostId
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
