<template>
  <md-tooltip v-bind="$attrs" v-on="$listeners" :class="classes">
    <slot></slot>
  </md-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    classes () {
      const classes = []
      switch (this.$attrs['md-direction']) {
      case 'top':
        classes.push('v-tooltip-top')
        break
      case 'right':
        classes.push('v-tooltip-right')
        break
      case 'bottom':
        classes.push('v-tooltip-bottom')
        break
      case 'left':
        classes.push('v-tooltip-left')
        break
      default:
        classes.push('v-tooltip-bottom')
      }
      return classes.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.md-tooltip.md-tooltip {
  max-width: 300px;
  width: fit-content;
  height: fit-content;
  padding: 10px 16px;
  border-radius: 10px;
  background-color: $modrn-blue-dark-90;
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: rgba(33, 36, 41, 5%);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  z-index: $z-heaven;
  white-space: break-spaces;

  @include respond-to(small) {
    max-width: 90vw;
  }

  &.v-tooltip-top,
  &.v-tooltip-right,
  &.v-tooltip-bottom,
  &.v-tooltip-left {
    &::before {
      position: absolute;
      content: "";
    }
  }

  &.v-tooltip-top,
  &.v-tooltip-bottom {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.v-tooltip-right,
  &.v-tooltip-left {
    &::before {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.v-tooltip-top {
    &::before {
      bottom: -7px;
      border-top: 8px solid $modrn-blue-dark-90;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
    }
  }

  &.v-tooltip-right {
    &::before {
      left: -7px;
      border-top: 12px solid transparent;
      border-right: 8px solid $modrn-blue-dark-90;
      border-bottom: 12px solid transparent;
    }
  }

  &.v-tooltip-bottom {
    &::before {
      top: -7px;
      border-right: 12px solid transparent;
      border-bottom: 8px solid $modrn-blue-dark-90;
      border-left: 12px solid transparent;
    }
  }

  &.v-tooltip-left {
    &::before {
      right: -7px;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 8px solid $modrn-blue-dark-90;
    }
  }
}
</style>
